import React from "react";
import { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import Table from "../Table";

const AllLiveSessionsComp = ({
  tableData = [],
  pageHeading = "All Live Sessions",
  tableHeading = [],
  handleTablePageChange,
  handlePublishSessionClick,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  handleEditLiveSession,
}) => {
  const [rowData, setRowData] = useState([]);

  // const viewContentFormatter = (cell, row) => {
  //   return (
  //     <div style={{ textAlign: "center" }} key={row._id}>
  //       <i
  //         className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
  //         onClick={() => handleViewSeries(row._id)}
  //       />
  //       <i
  //         className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
  //         onClick={() => handleEditLiveSession(row._id)}
  //       />
  //     </div>
  //   );
  // };

  const viewSeriesFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handlePublishSessionClick(row._id)}
        >
          End Live
        </Button>
      </div>
    );
  };

  useEffect(() => {
    // tableHeading.push({
    //   text: "Actions",
    //   dataField: "view",
    //   isDummyField: true,
    //   headerStyle: { textAlign: "center", width: "10%" },
    //   formatter: viewContentFormatter,
    // });
    tableHeading.push({
      text: "Undo Live Sessions",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewSeriesFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = {
          ...el,
          name: el.title,
          description: el.excerpt?.replace(/<\/?p>/g, ""),
        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllLiveSessionsComp;
