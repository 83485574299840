import axios from "axios";

import baseDomain, {
    getAllOffers,
    createOffer,
    deleteOffer,
    getOffersById,
    updateOffer
} from "../../../../Constants/apiRoutes";

export const getAllOfferReq = () => {
    return axios.get(`${baseDomain}${getAllOffers}`);
};
export const createOfferReq = (payload) => {
    return axios.post(`${baseDomain}${createOffer}`, payload);
};
export const updateOfferReq = (offerId,payload) => {
    return axios.put(`${baseDomain}${updateOffer}/${offerId}`,payload);
};
export const editOfferReq = (offerId) => {
    return axios.get(`${baseDomain}${getOffersById}/${offerId}`)
}
export const deleteOfferReq = (offerId) => {
    return axios.delete(`${baseDomain}${deleteOffer}/${offerId}`)
}