import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import QuotesCard from "../../../../../../Components/QuotesCard";
import CreateQuoteModel from "../../../../../../Modal/CreateQuotes";
import QuoteInfo from "../../../../../../Modal/QuoteInfo";

import { getQuote, deleteQuote, searchQuote } from "../../Quotes.Apis";
import { quoteHeading } from "../../Quotes.Constants";

const AllQuotes = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isQuoteInfoOpen, setIsQuoteInfoOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  });
  const { currentPage, totalSize } = paginationValue;
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [quoteInfo, setQuoteInfo] = useState({
    quote: "",
    isPriority: false,
  });

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);
  const toggleQuoteInfo = () => setIsQuoteInfoOpen(!isQuoteInfoOpen);

  // Delete Tags
  const handleDeleteClick = (id) => {
    setDeleteID(id);
    setShowDeleteAlert(true);
  };

  const deleteTagAction = async () => {
    await deleteQuote(deleteID);
    const filteredQuotes = quotes.filter(({ _id }) => _id !== deleteID);
    setQuotes(filteredQuotes);
    setDeleteID("");
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  };

  // Get initial  data
  const getData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getQuote(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setQuotes(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const getSearchData = async (text) => {
    try {
      const { data } = await searchQuote(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) {}
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleQuoteInfo = (item) => {
    setQuoteInfo({
      quote: item.text,
      isPriority: item.isPriority,
    });
    toggleQuoteInfo();
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  // Populate created tag
  const populateCreatedTag = (quote) => {
    setQuotes([quote, ...quotes]);
  };

  const select_quote = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>All Quotes</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Quote"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_quote}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleQuoteInfo(item)}
                      >
                        <div>{item ? item.text : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <QuotesCard
        openModel={toggleCreateModel}
        tableData={quotes}
        tableHeading={quoteHeading}
        handleDeleteTag={handleDeleteClick}
        setQuotes={setQuotes}
        allQuotes={true}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      <QuoteInfo
        quote={quoteInfo.text}
        isPriority={quoteInfo.isPriority}
        isQuoteInfoOpen={isQuoteInfoOpen}
        toggleQuoteInfo={toggleQuoteInfo}
      />

      <CreateQuoteModel
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        populateTag={populateCreatedTag}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteTagAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllQuotes;
