import React, { useState, useLayoutEffect } from "react";
import ReactApexChart from "react-apexcharts";

import { getPlanAlalysisGraphConfig } from "../../PlanAnalysysCard.helpers";

const PlanAnalysisCardGraph = ({ series, labels }) => {
  const [graphSettings, setGraphSettings] = useState({
    series: null,
    options: null,
  });

  useLayoutEffect(() => {
    const settings = getPlanAlalysisGraphConfig({ series, labels });
    setGraphSettings(settings);
  }, [series, labels]);

  return (
    <div id="donut-chart" className="apex-charts">
      {graphSettings.options && graphSettings.series && (
        <ReactApexChart
          options={graphSettings.options}
          series={graphSettings.series}
          type="donut"
          height="230"
        />
      )}
    </div>
  );
};

export default PlanAnalysisCardGraph;
