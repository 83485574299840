import { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import {
  createTag,
  getAllTags,
} from "../../Views/Dashboard/Components/Blogs/Api";

import "./style.scss";

const TagsInput = ({ tags, tagsId, setTagsId }) => {
  const [tag, setTag] = useState([]);
  const [userTag, setUserTag] = useState(tags);

  const getTags = async () => {
    const { data } = await getAllTags();
    setTag(
      data?.data?.map((item) => {
        return { ...item, text: item?.name, id: item?._id };
      })
    );
  };
  useEffect(() => {
    getTags();
  }, []);

  const handleDeleteTags = (i) => {
    setUserTag(userTag?.filter((tags, index) => index != i));
    setTagsId(tagsId?.filter((tags, index) => index != i));
  };

  const handleTagsAddition = async (tags) => {
    if (tags?.objectType) {
      setUserTag([...userTag, tags]);
      setTagsId([...tagsId, tags?.id]);
    } else {
      const { data } = await createTag({
        name: tags.text,
        objectType: "BLOGTAG",
        description: "Blogtag",
      });

      setUserTag([...userTag, { text: data.data.name, id: data.data._id }]);
      setTagsId([...tagsId, data.data._id]);
    }
  };

  return (
    <div>
      <ReactTags
        handleAddition={handleTagsAddition}
        suggestions={tag}
        tags={userTag}
        handleDelete={handleDeleteTags}
      />
    </div>
  );
};

export default TagsInput;
