const sMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const getGraphConfig = ({
  subscriptionData,
  usersData,
  activeData,
  labels,
}) => {
  return {
    series: [
      {
        name: "Subscriptions",
        type: "line",
        data: subscriptionData,
      },
      {
        name: "Users",
        type: "line",
        data: usersData,
      },
      {
        name: "Active Users",
        type: "line",
        data: activeData,
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [3, 3, 3],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        show: true,
        min: 0,
      },
      legend: {
        show: false,
      },
      colors: ["#1cbb8c", "#5664d2", "#ff3d60"],
      labels: labels,
    },
  };
};

export const sortGraphData = (data) => {
  const subscriptionData = [];
  const usersData = [];
  const activeData = [];
  const labels = [];

  if (data.length !== 0) {
    data.forEach(
      ({
        avgActiveUsersCount,
        avgSubscribedUsersCount,
        avgUsersCount,
        date,
      }) => {
        subscriptionData.push(avgSubscribedUsersCount | 0);
        usersData.push(avgUsersCount | 0);
        activeData.push(avgActiveUsersCount | 0);
        labels.push(`${date.dayOfMonth}, ${sMonths[date.month]}`);
      }
    );
  }
  return {
    subscriptionData,
    usersData,
    activeData,
    labels,
  };
};

export const getNDaysOldDate = (n) => {
  return new Date(new Date().getTime() - n * 24 * 60 * 60 * 1000);
};
