export const PublishedNutriDietTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Title",
    dataField: "title",
  },
  {
    text: "Advisor",
    dataField: "description",
  },
];

export const UnPublishedNutriDietTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Title",
    dataField: "title",
  },
  {
    text: "Advisor",
    dataField: "description",
  },
];

export const fileTypes = {
  NUTRI_DIET_IMAGE: "NUTRI_DIET_IMAGE",
};

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOAD_COMPLETE: 4,
};
