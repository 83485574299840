import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AllUserByHomeSessionsComp from "../../../../../../../../Components/AllUserByHomeSess";
import { getAllUserByHomeSessionsIdApi, getAllUserByHomeSessionsIdApiCSV } from "../../../Constants/api";
import { NutriHomeHeading } from "../../../Constants/table";

const AllUserByHomeSessions = () => {
  const [homeSessionsUser, setHomeSessionsUser] = useState();
  const { sessionsId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;

  const convertToIST = (isoString) => {
    const date = new Date(isoString);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(date.getTime() + istOffset);

    const day = String(istDate.getUTCDate()).padStart(2, '0');
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0');
    const year = istDate.getUTCFullYear();

    const hours = String(istDate.getUTCHours()).padStart(2, '0');
    const minutes = String(istDate.getUTCMinutes()).padStart(2, '0');
    const values = [minutes, hours, year, month, day];
    if (values.some(isNaN)) {
      return '-'
    }
    else {
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
  };
  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }

  const getAllUserByHomeSessionsCSVData = async (id) => {
    let payload = { yogaClassId: id.id }
    if (startDate) {
      const startOfDay = startDate; // Create a new Date object with the current date and time
      startOfDay.setHours(0, 0, 0, 1);
      const isoStringStart = startOfDay.toISOString();
      payload['startDate'] = formatDate(isoStringStart)
    }
    else {//if there is no date
      const startOfDay = new Date(); // Create a new Date object with the current date and time
      startOfDay.setHours(0, 0, 0, 1);
      const isoStringStart = startOfDay.toISOString();
      payload['startDate'] = isoStringStart
    }
    if (endDate) {
      const endOfDay = endDate; // Create a new Date object with the current date and time
      endOfDay.setHours(23, 59, 59, 999);
      const isoStringEnd = endOfDay.toISOString();
      payload['endDate'] = isoStringEnd
    }
    else {//if there is no date
      const endOfDay = new Date(); // Create a new Date object with the current date and time
      endOfDay.setHours(23, 59, 59, 999);
      const isoStringEnd = endOfDay.toISOString();
      payload['endDate'] = isoStringEnd
    }

    const { data } = await getAllUserByHomeSessionsIdApiCSV(payload);
    setCsvData(data.data)
  }

  const getAllUserByHomeSessionsIdData = async (params) => {
    try {
      setIsLoading(true);
      let payload = params
      if (startDate) {
        const startOfDay = startDate; // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = formatDate(isoStringStart)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }
      if (endDate) {
        const endOfDay = endDate; // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      console.log(payload);

      const { data } = await getAllUserByHomeSessionsIdApi(payload);
      // setHomeSessionsUser(data?.data)
      console.log(data.data, "getAllUserByHomeSessionsIdApi");
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });

      let tempArr = data?.data?.map((item) => {
        return {
          userName: item?.userForm?.participantName,
          userEmail: item?.userId?.email,
          userContact: item?.userId?.phoneNumber,
          appDate: convertToIST(item?.time?.startDate),  // Convert to IST
          appTime: convertToIST(item?.time?.sessionTime), // Convert to IST
          plan: item?.planId?.plan_description,
          price: item?.planId?.plan_display_price,
          title: item?.nutriId?.title,
          advisorName: item?.supportAdvisorId?.name,
          endDate: item?.expDate,
          userId: item?._id,
          meetingLink: item?.time?.appointmentJoiningLink,
          planDesc: item?.planId?.plan_description
        };
      });
      console.log(tempArr, "tempArr");
      setIsLoading(false);
      setHomeSessionsUser(tempArr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllUserByHomeSessionsIdData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };
  console.log(sessionsId, 'session vali id')
  useEffect(() => {
    setStartDate(new Date())
    setEndDate(new Date())
    getAllUserByHomeSessionsIdData({ id: sessionsId, page: 1, limit: 10 })
    getAllUserByHomeSessionsCSVData({ id: sessionsId })
  }, [])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const handleStartDateChange = (date) => {
    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }
  const [csvData, setCsvData] = useState([])

  const handleAllExportToCSV = () => {
    const csvDataAll = csvData?.map((item) => ({
      user: item?.userForm?.participantName,
      Email: item?.userId?.email,
      Contact: item?.userId?.phoneNumber,
      'Start-Date': convertToIST(item?.time?.startDate),  // Convert to IST
      'End-Date': item?.expDate,  // Convert to IST
      'Time': convertToIST(item?.time?.sessionTime), // Convert to IST
      Plan: item?.planId?.plan_description,
      Price: item?.planId?.plan_display_price,
      'Meeting Link': item?.time?.appointmentJoiningLink,
    }));

    return csvDataAll;
  }
  const handleConfirmClick = async () => {
    getAllUserByHomeSessionsIdData({ id: sessionsId, page: 1, limit: 10 })
    getAllUserByHomeSessionsCSVData({ id: sessionsId })
  }
  console.log(homeSessionsUser, 'homesessionuser')

  return (
    <AllUserByHomeSessionsComp
      tableData={homeSessionsUser}
      tableHeading={NutriHomeHeading}
      handleTablePageChange={handleTablePageChange}
      isLoading={isLoading}
      page={currentPage}
      sizePerPage={10}
      totalSize={totalSize}
      sessionsId={sessionsId}
      startDate={startDate}
      endDate={endDate}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
      handleConfirmClick={handleConfirmClick}
      handleAllExportToCSV={handleAllExportToCSV}
    />
  );
};

export default AllUserByHomeSessions;
