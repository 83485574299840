import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  // tagTypes,
  tagsTableHeading,
  categorieTableHeading,
  
} from "../../Faq.constants";

import {
  restoreTag,
  getFaqBin,
  getCategoryBin,
  getTagsBin,
} from "../../Faq.Apis";
import FaqCard from "../../../../../../Components/FaqCard";


const TagsBin = () => {
  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  // const [currentTag, setCurrentTag] = useState(tagTypes.TAG);
  const [restoreSettings, setRestoreSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });

  const [dataTags, setDataTags] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataSubcategory, setDataSubcategory] = useState([]);

  const [pageData, setPageData] = useState({
    tagsData: dataTags,
    categoryData: dataCategory,
    subCategoryData: dataSubcategory,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  });
  const { currentPageTags, totalSizeTags } = paginationValueTags;

  const [paginationValueCat, setPaginationValueCat] = useState({
    currentPageCat: 1,
    totalSizeCat: 20,
  });
  const { currentPageCat, totalSizeCat } = paginationValueCat;

  const [paginationValueSubcat, setPaginationValueSubcat] = useState({
    currentPageSubcat: 1,
    totalSizeSubcat: 20,
  });
  const { currentPageSubcat, totalSizeSubcat } = paginationValueSubcat;

  useEffect(() => {
    setPageData({
      ...pageData,
      tagsData: dataTags,
    });
    // eslint-disable-next-line
  }, [dataTags]);

  useEffect(() => {
    setPageData({
      ...pageData,
      categoryData: dataCategory,
    });
    // eslint-disable-next-line
  }, [dataCategory]);

  useEffect(() => {
    setPageData({
      ...pageData,
      subCategoryData: dataSubcategory,
    });
    // eslint-disable-next-line
  }, [dataSubcategory]);

  // const { tagsData, categoryData, subCategoryData } = pageData;

  // Restore Tags
  const handleRestoreTagClick = (id) => {
    setRestoreSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setShowRestoreAlert(true);
  };
  const handleRestoreCategoryClick = (id) => {
    setRestoreSettings({
      tagID: id,
      tagArray: "categoryData",
    });
    setShowRestoreAlert(true);
  };
  
  const restoreTagAction = async () => {
    await restoreTag(restoreSettings.tagID);
    const filteredTags = pageData[restoreSettings.tagArray].filter(
      ({ _id }) => _id !== restoreSettings.tagID
    );
    setPageData({
      ...pageData,
      [restoreSettings.tagArray]: filteredTags,
    });
    restoreSettings.tagArray === "tagsData" && setDataTags(filteredTags);
    restoreSettings.tagArray === "categoryData" &&
      setDataCategory(filteredTags);
    restoreSettings.tagArray === "subCategoryData" &&
      setDataSubcategory(filteredTags);
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  // Get initial  data
  const getTagsData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getTagsBin(queryParams);
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      });
      setDataTags(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTagsData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    getTagsData({ page, limit: sizePerPage });
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageTags: page, 
    });
  };

  const getCategoryData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getCategoryBin(queryParams);
      setPaginationValueCat({
        currentPageCat: queryParams.page,
        totalSizeCat: data.count,
      });
      setDataCategory(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCategoryData({ page: 1, limit: 10 });
  }, []);

  const handleTablePageChangeCat = (type, { page, sizePerPage }) => {
    getCategoryData({ page, limit: sizePerPage });
    setPaginationValueCat({
      ...paginationValueCat,
      currentPageCat: page,
    });
  };

 



  return (
    <>
      <FaqCard
        heading="Tags Bin"
        tableData={dataTags}
        tableHeading={tagsTableHeading}
        handleRestoreTag={handleRestoreTagClick}
        handleTablePageChange={handleTablePageChangeTags}
        page={currentPageTags}
        sizePerPage={10}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        allTags={false}
      />

      <FaqCard
        heading="Categories Bin"
        tableData={dataCategory}
        tableHeading={categorieTableHeading}
        handleRestoreTag={handleRestoreCategoryClick}
        handleTablePageChange={handleTablePageChangeCat}
        page={currentPageCat}
        sizePerPage={10}
        totalSize={totalSizeCat}
        isLoading={isLoading}
        allTags={false}
      />



      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore it!"
          reverseButtons={true}
          onConfirm={restoreTagAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
          }}
        >
          File has been restored
        </SweetAlert>
      ) : null}
    </>
  );
};

export default TagsBin;
