import React from "react";
import StoryCardPublish from "../../../../../../Components/StoryCardPublish";
import StoryCardUnpublish from "../../../../../../Components/StoryCardUnpublish";
import {
  getPublishedStoryApi,
  getUnPublishedStoryApi,
  publishStory,
  unpublishStory,
} from "../../Story.Apis";
import { useState, useEffect } from "react";
import { storyTabPublish, storyTabUnpublish } from "../../Story.Constants";
import SweetAlert from "react-bootstrap-sweetalert";

const AllStories = () => {
  const [errorRes, setErrorRes] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [storyData, setStoryData] = useState([]);
  const [storyDataUnpublish, setStoryDataUnpublish] = useState([]);
  const [publishID, setPublishID] = useState("");
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  });
  const { currentPage, totalSize } = paginationValue;
  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  });
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue;
  const [isLoading, setIsLoading] = useState(false);

  const getAllPublishedStories = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getPublishedStoryApi(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setStoryData(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUnPublishedStories = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getUnPublishedStoryApi(queryParams);
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setStoryDataUnpublish(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPublishedStories({ page: 1, limit: 10 });
    getAllUnPublishedStories({ page: 1, limit: 10 });
  }, []);

  const handleUnpublishStoryClick = async (storyId) => {
    setUnpublishId(storyId);
    setShowUnPublishAlert(true);
  };
  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllPublishedStories({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };
  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllUnPublishedStories({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };

  const unpublishStoryAction = async () => {
    try {
      await unpublishStory(unpublishId);
      const filteredMusics = storyData.filter(({ _id }) => _id !== unpublishId);
      setStoryData(filteredMusics);
      setUnpublishId("");
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error.response.data.error, "error.response.data.error");
      setErrorRes(error.response.data.error);
      setShowErr(true);
    }
  };

  const handlePublishStoryClick = async (storyId) => {
    setPublishID(storyId);
    setShowPublishAlert(true);
  };

  const publishStoryAction = async () => {
    try {
      await publishStory(publishID);
      setPublishID("");
      setShowPublishAlert(false);
    } catch (error) {}
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Stories</h2>
      </div>

      <StoryCardPublish
        tableData={storyData}
        tableHeading={storyTabPublish}
        unpublishStory={handleUnpublishStoryClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
          onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={() => {unpublishStoryAction()
                         setShowUnPublishAlert(false)}}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        ></SweetAlert>
      ) : null}

      <StoryCardUnpublish
        tableData={storyDataUnpublish}
        tableHeading={storyTabUnpublish}
        publishStory={handlePublishStoryClick}
        handleTablePageChange={handleUnpublishedTablePageChange}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        unpublishedTotalSize={unpublishedTotalSize}
        isLoading={isLoading}
      />

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={() => {
            publishStoryAction();
            setShowPublishConfirm(true);
          }}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published"
          onConfirm={() => {
            setShowPublishAlert(false);
            setShowPublishConfirm(false);
            window.location.reload();
          }}
        ></SweetAlert>
      ) : null}
    </>
  );
};

export default AllStories;
