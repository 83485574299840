import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
const OfferModal = ({
  isOpen,
  toggleModal,
  offerData,
  setOfferData,
  offerCode,
  couponDescription,
  discountPrice,
  expireTime,
  countryCode,
  handleUpdateOffer,
  objectType,
}) => {
  const handleClose = () => {
    toggleModal();
  };
  const countryCodeData = [
    { key: "INTERNATIONAL", label: "INTERNATIONAL", value: "INTERNATIONAL" },
    { key: "NATIONAL", label: "NATIONAL", value: "NATIONAL" },
  ];
  const offerTypeData = [
    { key: "flat", label: "flat", value: "flat" },
    { key: "percentage", label: "percentage", value: "percentage" },
  ];

  const handleCountryCode = (select) => {
    setOfferData({ ...offerData, countryCode: select.value });
  };

  const handleOfferType = (select) => {
    setOfferData({ ...offerData, objectType: select.value });
  };
  return (
    <>
      <Modal size="lg" isOpen={isOpen} backdrop="static">
        <AvForm className="needs-validation">
          <ModalHeader>Edit offer</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="offerCode">Offer code</Label>
                  <AvField
                    name="offerCode"
                    type="text"
                    className="form-control"
                    value={offerCode}
                    onChange={(e) =>
                      setOfferData({ ...offerData, offerCode: e.target.value })
                    }
                    id="offerCode"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="Description">Description</Label>
                  <AvField
                    name="Description"
                    type="text"
                    className="form-control"
                    value={couponDescription}
                    onChange={(e) =>
                      setOfferData({
                        ...offerData,
                        couponDescription: e.target.value,
                      })
                    }
                    id="couponDescription"
                    // disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="offerCode">Offer Type</Label>
                  <Select
                    value={objectType}
                    placeholder={objectType}
                    isMulti={false}
                    onChange={handleOfferType}
                    options={offerTypeData}
                    classNamePrefix="select1-selection"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="discountPrice">Discount</Label>
                  <AvField
                    name="discountPrice"
                    type="text"
                    className="form-control"
                    value={discountPrice}
                    onChange={(e) =>
                      setOfferData({
                        ...offerData,
                        discountPrice: e.target.value,
                      })
                    }
                    id="discountPrice"
                    // disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="expireTime">Expire Time</Label>
                  <AvField
                    name="expireTime"
                    type="date"
                    className="form-control"
                    value={expireTime.split("T")[0]}
                    onChange={(e) =>
                      setOfferData({ ...offerData, expireTime: e.target.value })
                    }
                    id="expireTime"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="countryCode">Country Code</Label>
                  <Select
                    value={countryCode}
                    placeholder={countryCode}
                    isMulti={false}
                    onChange={handleCountryCode}
                    options={countryCodeData}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={handleUpdateOffer}
              color="light"
              className="waves-effect"
            >
              Update
            </Button>
            <Button
              type="button"
              onClick={handleClose}
              color="light"
              className="waves-effect"
            >
              Close
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  );
};

export default OfferModal;
