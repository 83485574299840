// import { Component } from 'react'
import AllMasterClass from '../Components/AllMaster'

import CreateMasterClass from '../Components/CreateMaster'
import EditMasterClass from '../Components/EditMaster'
import AllUserMasterId from '../Components/UserMaster/AllUserMaster'
import EditUserMaster from '../Components/UserMaster/EditUserMaster'

const MasterClassRoutes = [
  {
    Component: AllMasterClass,
    path: '/all',
    exact: true,
    id: 'dashboardMasterAll',
    routePath: '/dashboard/master-class/all',
    name: 'All Master Class',
  },
  {
    Component: CreateMasterClass,
    path: '/create',
    exact: true,
    id: 'dashboardMasterCreate',
    routePath: '/dashboard/master-class/create',
    name: 'Create Master Class',
  },
  {
    Component: EditMasterClass,
    path: '/edit/:masterId',
    exact: true,
    id: 'dashboardMasterEdit',
  },
  {
    Component: AllUserMasterId,
    path: '/user',
    exact:true,
    id:'dashboardMasterUser',
    routePath:'/dashboard/master-class/user',
    name:'All MasterClass Users'
  },
  {
    Component: EditUserMaster,
    path: '/master-user/:sessionsId',
    routePath:'/dashboard/master-class/master-user',
    exact:true,
    id:'dashboardMasterUserEdit'
  }
]

export default MasterClassRoutes