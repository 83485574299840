import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";
import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
const VideoCard = ({
  heading,
  tableHeading,
  tableData,
  handleViewVideoClick,
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();
  const viewVideoFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => {
            handleViewVideoClick(row._id);
          }}
        />
      </div>
    );
  };
  useEffect(() => {
    tableHeading.push({
      text: "View info",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewVideoFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData) {
      var result = tableData.map((el, i) => {
        var o = Object.assign({ ...el });
        o.sNo = i + 1;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm className="form-horizontal">
              <Table
                rows={rowData}
                columns={tableHeading}
                page=""
                totalSize="0"
              />
              <Button
              className="w-100 mt-4"
              onClick={()=>history.push("/dashboard/ytVideos/add")}
             >Add video
             </Button>
              </AvForm>
            </CardBody>
            
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VideoCard;
