import React from 'react'
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const UnpublishedPlaylist = ({
  heading,
  tableData=[],
  tableHeading=[],
  page,
  buttonName,
  publishPlaylist,
  sizePerPage,
  totalSize
}) => {
  
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  const handleEditUserClick = (playlistId) => {
    history.push("/dashboard/playlist/editplaylist/" + playlistId);
  };

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => {
            
            handleEditUserClick(row._id)
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });

    tableHeading.push({
      text: "Publish playlist",
      dataField: "publish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: unpublishFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
  }, []);

  const unpublishFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => {
            publishPlaylist(row._id);
          }}
        >
          {buttonName}
        </Button>
      </div>
    );
  };
  useEffect(() => {
    if (tableData) {
      var result = tableData.map((el, i) => {
        var o = Object.assign({ ...el });
        o.sNo = i + 1 ;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);
  return (
    <>
     <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                // onTableChange=''
                // page={page}
                // sizePerPage={sizePerPage}
                // totalSize={totalSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    
    </>
  )
}

export default UnpublishedPlaylist
