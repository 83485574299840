export const quoteHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Quotes",
    dataField: "text",
  },
  {
    text: "Object Type",
    dataField: "objectType",
  }
];
