import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const NotificationInfo = ({ isNotiInfoOpen, toggleNotiInfoModal, title, description, objectType}) => {

  const handleClose = () => {
    toggleNotiInfoModal();
  };


  return (
    <Modal size="lg" isOpen={isNotiInfoOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Notification Information</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <AvField
                  name="title"
                  type="text"
                  className="form-control"
                  value={title}
                  id="title"
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">
                  Description
                </Label>
                <AvField
                  name="description"
                  type="text"
                  className="form-control"
                  value={description}
                  id="description"
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="objectType">
                  Notification Type
                </Label>
                <AvField
                  name="objectType"
                  type="text"
                  className="form-control"
                  value={objectType}
                  id="objectType"
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default NotificationInfo;
