import React, { useState } from "react";
import FileUploader from "../FileUploader";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  FormGroup,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

const EditSeriesForm = ({
  pageHeading = "Edit Series",
  createBtnText = "Edit Series",
  title,
  description,
  formData,
  setFormData,
  handleChange,
  getAllCategoryOptions,
  seriesImage,
  setSeriesImage,
  mediaType,
  setEditedData,
  setInputList,
  mediaData,
  MediaType,
  getAllMediaOptions,
  handleOnChangeMedia,
  handleSubmit,
  handleDropdownChange,
  isDataUpdated,
  categoryName,
  handleListAdd,
  inputList,
  mediaDataFilled,
  handleMediaOnChange,
  selectedTags,
  setSelectedTags,
  tagsData
}) => {
  let tagsObj =
  tagsData &&
  tagsData?.map((i) => {
    return {
      key: i._id,
      value: i._id,
      label: i.name,
    };
  });

  console.log(categoryName, 'categoryName');
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handleChange}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={seriesImage}
                    setFile={setSeriesImage}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handleChange}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: false } }}
                  id="description"
                />
              </FormGroup>
              <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="lessons">Tags</Label>
                      <Select
                        value={selectedTags}
                        isMulti={true}
                        onChange={(selected) => setSelectedTags(selected)}
                        options={tagsObj}
                        classNamePrefix="select2-selection"
                        placeholder="Select Tags"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              <Row>
                <Col ls={6}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Select Category</Label>
                    <Input
                      name="category"
                      placeholder="Select Type"
                      type="select"
                      value={formData.category}
                      onChange={(e) => setFormData({
                        ...formData,
                        category: e.target.value
                      })}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value="">Select Category</option>
                      {getAllCategoryOptions?.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>{item.name}</option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col ls={6}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Select Media Type</Label>
                    <Input
                      name='mediaType'
                      type="select"
                      isMulti={false}
                      value={mediaType}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mediaType: e.target.value
                        })
                      }}
                      options={MediaType}
                      classNamePrefix="select2-selection"
                      placeholder="Select Media Type"
                    >
                      <option value="">Select Media Type</option>
                      <option value="AUDIO">Mind</option>
                      <option value="VIDEO">Body</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Media</Label>
                    <Select
                      value={mediaDataFilled}
                      isMulti={true}
                      onChange={(e)=>{handleMediaOnChange(e)}}
                      
                      options={getAllMediaOptions}
                      classNamePrefix="select2-selection"
                      placeholder={
                        getAllMediaOptions?.length > 0
                          ? "Loading..."
                          : "Select Media Data"
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
             
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={!isDataUpdated ? true : false}
              >
                {createBtnText}
              </Button>
            </AvForm>
            <br />
            <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role="alert">
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default EditSeriesForm;

