import { useState, useEffect } from "react";
import CreateLessonForm from "../../../../../../Components/CreateLessonForm";
import UploadingLessonLoader from "../../../../../../Components/UploadingLessonLoader";
import { creatingStates, fileTypes } from "../../Constants/constant";
import { uploadFile } from "../../lessons.Constants";
import {
  getLessionbyIdApi,
  transCodingApi,
  updateLessonApi,
} from "../../lessons.Api";
import { useParams } from "react-router-dom";

const EditLesson = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    imgAsset: "",
    assetUrl: "",
    duration: "",
    oriUrl: "",
    objectType: "VIDEO",
  });

  const handleChangeThumbnail = (thumbFile) => {
    setIsDisabledBtn(false);
    setThumbnail(thumbFile);
  };
  const handleChangeThumbnailVideo = (thumbVideo) => {
    setIsDisabledBtn(false);
    setVideo(thumbVideo);
  };

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const { title, description, objectType } = formData;
  const params = useParams();
  const idval = params.lessonId;

  const getLessonId = async () => {
    try {
      const { data } = await getLessionbyIdApi(idval);
      setFormData({
        title: data.data.title,
        description: data.data.description,
        // imgAsset: data.data.imgAsset,
        // assetUrl: data.data.assetUrl,
        // duration: data.data.title,
        // oriUrl: data.data.title,
        // objectType: data.data.objectType,
      });
      setThumbnail(data.data.imgAsset);
      setVideo(data.data.assetURL);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLessonId();
  }, []);

  // Handle form IP
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // reset page
  const reset = () => {
    setThumbnail(null);
    setVideo(null);
    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      description: "",
      imgAsset: "",
      assetUrl: "",
      duration: "",
      oriUrl: "",
      objectType: "VIDEO",
    });

    setThumbnailUploadPercentage(0);
    setVideoUploadPercentage(0);
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  // Create VIDEO
  const handleSubmit = async () => {
    try {
      let thumbnailUrl = thumbnail;
      let videoUrl = video;
      // if (thumbnail || !video)
      if (thumbnail && typeof thumbnail !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        thumbnailUrl = await uploadFile(
          thumbnail,
          fileTypes.TRACK_IMAGE,
          setThumbnailUploadPercentage,
          10
        );
      }

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_VIDEO,
      });
      if (video && typeof video !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        videoUrl = await uploadFile(
          video,
          "TRACK_VIDEO",
          setVideoUploadPercentage,
          50
        );
      }
      // const videoDuration = await getAudioDuration(video);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_VIDEO,
      });
      // -------------
      // if (typeof thumbnail !== 'string') {
      //   setCreating({
      //     isCreating: true,
      //     creatingStage: creatingStates.UPLOADING_IMAGE,
      //   });
      //   thumbnailUrl = await uploadFile(
      //     thumbnail,
      //     fileTypes.TRACK_IMAGE,
      //     setThumbnailUploadPercentage,
      //     40
      //   );
      // } else setThumbnailUploadPercentage(40);

      // setCreating({
      //   isCreating: true,
      //   creatingStage: creatingStates.UPLOADING_VIDEO,
      // });
      // ------------

      const uploadData = {
        title,
        description,
        assetURL: videoUrl,
        oriUrl: videoUrl,
        imgAsset: thumbnailUrl,
        objectType: "VIDEO",
      };

      const { data } = await updateLessonApi({ uploadData, idval });

      console.log(data, "updated");
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      await transCodingApi({
        assetURL: data?.data?.assetURL,
        title: data?.data?.title,
        description: data?.data?.description,
        assetImage: data?.data?.imgAsset,
        id: data?.data?._id,
        type:'LESSON'
      });
    } catch (error) {
      console.log(error);
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setVideoUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to update Lesson please try again later",
        errorType: "danger",
      });
    }
  };

  return (
    <div>
      {isCreating ? (
        <UploadingLessonLoader
          currentStep={creatingStage}
          thumbnailuploadPercentage={thumbnailuploadPercentage}
          videoUploadPercentage={videoUploadPercentage}
          reset={reset}
        />
      ) : (
        <CreateLessonForm
          handelInput={handelInput}
          title={title}
          objectType={objectType}
          pageHeading={"Edit Lesson"}
          createBtnText={"Update Lesson"}
          description={description}
          thumbnail={thumbnail}
          setThumbnail={handleChangeThumbnail}
          video={video}
          setVideo={handleChangeThumbnailVideo}
          handleSubmit={handleSubmit}
          isError={isError}
          errorMessage={errorMessage}
          errorType={errorType}
        />
      )}
    </div>
  );
};

export default EditLesson;
