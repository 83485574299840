import React, { useEffect, useState } from "react";
import CreateTimeSlotComp from "../../../../../../Components/CreateTimeSlotComp";
import { getAllSupportAdvisorApi } from "../../../MasterClass/Constants/api";
import { createTimeSlotsApi } from "../../Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

const CreateTimeSlots = () => {
  const history = useHistory();
  const [advisorData, setAdvisorData] = useState();
  const [allAdvisor, setAllAdvisor] = useState();
  const [allTime, setAllTime] = useState({});
  const [timeAdvisorData, setTimeAdvisorData] = useState([]);
  const [allIsBooked, setAllIsBooked] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [timeAdvisorBack, setTimeAdvisorBack] = useState([]);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [formData, setFormData] = useState({
    supportAdvisorId: "",
    date: "",
    isBooked: "",
    time: "",
  });

  const { supportAdvisorId, date, isBooked, time } = formData;

  const getAllAdvisorData = async () => {
    const { data } = await getAllSupportAdvisorApi();
    setAdvisorData(data?.data);
    console.log(data.data, "advisorOption");
  };

  useEffect(() => {
    getAllAdvisorData();
  }, []);

  const addTimeNFree = () => {
    try {
      let be = new Date(selectedDate);
      let nnn = be.getUTCMonth() + 1 <= 9 ? `0${be.getUTCMonth() + 1}` : be.getUTCMonth() + 1;
      let formattedDay = be.getUTCDate() <= 9 ? `0${be.getUTCDate()}` : be.getUTCDate() + 1;
      let a = `${be.getUTCFullYear()}-${nnn}-${formattedDay}T${allTime?.value}:00:00.000`;
  
      if (allTime?.value && allIsBooked?.value !== "") {
        setTimeAdvisorData([
          ...timeAdvisorData,
          {
            time: a?.slice(11, 16),
            isBooked: allIsBooked?.value,
          },
        ]);
        setTimeAdvisorBack([
          ...timeAdvisorBack,
          {
            time: a,
            // isBooked: false,
          },
        ]);
        setAllTime([]);
        setAllIsBooked([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  
  console.log(timeAdvisorBack, 'timeadvisorback')

  const removeTimeNFree = (indexToRemove) => {
    const updatedTimeNFree = [...timeAdvisorData];

    const newTime = updatedTimeNFree?.filter(
      (idx, index) => index !== indexToRemove
    );
    console.log(newTime, "updatedTimeNFree");
    setTimeAdvisorData(newTime);
  };

  const handleCreateTimeSlots = async () => {
    setShowUnPublishAlert(true);
     const dateFromTimeAdvisorBack = timeAdvisorBack[0]?.time.slice(0, 10);

      await createTimeSlotsApi({
        supportAdvisorId: allAdvisor?.value,
        date: dateFromTimeAdvisorBack,
        slots: timeAdvisorBack,
      });
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
  };
  console.log(timeAdvisorData, "timeAdvisorData");

  return (
    <>
      <CreateTimeSlotComp
        advisorData={advisorData}
        setAdvisorData={setAdvisorData}
        allAdvisor={allAdvisor}
        setAllAdvisor={setAllAdvisor}
        formData={formData}
        setFormData={setFormData}
        supportAdvisorId={supportAdvisorId}
        date={date}
        isBooked={isBooked}
        allTime={allTime}
        setAllTime={setAllTime}
        allIsBooked={allIsBooked}
        setAllIsBooked={setAllIsBooked}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleCreateTimeSlots={handleCreateTimeSlots}
        time={time}
        addTimeNFree={addTimeNFree}
        timeAdvisorData={timeAdvisorData}
        removeTimeNFree={removeTimeNFree}
        setShowUnPublishAlert={setShowUnPublishAlert}
      />
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={() => {
            handleCreateTimeSlots();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/time-slots/all");
          }}
        >
          Time Slots for Advisor has been Created
        </SweetAlert>
      ) : null}
    </>
  );
};

export default CreateTimeSlots;
