import AllLiveSessions from '../Components/AllLiveSessions'
import EditLiveSession from '../Components/EditLiveSession'
import CreateLiveSession from '../Components/CreateLiveSession'

export const LiveSessionRoutes = [
  
  {
    Component: AllLiveSessions,
    path: "/all",
    exact: true,
    id: "dashboardLiveSessionsAll",
    routePath: "/dashboard/live-sessions/all",
    name:"All Live Sessions"
  },
  {
    Component: EditLiveSession,
    path: '/edit/:liveSessionId',
    exact: true,
    id: 'dashboardLiveSessionsEdit',
  },
  {
    Component: CreateLiveSession,
    path: "/create",
    exact: true,
    id: "dashboardLiveSessionsCreate",
    routePath: "/dashboard/live-sessions/create",
    name: "Create Live Session"
  }

]


