import React from "react";
// import ProgramsCard from "../../../../../../Components/AllProgramsCard";
// import { AllProgramsTableHeading } from "../../allPrograms.constants";
import { useState, useEffect } from "react";
import { deleteAdvisorById, getAllAdvisors } from "../../advisors.Apis";
import AdvisorsCard from "../../../../../../Components/AllAdvisorsCard";
import { AllAdvisorTableHeading } from "../../allAdvisorsConstant";
import SweetAlert from "react-bootstrap-sweetalert";
const AllAdvisors = () => {
  const [advisorData, setAdvisorData] = useState([]);

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = paginationValue;

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const getAllAdvisorData = async (queryParams) => {
    try {
      const { data } = await getAllAdvisors(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setAdvisorData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(advisorData)

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllAdvisorData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteID(id);
    setShowDeleteAlert(true);
  };

  const deleteAdvisor = async () => {

    await deleteAdvisorById(deleteID);
    const filteredAdvisors = advisorData.filter(
      ({ _id }) => _id !== deleteID
    );
    setAdvisorData(filteredAdvisors);
    setDeleteID("");
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  }

  useEffect(() => {
    getAllAdvisorData({ page: 1, limit: 10 });
  }, []);


  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <h2 style={{ margin: "0px" }}>All Advisors</h2>
      </div>

      {<AdvisorsCard
        tableData={advisorData}
        tableHeading={AllAdvisorTableHeading}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        handleDeleteAdv={handleDeleteClick}
      />}

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={() => {
            deleteAdvisor()
            
          }}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}
      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
          }}
        ></SweetAlert>
      ) : null}
    </>
  );

};

export default AllAdvisors;
