import './index.scss'
import { useState } from 'react'
import CopyLogo from '../../assets/images/copyIcon.png'
import TickMark from '../../assets/images/ticked.png'
import {
  Col, Row,
} from 'reactstrap'
const ImageCard = ({ item,key }) => {
  const [copyUrl,setCopyUrl] = useState('COPY URL' )
  
  const [copyIcon, setCopyIcon] = useState(0)
  const copyText = ( string )=> {

    navigator.clipboard.writeText(string)
    setCopyUrl('COPIED')
    setCopyIcon(1)
    
    setTimeout(() => { 
      setCopyUrl('COPY URL')
      setCopyIcon(0)
      
    },2000)
    // alert('Copied the text: ' + string)
  
  }

  return (
    
    <>             
      
      <Col xs={3} key={ key } className='imgCard' >
        <Row>
          <img className='upImages' src={item.image} alt={item.name}/>
        </Row>
        <Row>
          <Col className='copyUrl' onClick={()=>copyText(item.image)} >
            {copyUrl}
            { copyIcon===0?<img className='cLogo' src={CopyLogo} alt = '...' />
              :
              <img className='cLogo' src={TickMark} alt = '...' /> }
          </Col>
        </Row>
      </Col>             
    
    </>
  )
}

export default ImageCard
