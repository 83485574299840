import axios from "axios";

import baseDomain, { tagAPI, tagsAPI, categoryAPI, subcategoryAPI, tagsBinAPI, categoryBinAPI, subcategoryBinAPI, restoreTagAPI, searchTagAPI } from "../../../../Constants/apiRoutes";

export const getAllTags = () => {
  return axios.get(`${baseDomain}${tagAPI}`);
};

export const getMediaApi =({objectType,page,limit}) =>{
  return axios.get(`${baseDomain}/mediatags/get?objectType=${objectType}&page=${page}&limit= ${limit} `)
}

export const searchTag = (text) => {
  return axios.get(`${baseDomain}${searchTagAPI}/${text}`);
};

export const getTags = ({ page, limit }) => {
  return axios.get(`${baseDomain}${tagsAPI}?page=${page}&limit=${limit}`);
};

export const getCategory = ({ page, limit }) => {
  return axios.get(`${baseDomain}${categoryAPI}?page=${page}&limit=${limit}`);
};

export const getSubcategory = ({ page, limit }) => {
  return axios.get(`${baseDomain}${subcategoryAPI}?page=${page}&limit=${limit}`);
};

export const getTagsBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${tagsBinAPI}?page=${page}&limit=${limit}`);
};

export const getCategoryBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${categoryBinAPI}?page=${page}&limit=${limit}`);
};

export const getSubcategoryBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${subcategoryBinAPI}?page=${page}&limit=${limit}`);
};

export const deleteTag = (tagID) => {
  return axios.delete(`${baseDomain}${tagAPI}/${tagID}`);
};

export const restoreTag = (tagID) => {
  return axios.put(`${baseDomain}${restoreTagAPI}/${tagID}`);
};
