import React, { Fragment } from "react";
import {
  Link,
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import {
  Row,
  Col,
  // Alert,
  Container,
} from "reactstrap";

import { AuthRoutes } from "./Constants/routes";
import { headingSettings } from "./Constants/settings";

const Authentication = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/">
          <i className="mdi mdi-home-variant h2 text-white"></i>
        </Link>
      </div>

      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="h1">
                              Nispand
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            {headingSettings[location.pathname] &&
                              headingSettings[location.pathname].heading}
                          </h4>
                          <p className="text-muted">
                            {headingSettings[location.pathname] &&
                              headingSettings[location.pathname].subHead}
                          </p>
                        </div>

                        {/* {this.props.loginError && this.props.loginError ? (
                          <Alert color="danger">{this.props.loginError}</Alert>
                        ) : null} */}

                        <Switch>
                          <Redirect
                            exact
                            from={`${match.url}/`}
                            to={`${match.url}/login`}
                          />
                          {AuthRoutes.map(({ path, Component, exact, id }) => (
                            <Route
                              path={`${match.url}${path}`}
                              exact={exact}
                              component={Component}
                              key={id}
                            />
                          ))}
                          <Redirect to="/auth/login" />
                        </Switch>

                        <div className="mt-5 text-center">
                          {/* <p>
                            {headingSettings[location.pathname] &&
                              headingSettings[location.pathname].bottomMessage}
                            <Link
                              to={
                                headingSettings[location.pathname] &&
                                headingSettings[location.pathname].redirectLink
                              }
                              className="font-weight-medium text-primary"
                            >
                              {" "}
                              {headingSettings[location.pathname] &&
                                headingSettings[location.pathname].redirectText}
                            </Link>
                          </p> */}
                          <p>
                            © 2021 NexGen IoT Solutions. Crafted with &#10084;
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                {/* <div className="bg-overlay"></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Authentication;
