import Resizer from "react-image-file-resizer";
import OSS from "ali-oss";
import AWS from 'aws-sdk';

import { getSecureStsCred, regenerateSecureStsToken } from "./Music.Apis";

const getOssClient = ({
  securityToken,
  accessKeyId,
  accessKeySecret,
  ossBucketName,
  ossBucketRegion,
}) => {
  const client = new OSS({
    region: ossBucketRegion,
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket: ossBucketName,
    // Refresh the temporary access credential.
    refreshSTSToken: async () => {
      const { data } = await regenerateSecureStsToken();
      return {
        accessKeyId: data.data.accessKeyId,
        accessKeySecret: data.data.accessKeySecret,
        stsToken: data.data.securityToken,
      };
    },
  });

  return client;
};

const resizeFile = (file) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const getAudioDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const audioContext = new (window.AudioContext ||
        window.AudioContext)();
      audioContext.decodeAudioData(e.target.result, (buffer) => {
        const duration = Math.floor(buffer.duration);
        resolve(duration);
      });
    };
    reader.onerror = (event) => {
      reject("Error while getting audio");
    };
    reader.readAsArrayBuffer(file);
  });

export const formatForReactSelect = (tagsArray) => {
  console.log(tagsArray, 'tagsArray')
  const formatedData = tagsArray && tagsArray.length > 0 && tagsArray?.map(({ name, _id }) => ({
    label: name,
    value: _id,
    key: _id,
  }));

  console.log(formatedData, 'formatedData')
  return formatedData;

};



export const filterTagsAndFormat = (tags = []) => {
  const tagsData = formatForReactSelect(
    tags.filter(({ objectType }) => objectType === "TAG")
  );
  const categoryData = formatForReactSelect(
    tags.filter(({ objectType }) => objectType === "CATEGORY")
  );
  const subCategoryData = formatForReactSelect(
    tags.filter(({ objectType }) => objectType === "SUBCATEGORY")
  );
  return {
    tagsData,
    categoryData,
    subCategoryData,
  };
};

export const formatForRecommendationSelect = (userDataArray) => {
  const userformatedData = userDataArray.map(({ label, _id }) => ({
    label: label,
    value: label,
    key: _id,
  }));
  return userformatedData;
};

export const filterUserDataAndFormat = (userData = []) => {
  const genderData = formatForRecommendationSelect(
    userData.filter(
      ({ objectType }) => objectType === "UserRecommendationGender"
    )
  );
  const ageData = formatForRecommendationSelect(
    userData.filter(({ objectType }) => objectType === "UserRecommendationAge")
  );
  const maritalData = formatForRecommendationSelect(
    userData.filter(
      ({ objectType }) => objectType === "UserRecommendationMaritalStatus"
    )
  );
  const moodData = formatForRecommendationSelect(
    userData.filter(({ objectType }) => objectType === "UserRecommendationMood")
  );
  const bpmData = formatForRecommendationSelect(
    userData.filter(({ objectType }) => objectType === "UserRecommendationBPM")
  );
  const stressData = formatForRecommendationSelect(
    userData.filter(
      ({ objectType }) => objectType === "UserRecommendationStress"
    )
  );
  return {
    genderData,
    ageData,
    maritalData,
    moodData,
    bpmData,
    stressData,
  };
};

export const formatMultiSelectData = (data) => {
  console.log(data, 'asdfghjkl;sdfghjk')
  let formatedData
  if (Array.isArray(data)) {
    formatedData = data && data.length > 0 && data?.map(({ value }) => value);
  } else if (data?.value) {
    formatedData = data?.value
  } else {
    formatedData = []
  }
  return formatedData;
};

// AUDIO_IMAGE
// export const uploadFile = (file, type, onProgress, percentageW) =>
//   new Promise(async (resolve, reject) => {
//     try {
//       let uploadFile = file;
//       if (file.type.includes("image")) {
//         if (file.size < 50000) {
//         } else uploadFile = await resizeFile(file);
//       }
//       const { data } = await getSecureStsCred({
//         name: uploadFile.name,
//         type,
//       });

//       const client = getOssClient(data.data);
//       // const { url } = await client.put(data.data.fileLocation, uploadFile); // example for simple upload

//       const { name, bucket } = await client.multipartUpload(
//         data.data.fileLocation,
//         uploadFile,
//         {
//           progress: (p) => {
//             onProgress(p * percentageW);
//           },
//           partSize: 1024 * 1024 * 1, // this will create a 1MB chunk of file
//           parallel: 3,
//         }
//       );
//       resolve(
//         `https://${bucket}.${data.data.ossBucketRegion}.aliyuncs.com/${name}`
//       );
//     } catch (error) {
//       reject({
//         error: true,
//         errorMessage: "Unable to upload data try again later",
//       });
//     }
//   });

export const uploadFile = (file, type, onProgress, percentageW) => {
  return new Promise(async (resolve, reject) => {
    let uploadFile = file;
    // Configure AWS S3
    if (file.type.includes("image")) {
      if (file.size < 50000) {
      } else uploadFile = await resizeFile(file);
    }
    let fileName = uploadFile.name.replace(/ /g, '-')
    const { data } = await getSecureStsCred({
      name: fileName,//uploadFile.name,
      type,
    });
    let bucketDetails = data?.data

    const s3 = new AWS.S3({
      accessKeyId: bucketDetails?.accessKeyId,
      secretAccessKey: bucketDetails?.accessKeySecret,
      region: bucketDetails?.ossBucketRegion,
    });

    console.log(data);

    const params = {
      Bucket: bucketDetails?.ossBucketName,
      Key: bucketDetails?.fileLocation,//file.name,
      Body: file,
      ContentType: type,
      ContentLength: file.size,
    };

    const options = {
      partSize: 1024 * 1024 * 5, // 1MB chunks
      queueSize: 3, // 3 parallel uploads
    };

    // Create the upload and track progress
    const upload = s3.upload(params, options, (error, data) => {
      if (error) {
        console.error("Upload error:", error); // Log the error
        return reject({
          error: true,
          errorMessage: "Unable to upload data, try again later",
        });
      }

      console.log("Upload successful:", data); // Log the success response
      resolve(data.Location); // URL of the uploaded file
    });

    upload.on('httpUploadProgress', (progress) => {
      console.log(`Upload progress: ${progress.loaded} of ${progress.total} bytes`); // Log upload progress
      onProgress(progress.loaded / progress.total * percentageW);
    });
  })
};
export const formatArray = (arr) => {
  let updatedArray = [];
  for (let i in arr) {
    updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] });
  }
  return updatedArray;
};
