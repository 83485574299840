import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
const VideoModal = ({isOpen,toggleModal,title,thumbnailPic,url,videoData,setVideoData,handleUpdate}) => {
  const handleClose = () => {
    toggleModal();
  };
  
  return (
    <>
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Video details</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <AvField
                  name="title"
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e)=>setVideoData({...videoData, title:e.target.value})}
                  id="title"
                  disabled={true}
                   
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="thumbnailPic">
                  Add video thumbnail url
                </Label>
                <AvField
                  name="thumbnailPic"
                  type="text"
                  className="form-control"
                  value={thumbnailPic}
                  onChange={(e)=>setVideoData({...videoData, thumbnailPic:e.target.value})}
                  id="thumbnailPic"
                  disabled={true}
                  
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="url">
                  Add video embedded url
                </Label>
                <AvField
                  name="url"
                  type="text"
                  className="form-control"
                  value={url}
                  onChange={(e)=>setVideoData({...videoData, url:e.target.value})}
                  id="url"
                  disabled={true}
                  
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
        {/* <Button
            type="button"
            onClick={handleUpdate}
            color="light"
            className="waves-effect"
          >
            Update
          </Button> */}
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>

    </>
  )
}

export default VideoModal
