import axios from "axios";
import baseDomain, {
  businessApi,
  manualuserCreation,
  businessSearchAPI,
  EditUserApi,
  getUserEditApi,
  getNewVoucher,

  searchBusinessAPI

} from "../../../../Constants/apiRoutes";

export const getAllBusinessReq = ({ page, limit }) => {
  return axios.get(`${baseDomain}${businessApi}?page=${page}&limit=${limit}`);
};

export const getActiveBusniessReq = ({ isDisabled, page, limit }) => {
  return axios.get(
    `${baseDomain}${businessApi}?isDisabled=${isDisabled}&page=${page}&limit=${limit}`
  );
};

export const changeBusinessStatus = (status, userId) => {
  return axios.put(
    `${baseDomain}/business/user/status?status=${status}&userId=${userId}`
  );
};

export const getBusinessStatus = ({ status, page, limit }) => {
  return axios.get(
    `${baseDomain}/business/users?approveStatus=${status}&page=${page}&limit=${limit}`
  );
};

export const searchBusiness = (text, status) => {
  return axios.get(
    `${baseDomain}${searchBusinessAPI}?textInput=${text}&approveStatus=${status}`
  );
};

export const createManualUserApi = (data) => {
  return axios.post(`${baseDomain}${manualuserCreation}`, data);
};
export const searchBusinessUsersApi = (text) => {
  return axios.get(`${baseDomain}${businessSearchAPI}/${text}`);
};
export const editBusinessUserApi = (userId, payload) => {
  return axios.put(`${baseDomain}${EditUserApi}/${userId}`, payload);
};
export const getAllUsers = (userId) => {
  return axios.get(`${baseDomain}${getUserEditApi}/${userId}`);
};

export const userSubscription = (type, userId) => {
  return axios.put(`${baseDomain}/business/${type}/${userId}`);
};

export const UserNewVoucher = (userId, payload) => {
  return axios.post(
    `${baseDomain}/business/${getNewVoucher}/${userId}`,
    payload
  );
};
