export const createTagData = {
  TAG: {
    heading: "Tag",
    labelName: "Tag Name",
    nameError: "Enter Tag Name",
    labelDescription: "Tag Description",
    descriptionError: "Enter Tag Description",
    createBtn: "Create Tag",
  },
  CATEGORY: {
    heading: "Category",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Category Description",
    descriptionError: "Enter Category Description",
    createBtn: "Create Category",
  },
  SUBCATEGORY: {
    heading: "SubCategory",
    labelName: "SubCategory Name",
    nameError: "Enter Sub-Category Name",
    labelDescription: "SubCategory Description",
    descriptionError: "Enter Sub-Category Description",
    createBtn: "Create SubCategory",
    mediaOne:"Media One",
    mediaTwo:"media Two"
  },
  MAPPING: {
    heading: "Edit Mapping",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Sub Category Name",
    descriptionError: "Enter Sub Category Name",
    createBtn: "Edit Mapping",
    mediaOne: "Media One",
    mediaTwo: "media Two",
  },
};
