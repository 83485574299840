
import AllVedio from "./Components/AllVideo";
import CreateVedio from "./Components/CreateVideo";
import VideoBin from "./Components/VideoBin";
import EditVedio from "./Components/EditVideo";
import VideoPlayer from "./Components/VideoPlayer";

export const VideoRoutes = [
  {
    Component: AllVedio,
    path: '/all',
    exact: true,
    id: 'dashboardVideoAll',
    routePath: '/dashboard/video/all',
    name: 'All Video',
  },
  {
    Component: CreateVedio,
    path: '/create',
    exact: true,
    id: 'dashboardVideoCreate',
    routePath: '/dashboard/video/create',
    name: 'Add Video',
  },
  {
    Component: VideoBin,
    path: '/bin',
    exact: true,
    id: 'dashboardVideoBin',
    routePath: '/dashboard/video/bin',
    name: 'Video Bin',
  },
  {
    Component: EditVedio,
    path: '/edit/:musicID',
    exact: true,
    id: 'dashboardVideoEdit',
  },
  {
    Component: VideoPlayer,
    path: '/view/:musicID',
    exact: true,
    id: 'dashboardVideoView',
  },
];
