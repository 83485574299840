import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  // FormGroup,
  // Input,
  // Label,
} from "reactstrap";
import Table from "../Table";

// import { prioritiseQuoteReq, deprioritiseQuoteReq } from "./QuotesCard.Api";

const QuotesCard = ({
  openModel,
  tableData,
  tableHeading = [],
  handleDeleteTag,
  setQuotes,
  handleRestoreTag,
  allQuotes,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
}) => {
  const history = useHistory();
  const [rowData, setRowData] = useState([]);

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
          onClick={() => handleDeleteTag(row._id)}
        />
      </div>
    );
  };

  console.log(tableData, 'tabledata')

  const priorityFormatter = (cell, row) => {
    // const handlePriorityClick = async (rowID) => {
    //   if (row.isPriority) {
    //     await deprioritiseQuoteReq(rowID);
    //   } else {
    //     await prioritiseQuoteReq(rowID);
    //   }
    //   setQuotes((qpts) => {
    //     const temp = qpts.map((quote) => {
    //       if (quote._id === rowID) {
    //         return {
    //           ...quote,
    //           isPriority: !row.isPriority,
    //         };
    //       }
    //       return quote;
    //     });
    //     return temp;
    //   });
    // };
    return (
      // <FormGroup style={{ textAlign: "center" }} key={row._id}>
      //   <div className="custom-control custom-switch" dir="ltr">
      //     <Input
      //       type="checkbox"
      //       className="custom-control-input"
      //       checked={row.isPriority}
      //       name="isPriority"
      //       id={row._id}
      //       onClick={() => handlePriorityClick(row._id)}
      //     />
      //     <Label className="custom-control-label" htmlFor={row._id} />
      //   </div>
      // </FormGroup>
      <div style={{ textAlign: "center" }} key={row._id}>
        {row.specialDate ? moment(row.specialDate).format("DD/MM/YYYY") : "-"}
      </div>
    );
  };

  const restoreFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleRestoreTag(row._id)}
        >
          Restore
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (allQuotes === true) {
      tableHeading.push({
        text: "Actions",
        dataField: "actions",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "5%" },
        formatter: actionsFormatter,
      });
      tableHeading.push({
        text: "Priority",
        dataField: "priority",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "5%" },
        formatter: priorityFormatter,
      });
    } else {
      tableHeading.push({
        text: "Restore Quotes",
        dataField: "restore",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "15%" },
        formatter: restoreFormatter,
      });
    }
    return () => {
      if (allQuotes === true) {
        tableHeading.pop();
        tableHeading.pop();
      } else {
        tableHeading.pop();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {allQuotes === true && <h3 className="m-0">All Quotes</h3>}
                  {allQuotes === false && <h3 className="m-0">Quotes Bin</h3>}
                  {allQuotes === true && (
                    <div
                      className="page-title-right"
                      style={{ display: "flex", width: "20%", gap: "10px" }}
                    >
                      <Button
                        color="danger"
                        className="waves-effect waves-light"
                        style={{ width: "100%" }}
                        onClick={() => history.push("/dashboard/quotes/bin")}
                      >
                        Quotes Bin
                      </Button>
                      <Button
                        color="primary"
                        className="waves-effect waves-light"
                        style={{ width: "100%" }}
                        onClick={openModel}
                      >
                        Add Quotes
                      </Button>
                    </div>
                  )}
                  {allQuotes === false && (
                    <div className="page-title-right">
                      <Button
                        color="primary"
                        className="waves-effect waves-light"
                        style={{ width: "100%" }}
                        onClick={() => history.push("/dashboard/quotes/all")}
                      >
                        All Quotes
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default QuotesCard;
