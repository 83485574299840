import axios from "axios";

import { baseDomain, secureStsCredApi } from "../../../../Constants/apiRoutes";

export const getPublishedNutriDietAPI = ({ page, limit }) => {
  // publishedApi
  return axios.get(
    `${baseDomain}/v2/nutriClinic?isPublished=true&page=${page}&limit=${limit}`
  );
};
export const getUnPublishedNutriDietAPI = ({ page, limit }) => {
  // uppublishedApi

  return axios.get(
    `${baseDomain}/v2/nutriClinic?isPublished=false&page=${page}&limit=${limit}`
  );
};
export const UpdateUnPublishNutriDietAPI = (id) => {
  return axios.put(`${baseDomain}/v2/nutriClinic/unpublish/${id}`);
};
export const UpdatePublishNutriDietAPI = (id) => {
  return axios.put(`${baseDomain}/v2/nutriClinic/${id}`);
};
export const createNutriDietApi = (payload) => {
  console.log(payload, "bbbbbbbbbbb");
  return axios.post(`${baseDomain}/v2/nutriClinic`, payload);
};
export const getNutriDietbyIdApi = (id) => {
  console.log(id);
  return axios.get(`${baseDomain}/v2/nutriClinic/${id}`);
};
export const updateNutriDietApi = (payload) => {

  return axios.put(
    `${baseDomain}/v2/nutriClinic/update/${payload.idval}`,
    payload.updatedFormData
  );
};

export const getSecureStsCred = (params) => {
  return axios.get(`${baseDomain}/v2${secureStsCredApi}`, {
    params,
  });
};

export const getFaqlistApi = () => {
  return axios.get(`${baseDomain}/v2/faq/getAllFaq/NLIVE`);
};
