export const LiveSessionTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  
 
]

export const UnPubLiveSessionTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  
 
]