import React, { useState } from "react";
import DiscoverForm from "../../../../../Components/DiscoverForm";

const CreateDiscovers = () => {

  const [formData, setFormData] = useState({
    name: "",
    image: "",
    language:'en',
    card_desription: "",
    series_desription: "",
    singles_desription: "",
    backgroundImage: "",
    seriesBackgroundImage: "",
    redirect: false,
    dayOne:"",
    dayTwo:"",
    dayThree:"",
    dayFour:"",
    dayFive:""
  });
  return <DiscoverForm setFormData={setFormData} formData={formData} />;
};

export default CreateDiscovers;
