import React from "react";
import PlaylistForm from "../../../../../../Components/PlaylistForm";
import { useState, useEffect } from "react";
import { createNewPlaylist, getAllMedia,getAllTags } from "../../Playlist.Apis";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../Playlist.constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import Loader from "../../../../../../Components/Loader";

const CreatePlaylist = () => {
  const history = useHistory();
  const [getData, setGetData] = useState([]);
  const [showPlaylistCreateAlert, setShowPlaylistCreateAlert] = useState(false);
  const [showPlaylistCreateConfirm, setShowPlaylistCreateConfirm] =
    useState(false);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { isError, errorMessage, errorType } = error;
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    _id: ['test'],
  });
  const [coverImage, setCoverImage] = useState(null);
  const [showPostPlaylistConfirm, setShowPostPlaylistConfirm] = useState(false);
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [selectedTags,setSelectedTags] = useState('');
  const [tagsData,setTagsData] = useState([])

  const [playlistFields, setPlaylistFields] = useState({
    title: "",
    description: "",
    type: "PLAYLIST",
    mediaData: [],
    mediaType: "",
  });
  const { title, description, type, mediaData, mediaType } = playlistFields;
  const { isCreating, creatingStage } = creating;

  const handleInput = (e) => {
    setPlaylistFields({
      ...playlistFields,
      [e.target.name]: e.target.value,
    });
  };

  const getAllPlaylistInfo = async (queryParams) => {
    try {
      const { data } = await getAllMedia(queryParams);
      setGetData(data.data);
    } catch (error) {
      console.log("error");
    }
  };
  const getAllTagsData = async () => {
    const {data} = await getAllTags();
    console.log(data?.data,'hurraaahhhh');
    setTagsData(data?.data)
  }

  useEffect(() => {
    getAllPlaylistInfo({ page: 1, limit: 20 });
    getAllTagsData();
  }, []);

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const handleSubmit = async () => {
    try {
      if (!coverImage)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const imageThumbnailUrl = await uploadFile(
        coverImage,
        "IMAGE",
        setThumbnailUploadPercentage,
        30
      );

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      const categoryId = selectedCategory._id ? selectedCategory._id : null;

      await createNewPlaylist({
        title,
        description,
        type: "PLAYLIST",
        mediaData,
        tags:formatMultiSelectData(selectedTags),
        imgAsset: imageThumbnailUrl,
        // categoryId: selectedCategory._id,
        mediaType,
      });
      setShowPostPlaylistConfirm(true);
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
    } catch (error) {
      console.log(error, "playlist");
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setContentUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Image please try again later",
        errorType: "danger",
      });
    }
  };

  return (
    <>
      <PlaylistForm
        heading="Add playlist"
        getData={getData}
        setGetData={setGetData}
        SetShowPlaylistCreateAlert={setShowPlaylistCreateAlert}
        createHandle={handleSubmit}
        playlistDescription={playlistFields}
        handleInput={handleInput}
        SetShowPlaylistCreateConfirm={setShowPlaylistCreateConfirm}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        error={error}
        mediaData={mediaData}
        tagsData={tagsData}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      {showPostPlaylistConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/playlist/all");
          }}
        ></SweetAlert>
      ) : null}
    </>
  );
};

export default CreatePlaylist;
