
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import FileUploader from "../FileUploader";

const EditAdvisorForm = ({
  name,
  designation,
  experience,
  rating,
  image,
  setImage,
  handleChange,
  handleUpdateAdvisor,
  noOfPatients,
  speciality,
  advisoryTypeOptions,
  advisorTypeData,
  handleSelectedAdvisoryType,
  specialityInput,
  handleAddSpeciality,

  handleSpecialityChange,
  handleRemoveSpeciality

}) => {

  console.log(specialityInput)

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <AvForm className="form-horizontal" >
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="title">Name</Label>
                    <AvField
                      name="name"
                      placeholder="Enter name"
                      value={name}
                      onChange={handleChange}
                      type="text"
                      errorMessage="Name is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="description">Designation</Label>
                    <AvField
                      name="designation"
                      placeholder="Enter designation"
                      value={designation}
                      onChange={handleChange}
                      type="text"
                      errorMessage="designation is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="designation"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor='advisorType'>Advisor Type</Label>
                    <Select
                      value={advisorTypeData}
                      onChange={handleSelectedAdvisoryType}
                      options={advisoryTypeOptions}
                      classNamePrefix='select2-selection'
                      placeholder={
                        advisoryTypeOptions && advisoryTypeOptions.length === 0
                          ? 'Loading...'
                          : 'Select Type'
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor=''>Speciality</Label>
                    <AvField
                      name="speciality"
                      placeholder="Enter Speciality"
                      value={specialityInput}
                      onChange={handleChange}
                      type="text"
                      errorMessage="Advisor Type is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="speciality"
                    />
                  </FormGroup>
                  <div className="input-group-append">
                    <Button color="primary" onClick={handleAddSpeciality}>
                      <i className="fa fa-plus"></i> Add
                    </Button> &nbsp; &nbsp; &nbsp;
                    {speciality.map((item, index) => (
                      <div key={index} style={{ display: "inline-block", marginRight: "5px" }}>
                        <span style={{ fontWeight: "boldest", fontSize: "16px", marginLeft: "5px" }}>{item}</span>
                        <button
                          onClick={() => handleRemoveSpeciality(index)}
                          style={{ marginLeft: "5px", cursor: "pointer", fontSize: "8px" }}
                        >
                          X
                        </button>
                      </div>))}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor="title">Experience</Label>
                    <AvField
                      name="experience"
                      placeholder="Enter experience"
                      value={experience}
                      onChange={handleChange}
                      type="number"
                      errorMessage="Experience is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="experience"
                    />
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor="description">Rating</Label>
                    <AvField
                      name="rating"
                      placeholder="Enter rating"
                      value={rating}
                      onChange={handleChange}
                      type="number"
                      errorMessage="designation is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="rating"
                    />
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor="description">No. Of Patients</Label>
                    <AvField
                      name="noOfPatients"
                      placeholder="No. of Patients"
                      value={noOfPatients ? noOfPatients : "0"}
                      onChange={handleChange}
                      type="number"
                      errorMessage="designation is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="rating"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={image}
                    setFile={setImage}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                // disabled={!isDataUpdated ? true : false}
                onClick={handleUpdateAdvisor}
              >
                {'Save Changes'}
              </Button>
            </AvForm>
            <br />
          </CardBody>
        </Card>
      </Col>
    </Row>

  );
};

export default EditAdvisorForm;
