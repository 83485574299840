import React, { Fragment, useState, useContext } from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import AuthContext from "../../context/auth/authContext";

import stockUser from "../../assets/images/stockUser.png";

// import Profile from "src/assets/images/profile1.jpeg";

const ProfileMenu = () => {
  const authContext = useContext(AuthContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  return (
    <Fragment>
      <Dropdown
        isOpen={isMenuOpen}
        toggle={toggleMenu}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user mr-1"
            src={stockUser}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-1 text-transform" style={{ color: 'black' }}>
            {authContext && authContext.user && authContext.user.firstName || authContext && authContext.user && authContext.user.email}
          </span>
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className="text-danger" onClick={() => authContext.logout()}>
            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};

export default ProfileMenu;
