import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CreateMasteClassComp from "../../../../../../Components/CreateMasterComp";
import {
  getAllSupportAdvisorApi,
  createMasterClassApi,
} from "../../Constants/api";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import UploadBlogsStatus from "../../../../../../Components/UploadBlogsStatus";

const CreateMasterClass = () => {
  const history = useHistory();
  const [descData, setDescData] = useState("");
  const [aboutData, setAboutData] = useState("");
  const [headerImg, setHeaderImg] = useState("");
  const [advisor, setAdvisor] = useState([]);
  const [adName, setAdName] = useState([]);
  const [free, setFree] = useState([])
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  
  const [formData, setFormData] = useState({
    title: "",
    targetDate: "",
    tagline: "",
    joinLink: "",
    points:""
  });
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });

  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);

  const { title, tagline, targetDate, joinLink,points } = formData;

  const getAllAdvisorData = async () => {
    const { data } = await getAllSupportAdvisorApi();
    setAdvisor(data?.data);
  };

  useEffect(() => {
    getAllAdvisorData();
  }, []);

  const handleChangeMasterClass = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };





  const createMasterClass = async () => {
    try {
      if (!headerImg)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const headerImgUrl = await uploadFile(
        headerImg,
        "MASTER-CLASS-IMAGE",
        setThumbnailUploadPercentage,
        20
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      await createMasterClassApi({
        title: formData?.title,
        description: descData,
        image: headerImgUrl,
        targetDate: new Date(formData?.targetDate).toISOString(),
        tagline: formData?.tagline,
        points: formData?.points,
        joinLink: formData?.joinLink,
        supportAdvisorId: formatMultiSelectData(adName),
        // plans: {
        //   google: "monthly_offer_10_con",
        //   apple: "monthly_offer_10_con"
        // },
        isFree: formatMultiSelectData(free) === 'true' ? true : false
      })
      console.log(targetDate,'helloooo')

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });

    } catch (error) {
      console.log(error, "error");
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setContentUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Image please try again later",
        errorType: "danger",
      });
    }
  };

  const reset = () => {
    setHeaderImg(null);

    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      targetDate: "",
      tagline: "",
      joinLink: "",
    });
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };
  return (
    <>
      {isCreating ? (
        <UploadBlogsStatus
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={reset}
          fileName="master-class"
        />
      ) : (
        <CreateMasteClassComp
          free={free}
          setFree={setFree}
          setDescData={setDescData}
          descData={descData}
          setAboutData={setAboutData}
          aboutData={aboutData}
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          formData={formData}
          setFormData={setFormData}
          title={title}
          points={points}
          tagline={tagline}
          targetDate={targetDate}
          joinLink={joinLink}
          setAdName={setAdName}
          adName={adName}
          setAdvisor={setAdvisor}
          advisor={advisor}
          handleChangeMasterClass={handleChangeMasterClass}
          createMasterClass={createMasterClass}
          setShowUnPublishAlert={setShowUnPublishAlert}
        />
      )}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={() => {
            createMasterClass();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/master-class/all");
          }}
        >
          Master Class has been Created
        </SweetAlert>
      ) : null}
    </>
  );
};

export default CreateMasterClass;
