export const storyTabPublish = [
  {
    text: "S.No",
    dataField: "sNo"
  },

  {
    text: "Title",
    dataField: "title"
  }
]

export const storyTabUnpublish = [
  {
    text: "S.No",
    dataField: "sNo"
  },

  {
    text: "Title",
    dataField: "title"
  }
]