import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  CardTitle,
  Card,
  CardSubtitle,
  CardBody,
  Button,
  CardText,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";  
import { getAllOfferReq,deleteOfferReq,editOfferReq ,updateOfferReq} from "../../Offer.Api";
import OfferModal from "../../../../../../Modal/EditOffer";

const AllOffers = () => {
  const history = useHistory();
  const [offers, setOffers] = useState([]);
  const [offerId,setOfferId]=useState()

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [deleteOfferId, setDeleteOfferId] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false)
  const [offerData, setOfferData] = useState({
    offerCode:"",
    couponDescription:"",
    discountPrice:"",
    expireTime:"",
    countryCode:"",
    objectType:""
  })
  const { offerCode,couponDescription,discountPrice,expireTime,countryCode,objectType } = offerData;
  const toggleEditModel = () => setIsEditModalOpen(!isEditModalOpen);
 

  const getAllOffers = async () => {
    try {
      const {
        data: { data },
      } = await getAllOfferReq();
      setOffers([...data]);
    } catch (e) {
      console.log("error", e);
    }
  };


  const handleEditOffer = async (offerId) => {
    try {
      const{data}= await editOfferReq(offerId)
      setOfferData({
        offerCode: data.data.offerCode,
        couponDescription: data.data.couponDescription,
        discountPrice: data.data.discount,
        expireTime: data.data.expireTime,
        countryCode: data.data.countryCode,
        objectType:data.data.objectType
      }, offerId)
    } catch (error) {
      console.log(error)
    }
  }
  const handleCountryCode = (select) => {
    setFormData({...offerData, countryCode:select})
  };
  const handleUpdateOffer = async () => {
    try {
      await updateOfferReq(offerId,offerData)
      setShowUpdateConfirm(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteCoupon = (id) => {
    setDeleteOfferId(id);
    setShowDeleteAlert(true);
  };

  const handleDeleteAction = async () => {
    try {
      await deleteOfferReq(deleteOfferId);
      const filterCoupons = offers.filter(({ _id }) => _id != deleteOfferId);
      setOffers(filterCoupons);
      setDeleteOfferId("");
      setShowDeleteAlert(false);
    } catch (error) {}
    setShowDeleteAlert(false);
  };

 const handleEditOfferClick = (offerId) => {
    setOfferId(offerId)
    setIsEditModalOpen(true),
    handleEditOffer(offerId)
  }

  useEffect(() => {
    getAllOffers();
  }, []);
  return (
    <>
      <Row
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "0px 20px" }}
      >
        <h1>All Offers</h1>
        <div>
          <Button
            color="primary"
            className="waves-effect waves-light"
            style={{ width: "100%" }}
            onClick={() => {
              history.push({
                pathname:"/dashboard/offer/create",
                state:{
                  heading:"Create Offer",
                  buttonText:"Create Offer",
                  update:false,
                }
              });
            }}
          >
            Add Offer
          </Button>
        </div>
      </Row>

      <Row className="marginTopTen">
        {offers?.length > 0 &&
          offers.map((data) => {
            return (
              <Col lg={4} key={data._id}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <CardTitle>
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          OfferCode :
                        </span>
                        {" " + data.offerCode}
                      </CardTitle>
                    </div>
                    <CardText>
                      <div className="description">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Description :
                        </span>
                        {" " + data.couponDescription}
                      </div>
                      <div className="description">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Offer Type :
                        </span>
                        {" " + data.objectType}
                      </div>
                      <div className="expireTime marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Expire Date :
                        </span>
                        {" " + moment(data.expireTime).format("DD/MM/YYYY")}
                      </div>
                      <div className="countryCode marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Country Code :
                        </span>
                        {" " + data.countryCode}
                      </div>
                      <div className="discountValue marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Discount :
                        </span>
                        {" " + data.discount}
                      </div>
                      <div className="countValue marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          count :
                        </span>
                        {" " + data.count}
                      </div>
                    </CardText>
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={()=>handleEditOfferClick(data?._id)}
                    >
                      Edit Offer
                    </Button>
                    <Button
                      onClick={() => {
                        handleDeleteCoupon(data._id);
                      }}
                      className="marginLeftTen"
                    >
                      Delete Offer
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            );
          })}

        {showDeleteAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Delete it!"
            reverseButtons={true}
            onConfirm={handleDeleteAction}
            onCancel={() => setShowDeleteAlert(false)}
          ></SweetAlert>
        ) : null}
      </Row>


      <OfferModal
      isOpen={isEditModalOpen}
      toggleModal={toggleEditModel}
      offerData = {offerData}
      setOfferData = {setOfferData}
      offerCode = {offerCode}
      couponDescription = {couponDescription}
      discountPrice = {discountPrice}
      expireTime = {expireTime}
      countryCode = {countryCode}
      handleUpdateOffer = {handleUpdateOffer}
      handleCountryCode= {handleCountryCode}
      objectType={objectType}
      />

    {showUpdateConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUpdateConfirm(false)
            window.location.reload()
          }}
        ></SweetAlert>
      ) : null}
    </>
  );
};

export default AllOffers;
