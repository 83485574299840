import React from "react";
import { useEffect, useState } from "react";
import EditUserComp from "../../../../../../Components/EditUserComp";
import {
  getAllUsers,
  editBusinessUserApi,
  UserNewVoucher,
} from "../../Business.Api";
import { useParams } from "react-router-dom";
import StocksModal from "../../../../../../Modal/StocksModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

const EditUserAccount = () => {
  const { userId } = useParams();
  const [mobCountryCode, setMobCountryCode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showUserAlert, setShowUserAlert] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companySize: "",
    phoneNumber: "",
    role: "",
    Monthly: "",
    Annually: "",
    companionCount: 0,
    country: "",
    state: "",
    currency: "",
    countryCode: "",
    city: "",
    stateCode: "",
    totalGross: "",
    annuallyPlanCount: "",
    monthlyPlanCount: "",
    amountPaid: "",
  });
  const [previousData, setPreviousData] = useState({});
  const [prevPhone, setPrevPhone] = useState("");
  const [prevEmail, setPrevEmail] = useState("");
  const [userData, setUserData] = useState([]);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  let debounce;
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    clearTimeout(debounce);
    debounce = setTimeout(
      isFieldsChanged({
        ...formData,
        [e.target.name]: e.target.value,
      }),
      500
    );
  };
  const handleOnChange = (value, data) => {
    console.log(value);
    setFormData({ ...formData, phoneNumber: value });
    setMobCountryCode(data.dialCode);
  };

  const {
    email,
    firstName,
    lastName,
    companyName,
    companySize,
    designation,
    phoneNumber,
    currency,
    Monthly,
    Annually,
    companionCount,
    country,
    state,
    countryCode,
    city,
    stateCode,
    totalGross,
    monthlyPlanCount,
    annuallyPlanCount,
    amountPaid,
  } = formData;

  const getUsers = async () => {
    try {
      const { data } = await getAllUsers(userId);
      setFormData({
        ...data.stockLeft,
        ...data.userData,
        currency: data.currency,
        totalGross: data.totalGross,
      });
      setPreviousData({
        ...data.stockLeft,
        ...data.userData,
        currency: data.currency,
        totalGross: data.totalGross,
      });
      setPrevPhone(data.userData.phoneNumber);
      setPrevEmail(data.userData.email);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const isFieldsChanged = (curr) => {
    let prev = JSON.stringify(previousData);
    let current = JSON.stringify(curr);
    if (prev !== current) {
      setIsDataUpdated(true);
      return true;
    } else {
      setIsDataUpdated(false);
      return false;
    }
  };

  const handleEditUserSubmit = async () => {
    let body = () => {
      if (prevPhone === phoneNumber && prevEmail === email) {
        return {
          firstName,
          lastName,
          companyName,
          companySize,
          designation,
          currency,
          Monthly,
          Annually,
          companionCount: parseInt(companionCount),
          country,
          state,
          countryCode,
          city,
          stateCode,
          totalGross,
          annuallyPlanCount,
          monthlyPlanCount,
          amountPaid,
        };
      } else if (phoneNumber === prevPhone && prevEmail !== email) {
        return {
          email,
          firstName,
          lastName,
          companyName,
          companySize,
          designation,
          currency,
          Monthly,
          Annually,
          companionCount: parseInt(companionCount),
          country,
          state,
          countryCode,
          city,
          stateCode,
          totalGross,
          annuallyPlanCount,
          monthlyPlanCount,
          amountPaid,
        };
      } else if (email === prevEmail && prevPhone !== phoneNumber) {
        return {
          firstName,
          lastName,
          companyName,
          companySize,
          designation,
          phoneNumber,
          currency,
          Monthly,
          Annually,
          companionCount: parseInt(companionCount),
          country,
          state,
          countryCode,
          city,
          stateCode,
          totalGross,
          annuallyPlanCount,
          monthlyPlanCount,
          amountPaid,
        };
      }
    };
    try {
      const { data } = await editBusinessUserApi(userId, body());
      setShowUserAlert(true);
      setShowStatus(true);
      setFormData(data.data, userId);
    } catch (error) {
      console.log(error);
    }
    getVouchersData();
  };

  const getVouchersData = async () => {
    await UserNewVoucher(userId, formData);
    const newUserStocks = { ...userData };
    setUserData([...userData, newUserStocks]);
    setFormData({
      currency: currency,
      Monthly: newUserStocks.monthlyPlanCount,
      Annually: newUserStocks.annuallyPlanCount,
      totalGross: newUserStocks.amountPaid,
    });
    setShowStatus(true);
  };
  const handleUserStocks = () => {
    toggleStocksModal();
  };

  useEffect(() => {
    getUsers();
  }, []);

  const toggleStocksModal = () => {
    setIsOpen(!isOpen);
  };

  const handleRedirect = () => {
    history.push(`/dashboard/business/accountAll`);
  };
  return (
    <>
      {showUserAlert && showStatus && isDataUpdated ? (
        <SweetAlert
          success
          title="User Updated"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            handleEditUserSubmit();
            setShowStatus(false);
            handleRedirect();
          }}
        >
          User Updated
        </SweetAlert>
      ) : null}
      <EditUserComp
        pageHeading="Edit user"
        email={email}
        firstName={firstName}
        lastName={lastName}
        companyName={companyName}
        companySize={companySize}
        designation={designation}
        Monthly={Monthly}
        Annually={Annually}
        companionCount={companionCount}
        country={country}
        state={state}
        countryCode={countryCode}
        city={city}
        currency={currency}
        phoneNumber={phoneNumber}
        stateCode={stateCode}
        totalGross={totalGross}
        isDataUpdated={isDataUpdated}
        handelInput={handelInput}
        handleEditUserSubmit={handleEditUserSubmit}
        handleOnChange={handleOnChange}
        handleUserStocks={handleUserStocks}
      />
      <StocksModal
        monthlyPlanCount={monthlyPlanCount}
        annuallyPlanCount={annuallyPlanCount}
        amountPaid={amountPaid}
        currency={currency}
        isOpen={isOpen}
        toggleModal={toggleStocksModal}
        formData={formData}
        setFormData={setFormData}
        isFieldsChanged={isFieldsChanged}
      />
    </>
  );
};

export default EditUserAccount;
