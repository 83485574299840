import { EditUserApi } from "../../../../../Constants/apiRoutes";
import AllStories from "../Components/AllStories";
import CreateStories from "../Components/CreateStories";
import EditStories from "../Components/EditStories";

export const StoriesRoutes = [

  {
    Component: AllStories,
    path: "/all",
    exact: true,
    id: "dashboardStoriesAll",
    routePath: "/dashboard/stories/all",
    name: "All Stories",
  },
  {
    Component: CreateStories,
    path: "/create",
    exact: true,
    id: "dashboardStoriesCreate",
    routePath: "/dashboard/stories/create",
    name: "Create Story",
  },
  {
    Component: EditStories,
    path: "/edit/:storyId",
    exact: true,
    id: 'dashboardStoriesEdit',
  },
  
];


