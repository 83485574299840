import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AllCourseEditComp from "../../../../../../Components/AllCoursesEditComp";
import {
  getCourseByIdApi,
  updateCourseApi,
  getAllSupportAdvisorApi,
  getAllLessonApi,
  getAllFaq,
  getAllTags
} from "../../Constants/api";
import { creatingStates } from "../../../Blogs/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";

const EditCourses = () => {
  const { editID } = useParams();
  const history = useHistory();

  const [courseById, setCourseById] = useState("");
  const [certi, setCerti] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const [adName, setAdName] = useState([]);
  const [content, setContent] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [learnTitle, setLearnTitle] = useState("");
  const [learnImg, setLearnImg] = useState("");
  const [learnTitleTwo, setLearnTitleTwo] = useState("");
  const [learnImgTwo, setLearnImgTwo] = useState("");
  const [learnTitleThree, setLearnTitleThree] = useState("");
  const [learnImgThree, setLearnImgThree] = useState("");
  const [outComeGet, setOutComeGet] = useState("");
  const [certImg, setCertImg] = useState("");
  const [outCertImg, setOutCertImg] = useState("")
  const [certTitle, setCertTitle] = useState("");
  const [certDesc, setCertDesc] = useState("");
  const [gettingTitle, setGettingTitle] = useState("");
  const [gettingIcon, setGettingIcon] = useState("");
  const [gettingTitleTwo, setGettingTitleTwo] = useState("");
  const [gettingIconTwo, setGettingIconTwo] = useState("");
  const [gettingTitleThree, setGettingTitleThree] = useState("");
  const [gettingIconThree, setGettingIconThree] = useState("");
  const [headerImg, setHeaderImg] = useState("");
  const [lessonsData, setLessonsData] = useState([]);
  const [lessonName, setLessonName] = useState([]);
  const [intensityName, setIntensityName] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [faq, setFaq] = useState("");
  const [faqData, setFaqData] = useState("");
  const [tab, setTab] = useState("");
  const [tagsData,setTagsData] = useState([])
  const [selectedTag,setSelectedTag] = useState('')
  const [formData, setFormData] = useState({
    title: "",
    imgAsset: "",
    supportAdvisor: "",
    learnings: [],
    calories: "",
    intensity: "",
    lessons: [],
    Faqs: [],
    outCome: [],
    gettings: [],
  });

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { title, imgAsset, supportAdvisor, calories, intensity } = formData;

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const getAllLessonData = async () => {
    const { data } = await getAllLessonApi();
    setLessonsData(data?.data);
  };

  const getAllTagsData = async () => {
    const {data} = await getAllTags();
    console.log(data?.data,'hurraaahhhh');
    setTagsData(data?.data)
  }

  const getAllFaqData = async () => {
    const { data } = await getAllFaq();
    setFaqData(data?.faqs);
  };

  useEffect(() => {
    getAllFaqData();
    getAllLessonData();
    getAllTagsData();
  }, []);

  function htmlDecode(input) {
    console.log(input, "ceck");
    var e = document.createElement("div");
    e.innerHTML = input;
    console.log(e.innerHTML, "check");
    return e.firstChild.nodeValue;
  }

  const getCourseByIdData = async () => {
    const { data } = await getCourseByIdApi(editID);
    const res = await getAllSupportAdvisorApi();
    const res2 = await getAllFaq();
    setAdvisor(res?.data?.data);
    const advisorByID = res?.data?.data;
    const advisorName = data?.data?.supportAdvisor;
    console.log(advisorName, "advisorName");
    const filAdvsiorId = advisorByID?.filter((i) => i._id === advisorName);
    const advName = filAdvsiorId[0]?.name;
    const faqName = data?.data?.Faqs;
    const faqID = res2.data.faqs;
    const filFaq = faqID?.filter((ele) => ele?._id === faqName);
    console.log(data.data, "faqName");
    setCourseById(data?.data);
    setFormData({
      ...formData,
      title: data?.data?.title,
      calories: data?.data?.calories,
    });
    setIntensityName({
      key: data?.data?.intensity,
      label: data?.data?.intensity,
      value: data?.data?.intensity,
    });
    setTab({
      key: data?.data?.pageFilter,
      label: data?.data?.pageFilter,
      value: data?.data?.pageFilter,
    });
    setLessonName(
      data?.data?.lessons?.map((ele) => {
        return {
          key: ele?._id,
          label: ele?.title,
          value: data?.data?.lessons,
        };
      })
    );
    setSelectedTag(
      data?.data?.tags?.map((ele) => {
        return {
          key: ele?._id,
          label: ele?.name,
          value: data?.data?.tags,
        };
      })
    )
    setAdName({
      key: data?.data?.supportAdvisor,
      label: advName,
      value: data?.data?.supportAdvisor,
    });

    setFaq(
      data?.data?.Faqs?.map((ele) => {
        return {
          key: ele?._id,
          label: ele?.title,
          value: ele?.title,
        };
      })
    );
    setHeaderImg(data?.data?.imgAsset);
    setGettingIcon(data?.data?.gettings[0]?.icon);
    setGettingIconTwo(data?.data?.gettings[1]?.icon);
    setGettingIconThree(data?.data?.gettings[2]?.icon);
    setGettingTitle(data?.data?.gettings[0]?.title);
    setGettingTitleTwo(data?.data?.gettings[1]?.title);
    setGettingTitleThree(data?.data?.gettings[2]?.title);
    setLearnImg(data?.data?.learnings[0]?.img);
    setLearnImgTwo(data?.data?.learnings[1]?.img);
    setLearnImgThree(data?.data?.learnings[2]?.img);
    setLearnTitle(data?.data?.learnings[0]?.title);
    setLearnTitleTwo(data?.data?.learnings[1]?.title);
    setLearnTitleThree(data?.data?.learnings[2]?.title);
    setOutComeGet(data?.data?.outCome?.outComeGet);
    setOutCertImg(data?.data?.outCome?.courseCertificateAssetUrl)
    console.log(data?.data, "qwertyuioasdfghjk");
    data?.data?.outCome?.outComeCertificate &&
      setCerti(data?.data?.outCome?.outComeCertificate);
    if (data?.data?.description) {
      setContent(data?.data?.description);
    }
    if (data?.data?.outCome?.outComeCompletion) {
      setCertificate(data?.data?.outCome?.outComeCompletion);
    }
  };
  const intensityData = [
    {
      key: "LOW",
      label: "LOW",
      value: "LOW",
    },
    {
      key: "MEDIUM",
      label: "MEDIUM",
      value: "MEDIUM",
    },
    {
      key: "HIGH",
      label: "HIGH",
      value: "HIGH",
    },
  ];

  console.log(certi, "object");
  const handleSubmit = async () => {
    try {
      let headerImgUrl = headerImg;
      let learnImgUrl = learnImg;
      let learnImgTwoUrl = learnImgTwo;
      let learnImgThreeUrl = learnImgThree;
      let gettingIconUrl = gettingIcon;
      let gettingIconTwoUrl = gettingIconTwo;
      let gettingIconThreeUrl = gettingIconThree;
      let outcomeTemplate = outCertImg
      
      setShowUnPublishAlert(true);

      if (outCertImg && typeof outCertImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        outcomeTemplate = await uploadFile(
          outCertImg,
          "COURSES-IMAGE-HEADER",
          setThumbnailUploadPercentage,
          20
        );
      } else setContentUploadPercentage(20);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });



      if (headerImg && typeof headerImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        headerImgUrl = await uploadFile(
          headerImg,
          "COURSES-IMAGE-HEADER",
          setThumbnailUploadPercentage,
          20
        );
      } else setContentUploadPercentage(20);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImg && typeof learnImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgUrl = await uploadFile(
          learnImg,
          "COURSES-IMAGE-LEARN1",
          setThumbnailUploadPercentage,
          30
        );
      } else setContentUploadPercentage(30);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImgTwo && typeof learnImgTwo !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgTwoUrl = await uploadFile(
          learnImgTwo,
          "COURSES-IMAGE-LEARN2",
          setThumbnailUploadPercentage,
          40
        );
      } else setContentUploadPercentage(40);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImgThree && typeof learnImgThree !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgThreeUrl = await uploadFile(
          learnImgThree,
          "COURSES-IMAGE-LEARN3",
          setThumbnailUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (gettingIcon && typeof gettingIcon !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        gettingIconUrl = await uploadFile(
          gettingIcon,
          "COURSES-IMAGE-GET1",
          setThumbnailUploadPercentage,
          60
        );
      } else setContentUploadPercentage(60);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (gettingIconTwo && typeof gettingIconTwo !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        gettingIconTwoUrl = await uploadFile(
          gettingIconTwo,
          "COURSES-IMAGE-GET2",
          setThumbnailUploadPercentage,
          70
        );
      } else setContentUploadPercentage(70);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (gettingIconThree && typeof gettingIconThree !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        gettingIconThreeUrl = await uploadFile(
          gettingIconThree,
          "COURSES-IMAGE-GET3",
          setThumbnailUploadPercentage,
          80
        );
      } else setContentUploadPercentage(80);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      {
        var certificateData = await Promise.all(
          certi &&
            certi?.map(async (item) => {
              console.log(item, "ijnuhbrfvwtsgfx");
              let certImgUrl = "";
              if (typeof item?.imgAsset !== "string") {
                certImgUrl = await uploadFile(
                  item.imgAsset,
                  "COURSES-IMAGE-CERT",
                  setThumbnailUploadPercentage,
                  90
                );
              } else {
                certImgUrl = item?.imgAsset;
              }

              return {
                title: item.title,
                imgAsset: certImgUrl,
                description: item.description,
              };
            })
        );
      }
    

      console.log(certificateData, "certificateData");

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      await updateCourseApi(editID, {
        title: formData.title,
        description: content,
        imgAsset: headerImgUrl,
        supportAdvisor: formatMultiSelectData(adName),
        pageFilter:formatMultiSelectData(tab),
        learnings: [
          {
            img: learnImgUrl,
            title: learnTitle,
          },
          {
            img: learnImgTwoUrl,
            title: learnTitleTwo,
          },
          {
            img: learnImgThreeUrl,
            title: learnTitleThree,
          },
        ],
        calories: calories,
        intensity: formatMultiSelectData(intensityName),
        lessons: lessonName?.map((i) => i.key),
        Faqs: faq?.map((i) => i.key),
        outCome: {
          outComeGet: outComeGet,
          outComeCompletion: certificate,
          outComeCertificate: certificateData,
          courseCertificateAssetUrl:outcomeTemplate
        },
        tags:selectedTag,
        gettings: [
          {
            icon: gettingIconUrl,
            title: gettingTitle,
          },
          {
            icon: gettingIconTwoUrl,
            title: gettingTitleTwo,
          },
          {
            icon: gettingIconThreeUrl,
            title: gettingTitleThree,
          },
        ],
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCourse = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getCourseByIdData();
  }, [editID]);

  return (
    <>
      {content && certificate && (
        <AllCourseEditComp
          setTab={setTab}
          tab={tab}
          setFaqData={setFaqData}
          faqData={faqData}
          faq={faq}
          setFaq={setFaq}
          setIntensityName={setIntensityName}
          intensityName={intensityName}
          intensityData={intensityData}
          setLessonName={setLessonName}
          lessonName={lessonName}
          setLessonsData={setLessonsData}
          lessonsData={lessonsData}
          setCerti={setCerti}
          certi={certi}
          content={content}
          setAdName={setAdName}
          adName={adName}
          setAdvisor={setAdvisor}
          advisor={advisor}
          certificate={certificate}
          setCertificate={setCertificate}
          setHeaderImg={setHeaderImg}
          headerImg={headerImg}
          setGettingIconThree={setGettingIconThree}
          setGettingTitleThree={setGettingTitleThree}
          setLearnImgThree={setLearnImgThree}
          setLearnTitleThree={setLearnTitleThree}
          setLearnImgTwo={setLearnImgTwo}
          setGettingIconTwo={setGettingIconTwo}
          setGettingTitleTwo={setGettingTitleTwo}
          setLearnTitleTwo={setLearnTitleTwo}
          gettingIconThree={gettingIconThree}
          gettingTitleThree={gettingTitleThree}
          gettingIconTwo={gettingIconTwo}
          gettingTitleTwo={gettingTitleTwo}
          learnImgThree={learnImgThree}
          learnTitleThree={learnTitleThree}
          learnImgTwo={learnImgTwo}
          learnTitleTwo={learnTitleTwo}
          setGettingIcon={setGettingIcon}
          gettingIcon={gettingIcon}
          setGettingTitle={setGettingTitle}
          gettingTitle={gettingTitle}
          setCertDesc={setCertDesc}
          certDesc={certDesc}
          certImg={certImg}
          setCertImg={setCertImg}
          certTitle={certTitle}
          setCertTitle={setCertTitle}
          setOutComeGet={setOutComeGet}
          outComeGet={outComeGet}
          setLearnImg={setLearnImg}
          learnImg={learnImg}
          setContent={setContent}
          formData={formData}
          setFormData={setFormData}
          title={title}
          imgAsset={imgAsset}
          supportAdvisor={supportAdvisor}
          calories={calories}
          intensity={intensity}
          learnTitle={learnTitle}
          setLearnTitle={setLearnTitle}
          handleChangeCourse={handleChangeCourse}
          handleSubmit={handleSubmit}
          outCertImg={outCertImg}
          setOutCertImg={setOutCertImg}
          tagsData={tagsData}
          setTagsData={setTagsData}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      )}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmit();
            setShowUnPublishAlert();
          }}
          onCancel={() => setShowUnPublishAlert()}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/courses/all");
          }}
        >
          Courses has been edited
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditCourses;
