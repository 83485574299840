export const DiscoverTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Discover Name",
    dataField: "name",
  },
  {
    text: "Card Description",
    dataField: "card_desription",
  },
  {
    text: "Series Description",
    dataField: "series_desription",
  },
  {
    text: "Singles Description",
    dataField: "singles_desription",
  }
];

export const fileTypes = {
  DISCOVER_IMAGE: "DISCOVER_IMAGE",
  BACKGROUND_IMAGE: "BACKGROUND_IMAGE",
  SERIES_BACKGROUND_IMAGE: "SERIES_BACKGROUND_IMAGE",
};