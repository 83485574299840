import React, { useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import FileUploader from "../FileUploader";
import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";

const MusicForm = ({
  musicID,
  pageHeading = "Create Media",
  handelInput,
  title,
  language,
  objectType,
  description,
  isFree,
  UserRecommendationGender,
  genderData,
  handleGenderSelect,
  UserRecommendationAge,
  ageData,
  handleAgeSelect,
  UserRecommendationMaritalStatus,
  maritalData,
  handleMaritalSelect,
  UserRecommendationMood,
  UserRecommendationStress,
  UserRecommendationBPM,
  bpmData,
  handleBpmSelect,
  handleCategoriesSelect,
  categories,
  handleSubCategoriesSelect,
  subCategories,
  handleTagsSelect,
  tags,
  handleMoodsSelect,
  moods,
  handleStressLevelSelect,
  stressLevel,
  moodData,
  stressLevelData,
  setThumbnail,
  thumbnail,
  audio,
  setAudio,
  handleSubmit,
  isError,
  errorMessage,
  errorType,
  showSelectAudio = true,
  isDisabledBtn = false,
  createBtnText = "Create Media",
  setThumbnailColor,
  thumbnailColor,
  img115x150,
  setImg115x150,
  img145x345,
  setImg145x345,
  img155x135,
  setImg155x135,
  img180x160,
  setImg180x160,
  UserRecommendationFocusOnPresentMoment,
  focusOnPresentMomentData,
  handlefocusOnPresentMomentSelect,
  UserRecommendationExperienceStressAnxiety,
  experienceStressAnxietyData,
  handleExperienceStressAnxiety,
  UserRecommendationSleep,
  sleepData,
  handleUserSleep,
  UserRecommendationHoursOfSleep,
  sleepHoursData,
  handleUserSleepHours,
  UserRecommendationIrritatedOrAngry,
  irritatedOrAngryData,
  handleIrritatedOrAngry,
  UserRecommendationFrequentLethargy,
  lethargyData,
  handleLethargy,
  UserRecommendationCommitmentAtWork,
  commitWorkData,
  handleCommitWork,
  UserRecommendationIssuesInRelationship,
  issueRelationshipData,
  handleIssueInRelationship,
  UserRecommendationLowSelfEsteem,
  esteemData,
  handleEsteem,
  UserRecommendationHoursSpendOnSocialMedia,
  hoursOnSocialData,
  handleHoursOnSocialMedia,
  useSameAudio,
  setUseSameAudio,
  setUseSameThumbnail,
  useSameThumbnail,
  prevThumbnail,
  useSameImg145x345,
  useSameImg115x150,
  useSameImg155x135,
  useSameImg180x160,
  setUseSameImg115x150,
  setUseSameImg145x345,
  setUseSameImg155x135,
  setUseSameImg180x160,
  prevImg115x150,
  prevImg145x345,
  prevImg155x135,
  prevImg180x160,
  getCategory,
  getSubCategory,
  getTags,
  mediaVideo,
  editMediaVideo,
  setVideo,
  video,
  level,
  handleLevelSelect,
}) => {
  const [openColorBox, setopenColorBox] = useState(false);
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row style={{ alignItems: "center" }}>
              <Col xs={10}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {editMediaVideo === true ? (
                    <>
                      <h3 className="m-0"> Video Media</h3>
                    </>
                  ) : (
                    <>
                      <h3 className="m-0"> Audio Media</h3>
                    </>
                  )}
                  {/* {
                    editMediaVideo && mediaVideo !== true ? 
                    <>
                    <h3 className='m-0'>Edit Video Media</h3>
                    </>
                    :
                    <>
                    <h3 className='m-0'>Edit Audio Media</h3>
                    
                    </>
                  } */}
                </div>
              </Col>
              {pageHeading === "Edit Music" && (
                <Col>
                  <Link to={`/dashboard/media/alternate/${musicID}`}>
                    <Button color="primary">Create Alternate</Button>
                  </Link>
                </Col>
              )}
            </Row>

            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row style={{ justifyContent: "flex-end" }}>
                <Col lg={2}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">Language</Label>
                      <Input
                        name="language"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={language}
                        onChange={handelInput}
                        validate={{ required: { value: true } }}
                        id="languageSelect"
                      >
                        <option
                          value="en"
                          selected={language === "en" ? true : false}
                          disabled={language === "en" ? true : false}
                        >
                          ENGLISH
                        </option>
                        <option
                          value="hi"
                          selected={language === "hi" ? true : false}
                          disabled={language === "hi" ? true : false}
                        >
                          HINDI
                        </option>
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  {" "}
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
                <Col className="d-flex align-items-center mt-3" xs={2}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="isFree"
                      checked={isFree}
                      onClick={handelInput}
                      id="free"
                    />
                    <Label className="custom-control-label" htmlFor="free">
                      Is Free
                    </Label>
                  </div>
                </Col>
                {/* <Col lg={2}>
                  <FormGroup>
                    <div dir='ltr'>
                      <Label htmlFor='notificationSelect'>Media Type</Label>
                      <Input
                        name='objectType'
                        placeholder='Select Type'
                        type='select'
                        className='form-control'
                        value={objectType}
                        onChange={handelInput}
                        validate={{ required: { value: true } }}
                        id='languageSelect'
                      >
                        <option
                          value='AUDIO'
                          selected={objectType === 'AUDIO' ? true : false}
                          disabled={objectType === 'AUDIO' ? true : false}
                        >
                          AUDIO
                        </option>
                        <option
                          value='VIDEO'
                          selected={objectType === 'VIDEO' ? true : false}
                          disabled={objectType === 'VIDEO' ? true : false}
                        >
                          VIDEO
                        </option>
                      </Input>
                    </div>
                  </FormGroup>
                </Col> */}
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handelInput}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="description"
                />
              </FormGroup>

              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="genderSelect">Gender</Label>
                    <Select
                      value={UserRecommendationGender}
                      isMulti={true}
                      onChange={handleGenderSelect}
                      options={genderData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        genderData && genderData.length === 0
                          ? "Loading..."
                          : "Select Gender"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="ageSelect">Age</Label>
                    <Select
                      value={UserRecommendationAge}
                      isMulti={true}
                      onChange={handleAgeSelect}
                      options={ageData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        ageData && ageData.length === 0
                          ? "Loading..."
                          : "Select Age"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="maritalSelect">Select Marital Status</Label>
                    <Select
                      value={UserRecommendationMaritalStatus}
                      isMulti={true}
                      onChange={handleMaritalSelect}
                      options={maritalData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        maritalData && maritalData.length === 0
                          ? "Loading..."
                          : "Select Marital Status"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="moodSelect">Mood</Label>
                    <Select
                      value={moods}
                      isMulti={true}
                      onChange={handleMoodsSelect}
                      options={moodData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        moodData && moodData.length === 0
                          ? "Loading..."
                          : "Select Mood"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="bpmSelect">BPM</Label>
                    <Select
                      value={UserRecommendationBPM}
                      isMulti={true}
                      onChange={handleBpmSelect}
                      options={bpmData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        bpmData && bpmData.length === 0
                          ? "Loading..."
                          : "Select Mood"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="stressSelect">Stress Level</Label>
                    <Select
                      value={stressLevel}
                      isMulti={true}
                      onChange={handleStressLevelSelect}
                      options={stressLevelData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        stressLevelData && stressLevelData.length === 0
                          ? "Loading..."
                          : "Select Stress Level"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Categories</Label>
                    <Select
                      value={categories}
                      isMulti={false}
                      onChange={handleCategoriesSelect}
                      options={getCategory}
                      classNamePrefix="select2-selection"
                      placeholder={
                        getCategory?.length === 0
                          ? "Loading..."
                          : "Select Categories"
                      }
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Sub Categories</Label>
                    <Select
                      value={subCategories}
                      isMulti={true}
                      onChange={handleSubCategoriesSelect}
                      options={getSubCategory}
                      classNamePrefix="select2-selection"
                      placeholder={
                        getSubCategory?.length === 0
                          ? "No Subcategory"
                          : "Select Sub Categories"
                      }
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Tags</Label>
                    <Select
                      value={tags}
                      isMulti={true}
                      onChange={handleTagsSelect}
                      options={getTags}
                      classNamePrefix="select2-selection"
                      placeholder={
                        getTags?.length === 0 ? "Loading..." : "Select Tags"
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Focus On Present Moment
                    </Label>
                    <Select
                      value={UserRecommendationFocusOnPresentMoment}
                      isMulti={true}
                      onChange={handlefocusOnPresentMomentSelect}
                      options={focusOnPresentMomentData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        focusOnPresentMomentData?.length === 0
                          ? "Loading..."
                          : "Select Focus on present moment"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Select User Stress Anxiety
                    </Label>
                    <Select
                      value={UserRecommendationExperienceStressAnxiety}
                      isMulti={true}
                      onChange={handleExperienceStressAnxiety}
                      options={experienceStressAnxietyData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        experienceStressAnxietyData?.length === 0
                          ? "Loading..."
                          : "Select User Stress Anxiety"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Select User Sleep</Label>
                    <Select
                      value={UserRecommendationSleep}
                      isMulti={true}
                      onChange={handleUserSleep}
                      options={sleepData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        sleepData?.length === 0
                          ? "Loading..."
                          : "Select User Sleep"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Users Hours of sleep
                    </Label>
                    <Select
                      value={UserRecommendationHoursOfSleep}
                      isMulti={true}
                      onChange={handleUserSleepHours}
                      options={sleepHoursData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        sleepHoursData?.length === 0
                          ? "Loading..."
                          : "Select User Sleep Hours"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Select User Irritated Or Angry
                    </Label>
                    <Select
                      value={UserRecommendationIrritatedOrAngry}
                      isMulti={true}
                      onChange={handleIrritatedOrAngry}
                      options={irritatedOrAngryData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        irritatedOrAngryData?.length === 0
                          ? "Loading..."
                          : "Select User Irritated or angry"
                      }
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Select User Frequent Lethargy
                    </Label>
                    <Select
                      value={UserRecommendationFrequentLethargy}
                      isMulti={true}
                      onChange={handleLethargy}
                      options={lethargyData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        lethargyData?.length === 0
                          ? "Loading..."
                          : "Select User Frequent Lethargy"
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User commitment at work
                    </Label>
                    <Select
                      value={UserRecommendationCommitmentAtWork}
                      isMulti={true}
                      onChange={handleCommitWork}
                      options={commitWorkData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        commitWorkData?.length === 0
                          ? "Loading..."
                          : "Select User commitment at work"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Select User Issue in Relationship
                    </Label>
                    <Select
                      value={UserRecommendationIssuesInRelationship}
                      isMulti={true}
                      onChange={handleIssueInRelationship}
                      options={issueRelationshipData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        issueRelationshipData?.length === 0
                          ? "Loading..."
                          : "Select User Issue in Relationship"
                      }
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      Select User Low Self Esteem
                    </Label>
                    <Select
                      value={UserRecommendationLowSelfEsteem}
                      isMulti={true}
                      onChange={handleEsteem}
                      options={esteemData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        esteemData?.length === 0
                          ? "Loading..."
                          : "Select User Low Self Esteem"
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Level</Label>
                    <select
                      value={level}
                      onChange={handleLevelSelect}
                      className="form-control"
                    >
                      <option value="">Select Level</option>
                      <option value="BEGINNER">Beginner</option>
                      <option value="INTERMEDIATE">Intermediate</option>
                      <option value="ADVANCED">Advanced</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Hours On Social Media
                    </Label>
                    <Select
                      value={UserRecommendationHoursSpendOnSocialMedia}
                      isMulti={true}
                      onChange={handleHoursOnSocialMedia}
                      options={hoursOnSocialData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        hoursOnSocialData?.length === 0
                          ? "Loading..."
                          : "Select User Hours On Social Media"
                      }
                      isDisabled={pageHeading === "Alternate Music"}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={showSelectAudio ? 6 : 12}>
                  {pageHeading === "Alternate Music" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="useSameThumbnail"
                        checked={useSameThumbnail}
                        onChange={(e) => setUseSameThumbnail(e.target.checked)}
                        id="useSameThumbnail"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="useSameThumbnail"
                      >
                        Use Same Thumbnail
                      </Label>
                    </div>
                  )}
                  <FileUploader
                    file={useSameThumbnail ? prevThumbnail : thumbnail}
                    setFile={setThumbnail}
                    dragText="Drop media image here"
                    heading="Select Track Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    setImageColor={setThumbnailColor}
                    imageColor={thumbnail && thumbnailColor}
                    isImage
                    isDisabled={useSameThumbnail}
                    imgRatioRangeLow={0.95}
                    imgRatioRangeHigh={1.05}
                    errMsg="Please select square image"
                  />
                </Col>
                {showSelectAudio && (
                  <Col lg={6}>
                    {pageHeading === "Alternate Music" && (
                      <div
                        className="custom-control custom-switch mb-2 "
                        dir="ltr"
                        style={{
                          position: "absolute",
                          top: "18px",
                          left: "auto",
                          right: "20px",
                        }}
                      >
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="useSameAudio"
                          checked={useSameAudio}
                          onChange={(e) => setUseSameAudio(e.target.checked)}
                          id="useSameAudio"
                        />
                        <Label
                          className="custom-control-label"
                          htmlFor="useSameAudio"
                        >
                          Use Same Audio
                        </Label>
                      </div>
                    )}
                    {mediaVideo ? (
                      <>
                        <FileUploader
                          file={video}
                          setFile={setVideo}
                          dragText="Drop video here"
                          heading="Select Video"
                          accept=".mp4, video/*"
                          isDisabled={useSameAudio}
                        />
                      </>
                    ) : (
                      <>
                        <FileUploader
                          file={audio}
                          setFile={setAudio}
                          dragText="Drop track audio here"
                          heading="Select Track Audio"
                          accept=".mp3,audio/*"
                          isDisabled={useSameAudio}
                        />
                      </>
                    )}
                  </Col>
                )}
              </Row>

              <Row>
                <Col>
                  {pageHeading === "Alternate Music" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="usePrevImg115x150"
                        checked={useSameImg115x150}
                        onChange={(e) => setUseSameImg115x150(e.target.checked)}
                        id="usePrevImg115x150"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="usePrevImg115x150"
                      >
                        Use Previous Image
                      </Label>
                    </div>
                  )}
                  <FileUploader
                    file={useSameImg115x150 ? prevImg115x150 : img115x150}
                    setFile={setImg115x150}
                    dragText="Drop 115x150 image here"
                    heading="Select 115x150 Image (Optional)"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    isImage
                    imgRatioRangeLow={0.7}
                    imgRatioRangeHigh={0.8}
                    errMsg="Please select 115x150 image"
                  />
                </Col>
                <Col lg={6}>
                  {pageHeading === "Alternate Music" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="usePrevImg145x345"
                        checked={useSameImg145x345}
                        onChange={(e) => setUseSameImg145x345(e.target.checked)}
                        id="usePrevImg145x345"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="usePrevImg145x345"
                      >
                        Use Previous Image
                      </Label>
                    </div>
                  )}
                  <FileUploader
                    file={useSameImg145x345 ? prevImg145x345 : img145x345}
                    setFile={setImg145x345}
                    dragText="Drop 145x345 image here"
                    heading="Select 145x345 Image (Optional)"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    isImage
                    imgRatioRangeLow={0.38}
                    imgRatioRangeHigh={0.46}
                    errMsg="Please select 145x345 image"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {pageHeading === "Alternate Music" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="usePrevImg180x160"
                        checked={useSameImg180x160}
                        onChange={(e) => setUseSameImg180x160(e.target.checked)}
                        id="usePrevImg180x160"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="usePrevImg180x160"
                      >
                        Use Previous Image
                      </Label>
                    </div>
                  )}
                  <FileUploader
                    file={useSameImg180x160 ? prevImg180x160 : img180x160}
                    setFile={setImg180x160}
                    dragText="Drop 180x160 image here"
                    heading="Select 180x160 Track Image (Optional)"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    isImage
                    imgRatioRangeLow={1.07}
                    imgRatioRangeHigh={1.17}
                    errMsg="Please select 180x160 image"
                  />
                </Col>
                <Col lg={6}>
                  {pageHeading === "Alternate Music" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="usePreviousImg155x135"
                        checked={useSameImg155x135}
                        onChange={(e) => setUseSameImg155x135(e.target.checked)}
                        id="usePreviousImg155x135"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="usePreviousImg155x135"
                      >
                        Use Previous Image
                      </Label>
                    </div>
                  )}
                  <FileUploader
                    file={useSameImg155x135 ? prevImg155x135 : img155x135}
                    setFile={setImg155x135}
                    dragText="Drop 155x135 image here"
                    heading="Select 155x135 Image (Optional)"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    isImage
                    imgRatioRangeLow={1.1}
                    imgRatioRangeHigh={1.19}
                    errMsg="Please select 155x135 image"
                  />
                </Col>
              </Row>
              {thumbnailColor && (
                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      onClick={() => setopenColorBox(!openColorBox)}
                      value={thumbnailColor}
                      readOnly
                    />
                    {openColorBox && (
                      <SketchPicker
                        color="#fff"
                        value={thumbnailColor}
                        width="350px"
                        onChangeComplete={(e) => setThumbnailColor(e.hex)}
                      />
                    )}
                  </Col>
                </Row>
              )}

              {isError && (
                <Alert color={errorType} role="alert">
                  {errorMessage}
                </Alert>
              )}

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MusicForm;
