import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import AuthState from "./context/auth/AuthState";

import { MainRoutes } from "./Constants/routes";

import "./theme.scss";
import "./style.scss";

const App = () => {
  return (
    <AuthState>
      <Router>
        <Switch>
          <Redirect exact from={`/`} to={`/dashboard`} />{" "}
          {MainRoutes.map(({ Component, path, exact = false }) => {
            return (
              <Route
                key={path}
                component={Component}
                path={path}
                exact={exact}
              />
            );
          })}{" "}
          <Redirect exact to={`/dashboard`} />{" "}
        </Switch>{" "}
      </Router>{" "}
    </AuthState>
  );
};

export default App;
