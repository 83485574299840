import React from "react";

import { Card, CardBody, Row, Col, Button } from "reactstrap";

import AnalysisCardGraph from "./Components/AnalysisCardGraph";

const EarningReports = ({
  heading,
  amount,
  btnText,
  btnHandleClick,
  charts,
  cardColNum,
  customStyle,
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {heading && (
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="card-title mb-0 py-2">
                {heading}
                {amount && (
                  <span className="font-weight-bold text-secondary">
                    {amount}
                  </span>
                )}
              </h4>

              {btnText && btnHandleClick && (
                <div className="page-title-right">
                  <Button
                    color="primary"
                    className="waves-effect waves-light"
                    style={{
                      width: "100%",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                    onClick={btnHandleClick}
                  >
                    {btnText}
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className="text-center">
            {charts && (
              <Row>
                {charts.map(
                  ({ subHeading, subAmount, color, percent, clickHandler }) => (
                    <Col
                      {...(customStyle && { style: customStyle })}
                      {...(!customStyle && {
                        xl: cardColNum ? cardColNum : 12 / charts.length,
                      })}
                    >
                      <div
                        className={`py-4 ${clickHandler && "bg-hover-effect"}`}
                        onClick={clickHandler ? clickHandler : null}
                      >
                        <AnalysisCardGraph percent={percent} color={color} />

                        <p className="text-muted text-truncate mb-2">
                          {subHeading}
                        </p>
                        {subAmount && <h5>{subAmount}</h5>}
                      </div>
                    </Col>
                  )
                )}
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EarningReports;
