import React from "react";
import { Card, CardBody, Media } from "reactstrap";

const DashobardMiniWidgets = ({ title, value, icon, iconColorClass }) => {
  console.log(iconColorClass);
  return (
    <Card>
      <CardBody>
        <Media>
          <Media body className="overflow-hidden">
            <p className="text-truncate font-size-14 mb-2">{title}</p>
            <h4 className="mb-0">{value}</h4>
          </Media>
          <div className={iconColorClass || "text-primary"}>
            <i className={icon + " font-size-24"}></i>
          </div>
        </Media>
      </CardBody>

      {/* <CardBody className="border-top py-3">
      <div className="text-truncate">
          <span className="badge badge-soft-success font-size-11 mr-1"><i className="mdi mdi-menu-up"> </i> {report.rate}</span>
          <span className="text-muted ml-2">{report.desc}</span>
      </div>
  </CardBody> */}
    </Card>
  );
};

export default DashobardMiniWidgets;
