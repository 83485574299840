import AllCoupons from "../Components/AllCoupons";
import CreateCoupons from "../Components/CreateCoupons";


export const CouponRoutes = [
  {
    Component: AllCoupons,
    path: "/all",
    exact: true,
    id: "dashboardCouponAll",
    routePath: "/dashboard/coupon/all",
    name: "All Coupons",
  },
  {
    Component: CreateCoupons,
    path: "/create",
    exact: true,
    id: "dashboardCouponCreate",
    routePath: "/dashboard/coupon/create",
    name: "Add Coupons",
  },
];
