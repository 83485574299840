import axios from "axios";

import baseDomain, {
  musicAPI,
  searchMusicAPI,
  createdMusicAPI,
  publishedMusicAPI,
  publishMusicAPI,
  unpublishMusicAPI,
  musicBinAPI,
  restoreMusicAPI,
  secureStsCredApi,
  regenerateSecureStsTokenApi,
} from "../../../../Constants/apiRoutes";

export const getAllMusicReq = ({ page, limit }) => {
  return axios.get(`${baseDomain}${musicAPI}?page=${page}&limit=${limit}`);
};

export const createdMusicReq = ({ page, limit, objectType }) => {
  return axios.get(
    `${baseDomain}${createdMusicAPI}?page=${page}&limit=${limit}&objectType=${objectType}`
  );
};

export const publishedMusicReq = ({ page, limit, objectType }) => {
  return axios.get(
    `${baseDomain}${publishedMusicAPI}?page=${page}&limit=${limit}&objectType=${objectType}`
  );
};

export const searchMusic = (text) => {
  return axios.get(`${baseDomain}${searchMusicAPI}/${text}`);
};

export const publishMusic = (musicID) => {
  return axios.put(`${baseDomain}${publishMusicAPI}/${musicID}`);
};

export const unpublishMusic = (musicID) => {
  return axios.put(`${baseDomain}${unpublishMusicAPI}/${musicID}`);
};

export const getMusicBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${musicBinAPI}?page=${page}&limit=${limit}`);
};

export const restoreMusic = (musicID) => {
  return axios.put(`${baseDomain}${restoreMusicAPI}/${musicID}`);
};

export const getMusicReq = (musicID) => {
  return axios.get(`${baseDomain}${musicAPI}/${musicID}`);
};

export const createMusicReq = (data) => {
  return axios.post(`${baseDomain}${musicAPI}`, data);
};

export const editMusicReq = (data, musicID) => {
  return axios.put(`${baseDomain}${musicAPI}/${musicID}`, data);
};

export const deleteMusicReq = (musicID) => {
  return axios.delete(`${baseDomain}${musicAPI}/${musicID}`);
};

export const getSecureStsCred = (params) => {
  return axios.get(`${baseDomain}${secureStsCredApi}`, {
    params,
  });
};

export const regenerateSecureStsToken = () => {
  return axios.get(`${baseDomain}${regenerateSecureStsTokenApi}`);
};
