import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Button } from "reactstrap";
import FileUploader from "../FileUploader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import htmlToDraft from "html-to-draftjs";

const AllCourseEditComp = ({
  setContent,
  setGettingIcon,
  gettingIcon,
  setGettingTitle,
  gettingTitle,
  setCertDesc,
  certDesc,
  certImg,
  setCertImg,
  certTitle,
  setCertTitle,
  setOutComeGet,
  outComeGet,
  setLearnImg,
  learnImg,
  title,
  // description,
  // setDescription,
  calories,
  learnTitle,
  setLearnTitle,
  gettingIconThree,
  gettingTitleThree,
  gettingIconTwo,
  gettingTitleTwo,
  learnImgThree,
  learnTitleThree,
  learnImgTwo,
  learnTitleTwo,
  setGettingIconThree,
  setGettingTitleThree,
  setLearnImgThree,
  setLearnTitleThree,
  setLearnImgTwo,
  setGettingIconTwo,
  setGettingTitleTwo,
  setLearnTitleTwo,
  setHeaderImg,
  headerImg,
  // outComeCompletion,
  // setOutComeCompletion,
  setCertificate,
  advisor,
  setAdName,
  adName,
  setCerti,
  certi,
  setLessonName,
  lessonName,
  lessonsData,
  intensityData,
  setIntensityName,
  intensityName,
  content,
  certificate,
  handleSubmit,
  handleChangeCourse,
  faqData,
  faq,
  setFaq,
  setTab,
  tab,
  outCertImg,
  setOutCertImg,
  tagsData,
  setTagsData,
  setSelectedTag,
  selectedTag
}) => {
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  const blocksFromHtmlCert = htmlToDraft(certificate);
  const data = blocksFromHtmlCert;
  const contentStateCert = ContentState.createFromBlockArray(
    data?.contentBlocks,
    data?.entityMap
  );

  const [outComeCompletion, setOutComeCompletion] = useState(
    EditorState.createWithContent(contentStateCert)
  );
  const [description, setDescription] = useState(
    EditorState.createWithContent(contentState)
  );

  const rawContentState = convertToRaw(description.getCurrentContent());
  const certificateState = convertToRaw(outComeCompletion.getCurrentContent());

  const markup = draftToHtml(
    rawContentState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );
  const certificateMarkup = draftToHtml(
    certificateState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );

  setContent(markup);
  setCertificate(certificateMarkup);

  let advisorName =
    advisor &&
    advisor?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.name,
      };
    });

    let tagsObj =
    tagsData &&
    tagsData?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.name,
      };
    });

  let faqName =
    faqData &&
    faqData?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.title,
      };
    });
  const tabData = [
    {
      key: "MIND",
      label: "MIND",
      value: "MIND",
    },
    {
      key: "BODY",
      label: "BODY",
      value: "BODY",
    },
  ];
  let lessonObj =
    lessonsData &&
    lessonsData?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.title,
      };
    });

  console.log(certi, "zxcvbnm");
  const addCertificate = (value) => {
    
    setCerti([value, ...certi]);
    setCertImg("");
    setCertDesc("");
    setCertTitle("");
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Edit Course</h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
                onValidSubmit={() => handleSubmit()}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="title">Course Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter title"
                        value={title}
                        onChange={handleChangeCourse}
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="pageFilter">Tab</Label>
                      <Select
                        value={tab}
                        isMulti={false}
                        onChange={(select) => setTab(select)}
                        options={tabData}
                        classNamePrefix="select2-selection"
                        placeholder="Select Tab"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FileUploader
                      file={headerImg}
                      setFile={setHeaderImg}
                      dragText="Drop Header Image here"
                      heading="Header Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="calories">Calories</Label>
                      <AvField
                        name="calories"
                        placeholder="Enter Calories"
                        value={calories}
                        onChange={handleChangeCourse}
                        type="text"
                        errorMessage="calories is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="calories"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="intensity">Intentsity</Label>
                      <Select
                        value={intensityName}
                        isMulti={false}
                        onChange={(select) => setIntensityName(select)}
                        options={intensityData}
                        classNamePrefix="select2-selection"
                        placeholder="Select Intensity"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="supportAdvisor">Co-ordinator Name</Label>
                      <Select
                        value={adName}
                        isMulti={false}
                        onChange={(select) => setAdName(select)}
                        options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Co-ordinator Name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="lessons">Lessons</Label>
                      <Select
                        value={lessonName}
                        isMulti={true}
                        onChange={(selected) => setLessonName(selected)}
                        options={lessonObj}
                        classNamePrefix="select2-selection"
                        placeholder="Select Lessons"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="tags">Tags</Label>
                      <Select
                        value={selectedTag}
                        isMulti={true}
                        onChange={(selected) => setSelectedTag(selected)}
                        options={tagsObj}
                        classNamePrefix="select2-selection"
                        placeholder="Select Tags"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="Faqs">FAQs</Label>
                      <Select
                        value={faq}
                        isMulti={true}
                        onChange={(selected) => setFaq(selected)}
                        options={faqName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Lessons"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label htmlFor="description">Things to Note (desc)</Label>
                    <Editor
                      editorState={description}
                      toolbarClassName="toolbarClass"
                      wrapperClassName="wrapperClass"
                      editorClassName="editorClass"
                      onEditorStateChange={setDescription}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="mt-3" htmlFor="learning">
                        What you’ll Learn (Learning)
                      </Label>
                      <Row>
                        <Col>
                          <Label htmlFor="learnTitle">Title </Label>
                          <AvField
                            name="learnTitle"
                            placeholder="Enter title"
                            value={learnTitle}
                            onChange={(e) => setLearnTitle(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="learnTitle"
                          />

                          <FileUploader
                            file={learnImg}
                            setFile={setLearnImg}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="learnTitleTwo">Title</Label>
                          <AvField
                            name="learnTitleTwo"
                            placeholder="Enter title"
                            value={learnTitleTwo}
                            onChange={(e) => setLearnTitleTwo(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="learnTitleTwo"
                          />

                          <FileUploader
                            file={learnImgTwo}
                            setFile={setLearnImgTwo}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="learnTitleThree">Title</Label>
                          <AvField
                            name="learnTitleThree"
                            placeholder="Enter title"
                            value={learnTitleThree}
                            onChange={(e) => setLearnTitleThree(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="learnTitleThree"
                          />

                          <FileUploader
                            file={learnImgThree}
                            setFile={setLearnImgThree}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="learning">
                        What you’ll Get (Getting)
                      </Label>
                      <Row>
                        <Col>
                          <Label htmlFor="gettingTitle">Title </Label>
                          <AvField
                            name="gettingTitle"
                            placeholder="Enter title"
                            value={gettingTitle}
                            onChange={(e) => setGettingTitle(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="gettingTitle"
                          />

                          <FileUploader
                            file={gettingIcon}
                            setFile={setGettingIcon}
                            dragText="Drop Icon here"
                            heading="Getting Icon"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="gettingTitleTwo">Title</Label>
                          <AvField
                            name="gettingTitleTwo"
                            placeholder="Enter title"
                            value={gettingTitleTwo}
                            onChange={(e) => setGettingTitleTwo(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="gettingTitleTwo"
                          />

                          <FileUploader
                            file={gettingIconTwo}
                            setFile={setGettingIconTwo}
                            dragText="Drop Icon here"
                            heading="Getting Icon"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="gettingTitleThree">Title</Label>
                          <AvField
                            name="gettingTitleThree"
                            placeholder="Enter title"
                            value={gettingTitleThree}
                            onChange={(e) =>
                              setGettingTitleThree(e.target.value)
                            }
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="gettingTitleThree"
                          />

                          <FileUploader
                            file={gettingIconThree}
                            setFile={setGettingIconThree}
                            dragText="Drop Icon here"
                            heading="Getting Icon"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="learning">Outcome</Label>

                      <Row>
                        <Col>
                          <Label htmlFor="outComeGet">What you’ll get </Label>
                          <AvField
                            name="outComeGet"
                            placeholder="Enter title"
                            value={outComeGet}
                            onChange={(e) => setOutComeGet(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="outComeGet"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FileUploader
                            file={outCertImg}
                            setFile={setOutCertImg}
                            dragText="Drop certificate template here"
                            heading="Certificate Template"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label htmlFor="Certification">Certification</Label>
                          {certi?.map((ele) => {
                            return (
                              <Row>
                                <Col className="mt-3">
                                  <Label htmlFor="certTitle">
                                    Certification Title
                                  </Label>
                                  <AvField
                                    name="certTitle"
                                    placeholder="Enter title"
                                    value={ele?.title}
                                    type="text"
                                    errorMessage="Title name is required"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="certTitle"
                                  />
                                </Col>
                                <Col className="mt-3">
                                  <Label htmlFor="certDesc">
                                    Certification Description
                                  </Label>
                                  <AvField
                                    name="certDesc"
                                    placeholder="Enter Certificate Description"
                                    value={ele?.description}
                                    type="text"
                                    errorMessage="Title name is required"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="certDesc"
                                  />
                                </Col>
                                <Col>
                                  <FileUploader
                                    file={ele?.imgAsset}
                                    setFile={ele?.imgAsset}
                                    dragText="Drop Certification Image here"
                                    heading="Certification Image"
                                    accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                                  />
                                </Col>
                              </Row>
                            );
                          })}

                          <Row>
                            <Col className="mt-3">
                              <Label htmlFor="certTitle">
                                Certification Title
                              </Label>
                              <AvField
                                name="certTitle"
                                placeholder="Enter title"
                                value={certTitle}
                                onChange={(e) => setCertTitle(e.target.value)}
                                type="text"
                                errorMessage="Title name is required"
                                className="form-control"
                                validate={{ required: { value: false } }}
                                id="certTitle"
                              />
                            </Col>
                            <Col className="mt-3">
                              <Label htmlFor="certDesc">
                                Certification Description
                              </Label>
                              <AvField
                                name="certDesc"
                                placeholder="Enter Certificate Description"
                                value={certDesc}
                                onChange={(e) => setCertDesc(e.target.value)}
                                type="text"
                                errorMessage="Title name is required"
                                className="form-control"
                                validate={{ required: { value: false } }}
                                id="certDesc"
                              />
                            </Col>
                            <Col>
                              <FileUploader
                                file={certImg}
                                setFile={setCertImg}
                                dragText="Drop Certification Image here"
                                heading="Certification Image"
                                accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Button
                        color="primary"
                        className="w-20 mb-3"
                        type="button"
                        onClick={() =>
                          addCertificate({
                            title: certTitle,
                            imgAsset: certImg,
                            description: certDesc,
                          })
                        }
                      >
                        Add this Certification
                      </Button>
                      <Row>
                        <Col>
                          <Label htmlFor="title">Post Completion</Label>
                          <Editor
                            editorState={outComeCompletion}
                            toolbarClassName="toolbarClass"
                            wrapperClassName="wrapperClass"
                            editorClassName="editorClass"
                            onEditorStateChange={setOutComeCompletion}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  // onClick={() => handleSubmit()}
                  // disabled={isDisabledBtn}
                >
                  Edit Course
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllCourseEditComp;
