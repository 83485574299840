import axios from "axios";

import baseDomain, { notificationAPI, notificationBinAPI, deleteNotificationAPI, restoreNotificationAPI, searchNotificationAPI } from "../../../../Constants/apiRoutes";

export const getNotification = ({ page, limit }) => {
  return axios.get(`${baseDomain}${notificationAPI}?page=${page}&limit=${limit}`);
};

export const searchNotification = (text) => {
  return axios.get(`${baseDomain}${searchNotificationAPI}/${text}`);
};

export const getNotificationBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${notificationBinAPI}?page=${page}&limit=${limit}`);
};

export const deleteNotificationReq = (notificationid) => {
  return axios.put(`${baseDomain}${deleteNotificationAPI}/${notificationid}`);
};

export const restoreNotificationReq = (notificationid) => {
  return axios.put(`${baseDomain}${restoreNotificationAPI}/${notificationid}`);
};