const cmsBaseDomain = 'https://cms-prod-be.theyogainstitute.org/v1'
import axios from 'axios'

export const getAllImages = () => {
  return axios.get(`${cmsBaseDomain}/image`)
}

export const uploadImages = (data) => {
  return axios.post(`${cmsBaseDomain}/image`,data)
}


