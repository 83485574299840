export const baseDomain = "https://back-end-preprod.nispand.com";

export const stage = "/v2";

export const loginAPI = "/auth/login"; // POST

export const getMe = "/auth/me"; // GET Protected

// POST, Create Tag Protected
export const tagAPI = "/tag";
export const searchTagAPI = "/tag/searchTag"; // add textInput
export const tagsAPI = "/tag/tags";
export const categoryAPI = "/tag/category";
export const subcategoryAPI = "/tag/subcategory";
export const tagsBinAPI = "/tag/bin/faqtags";
export const categoryBinAPI = "/tag/bin/faqcategory";
export const subcategoryBinAPI = "/tag/bin/subcategory";
export const restoreTagAPI = "/tag/enable"; // add tagID

//FAQ API
export const faqAPI = "/faq";
export const faqsAPI = "/tag/faqtags";
export const editFaqTagApi = "/tag/updateFaq";
export const searchFaqAPI = "/faq/search";

export const searchFaqTagAPI = "/tag/searchFaqTag";
export const addFaqTagApi = "/tag";
export const categoryFaqAPI = "/tag/faqcategory";
export const allFaqTagsApi = "/faq/tags";
export const createFaqAPI = "/faq";
export const unpublishFaqAPI = "/faq/createFaq";
export const publishFaqApi = "/faq/publishedFaq";
export const publishActionApi = "/faq/publish";
export const unpublishActionApi = "/faq/unpublish";
export const searchAllFaqApi = "/faq/searchfaq";
export const updateFaqApi = "/faq/update";
export const editFaqApi = "/faq/getById";
export const quoteAPI = "/factsandquotes";
export const quoteAPIAll = "/factsandquotes/get";
export const searchQuoteAPI = "/quote/searchQuote"; // add textInput
export const quoteBinAPI = "/quote/bin";
export const restoreQuoteAPI = "/quote/conceal"; // add quoteID
export const deprioritiseQuoteAPI = "/quote/deprioritise"; // add quoteID
export const prioritiseQuoteAPI = "/quote/prioritise"; // add quoteID

// Users
export const userApi = "/user";
export const searchUserAPI = "/user/searchUser"; // add textInput
export const userDisableAPI = "/user/disable"; // add userID
export const userEnableAPI = "/user/enable"; // add userID
export const userAllAPI = "/user/all"; // add userID

export const notificationAPI = "/notification";
export const searchNotificationAPI = "/notification/searchNotification"; // add textInput
export const notificationBinAPI = "/notification/bin";
export const deleteNotificationAPI = "/notification/disable"; // add notificationid
export const restoreNotificationAPI = "/notification/enable"; // add notificationid
export const sendNotificationAPI = "/notification/sendAdmin"; // add notificationid

// Music
export const musicAPI = "/media";
export const searchMusicAPI = "/media/search"; // add textInput
export const createdMusicAPI = "/media/createdMusic";
export const publishedMusicAPI = "/media/publishedMusic";
export const publishMusicAPI = "/media/publish"; // add musicID
export const unpublishMusicAPI = "/media/conceal"; // add musicID
export const musicBinAPI = "/media/bin";
export const restoreMusicAPI = "/media/restore"; // add musicID
export const sendAudioNotificationAPI = "/notification/sendAdmin/music"; // add musicID
export const secureStsCredApi = "/media/secure/sts/cred";
export const regenerateSecureStsTokenApi = "/media/secure/sts/regenerate";

// PlansForm
export const planAPI = "/plan/plans";
export const searchPlanAPI = "/plan/searchPlan"; // add textInput
export const deactivatePlanAPI = "/plan/deactivePlan"; // add planID
export const plansBinAPI = "/plan/bin";
export const createPlanAPI = "/plan"; // post
export const viewPlanAPI = "/plan/getById"; // add planID
export const editPlanAPI = "/plan/update"; // add planID
export const restorePlanAPI = "/plan/restore"; // add planID

// CouponsForm
export const couponAPI = "/coupon";
export const createCouponPost = "/coupon"; // couponCrete
export const deleteCouponApi = "/coupon/delete"; // couponDelete
export const updateCouponApi = "/coupon/update"; // couponUpdate
// Discover
export const discoverAPI = "/page/discover"; // GET
export const createDiscoverAPI = "/page/discover"; // POST
export const editDiscoverAPI = "/page/discover"; // PUT

// Dashboard Stats
export const dashboardApi = "/dashboard/data"; // GET
export const userAnalyticsDataApi = "/dashboard/analytics/user"; // GET
export const revenueAnalyticsDataApi = "/dashboard/analytics/revenue"; // GET

//Business
export const businessApi = "/business/users"; //Get
export const searchBusinessAPI = "/business/search";
export const manualuserCreation = "/business/onboardBusiness";
export const businessSearchAPI = "/business/search"; // same as searchBusinessAPI
export const EditUserApi = "/business/updateBusinessUser";
export const getUserEditApi = "/business/userById";
export const getNewVoucher = "/createNewVouchers";

//Blogs

export const createBlogApi = "/blogs";
export const createBlogTagApi = "/tag";
export const unPublishBlogApi = "/blogs/UnpublishedBlogs";
export const publishBlogApi = "/blogs/publishedBlogs";
export const allBlogTags = "/tag/blogs";
export const updateBlogApi = "/blogs/update";
export const deletedBlogsApi = "/blogs/bin";
export const unpublishToPublishBlogApi = "/blogs/publishBlog";
export const publishToUnpublishBlogApi = "/blogs/UnpublishBlog";
export const deleteBlogApi = "/blogs/deleteBlog";
export const getBlogReqApi = "/blogs/getById";
export const searchBlogApi = "/blogs/searchPost";
export const restoreBlogApi = "/blogs/conceal";

export default `${baseDomain}${stage}`; // eslint-disable-line

// Youtube videos
export const getAllVideos = "/yt";
export const createVideo = "/yt";
export const editVideo = "/yt/getById";
export const updateVideo = "/yt";

// Offers
export const createOffer = "/offer";
export const getAllOffers = "/offer";
export const getOffersById = "/offer/getById";
export const updateOffer = "/offer/update";
export const deleteOffer = "/offer/delete";

// Courses
export const coursesApi = "/course"; //GET

// export const secureStsCredApi = '/secure/sts/cred'

export const secureStsVerifyApi = "/secure/sts/verify";

//Upload Content
export const generateCred = "/alibaba/generateCred";

// Media Tags, Categories, SubCategories, and Mapping

export const mediaTagCatAndSubCatAPI = "/mediatags";
export const searchMediaTagAPI = "/mediatags/searchbytype";
export const publishMediaAPI = "/enable"; // add tagID
export const allAudioVideo = "media/getAllAudioVideo"

//Mind
export const getAllCategories = "/pageTab";
export const updateMindTab = "/pageTab"; // add category id
export const mindTabGoals = "/pagetab/get";
export const allMediaItems = "/media";
export const editMind = "/pageTab/getById";
export const getAllSubcategoiresAndCategories = "/mediatags/getByCatSubcat";
export const optionsMedia = "/media/getMediaByCategory"; //dropdown categoryId
export const getAllCatImages = "/mediatags";
export const getMediaTags = "/mediatags/get";
//Playlist Api's

export const createPlaylist = "/modules/nispandplaylist";
export const allMedia = "/media";
export const allUnpublishedPlaylist = "/modules/unpublished";
export const allPublishedPlaylist = "/modules/get";
export const publishPlaylist = "/modules/publish";
export const UnpublishPlaylist = "/modules/unpublish";
export const getById = "/modules/catMod";
export const updatePlaylist = "/modules/updateplaylist";
export const addMedia = "/modules/addmedia";
export const removeMedia = "/modules/remove";

//Todays Programs Api's

export const getProgramsData = "/daily";
export const getProgramsDataById = "/daily/getById";
export const updateProgramsData = "/daily";
export const programMedia = "/media";
export const publishedMusic = "/publishedMusic";

//Advisors
export const getAdvisorsData = "/supportAdvisor";
export const getAdvisorsDataById = "/supportAdvisor";
export const updateAdvisor = "/supportAdvisor";
export const createAdvisor = "/supportAdvisor";

//SleepSurvey
export const createQuestion = "/sleepSurveyquestion";
export const getByIdQuestion = "/sleepSurveyQuestion";
export const getAllQuestions = "/sleepSurveyQuestion";
export const updateQuest = "/sleepSurveyQuestion/update";
export const publishQuest = "/sleepSurveyquestion/publish";
export const unpublishQuest = "/sleepSurveyquestion/unPublish";

//Stories
export const stories = "/stories";
