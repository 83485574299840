import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";
import { useHistory } from "react-router-dom";

const SleepSessionCardUnpublish = ({
  heading = "Unpublished questions",
  tableData = [],
  tableHeading = [],
  handleUnPublishSleepSessionClick,
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  const handleEditSessionClick = (questionId) => {
    history.push("/dashboard/sleep-sessions/editquestions/" + questionId);
  };
  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => {
            handleEditSessionClick(row._id);
          }}
        />
      </div>
    );
  };
  const viewSeriesFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleUnPublishSleepSessionClick(row._id)}
        >
          Publish
        </Button>
      </div>
    );
  };
  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    tableHeading.push({
      text: "Publish Sleep Sessions",
      dataField: "publish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewSeriesFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      const sortedData = [...tableData];
      sortedData.sort((a, b) => a.orderNo - b.orderNo);
      setRowData(sortedData);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <Table rows={rowData} columns={tableHeading} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SleepSessionCardUnpublish;
