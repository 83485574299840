import React, { useState } from "react";

import PlansForm from "../../../../../Components/PlansForm";

const CreatePlans = () => {
  const [formData, setFormData] = useState({
    plan_identifier: "",
    plan_description: "",
    plan_display_price: "",
    plan_discount_price: "",
    plan_duration_indays: "",
    country_code: "IND",
    IOS: false,
    Android: false,
    WEB: false,
    freeDays:"",
  });
  return <PlansForm setFormData={setFormData} formData={formData} />;
};

export default CreatePlans;
