import axios from "axios";
import baseDomain,{
  getAllCategories,
  updateMindTab,
  mindTabGoals,
  allMediaItems,
  editMind,
  getAllSubcategoiresAndCategories,
  optionsMedia,
  getAllCatImages,
  getMediaTags

} from '../../../../Constants/apiRoutes';

export const allCategories =() => {
  return axios.get(`${baseDomain}${getAllCategories}`)
}

export const updateMind =(categoryId,payload) => {
  return axios.put(`${baseDomain}${updateMindTab}/${categoryId}`,payload)
}

export const allTabs = () => {
  return axios.get (`${baseDomain}${mindTabGoals}`)
}

export const getAllMedia = () => {
  return axios.get(`${baseDomain}${allMediaItems}`)
}
export const editMindData = (tabId) => {
  return axios.get(`${baseDomain}${editMind}/${tabId}`)
}
export const getAllDataByCategoriesAndSubcategories = () =>{
  return axios.get (`${baseDomain}${getAllSubcategoiresAndCategories}`)
}
export const mediaitems = (catId) => {
  return axios.get (`${baseDomain}${optionsMedia}/${catId}`)
}
export const getAllCategoryImages = (catId) =>{
  return axios.get (`${baseDomain}${getAllCatImages}/${catId}`)
}
export const getAllDataTags = () => {
  return axios.get(
    `${baseDomain}${getMediaTags}`,
     {
      params: {
        objectType: "TAG",
        page: 1,
        limit: 140000,
      },
    }
  );
};