import AllQuestiions from "../Components/AllSleepSessions";
import CreateQuestions from "../Components/CreateSleepSessions";
import EditQuestions from "../Components/EditSleepSessions";
import SleepSurveyList from "../Components/SleepSurvey";

export const SleepSessionsRoutes = [

  {
    Component: AllQuestiions,
    path: "/all",
    exact: true,
    id: "dashboardSleepSessionsAll",
    routePath: "/dashboard/sleep-sessions/all",
    name: "All questions"
  },

  {
    Component: CreateQuestions,
    path: "/create",
    exact: true,
    id: "dashboardSleepSessionsCreate",
    routePath: "/dashboard/sleep-sessions/create",
    name: "Create questions"
  },

  {
    Component: EditQuestions,
    path: "/editquestions/:questionId",
    exact: true,
    id: "dashboardSleepSessionsEdit"

  },
  {
    Component: SleepSurveyList,
    path: '/survey-list',
    exact: true,
    id: 'dashboardMasterUser',
    routePath: '/dashboard/sleep-sessions/survey-list',
    name: 'Survey'
  },
]
















