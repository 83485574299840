import { AvField, AvForm } from "availity-reactstrap-validation";
import React, {useState} from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Button } from "reactstrap";
import FileUploader from "../FileUploader";
// import { ContentState, Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import DatePicker from "react-flatpickr";

const EditSessionCreateComp = ({
  pageHeading = "Edit Live Session",
  handleChangeSession,
  headerImg,
  setHeaderImg,
  faq,
  setFaq,
  setAdName,
  adName,
  setLearnImgThree,
  setLearnTitleThree,
  setLearnImgTwo,
  setLearnTitleTwo,
  learnImgThree,
  learnTitleThree,
  learnImgTwo,
  learnTitleTwo,
  setLearnImg,
  learnImg,
  setContent,
  formData,
  setFormData,
  faqData,
  title,
  image,
  advisor,
  setAdvisor,
  supportAdvisor,
  learnTitle,
  setLearnTitle,
  handleSubmit,
  content,
  showUnPublishAlert,
  setShowUnPublishAlert,
  setMeetLink,
  meetLink,
  selectDate,
  setSelectDate,
  selectedDate,
  setSelectedDate,
 

}) => {
  let advisorName =
    advisor &&
    advisor?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.name,
      };
    });

  const handleAdvisor = (selectedData) => {
    setAdName(selectedData);
  };

  const handleMeetLinkChange = (e) => {
    setMeetLink(e.target.value)
  }

  

  // let lessonObj =
  //   lessonsData &&
  //   lessonsData?.map((i) => {
  //     return {
  //       key: i._id,
  //       value: i._id,
  //       label: i.title,
  //     };
  //   });

  const handleFaqChange = (selectedData) => {
    setFaq(selectedData);
  };
  let faqName = faqData && faqData?.map((i) => {
    return {
      key: i._id,
      value: i._id,
      label: i.title,
    }
  })

  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const [description, setDescription] = useState(
    EditorState.createWithContent(contentState)
  );
  const rawContentState = convertToRaw(description.getCurrentContent());
  const markup = draftToHtml(
    rawContentState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );
  setContent(markup);


  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
                // onValidSubmit={() => handleSubmit()}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="title">Live Session Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter title"
                        value={title}
                        onChange={handleChangeSession}
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FileUploader
                      file={headerImg}
                      setFile={setHeaderImg}
                      dragText="Drop Header Image here"
                      heading="Header Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="supportAdvisor">Co-ordinator Name</Label>
                      <Select
                        value={adName}
                        isMulti={false}
                        onChange={handleAdvisor}
                        options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Co-ordinator Name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="Faqs">FAQs</Label>
                      <Select
                        value={faq}
                        isMulti={false}
                        onChange={handleFaqChange}
                        options={faqName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Lessons"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="assetUrl">Live Session Link</Label>
                      <AvField
                        name="assetUrl"
                        placeholder="Enter Link"
                        value={meetLink}
                        onChange={handleMeetLinkChange}
                        type="text"
                        errorMessage="Link is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="assetUrl"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="startDate">Start Date and Time</Label>
                      <DatePicker
                        type="number"
                        className="date_picker"
                        placeholderText="Enter Day Date and Time"
                        selected={new Date(selectedDate)}
                        value={selectedDate}
                        form={formData}
                        setField={setFormData}
                        keyName="startDate"
                        dateFormat="Y-m-d H:i" // Include time format
                        onChange={(selectedDate) => {
                          setSelectedDate(selectedDate);
                          setFormData({
                            ...formData,
                            startDate: selectedDate,
                          });
                        }}
                        options={{
                          enableTime: true, // Enable time selection
                          noCalendar: false, // Show calendar as well
                          altInput: true, // Use alternative input display
                          altFormat: "F j, Y H:i", // Format for alternative input display
                          time_24hr: true, // Use 24-hour time format
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="endDate">End Date and Time</Label>
                      <DatePicker
                        type="number"
                        className="date_picker"
                        placeholderText="Enter Day Date and Time"
                        selected={new Date(selectDate)}
                        value={selectDate}
                        form={formData}
                        setField={setFormData}
                        keyName="endDate"
                        dateFormat="Y-m-d H:i" // Include time format
                        onChange={(selectDate) => {
                          setSelectDate(selectDate);
                          setFormData({
                            ...formData,
                            endDate: selectDate,
                          });
                        }}
                        options={{
                          enableTime: true, // Enable time selection
                          noCalendar: false, // Show calendar as well
                          altInput: true, // Use alternative input display
                          altFormat: "F j, Y H:i", // Format for alternative input display
                          time_24hr: true, // Use 24-hour time format
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="targetDate">
                        Enter Day Date and Time
                      </Label>
                      <DatePicker
                        type="number"
                        className="date_picker"
                        placeholderText="Enter Day Date and Time"
                        selected={new Date(selectedDate)}
                        value={selectedDate}
                        form={formData}
                        setField={setFormData}
                        keyName="targetDate"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => handleDate(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <Label htmlFor="description">Things to Note (desc)</Label>
                    <Editor
                      editorState={description}
                      toolbarClassName="toolbarClass"
                      wrapperClassName="wrapperClass"
                      editorClassName="editorClass"
                      onEditorStateChange={setDescription}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="mt-3" htmlFor="learning">
                        What you’ll Learn (Learning)
                      </Label>
                      <Row>
                        <Col>
                          <Label htmlFor="learnTitle">Title </Label>
                          <AvField
                            name="learnTitle"
                            placeholder="Enter title"
                            value={learnTitle}
                            onChange={(e) => setLearnTitle(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="learnTitle"
                          />

                          <FileUploader
                            file={learnImg}
                            setFile={setLearnImg}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="learnTitleTwo">Title</Label>
                          <AvField
                            name="learnTitleTwo"
                            placeholder="Enter title"
                            value={learnTitleTwo}
                            onChange={(e) => setLearnTitleTwo(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="learnTitleTwo"
                          />

                          <FileUploader
                            file={learnImgTwo}
                            setFile={setLearnImgTwo}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="learnTitleThree">Title</Label>
                          <AvField
                            name="learnTitleThree"
                            placeholder="Enter title"
                            value={learnTitleThree}
                            onChange={(e) => setLearnTitleThree(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="learnTitleThree"
                          />

                          <FileUploader
                            file={learnImgThree}
                            setFile={setLearnImgThree}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={() => handleSubmit()}
                // disabled={isDisabledBtn}
                >
                  Update Live Session
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditSessionCreateComp;
