import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  CardTitle,
  Card,
  CardSubtitle,
  CardBody,
  Button,
  CardText,
} from "reactstrap";
import "./index.css";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { getAllCouponReq, deleteCoupon } from "../Coupons.Apis";
import SweetAlert from "react-bootstrap-sweetalert";

const AllCoupons = () => {
  const history = useHistory();
  const [coupons, setCoupons] = useState([]);
  const [deleteCouponId, setDeleteCouponId] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  useEffect(() => {
    getAllCoupons();
  }, []);

  const getAllCoupons = async () => {
    try {
      const {
        data: { data },
      } = await getAllCouponReq();
      setCoupons([...data]);
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleDeleteCoupon = (id) => {
    setDeleteCouponId(id);
    setShowDeleteAlert(true);
  };

  const handleDeleteAction = async () => {
    try {
      await deleteCoupon(deleteCouponId);
      const filterCoupons = coupons.filter(({ _id }) => _id != deleteCouponId);
      setCoupons(filterCoupons);
      setDeleteCouponId("");
      setShowDeleteAlert(false);
    } catch (error) {}
    setShowDeleteAlert(false);
  };


  return (
    <>
      <Row
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "0px 20px" }}
      >
        <h1>All Coupons</h1>
        <div>
          <Button
            color="primary"
            className="waves-effect waves-light"
            style={{ width: "100%" }}
            onClick={() => {
              history.push({
                pathname:"/dashboard/coupon/create",
                state:{
                  heading:"Create Coupon",
                  buttonText:"Create Coupon",
                  update:false,
                }
              });
            }}
          >
            Add Coupon
          </Button>
        </div>
      </Row>

      <Row className="marginTopTen">
        {coupons?.length > 0 &&
          coupons.map((data) => {
            return (
              <Col lg={4} key={data._id}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <CardTitle>
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Coupon Code :
                        </span>
                        {" " + data.couponCode}
                      </CardTitle>
                    </div>
                    <CardText>
                      <div className="planName">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Plan Name :
                        </span>
                        {" " + data.planId.appliedPlanId.plan_description}
                      </div>
                      <div className="expireTime marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Expire Date :
                        </span>
                        {" " + moment(data.expireTime).format("DD/MM/YYYY")}
                      </div>
                      <div className="countryCode marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Country Code :
                        </span>
                        {" " + data.countryCode}
                      </div>
                      <div className="discountValue marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          Discount :
                        </span>
                        {" " + data.discount}
                      </div>
                      <div className="countValue marginTopTen">
                        <span
                          style={{ fontWeight: "normal", fontSize: "20px" }}
                        >
                          count :
                        </span>
                        {" " + data.count}
                      </div>
                    </CardText>
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={() => {
                        history.push({
                          pathname: "/dashboard/coupon/create",
                          state: {
                            heading: "Edit Coupon",
                            couponData:{...data,expireTime:moment(data.expireTime).format("YYYY-MM-DD")},
                            buttonText:"Update Coupon",
                            update:true,
                          },
                        });
                      }}
                      // style={}
                    >
                      Edit Coupon
                    </Button>
                    <Button
                      onClick={() => {
                        handleDeleteCoupon(data._id);
                      }}
                      className="marginLeftTen"
                    >
                      Delete Coupon
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            );
          })}

        {showDeleteAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Delete it!"
            reverseButtons={true}
            onConfirm={handleDeleteAction}
            onCancel={() => setShowDeleteAlert(false)}
          ></SweetAlert>
        ) : null}
      </Row>
    </>
  );
};

export default AllCoupons;
