export const AllAdvisorTimeTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'name',
  },
  {
    text: 'Designation',
    dataField: 'designation',
  }
]

export const AllAdvisorTimeTableHeadingTime = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  // {
  //   text: 'Time',
  //   dataField: 'time',
  // },
  {
    text: 'Date',
    dataField: 'date',
  }
]

