import React, { Fragment } from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()}© NexgenIot Solutions.
            </Col>
            {/* to do: change the text style */}
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block pb-4">
                Nispand
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
