export const PublishedDiscoverTableHeading = [
  {
    text: "OrderNo.",
    dataField: "order",
  },
  {
    text: "Title",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const UnPublishedDiscoverTableHeading = [
  {
    text: "OrderNo.",
    dataField: "order",
  },
  {
    text: "Title",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const fileTypes = {
  DISCOVER_IMAGE: "DISCOVER_IMAGE",
};

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOAD_COMPLETE: 4,
};
