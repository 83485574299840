import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import ReactPlayer from "react-player";

import FileUploader from "../FileUploader";
import { sendAudioNotificationReq } from "./ViewMusic.Api";

const ViewMusic = ({
  pageHeading = "Media Info",
  title,
  description,
  isFree,
  UserRecommendationGender,
  UserRecommendationAge,
  UserRecommendationMaritalStatus,
  UserRecommendationBPM,
  _id,
  isPublished,
  isDisabled,
  categories,
  subCategories,
  moods,
  stressLevel,
  tagsData,
  tags,
  categoryData,
  subCategoryData,
  setShowSendConfirm,
  setThumbnail,
  thumbnail,
  audio,
  handleSubmit,
  showSelectAudio = true,
  setThumbnailColor,
  thumbnailColor,
  img115x150,
  img145x345,
  img155x135,
  img180x160,
  unpublishMusicAction,
  createAlterNate,
  handleEditMusic,
  UserRecommendationFocusOnPresentMoment,
  UserRecommendationExperienceStressAnxiety,
  UserRecommendationSleep,
  UserRecommendationHoursOfSleep,
  UserRecommendationIrritatedOrAngry,
  UserRecommendationFrequentLethargy,
  UserRecommendationCommitmentAtWork,
  UserRecommendationIssuesInRelationship,
  UserRecommendationLowSelfEsteem,
  UserRecommendationHoursSpendOnSocialMedia,
  videoMedia,
  video,
  getCategory,
  getSubCategory,
  getTags,
  level,
  handleLevelSelect
}) => {
  const [bpmData, setBpmData] = useState([]);
  const bpmKeys = {
    FROM_110_TO_120: "FROM_90_TO_110",
    ABOVE_120: "ABOVE_110",
    BELOW_110: "BELOW_90",
    ALL: "ALL",
  };

  useEffect(() => {
    const newDdta = UserRecommendationBPM.map((item) => {
      item.label = bpmKeys[item.label];
      return item;
    });
    setBpmData(newDdta);
    // eslint-disable-next-line
  }, [UserRecommendationBPM]);

  const handleSendAudioNotification = async (musicID) => {
    try {
      await sendAudioNotificationReq(musicID);
      setShowSendConfirm(true);
    } catch (error) {}
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                  <div style={{ textAlign: "right", width: "50%" }}>
                    {isPublished === true && isDisabled === false && (
                      <Row>
                        <Col lg={4}>
                          <Button
                            color="primary"
                            className="waves-effect waves-light mb-2"
                            style={{ width: "100%" }}
                            onClick={() => handleSendAudioNotification(_id)}
                          >
                            Send Notification
                          </Button>
                        </Col>
                        <Col lg={4}>
                          <Button
                            color="danger"
                            className="waves-effect waves-light"
                            style={{ width: "100%" }}
                            onClick={() => unpublishMusicAction(_id)}
                          >
                            Unpublish
                          </Button>
                        </Col>
                        <Col lg={4}>
                          <Button
                            color="primary"
                            className="waves-effect waves-light"
                            style={{ width: "100%" }}
                            onClick={() => createAlterNate(_id)}
                          >
                            Create Alternate
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {isPublished === false && isDisabled === false && (
                      <Button
                        color="primary"
                        className="waves-effect waves-light mb-2"
                        onClick={() => handleEditMusic(_id)}
                      >
                        Edit Music
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={10}>
                  {" "}
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      disabled
                      value={title}
                      className="form-control"
                      id="title"
                    />
                  </FormGroup>
                </Col>
                <Col className="d-flex align-items-center mt-3" xs={2}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="isFree"
                      checked={isFree}
                      id="free"
                    />
                    <Label className="custom-control-label" htmlFor="free">
                      Is Free
                    </Label>
                  </div>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  disabled
                  value={description}
                  className="form-control"
                  id="description"
                />
              </FormGroup>

              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="genderSelect">Gender</Label>
                    <Select
                      value={UserRecommendationGender}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="ageSelect">Age</Label>
                    <Select
                      value={UserRecommendationAge}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="marritalSelect">Marital Status</Label>
                    <Select
                      value={UserRecommendationMaritalStatus}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="moodSelect">Mood</Label>
                    <Select
                      value={moods}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="bpmSelect">BPM</Label>
                    <Select
                      value={bpmData}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="stressSelect">Stress Level</Label>
                    <Select
                      value={stressLevel}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Categories</Label>
                    <Select
                      value={categories}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      placeholder={
                        categoryData.length === 0
                          ? "Loading..."
                          : "Select Categories"
                      }
                      isDisabled="true"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Sub Categories</Label>
                    <Select
                      value={subCategories}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      placeholder={
                        subCategoryData.length === 0
                          ? "Loading..."
                          : "Select Sub Categories"
                      }
                      isDisabled="true"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Tags</Label>
                    <Select
                      value={tags}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      placeholder={
                        tagsData.length === 0 ? "Loading..." : "Select Tags"
                      }
                      isDisabled="true"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">User Focus</Label>
                    <Select
                      value={UserRecommendationFocusOnPresentMoment}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">User Stress Anxiety</Label>
                    <Select
                      value={UserRecommendationExperienceStressAnxiety}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">User Sleep</Label>
                    <Select
                      value={UserRecommendationSleep}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">User Sleep Hours</Label>
                    <Select
                      value={UserRecommendationHoursOfSleep}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Irritated or Angry
                    </Label>
                    <Select
                      value={UserRecommendationIrritatedOrAngry}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Frequent Lethargy
                    </Label>
                    <Select
                      value={UserRecommendationFrequentLethargy}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Commitment At Work
                    </Label>
                    <Select
                      value={UserRecommendationCommitmentAtWork}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Issues In Relationship
                    </Label>
                    <Select
                      value={UserRecommendationIssuesInRelationship}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Low Self Esteem
                    </Label>
                    <Select
                      value={UserRecommendationLowSelfEsteem}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* sachin row started 3 */}
              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">
                      User Hours Spend On Social Media
                    </Label>
                    <Select
                      value={UserRecommendationHoursSpendOnSocialMedia}
                      isMulti={true}
                      classNamePrefix="select2-selection"
                      isDisabled="true"
                      placeholder={"No Value Added"}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Level</Label>
                    <select
                      value={level}
                      onChange={handleLevelSelect}
                      className="form-control"
                    >
                      <option value="">Select Level</option>
                      <option value="BEGINNER">Beginner</option>
                      <option value="INTERMEDIATE">Intermediate</option>
                      <option value="ADVANCED">Advanced</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              {/* sachin row ended 3 */}
              <Row>
                <Col lg={showSelectAudio ? 6 : 12}>
                  <div style={{ marginTop: "-15px" }}>
                    <FileUploader
                      file={thumbnail}
                      setFile={setThumbnail}
                      dragText="Drop music image here"
                      heading="Track Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      setImageColor={setThumbnailColor}
                      imageColor={thumbnailColor}
                      isDisabled={true}
                    />
                  </div>
                </Col>
              </Row>
              <FormGroup className="select2-container">
              {
                  videoMedia ? 
                  <>
                 
                <Label className="control-label">Play Video</Label>
               
                  <ReactPlayer
                  url={video}
                  width="100%"
                  height="200px"
                  playing={false}
                  controls={true}
                /> 
                 </>
                : 
                <>
                 
                <Label className="control-label">Play Music</Label>
               
                  <ReactPlayer
                  url={video}
                  width="100%"
                  height="50px"
                  playing={false}
                  controls={true}
                /> 
                 </>
                }
               
              </FormGroup>
              {(img115x150 || img145x345) && (
                <Row>
                  {img115x150 && (
                    <Col lg={img145x345 ? 6 : 12}>
                      <FileUploader
                        file={img115x150}
                        dragText="Drop 115x150 image here"
                        heading="Select 115x150 Image (Optional)"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                      />
                    </Col>
                  )}
                  {img145x345 && (
                    <Col lg={img115x150 ? 6 : 12}>
                      <FileUploader
                        file={img145x345}
                        dragText="Drop 145x345 image here"
                        heading="Select 145x345 Image (Optional)"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                      />
                    </Col>
                  )}
                </Row>
              )}
              {(img180x160 || img155x135) && (
                <Row>
                  {img180x160 && (
                    <Col lg={img155x135 ? 6 : 12}>
                      <FileUploader
                        file={img180x160}
                        dragText="Drop 180x160 image here"
                        heading="Select 180x160 Track Image (Optional)"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                      />
                    </Col>
                  )}
                  {img155x135 && (
                    <Col lg={img180x160 ? 6 : 12}>
                      <FileUploader
                        file={img155x135}
                        dragText="Drop 155x135 image here"
                        heading="Select 155x135 Image (Optional)"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                      />
                    </Col>
                  )}
                </Row>
              )}
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ViewMusic;
