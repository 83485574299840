import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { AllAdvisorTimeTableHeadingTime } from "../../Views/Dashboard/Components/TimeSlots/Constants/constant";
import AllTimeByIdComp from "../AllTimeById";
import Table from "../Table";

const AllTimeSlotsComp = ({
  tableData = [],
  tableHeading = [],
  page,
  sizePerPage,
  totalSize,
  handleTablePageChange,
}) => {
  const [rowData, setRowData] = useState([]);

  const history = useHistory();
  
  const handleEditUserClick = (advisorId) => {
    history.push("/dashboard/time-slots/edit/all-time-slots/" + advisorId);
  };




  const actionsFormatter = (cell, row) => {
    return (
      <>
        <div style={{ textAlign: "center" }} key={row._id}>
          <i
            className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
            onClick={() => {
              handleEditUserClick(row._id);
            }}
          />
          {/* <i
            className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
            onClick={() => {
              handleDeleteAdv(row._id);
            }}
          /> */}
        </div>
      </>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData) {
      var result = tableData.map((el, i) => {
        var o = Object.assign({ ...el });
        o.sNo = i + 1;
        return o;
      });

      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    {/* <h3 className="m-0">{"Today's Program"}</h3> */}
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
     
    </>
  );
};

export default AllTimeSlotsComp;
