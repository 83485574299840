import { useState, useEffect } from "react";
import CreateNutriDietForm from "../../../../../../Components/CreateNutriDietForm";
import UploadingNutriDietLoader from "../../../../../../Components/UploadingNutriDietLoader";
import { creatingStates, fileTypes } from "../../Constants/constant";
import { uploadFile } from "../../nutridiets.Constants";
import {
  createNutriDietApi,
  getFaqlistApi,
  getNutriDietbyIdApi,
  updateNutriDietApi,
} from "../../nutridiets.Api";
import { useParams } from "react-router-dom";
import EditNutriDietForm from "../../../../../../Components/EditNutriDietForm";

const EditNutriDiet = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [faqList, setFaqList] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [descData, setDescData] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    supportAdvisorsId: "",
    faqId: [],
    imgAsset: "",
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const params = useParams();
  const idval = params.nutridietId;

  useEffect(() => {
    const getByIdFunc = async () => {
      try {
        const { data } = await getNutriDietbyIdApi(idval);
        if (data && data.data) {
          setFormData({
            title: data.data.title,
          });
          setFaqData(
            data.data.faqId.map((ele) => ({
              value: ele._id,
              label: ele.title,
              key: ele._id,
            }))
          );
          setThumbnail(data.data.imgAsset);
        }
        if (data?.data?.description) {
          setDescData(data?.data?.description);
        }
      } catch (error) {
        console.error("Error fetching NutriDiet by ID:", error);
      }
    };

    getByIdFunc();
  }, [idval]);

  useEffect(() => {
    const faqList = async () => {
      const { data } = await getFaqlistApi();
      setFaqList(data.faqs);
    };
    faqList();
  }, []);

  // Handle form IP
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // reset page
  const reset = () => {
    setThumbnail(null);
    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      description: "",
      supportAdvisorsId: "",
      faqId: [],
      imgAsset: "",
    });

    setThumbnailUploadPercentage(0);
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  console.log(faqData, "7777");

  const faqIds = faqData ? faqData.map((item) => item.value) : [];
  // submit
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    console.log(thumbnail, "thumbnailUrl");

    let thumbnailUrl = thumbnail;

    if (!thumbnail)
      return setError({
        isError: true,
        errorMessage: "Please select thumbnail",
        errorType: "warning",
      });

    try {
      if (thumbnail && typeof thumbnail !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        thumbnailUrl = await uploadFile(
          thumbnail,
          fileTypes.NUTRI_DIET_IMAGE,
          setThumbnailUploadPercentage,
          10
        );
      }

      const updatedFormData = {
        title: formData.title,
        description: descData,
        supportAdvisorsId: "",
        faqId: faqIds,
        imgAsset: thumbnailUrl || thumbnail,
      };

      await updateNutriDietApi({ updatedFormData, idval });

      // Console log the values
      console.log("Submitted Data:", updatedFormData);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {descData && (
        <>
          {isCreating ? (
            <UploadingNutriDietLoader
              currentStep={creatingStage}
              thumbnailuploadPercentage={thumbnailuploadPercentage}
              reset={reset}
            />
          ) : (
            <EditNutriDietForm
              handelInput={handelInput}
              title={formData.title}
              pageHeading={"Edit NutriDiet"}
              setFaqData={setFaqData}
              setFaqList={setFaqList}
              faqList={faqList}
              faqData={faqData}
              setDescData={setDescData}
              descData={descData}
              setThumbnail={setThumbnail}
              thumbnail={thumbnail}
              handleSubmit={handleSubmit}
              isError={isError}
              errorMessage={errorMessage}
              errorType={errorType}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditNutriDiet;
