import axios from "axios"

import baseDomain, { createPlaylist,allMedia,allUnpublishedPlaylist,allPublishedPlaylist,publishPlaylist,UnpublishPlaylist,getById,updatePlaylist,addMedia,removeMedia} from "../../../../Constants/apiRoutes";

export const createNewPlaylist = (data)=>{
  return axios.post(`${baseDomain}${createPlaylist}`,data)
}
export const getAllMedia = ({ page, limit })=>{
  return axios.get(`${baseDomain}${allMedia}?page=${page}&limit=${limit}`)
}
export const getAllUnpublishedPlaylist = ()=>{
  return axios.get(`${baseDomain}${allUnpublishedPlaylist}?type=PLAYLIST`)
}
export const getAllPublishedPlaylist=()=>{
  return axios.get(`${baseDomain}${allPublishedPlaylist}?type=PLAYLIST`)
}
export const publishPlaylistActions = (publishID)=>{
  return axios.put(`${baseDomain}${publishPlaylist}/${publishID}`)
}
export const unpublishPlaylistActions = (unpublishID)=>{
  return axios.put(`${baseDomain}${UnpublishPlaylist}/${unpublishID}`)
}
export const getPlaylistByid = (playlistId)=>{
  return axios.get(`${baseDomain}${getById}/${playlistId}`)
}
export const updatePlaylistDetails = (playlistId,payload)=>{
  return axios.put(`${baseDomain}${updatePlaylist}/${playlistId}`,payload)
}
export const addMediaToPlaylist = (mediaId,payload) => {
  return axios.put(`${baseDomain}${addMedia}/${mediaId}`,payload)
}
export const removeMediaFromPlaylist = (mediaId,payload) => {
  return axios.put(`${baseDomain}${removeMedia}/${mediaId}`,payload)
}
export const getAllTags = () => {
  return axios.get(`${baseDomain}/mediatags/get?objectType=TAG&page=1&limit=4000`)
}
