import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { unpublishPlanReq } from "../../Views/Dashboard/Components/Plan/Plans.Apis";

import { PlanTableHeading } from "../../Views/Dashboard/Components/Plan/Plans.Constants";
import Table from "../Table";

const PlanCard = ({
  tableData = [],
  setTableData,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleViewPlan,
  handleEditPlan,
}) => {
  const history = useHistory();

  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [planID, setPlanID] = useState("");
  const [rowData, setRowData] = useState([]);

  const handleUnpublishPlan = async (planID) => {
    setPlanID(planID);
    setShowRestoreAlert(true);
  };

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData, page]);

  const restoreTagAction = async () => {
    try {
      await unpublishPlanReq(planID);
      setPlanID("");
      const filteredPlans = tableData.filter(({ _id }) => _id !== planID);
      setTableData(filteredPlans);
      setShowRestoreAlert(false);
      setShowRestoreConfirm(true);
    } catch (error) {}
  };

  const viewPlanFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewPlan(row._id)}
        />
      </div>
    );
  };

  const editPlanFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditPlan(row._id)}
        />
      </div>
    );
  };

  const unpublishFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleUnpublishPlan(row._id)}
        >
          Deactivate
        </Button>
      </div>
    );
  };

  useEffect(() => {
    PlanTableHeading.push({
      text: "View Plan",
      dataField: `view_plan`,
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewPlanFormatter,
    });
    PlanTableHeading.push({
      text: "Edit Plan",
      dataField: `edit_plan`,
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: editPlanFormatter,
    });
    PlanTableHeading.push({
      text: "Deactivate Plan",
      dataField: "deactivate_plan",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: unpublishFormatter,
    });
    return () => {
      PlanTableHeading.pop();
      PlanTableHeading.pop();
      PlanTableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            {/* <ViewPlanModal planID={planID} isOpen={isOpen} toggleModal={toggleViewPlanModal} isEditable={isEditable} data={modalData} setData={setModalData} isLoading={isLoading} /> */}
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Published Plans</h3>
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  >
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/plan/bin")}
                    >
                      Plan Bin
                    </Button>
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/plan/create")}
                    >
                      Add Plan
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={PlanTableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
        {showRestoreAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Deactivate it!"
            reverseButtons={true}
            onConfirm={restoreTagAction}
            onCancel={() => setShowRestoreAlert(false)}
          ></SweetAlert>
        ) : null}

        {showRestoreConfirm ? (
          <SweetAlert
            success
            title="Deactivated!"
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setShowRestoreAlert(false);
              setShowRestoreConfirm(false);
            }}
          >
            Plan has been Deactivated
          </SweetAlert>
        ) : null}
      </Col>
    </Row>
  );
};

export default PlanCard;
