import { USER_LOGGEDIN, LOGIN_ERROR, SET_LOADING, END_LOADING, LOGOUT } from "../types";

const AuthReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGGEDIN:
      return {
        ...state,
        user: action.payload,
        errorMessage: null,
        isLoggedIn: true,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        user: {},
        errorMessage: "Invalid Credentials",
        isLoggedIn: false,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        isLoggedIn: false,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
