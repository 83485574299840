import React, { useState, useEffect } from "react";
import { SleepSurveyListHead } from "../../SleepSessions.constants";
import AllSurveyUsersList from "../../../../../../Components/AllSurveyUsers";
import { AllSurveyData, AllSurveyDataCSV } from "../../SleepSessions.Api";
// import { getAllEnableMasterClass, csvDataMasterClass } from "../../../Constants/api";

const SleepSurveyList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [publishedCourse, setPublishedCourse] = useState([]);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = publishPage;
  const [masterId, setMasterId] = useState([]);
  const [csvData, setCsvData] = useState([])

  const getSurveyList = async (params) => {
    try {
      setIsLoading(true);
      let payload = params
      if (startDate) {
        const startOfDay = startDate; // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = formatDate(isoStringStart)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }
      if (endDate) {
        const endOfDay = endDate; // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }

      const { data } = await AllSurveyData(params)//getAllEnableMasterClass(params);

      setPublishedCourse(data?.data);
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      // if (data?.allRegisteredMasterClass?.length > 0) {
      //   setMasterId(data.allRegisteredMasterClass[0]._id);
      // }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // const extractAndSetMasterIds = (courses) => {
  //   if (courses?.length > 0) {
  //     const ids = courses.map((course) => course._id);
  //     setMasterId(ids);
  //   }
  // };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getSurveyList({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  const handleEditSeries = (id) => {
    history.push("/dashboard/master-user/" + id);
  };

  const getAllUsersDataCsv = async (params) => {
    try {

      setIsLoading(true)
      let payload = {}
      if (startDate) {
        const startOfDay = startDate; // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = formatDate(isoStringStart)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }
      if (endDate) {
        const endOfDay = endDate; // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      const { data } = await AllSurveyDataCSV(payload)
      setCsvData(data?.data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching or processing data:', error);
    }
  };
  console.log(csvData, 'printData')

  const handleAllExportToCSV = () => {
    const csvDataAll = csvData?.map((item) => {
      // Create individual question and answer fields
      const qnaFields = item?.qna?.reduce((acc, qnaItem, index) => {
        acc[`Q ${index + 1}`] = qnaItem.question?.question;
        acc[`A ${index + 1}`] = qnaItem.answer;
        return acc;
      }, {}) || {};

      return {
        Name: item?.userId?.firstName,
        Email: item?.userId?.email,
        'Phone Number': item?.userId?.phoneNumber,
        Goal: item?.goal,
        CreatedAt: item?.createdAt ? new Date(item.createdAt).toISOString().substring(0, 10) : '',
        ...qnaFields // Spread the question and answer fields into the returned object
      };
    });

    return csvDataAll;
  };

  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const handleStartDateChange = (date) => {
    setStartDate(date)
  }
  const handleEndDateChange = (date) => {
    setEndDate(date)
  }
  const handleConfirmClick = async () => {
    getSurveyList({ page: 1, limit: 10 });
    getAllUsersDataCsv()
  }


  useEffect(() => {
    setStartDate(new Date())
    setEndDate(new Date())
    getSurveyList({ page: 1, limit: 10 });
    getAllUsersDataCsv()
  }, []);

  // console.log(masterId, "dedo naa");

  return (
    <>
      <AllSurveyUsersList
        tableData={publishedCourse}
        tableHeading={SleepSurveyListHead}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        // handleEditSeries={handleEditSeries}
        masterId={masterId}
        handleAllExportToCSV={handleAllExportToCSV}
        startDate={startDate}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
        handleStartDateChange={handleStartDateChange}
        handleConfirmClick={handleConfirmClick}
        handleTablePageChange={handleTablePageChange}
      />
    </>
  );
};

export default SleepSurveyList;
