import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import ViewMusic from "../../../../../../Components/ViewMusic";
import Loader from "../../../../../../Components/Loader";

import {
  filterTagsAndFormat,
  formatForReactSelect,
  formatArray,
} from "../../Music.Helpers";
import { getAllTags } from "../../../Tag/Tag.Apis";
import { getMusicReq, unpublishMusic } from "../../Music.Apis";

const MusicPlayer = () => {
  const { musicID } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [ errorRes, setErrorRes] = useState('')
  const [showErr, setShowErr] = useState(false)
  const [thumbnail, setThumbnail] = useState(null);
  const [img115x150, setImg115x150] = useState(null);
  const [img145x345, setImg145x345] = useState(null);
  const [img180x160, setImg180x160] = useState(null);
  const [img155x135, setImg155x135] = useState(null);
  const [audio, setAudio] = useState(null);
  const [thumbnailColor, setThumbnailColor] = useState(null);
  const [showSendConfirm, setShowSendConfirm] = useState(false);
  const [level,setLevel] = useState('')
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    isFree: true,
    UserRecommendationAge: "",
    UserRecommendationMaritalStatus: "",
    UserRecommendationMood: "",
    UserRecommendationStress: "",
    UserRecommendationBPM: "",
    _id: "",
    isPublished: false,
    isDisabled: false,
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [moods, setMoods] = useState([]);
  const [stressLevel, setStressLevel] = useState([]);
  const [
    UserRecommendationFocusOnPresentMoment,
    setUserRecommendationFocusOnPresentMoment,
  ] = useState([]);
  const [
    UserRecommendationExperienceStressAnxiety,
    setUserRecommendationExperienceStressAnxiety,
  ] = useState([]);
  const [UserRecommendationSleep, setUserRecommendationSleep] = useState([]);
  const [UserRecommendationHoursOfSleep, setUserRecommendationHoursOfSleep] =
    useState([]);
  const [
    UserRecommendationIrritatedOrAngry,
    setUserRecommendationIrritatedOrAngry,
  ] = useState([]);
  const [
    UserRecommendationFrequentLethargy,
    setUserRecommendationFrequentLethargy,
  ] = useState([]);
  const [
    UserRecommendationCommitmentAtWork,
    setUserRecommendationCommitmentAtWork,
  ] = useState([]);
  const [
    UserRecommendationIssuesInRelationship,
    setUserRecommendationIssuesInRelationship,
  ] = useState([]);
  const [UserRecommendationLowSelfEsteem, setUserRecommendationLowSelfEsteem] =
    useState([]);
  const [
    UserRecommendationHoursSpendOnSocialMedia,
    setUserRecommendationHoursSpendOnSocialMedia,
  ] = useState([]);
  const [UserRecommendationGender, setUserRecommendationGender] = useState([]);
  const {
    title,
    description,
    isFree,
    UserRecommendationAge,
    UserRecommendationMaritalStatus,
    UserRecommendationMood,
    UserRecommendationBPM,
    UserRecommendationStress,
    _id,
    isPublished,
    isDisabled,
  } = formData;
  const [allTags, setAllTags] = useState({
    tagsData: [],
    categoryData: [],
    subCategoryData: [],
  });
  const { tagsData, categoryData, subCategoryData } = allTags;

  const catObj=(obj)=>{
    {
      const { name, _id } = obj;
      return { label: name, value: _id, key: _id };
    }
  }
  // Get initial data
  const getAllTagsData = async () => {
    try {
      const { data } = await getAllTags();
      const filteredData = filterTagsAndFormat(data.data);
      setAllTags(filteredData);
    } catch (error) {}
  };

  const unpublishMusicAction = async (unpublishID) => {
   try {
    await unpublishMusic(unpublishID);
    history.push("/dashboard/media/all");
   } catch (error) {
      console.log(error.response.data.error, "error.response.data.error");
      setErrorRes(error.response.data.error);
      setShowErr(true)
    
   }
  };
  const createAlterNate = async (musicID) => {
    history.push(`/dashboard/media/alternate/${musicID}`);
  };
  const handleEditMusic = async (musicId) => {
    history.push(`/dashboard/media/edit/${musicId}`);
  };
  const handleLevelSelect = (event) => {
    setLevel(event.target.value);
  };

  const getMusic = async (musicID) => {
    try {
      setIsLoading(true);
      const { data } = await getMusicReq(musicID);
      setFormData({
        title: data.data.title,
        description: data.data.description,
        isFree: data.data.isFree || false,
        UserRecommendationAge: formatArray(data?.data?.UserRecommendationAge),
        UserRecommendationMaritalStatus: formatArray(
          data?.data?.UserRecommendationMaritalStatus
        ),
        UserRecommendationMood: data.data.UserRecommendationMood,
        UserRecommendationBPM: formatArray(data?.data?.UserRecommendationBPM),
        UserRecommendationStress: data.data.UserRecommendationStress,
        _id: data.data._id,
        isPublished: data.data.isPublished,
        isDisabled: data.data.isDisabled,
      });
      setCategories(catObj.length > 0 && catObj(data?.data?.categorie));
      setLevel(data?.data?.level)
     
      setSubCategories(formatForReactSelect(data?.data?.subCategories));
      setTags(formatForReactSelect(data?.data?.tags));
      setUserRecommendationFocusOnPresentMoment(
        formatArray(data?.data?.UserRecommendationFocusOnPresentMoment)
      );
      setUserRecommendationExperienceStressAnxiety(
        formatArray(data?.data?.UserRecommendationExperienceStressAnxiety)
      );
      setUserRecommendationSleep(
        formatArray(data?.data?.UserRecommendationSleep)
      );
      setUserRecommendationHoursOfSleep(
        formatArray(data?.data?.UserRecommendationHoursOfSleep)
      );
      setUserRecommendationIrritatedOrAngry(
        formatArray(data?.data?.UserRecommendationIrritatedOrAngry)
      );
      setUserRecommendationFrequentLethargy(
        formatArray(data?.data?.UserRecommendationFrequentLethargy)
      );
      setUserRecommendationIssuesInRelationship(
        formatArray(data?.data?.UserRecommendationIssuesInRelationship)
      );
      setUserRecommendationLowSelfEsteem(
        formatArray(data?.data?.UserRecommendationLowSelfEsteem)
      );
      setUserRecommendationHoursSpendOnSocialMedia(
        formatArray(data?.data?.UserRecommendationHoursSpendOnSocialMedia)
      );
      setUserRecommendationCommitmentAtWork(
        formatArray(data?.data?.UserRecommendationCommitmentAtWork)
      );
      setUserRecommendationGender(
        formatArray(data?.data?.UserRecommendationGender)
      );
      let moodApiData = data.data.UserRecommendationMood;
      setMoods(formatArray(moodApiData));
      let stressApiData = data.data.UserRecommendationStress;
      setStressLevel(formatArray(stressApiData));
      setThumbnailColor(data.data.thumbnailColorPalette);
      setThumbnail(data.data.assetImage);
      setAudio(data.data.assetURL);
      setImg115x150(data.data.img115x150);
      setImg145x345(data.data.img145x345);
      setImg155x135(data.data.img155x135);
      setImg180x160(data.data.img180x160);
      setIsLoading(false);
    } catch (error) {
      console.log(error,'error')
    }
  };

  console.log(categories,'cat')
  useEffect(() => {
    getMusic(musicID);
    getAllTagsData();
    // eslint-disable-next-line
  }, []);


  return (
    <>
      {isLoading ? (
        <>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h3 className='m-0'>Media Info</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          <ViewMusic
            pageHeading='Media Info'
            title={title}
            description={description}
            UserRecommendationGender={UserRecommendationGender}
            UserRecommendationAge={UserRecommendationAge}
            UserRecommendationMaritalStatus={UserRecommendationMaritalStatus}
            UserRecommendationMood={UserRecommendationMood}
            UserRecommendationBPM={UserRecommendationBPM}
            UserRecommendationStress={UserRecommendationStress}
            categories={categories}
            subCategories={subCategories}
            moods={moods}
            stressLevel={stressLevel}
            tagsData={tagsData}
            tags={tags}
            categoryData={categoryData}
            subCategoryData={subCategoryData}
            thumbnail={thumbnail}
            audio={audio}
            showSelectAudio={false}
            createBtnText={'Edit Music'}
            isFree={isFree}
            _id={_id}
            isPublished={isPublished}
            isDisabled={isDisabled}
            showSendConfirm={showSendConfirm}
            setShowSendConfirm={setShowSendConfirm}
            setThumbnailColor={setThumbnailColor}
            thumbnailColor={thumbnailColor}
            img115x150={img115x150}
            img145x345={img145x345}
            img155x135={img155x135}
            img180x160={img180x160}
            unpublishMusicAction={unpublishMusicAction}
            createAlterNate={createAlterNate}
            handleEditMusic={handleEditMusic}
            level={level}
            handleLevelSelect={handleLevelSelect}
            UserRecommendationFocusOnPresentMoment={
              UserRecommendationFocusOnPresentMoment
            }
            UserRecommendationExperienceStressAnxiety={
              UserRecommendationExperienceStressAnxiety
            }
            UserRecommendationSleep={UserRecommendationSleep}
            UserRecommendationHoursOfSleep={UserRecommendationHoursOfSleep}
            UserRecommendationIrritatedOrAngry={
              UserRecommendationIrritatedOrAngry
            }
            UserRecommendationFrequentLethargy={
              UserRecommendationFrequentLethargy
            }
            UserRecommendationCommitmentAtWork={
              UserRecommendationCommitmentAtWork
            }
            UserRecommendationIssuesInRelationship={
              UserRecommendationIssuesInRelationship
            }
            UserRecommendationLowSelfEsteem={UserRecommendationLowSelfEsteem}
            UserRecommendationHoursSpendOnSocialMedia={
              UserRecommendationHoursSpendOnSocialMedia
            }
          />
          {showSendConfirm ? (
            <SweetAlert
              success
              title='Sent!'
              confirmBtnBsStyle='primary'
              onConfirm={() => {
                setShowSendConfirm(false);
              }}
            >
              Notification has been sent
            </SweetAlert>
          ) : null}

          {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
          onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}
        </>
      )}
    </>
  );
};

export default MusicPlayer;
