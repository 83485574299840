import Tag from "../Components/Tag";
import Music from "../Components/Music";
import Quotes from "../Components/Quotes";
import Users from "../Components/Users";
import Notifications from "../Components/Notifications";
import Plan from "../Components/Plan";
import Coupon from "../Components/Coupon";
import Dashboard from "../Components/Dashboard";
import FAQ from "../Components/Faq";
import Blogs from "../Components/Blogs";
import Series from "../Components/Series";
import MediaProperties from "../Components/MediaProperties";
import { TagsRoutes } from "../Components/Tag/Constants/routes";
import { MusicRoutes } from "../Components/Music/Constants/routes";
import { QuotesRoutes } from "../Components/Quotes/Constants/routes";
import { NotificationsRoutes } from "../Components/Notifications/Constants/routes";
import { PlanRoutes } from "../Components/Plan/Constants/routes";
import { CouponRoutes } from "../Components/Coupon/Constants/routes";
import Discover from "../Components/Discover";
import { DiscoverRoutes } from "../Components/Discover/Constants/routes";
import { BusinessRoutes } from "../Components/Business/Constants/routes";
import YoutubeVidz from "../Components/YoutubeVideos";
import { YtVideosRoutes } from "../Components/YoutubeVideos/Constants/routes";
import BlogRoutes from "../Components/Blogs/Constants/routes";
import AllVideos from "../Components/YoutubeVideos/Components/AllVideos";
import SeriesRoutes from "../Components/Series/Constants/routes";

import Business from "../Components/Business";
import { FaqRoutes } from "../Components/Faq/Constants/routes";
import Offer from "../Components/Offers";
import { OfferRoutes } from "../Components/Offers/Constants/routes";
import { MediaPropertiesRouts } from "../Components/MediaProperties/Constants/routes";
import Mind from "../Components/Mind";
import { MindRoutes } from "../Components/Mind/Constants/routes";
import MindAll from "../Components/Mind";
import Playlist from "../Components/Playlist";
import { PlaylistRoutes } from "../Components/Playlist/Constants/routes";
import Video from "../Components/VideoMedia";
import { VideoRoutes } from "../Components/VideoMedia/routes";
// import { CoursesSubRoute } from "../Components/Courses/Constants/routes";
import Lesson from "../Components/Lessons";
import { LessonsSubRoute } from "../Components/Lessons/Constants/routes";

import { NutriDietsSubRoute } from "../Components/NutriDiet/Constants/routes";

import Courses from "../Components/Courses";
import CoursesRoutes from "../Components/Courses/Constants/routes";
import MasterClass from "../Components/MasterClass";
import MasterClassRoutes from "../Components/MasterClass/Constants/routes";
import AllPrograms from "../Components/TodaysPrograms/Components/AllPrograms";
import ProgramsAll from "../Components/TodaysPrograms";
import { ProgramRoutes } from "../Components/TodaysPrograms/Constants/routes";
import BookingSessions from "../Components/BookingHomeSessions";
import BookingSessionsRoutes from "../Components/BookingHomeSessions/Constants/routes";
import DiscoverNispand from "../Components/DiscoverNispand";
import DiscoverNispandRoutes from "../Components/DiscoverNispand/Constants/routes";
import AdvisorsAll from "../Components/ServiceAdvisors";
import { AdvisorsRoutes } from "../Components/ServiceAdvisors/Constants/routes";

import NutriDiets from "../Components/NutriDiet";
import LiveSessionsAll from "../Components/LiveSessions";
import { LiveSessionRoutes } from "../Components/LiveSessions/Constants/routes";
import TimeSlots from "../Components/TimeSlots";
import TimeSlotsRoutes from "../Components/TimeSlots/Constants/routes";
import Appointments from "../Components/Appointment";
import AppointmentRoutes from "../Components/Appointment/Constant/routes";
import SleepSessions from "../Components/SleepSessions";
import { SleepSessionsRoutes } from "../Components/SleepSessions/Constants/routes";
import NutriLiveClass from "../Components/N-Live/NutriClinic";
import NutriLiveRoutes from "../Components/N-Live/NutriClinic/Constants/routes";
import Stories from "../Components/Stories";
import { StoriesRoutes } from "../Components/Stories/constants/routes";

export const DashboardRoutes = [
  {
    Component: Dashboard,
    path: "/home",
    exact: true,
    id: "dashboardHome",
    routePath: "/dashboard/home",
    name: "Home",
    icon: "ri-dashboard-fill",
  },
  {
    Component: Tag,
    path: "/tag",
    exact: false,
    id: "dashboardTags",
    routePath: "/dashboard/tag",
    name: "Tags and Categories",
    icon: "ri-price-tag-3-fill",
    subRoutes: TagsRoutes,
  },
  {
    Component: Music,
    path: "/media",
    exact: false,
    id: "dashboardMusic",
    routePath: "/dashboard/media",
    name: "Media",
    icon: "ri-folder-music-fill",
    subRoutes: MusicRoutes,
  },
  {
    Component: Video,
    path: "/video",
    exact: false,
    id: "dashboardVideo",
    routePath: "/dashboard/video",
    name: "Video",
    icon: "ri-folder-music-fill",
    subRoutes: VideoRoutes,
  },
  {
    Component: Courses,
    path: "/courses",
    exact: false,
    id: "dashboardCourses",
    routePath: "/dashboard/courses",
    name: "Courses",
    icon: "ri-money-dollar-box-line",
    subRoutes: CoursesRoutes,
  },
  {
    Component: MasterClass,
    path: "/master-class",
    exact: false,
    id: "dashboardMaster",
    routePath: "/dashboard/master-class",
    name: "Master Class",
    icon: "ri-money-dollar-box-line",
    subRoutes: MasterClassRoutes,
  },
  {
    Component: TimeSlots,
    path: "/time-slots",
    exact: false,
    id: "dashboardTimeSlot",
    routePath: "/dashboard/time-slots",
    name: "Time Slot for Advisor",
    icon: "ri-money-dollar-box-line",
    subRoutes: TimeSlotsRoutes,
  },
  {    
    Component: BookingSessions,
    path: "/booking-session",
    exact: false,
    id: "dashboardBookingSessions",
    routePath: "/dashboard/booking-session",
    name: "Home Sessions",
    icon: "ri-money-dollar-box-line",
    subRoutes: BookingSessionsRoutes
  },
  {

    Component: DiscoverNispand,
    path: "/discover-nispand",
    exact: false,
    id: "dashboardDiscoverNispand",
    routePath: "/dashboard/discover-nispand",
    name: "Discover Nispand",
    icon: "ri-money-dollar-box-line",
    subRoutes: DiscoverNispandRoutes,
  },
  {
    Component: Quotes,
    path: "/quotes",
    exact: false,
    id: "dashboardQuotes",
    routePath: "/dashboard/quotes",
    name: "Quotes",
    icon: "ri-chat-quote-fill",
    subRoutes: QuotesRoutes,
  },
  {
    Component: Users,
    path: "/users",
    exact: true,
    id: "dashboardUsers",
    routePath: "/dashboard/users",
    name: "Users",
    icon: "ri-user-fill",
  },
  {
    Component: Notifications,
    path: "/notifications",
    exact: false,
    id: "dashboardNotifications",
    routePath: "/dashboard/notifications",
    name: "Notifications",
    icon: " ri-chat-2-fill",
    subRoutes: NotificationsRoutes,
  },
  {
    Component: Plan,
    path: "/plan",
    exact: false,
    id: "dashboardPlans",
    routePath: "/dashboard/plan",
    name: "Plans",
    icon: "  ri-money-dollar-box-line",
    subRoutes: PlanRoutes,
  },
  {
    Component: Coupon,
    path: "/coupon",
    exact: false,
    id: "dashboardCoupons",
    routePath: "/dashboard/coupon",
    name: "Coupons",
    icon: "  ri-money-dollar-box-line",
    subRoutes: CouponRoutes,
  },
  {
    Component: Discover,
    path: "/discover",
    exact: false,
    id: "dashboardDiscover",
    routePath: "/dashboard/discover",
    name: "Discovers",
    icon: "  ri-money-dollar-box-line",
    subRoutes: DiscoverRoutes,
  },
  {
    Component: FAQ,
    path: "/faq",
    exact: false,
    id: "dashboardFaq",
    routePath: "/dashboard/faq",
    name: "Faq",
    icon: "  ri-money-dollar-box-line",
    subRoutes: FaqRoutes,
  },
  {
    Component: Business,
    path: "/business",
    exact: false,
    id: "dashboardBusiness",
    routePath: "/dashboard/business",
    name: "Business",
    icon: "  ri-money-dollar-box-line",
    subRoutes: BusinessRoutes,
  },
  {
    Component: YoutubeVidz,
    path: "/ytVideos",
    exact: false,
    id: "dashboardVideos",
    routePath: "/dashboard/ytVideos",
    name: "Youtube Videos",
    icon: "ri-money-dollar-box-line",
    subRoutes: YtVideosRoutes,
  },
  {
    Component: Offer,
    path: "/offer",
    exact: false,
    id: "dashboardOffer",
    routePath: "/dashboard/offer",
    name: "Offers",
    icon: "ri-money-dollar-box-line",
    subRoutes: OfferRoutes,
  },
  {
    Component: Blogs,
    path: "/blogs",
    exact: false,
    id: "dashboardBlogs",
    routePath: "/dashboard/blogs",
    name: "Blogs",
    icon: "ri-money-dollar-box-line",
    subRoutes: BlogRoutes,
  },
  {
    Component: MediaProperties,
    path: "/media-properties",
    exact: false,
    id: "dashboardMediaProperties",
    routePath: "/dashboard/media-properties",
    name: "Media Properties",
    icon: "ri-money-dollar-box-line",
    subRoutes: MediaPropertiesRouts,
  },
  {
    Component: MindAll,
    path: "/Mind",
    exact: false,
    id: "dashboardMind",
    routePath: "/dashboard/Mind",
    name: "Mind and Body",
    icon: "ri-money-dollar-box-line",
    subRoutes: MindRoutes,
  },
  {
    Component: Playlist,
    path: "/playlist",
    exact: false,
    id: "dashboardPlaylist",
    routePath: "/dashboard/playlist",
    name: "Playlist",
    icon: "ri-money-dollar-box-line",
    subRoutes: PlaylistRoutes,
  },
  {
    Component: Series,
    path: "/series",
    exact: false,
    id: "dashboardSeries",
    routePath: "/dashboard/series",
    name: "Series",
    icon: "ri-money-dollar-box-line",
    subRoutes: SeriesRoutes,
  },
  {
    Component: Lesson,
    path: "/lessons",
    exact: false,
    id: "dashboardLesson",
    routePath: "/dashboard/lessons",
    name: "Lessons",
    icon: "ri-money-dollar-box-line",
    subRoutes: LessonsSubRoute,
  },
  {
    Component: NutriDiets,
    path: "/nutri-diets",
    exact: false,
    id: "dashboardNutriDiet",
    routePath: "/dashboard/nutri-diets",
    name: "Nutri Diet",
    icon: "ri-money-dollar-box-line",
    subRoutes: NutriDietsSubRoute,
  },
  {
    Component: ProgramsAll,
    path: "/programs",
    exact: false,
    id: "dashboardProgram",
    routePath: "/dashboard/programs",
    name: "Today's Programs",
    icon: "ri-money-dollar-box-line",
    subRoutes: ProgramRoutes,
  },
  {
    Component: AdvisorsAll,
    path: "/advisor",
    exact: false,
    id: "dashboardAdvisor",
    routePath: "/dashboard/advisor",
    name: "Service Advisors",
    icon: "ri-money-dollar-box-line",
    subRoutes: AdvisorsRoutes,
  },
  {
    Component : SleepSessions,
    path : "/sleep-sessions",
    exact : false,
    id: "dashboardSleepSessions",
    routePath: "/dashboard/sleep-sessions",
    name: "Sleep sessions",
    icon: "ri-money-dollar-box-line",
    subRoutes: SleepSessionsRoutes,
  },
  {
    Component: LiveSessionsAll,
    path: "/live-sessions",
    exact: false,
    id: "dashboardLiveSessions",
    routePath: "/dashboard/live-sessions",
    name: "Live Sessions",
    icon: "ri-money-dollar-box-line",
    subRoutes: LiveSessionRoutes,
  },
  {
    Component: Appointments,
    path: "/user-appointment",
    exact: false,
    id: "dashboardAppointmen",
    routePath: "/dashboard/user-appointment",
    name: "Appointment",
    icon: "ri-money-dollar-box-line",
    subRoutes: AppointmentRoutes,
  },
  {
    Component: NutriLiveClass,
    path: "/n-live",
    exact: false,
    id: "dashboardNutriLive",
    routePath: "/dashboard/n-live",
    name: "N-Live",
    icon: "ri-money-dollar-box-line",
    subRoutes: NutriLiveRoutes,
  },
  {
    Component: Stories,
    path: "/stories",
    exact: false,
    id: "dashboardStories",
    routePath: "/dashboard/stories",
    name: "Stories",
    icon: "ri-money-dollar-box-line",
    subRoutes: StoriesRoutes,
  },
];
