import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MediaTagsCard from "../../../../../../Components/MediaTagsCard";
import CreateMediaTag from "../../../../../../Modal/CreateMediaTag";
import EditMedia from "../../../../../../Modal/EditMedia";
import MediaInfo from "../../../../../../Modal/MediaInfo";

import {
  tagTypes,
  categorieTableHeading,
  RestoreTableHeading,
} from "../../Tag.Constants";
import {
  getMediaTags,
  getTagsUnPublished,
  publishMedia,
  searchMediaTag,
  unPublishMedia,
} from "../../Tag.Apis";

const MediaCategories = () => {

  const [ errorRes, setErrorRes] = useState('')
  const [imgAsset, setImgAsset] = useState(null)
  const [showErr, setShowErr] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTagInfoOpen, setIsTagInfoOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [currentTag, setCurrentTag] = useState(tagTypes.CATEGORY);
  const [editSettings, setEditSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });
  const [deleteSettings, setDeleteSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });

  const [dataCategory, setDataCategory] = useState([]);

  const [dataCatsUnPublished, setDataCatsUnPublished] = useState([]);
  const [pageData, setPageData] = useState({
    categoryData: dataCategory,
  });
  const [paginationValueUnPublished, setPaginationValueUnPublished] = useState({
    currentPageUnPublished: 1,
    totalSizeUnPublished: 20,
  });

  const [isLoading, setIsLoading] = useState(false);
  const { currentPageUnPublished, totalSizeUnPublished } =
    paginationValueUnPublished;

  const [paginationValueCat, setPaginationValueCat] = useState({
    currentPageCat: 1,
    totalSizeCat: 20,
  });
  const { currentPageCat, totalSizeCat } = paginationValueCat;

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentSearch, setCurrentSearch] = useState([]);
  const [tagInfo, setTagInfo] = useState({
    name: "",
    description: "",
    objectType: "",
  });
  const [catType, setCatType] = useState(''); 
  const [filter, setFilter] = useState(''); 

  const handleCatTypeChange = (selectedOption) => {
    setCatType(selectedOption);
  };


  const handleFilterChange = (selectedOption) => {
    setFilter(selectedOption);
  };

  useEffect(() => {
    setPageData({
      ...pageData,
      categoryData: dataCategory,
    });
    
  }, [dataCategory]);
  useEffect(() => {
    setPageData({
      ...pageData,
      tagsData: dataCatsUnPublished,
    });
    
  }, [dataCatsUnPublished]);

  const { categoryData } = pageData;

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);
  const toggleEditModel = () => setIsEditModalOpen(!isEditModalOpen);
  const toggleTagInfo = () => setIsTagInfoOpen(!isTagInfoOpen);

  // Edit Tags

  const handleEditCategoryClick = (id) => {
    setCurrentTag(tagTypes.CATEGORY);
    setEditSettings({
      tagID: id,
      tagArray: "categoryData",
    });

    setIsEditModalOpen(true);
  };

  // Delete CATs

  const handleDeleteCategoryClick = (id) => {
    setDeleteSettings({
      tagID: id,
      tagArray: "categoryData",
    });
    
    
    setShowDeleteAlert(true);

  };

  const deleteTagAction = async () => {
    try {
      await unPublishMedia(deleteSettings.tagID);

      const filteredTags = pageData[deleteSettings.tagArray].filter(
        ({ _id }) => _id !== deleteSettings.tagID
      );
      setPageData({
        ...pageData,
        [deleteSettings.tagArray]: filteredTags,
      });
      deleteSettings.tagArray === "categoryData" &&
        setDataCatsUnPublished(filteredTags);
      setShowDeleteAlert(false);
      setShowDeleteConfirm(true);
    } catch (error) {
      console.log(error.response.data.error, "error.response.data.error");
      setErrorRes(error.response.data.error);
      setShowErr(true)
      
    }
    
   
  };

  // Get initial  data
  const getCategoryData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getMediaTags(queryParams);
      setPaginationValueCat({
        currentPageCat: queryParams.page,
        totalSizeCat: data.count,
      });
      setDataCategory(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCategoryData({ objectType: "CATEGORY", page: 1, limit: 10 });
  }, []);

  const handleTablePageChangeCat = (type, { page, sizePerPage }) => {
    getCategoryData({ objectType: "CATEGORY", page, limit: sizePerPage });
    setPaginationValueCat({
      ...paginationValueCat,
      currentPageCat: page,
    });
  };

  const getSearchData = async (text) => {
    try {
      const { data } = await searchMediaTag(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) {
      setCurrentSearch([]);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData({ objectType: "CATEGORY", searchBar });
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleTagInfo = (item) => {
    setTagInfo({
      name: item.name,
      description: item.description,
      objectType: item.objectType,
      editID : item._id
    });
    setImgAsset(item.imgAsset)
    toggleTagInfo();
  };

 
  const populateCreatedTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.CATEGORY:
        setPageData({
          ...pageData,
          categoryData: [tag, ...categoryData],
        });
        setDataCategory([tag, ...categoryData]);
        break;
      default:
        break;
    }
  };

  // Populate created tag
  const populateUpdatededTag = ({ tag, tagType }) => {
    switch (tagType) {
   
      case tagTypes.CATEGORY:
        setPageData({
          ...pageData,
          categoryData: [
            tag,
            ...categoryData.filter(({ _id }) => _id !== tag._id),
          ],
        });
        setDataCategory([
          tag,
          ...categoryData.filter(({ _id }) => _id !== tag._id),
        ]);
        break;
      default:
        break;
    }
  };

  // Handle Models
  const openCreateCategorieMogel = () => {
    setCurrentTag(tagTypes.CATEGORY);
    toggleCreateModel();
  };
  //  ------------------------------------------
  // getTagsUnPublishedData
  const getTagsUnPublishedData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getTagsUnPublished(queryParams);

      setPaginationValueUnPublished({
        currentPageUnPublished: queryParams.page,
        // currentPageTags: queryParams.page,
        totalSizeUnPublished: data.count,
      });
      setDataCatsUnPublished(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTagsUnPublishedData({ objectType: "CATEGORY", page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const handleTablePageChangeUnPublishedTags = (
    type,
    { page, sizePerPage }
  ) => {
    getTagsUnPublishedData({
      objectType: "CATEGORY",
      page,
      limit: sizePerPage,
    });
    setPaginationValueUnPublished({
      ...paginationValueUnPublished,
      currentPageUnPublished: page,
    });
  };

  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreSettings, setRestoreSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });
  const handleRestoreTagClick = (id) => {
    setRestoreSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setShowRestoreAlert(true);
  };

  const restoreTagAction = async () => {
    await publishMedia(restoreSettings.tagID);
    const filteredTags = pageData[restoreSettings.tagArray].filter(
      ({ _id }) => _id !== restoreSettings.tagID
    );
    setPageData({
      ...pageData,
      [restoreSettings.tagArray]: filteredTags,
    });
    restoreSettings.tagArray === "categoryData" &&
    setDataCategory(filteredTags);
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  const select_tag = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  console.log(currentSearch,'currentSearch')
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Media Categories</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_tag}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleTagInfo(item)}
                      >
                        <div>{item ? item.name : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <MediaTagsCard
        heading="Categories"
        openModel={openCreateCategorieMogel}
        modelBtnText="Add Category"
        tableData={dataCategory}
        tableHeading={categorieTableHeading}
        handleEditTag={handleEditCategoryClick}
        handleDeleteTag={handleDeleteCategoryClick}
        handleTablePageChange={handleTablePageChangeCat}
        page={currentPageCat}
        sizePerPage={10}
        totalSize={totalSizeCat}
        isLoading={isLoading}
        allTags={true}
      />
      <MediaTagsCard
        heading="Categories Unpublished"
        tableData={dataCatsUnPublished}
        tableHeading={RestoreTableHeading}
        handleRestoreTag={handleRestoreTagClick}
        handleTablePageChange={handleTablePageChangeUnPublishedTags}
        page={currentPageUnPublished}
        sizePerPage={10}
        totalSize={totalSizeUnPublished}
        isLoading={isLoading}
        allTags={false}
        errorRes={errorRes}
      />

      <MediaInfo
        name={tagInfo.name}
        description={tagInfo.description}
        objectType={tagInfo.objectType}
        isTagInfoOpen={isTagInfoOpen}
        toggleTagInfoModal={toggleTagInfo}
        mapping={false}
        imgAsset={imgAsset}
        currentTagID={editSettings.tagID}
        editid = {tagInfo.editID}
        setTagInfo={setTagInfo}
        tagInfo={tagInfo}
        setImgAsset={setImgAsset}
        handleCatTypeChange={handleCatTypeChange}
        handleFilterChange={handleFilterChange}
        catType={catType}
        setCatType={setCatType}
        filter={filter}
        setFilter={setFilter}
      />

      <CreateMediaTag
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        currentTag={currentTag}
        populateTag={populateCreatedTag}
        mediaDropdown={false}
        catImg={true}
        catMedia={true}
        handleCatTypeChange={handleCatTypeChange}
        handleFilterChange={handleFilterChange}
        catType={catType}
        setCatType={setCatType}
        filter={filter}
        setFilter={setFilter}
      />

      <EditMedia
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModel}
        currentTag={currentTag}
        currentTagID={editSettings.tagID}
        currentArray={pageData[editSettings.tagArray]}
        populateTag={populateUpdatededTag}
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
        mediaDropdown={false}
        mappingDropDown={false}
        catImg={true}
        handleCatTypeChange={handleCatTypeChange}
        handleFilterChange={handleFilterChange}
        catType={catType}
        setCatType={setCatType}
        filter={filter}
        setFilter={setFilter}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={deleteTagAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
          onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
            window.location.reload();
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={restoreTagAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
            window.location.reload();
          }}
        >
          File has been restored
        </SweetAlert>
      ) : null}
    </>
  );
};

export default MediaCategories;
