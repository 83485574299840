import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import Table from "../Table";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AllHomeSessionsComp = ({
  tableData = [],
  pageHeading = "All Home Sessions Class",
  tableHeading = [],
  handleTablePageChange,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  handleAllExportToCSV,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick,
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();
  const handleUserEditAppointment = (_id) => {
    history.push(
      "/dashboard/n-live/booking-sessions/all-booking-sessions-user/" + _id
    );
  };
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleUserEditAppointment(row._id)}
        />
      </div>
    );
  };
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }

  useEffect(() => {
    tableHeading.push({
      text: "Action",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewContentFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData) {
      var result =
        tableData &&
        tableData.length > 0 &&
        tableData?.map(function (el, i) {
          var o = {
            ...el,
            'createdAt': el?.createdAt?.slice(0, 10),
            name: el.userName,
            date: el.appDate?.slice(0, 10),
            time: el.appTime?.slice(11, 16),
          };
          o.sNo = i + 1 + page * 10 - 10;
          return o;
        });
      setRowData(result);
    }
  }, [tableData]);
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                  <CSVLink
                    data={handleAllExportToCSV()}
                    filename={'selected_users.csv'}
                    className="export-csv-btn" >
                    <Button
                      color="success"
                      className="waves-effect waves-light"
                      style={{ width: "100%", cursor: 'pointer' }}
                    >
                      Export to Csv
                    </Button>
                  </CSVLink>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={4}>
                <div style={{ display: 'flex', gap: '14px' }}>
                  <DatePicker
                    // type="number"
                    className="date_picker"
                    placeholderText="Select Start Date"
                    dateFormat="dd/MM/yyyy"
                    onChange={handleStartDateChange}
                    selected={startDate}
                    clearIcon={null}
                  />
                  <DatePicker
                    type="number"
                    className="date_picker"
                    placeholderText="Select End Date"
                    dateFormat="dd/MM/yyyy"
                    onChange={handleEndDateChange}
                    selected={endDate}
                    clearIcon={null}
                  />
                </div>
              </Col>
              <Col xs="auto">

                <Button
                  color="primary"
                  className="waves-effect waves-light"
                  style={{ width: "100%", cursor: 'pointer' }}
                  disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}
                  onClick={() => handleConfirmClick()}
                >
                  Apply Filters
                </Button>
              </Col>
              {rowData?.length > 0 &&
                <Col xs="auto">
                  <CSVLink
                    data={handleAllExportToCSV()}
                    filename={'all_booking_sessions_class.csv'}
                    className="export-csv-btn" >
                    <Button
                      color="success"
                      className="waves-effect waves-light"
                      style={{ width: "100%", cursor: 'pointer' }}
                    >
                      Export to Csv
                    </Button>
                  </CSVLink>
                </Col>}
            </Row> */}
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AllHomeSessionsComp;
