import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// import MediaTagsCard from "../../../../../../Components/MediaTagsCard";
import CreateMediaTag from "../../../../../../Modal/CreateMediaTag";
import EditTagModal from "../../../../../../Modal/EditTag";

import {
  tagTypes,
  tagsTableHeading,
  categorieTableHeading,
  subCategoriesTableHeading,
  RestoreTableHeading,
} from "../../Tag.Constants";

import {
  getMediaTags,
  searchMediaTag,
  unPublishMedia,
  getTagsUnPublished,
  publishMedia,
} from "../../Tag.Apis";
import TagInfo from "../../../../../../Modal/TagInfo";
import MediaTagsCard from "../../../../../../Components/MediaTagsCard";
import EditMedia from "../../../../../../Modal/EditMedia";

const MediaSubCategories = () => {

  const [ errorRes, setErrorRes] = useState('')
  const [imgAsset, setImgAsset] = useState(null)
  const [showErr, setShowErr] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTagInfoOpen, setIsTagInfoOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [currentTag, setCurrentTag] = useState(tagTypes.SUBCATEGORY);
  const [editSettings, setEditSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });
  const [deleteSettings, setDeleteSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });

  const [dataSubcategory, setDataSubcategory] = useState([]);
  const [dataSubCatsUnPublished, setDataSubCatsUnPublished] = useState([]);

  const [pageData, setPageData] = useState({
    subCategoryData: dataSubcategory,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValueSubcat, setPaginationValueSubcat] = useState({
    currentPageSubcat: 1,
    totalSizeSubcat: 20,
  });
  const { currentPageSubcat, totalSizeSubcat } = paginationValueSubcat;
  const [paginationValueUnPublished, setPaginationValueUnPublished] = useState({
    currentPageUnPublished: 1,
    totalSizeUnPublished: 20,
  });
  const { currentPageUnPublished, totalSizeUnPublished } =
    paginationValueUnPublished;

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentSearch, setCurrentSearch] = useState([]);
  const [tagInfo, setTagInfo] = useState({
    name: "",
    description: "",
    objectType: "",
    mediaIds: [],
  });

  useEffect(() => {
    setPageData({
      ...pageData,
      subCategoryData: dataSubcategory,
    });
  }, [dataSubcategory]);

  useEffect(() => {
    setPageData({
      ...pageData,
      tagsData: dataSubCatsUnPublished,
    });
  }, [dataSubCatsUnPublished]);
  const { subCategoryData } = pageData;
  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);
  const toggleEditModel = () => setIsEditModalOpen(!isEditModalOpen);
  const toggleTagInfo = () => setIsTagInfoOpen(!isTagInfoOpen);

  // Edit Tags
  const handleEditSubCategoryClick = (id) => {
    setCurrentTag(tagTypes.SUBCATEGORY);
    setEditSettings({
      tagID: id,
      tagArray: "subCategoryData",
    });
    setIsEditModalOpen(true);
  };

  // Delete Tags
  const handleDeleteSubCategoryClick = (id) => {
    setDeleteSettings({
      tagID: id,
      tagArray: "subCategoryData",
    });
    setShowDeleteAlert(true);
  };
  const deleteTagAction = async () => {
    try {
      await unPublishMedia(deleteSettings.tagID);
      const filteredTags = pageData[deleteSettings.tagArray].filter(
        ({ _id }) => _id !== deleteSettings.tagID
      );
      setPageData({
        ...pageData,
        [deleteSettings.tagArray]: filteredTags,
      });
      deleteSettings.tagArray === "subCategoryData" &&
        setDataSubCatsUnPublished(filteredTags);
      setShowDeleteAlert(false);
      setShowDeleteConfirm(true);
    } catch (error) {
      setErrorRes(error.response.data.error);
      setShowErr(true)
    }
  
  };

  // Get initial  data
  const getSubcategoryData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getMediaTags(queryParams);
      setPaginationValueSubcat({
        currentPageSubcat: queryParams.page,
        totalSizeSubcat: data.count,
      });
      setDataSubcategory(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSubcategoryData({ objectType: "SUBCATEGORY", page: 1, limit: 10 });
  }, []);

  const handleTablePageChangeSubCat = (type, { page, sizePerPage }) => {
    getSubcategoryData({ objectType: "SUBCATEGORY", page, limit: sizePerPage });
    setPaginationValueSubcat({
      ...paginationValueSubcat,
      currentPageSubcat: page,
    });
  };
  const getSearchData = async (text) => {
    try {
      const { data } = await searchMediaTag(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) {
      setCurrentSearch([]);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData({ objectType: "SUBCATEGORY", searchBar });
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleTagInfo = (item) => {
    console.log(item,'fuckoffff')
    setTagInfo({
      name: item.name,
      description: item.description,
      objectType: item.objectType,
      editID : item._id,
      mediaIds:item.mediaIds
    });
    setImgAsset(item.imgAsset)
    toggleTagInfo();
  };

  

  // Populate created tag
  const populateCreatedTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.SUBCATEGORY:
        setPageData({
          ...pageData,
          subCategoryData: [tag, ...subCategoryData],
        });
        setDataSubcategory([tag, ...subCategoryData]);
        break;
      default:
        break;
    }
  };

  // Populate update subCat tag
  const populateUpdatededTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.SUBCATEGORY:
        setPageData({
          ...pageData,
          subCategoryData: [
            tag,
            ...subCategoryData.filter(({ _id }) => _id !== tag._id),
          ],
        });
        setDataSubcategory([
          tag,
          ...subCategoryData.filter(({ _id }) => _id !== tag._id),
        ]);
        break;
      default:
        break;
    }
  };

  // Handle Models
  const openCreateSubCategorieMogel = () => {
    setCurrentTag(tagTypes.SUBCATEGORY);
    toggleCreateModel();
  };
  //  ------------------------------------------

  // getTagsUnPublishedData
  const getTagsUnPublishedData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getTagsUnPublished(queryParams);

      setPaginationValueUnPublished({
        currentPageUnPublished: queryParams.page,
        totalSizeUnPublished: data.count,
      });
      setDataSubCatsUnPublished(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTagsUnPublishedData({ objectType: "SUBCATEGORY", page: 1, limit: 10 });
  }, []);

  const handleTablePageChangeUnPublishedTags = (
    type,
    { page, sizePerPage }
  ) => {
    getTagsUnPublishedData({
      objectType: "SUBCATEGORY",
      page,
      limit: sizePerPage,
    });
    setPaginationValueUnPublished({
      ...paginationValueUnPublished,
      currentPageUnPublished: page,
    });
  };
  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreSettings, setRestoreSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });
  const handleRestoreTagClick = (id) => {
    setRestoreSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setShowRestoreAlert(true);
  };
  const restoreTagAction = async () => {
    await publishMedia(restoreSettings.tagID);
    const filteredTags = pageData[restoreSettings.tagArray].filter(
      ({ _id }) => _id !== restoreSettings.tagID
    );
    setPageData({
      ...pageData,
      [restoreSettings.tagArray]: filteredTags,
    });
    restoreSettings.tagArray === "subCategoryData" &&
      setDataSubcategory(filteredTags);
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  const select_tag = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Media SubCategories</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Media SubCategories"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_tag}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleTagInfo(item)}
                      >
                        <div>{item ? item.name : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <MediaTagsCard
        heading="SubCategories"
        openModel={openCreateSubCategorieMogel}
        modelBtnText="Add SubCategories"
        tableData={dataSubcategory}
        tableHeading={subCategoriesTableHeading}
        handleEditTag={handleEditSubCategoryClick}
        handleDeleteTag={handleDeleteSubCategoryClick}
        handleTablePageChange={handleTablePageChangeSubCat}
        page={currentPageSubcat}
        sizePerPage={10}
        totalSize={totalSizeSubcat}
        isLoading={isLoading}
        allTags={true}
      />
      <MediaTagsCard
        heading="SubCategories Unpublished"
        tableData={dataSubCatsUnPublished}
        tableHeading={RestoreTableHeading}
        handleRestoreTag={handleRestoreTagClick}
        handleTablePageChange={handleTablePageChangeUnPublishedTags}
        page={currentPageUnPublished}
        sizePerPage={10}
        totalSize={totalSizeUnPublished}
        isLoading={isLoading}
        allTags={false}
      />

      <TagInfo
        name={tagInfo.name}
        description={tagInfo.description}
        objectType={tagInfo.objectType}
        isTagInfoOpen={isTagInfoOpen}
        toggleTagInfoModal={toggleTagInfo}
        mapping={false}
        imgAsset={imgAsset}
        currentTagID={editSettings.tagID}
        editid = {tagInfo.editID}
        setTagInfo={setTagInfo}
        tagInfo={tagInfo}
        setImgAsset={setImgAsset}
        mediaDropdown={true}
        media={tagInfo.mediaIds}
      />

      <CreateMediaTag
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        currentTag={currentTag}
        populateTag={populateCreatedTag}
        mediaDropdown={true}
        catImg={true}
      />

      <EditMedia
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModel}
        currentTag={currentTag}
        currentTagID={editSettings.tagID}
        currentArray={pageData[editSettings.tagArray]}
        populateTag={populateUpdatededTag}
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
        mediaDropdown={true}
        mappingDropDown={false}
        catImg={true}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteTagAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

{showErr ? (
        <SweetAlert
          warning
          title={errorRes}
          onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
            window.location.reload();
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={restoreTagAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
            window.location.reload();
          }}
        >
          File has been restored
        </SweetAlert>
      ) : null}
    </>
  );
};

export default MediaSubCategories;
