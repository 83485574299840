import { useState, useEffect } from "react";
import CreateLessonForm from "../../../../../../Components/CreateLessonForm";
import UploadingLessonLoader from "../../../../../../Components/UploadingLessonLoader";
import { creatingStates, fileTypes } from "../../Constants/constant";
import { uploadFile } from "../../lessons.Constants";
import { createLessonApi, transCodingApi } from "../../lessons.Api";
import { getAudioDuration } from "../../../Music/Music.Helpers";

const CreateNewLesson = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    imgAsset: "",
    assetUrl: "",
    duration: "",
    oriUrl: "",
    objectType: "VIDEO",
  });
  const { title, description, objectType } = formData;

  // Handle form IP
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  console.log(formData, "TTTTTT");

  // reset page
  const reset = () => {
    setThumbnail(null);
    setVideo(null);
    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      description: "",
      imgAsset: "",
      assetUrl: "",
      duration: "",
      oriUrl: "",
      objectType: "VIDEO",
    });

    setThumbnailUploadPercentage(0);
    setVideoUploadPercentage(0);
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  // Create VIDEO
  const handleSubmit = async () => {
    if (!thumbnail || !video)
      return setError({
        isError: true,
        errorMessage: "Please select thumbnail and Video",
        errorType: "warning",
      });

    try {
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const thumbnailUrl = await uploadFile(
        thumbnail,
        fileTypes.TRACK_IMAGE,
        setThumbnailUploadPercentage,
        10
      );

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_VIDEO,
      });
      const videoDuration = await getAudioDuration(video);
      const videoUrl = await uploadFile(
        video,
        "TRACK_VIDEO",
        setVideoUploadPercentage,
        50
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_VIDEO,
      });

      const { data } = await createLessonApi({
        title,
        description,
        objectType,
        assetURL: videoUrl,
        oriURL: videoUrl,
        imgAsset: thumbnailUrl,
        runtime: videoDuration,
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      await transCodingApi({
        assetURL: data?.data?.assetURL,
        title: data?.data?.title,
        description: data?.data?.description,
        assetImage: data?.data?.imgAsset,
        id: data?.data?._id,
        type: "LESSON",
      });
    } catch (error) {
      console.log(error);
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setVideoUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Lesson please try again later",
        errorType: "danger",
      });
    }
  };

  return (
    <div>
      {isCreating ? (
        <UploadingLessonLoader
          currentStep={creatingStage}
          thumbnailuploadPercentage={thumbnailuploadPercentage}
          videoUploadPercentage={videoUploadPercentage}
          reset={reset}
        />
      ) : (
        <CreateLessonForm
          handelInput={handelInput}
          title={title}
          pageHeading={"Create Lesson"}
          objectType={objectType}
          description={description}
          setThumbnail={setThumbnail}
          thumbnail={thumbnail}
          video={video}
          setVideo={setVideo}
          handleSubmit={handleSubmit}
          isError={isError}
          errorMessage={errorMessage}
          errorType={errorType}
        />
      )}
    </div>
  );
};

export default CreateNewLesson;
