import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { createManualUserApi } from "../../Views/Dashboard/Components/Business/Business.Api";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { validateEmail } from "../../utils/validationRegex";

const BusinessForm = ({
  pageHeading = "Create User",
  createBtnText = "Create User",
  formData,
  setFormData,
}) => {
  const [error, setError] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobCountryCode, setMobCountryCode] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleMobile = (value, data) => {
    setPhoneNumber(value);
    setMobCountryCode(data.dialCode);
  };

  const {
    first_Name,
    last_Name,
    company_Name,
    company_Size,
    email,
    companions,
    your_Role,
    annual_Stocks,
    monthly_Stocks,
    country,
    state,
    city,
    amountPaid,
    currency,
  } = formData;

  const handleCreateUser = async () => {
    const body = {
      firstName: first_Name,
      lastName: last_Name,
      companyName: company_Name,
      companySize: company_Size,
      email: email,
      companionCount: companions,
      designation: your_Role,
      country: country,
      state: state,
      city: city,
      monthlyPlanCount: monthly_Stocks,
      annuallyPlanCount: annual_Stocks,
      phoneNumber: phoneNumber.slice(mobCountryCode.length),
      countryCode: "+" + mobCountryCode,
      amountPaid: amountPaid * 1,
      currency: currency,
    };

    if (
      first_Name === "" ||
      last_Name === "" ||
      company_Size === "" ||
      company_Name === "" ||
      email === "" ||
      companions === "" ||
      your_Role === "" ||
      state === "" ||
      country === "" ||
      monthly_Stocks === "" ||
      annual_Stocks === "" ||
      city === "" ||
      amountPaid === "" ||
      currency === ""
    ) {
      setError(1);
    } else {
      setError(0);
      if (validateEmail(email) === true) {
        try {
          await createManualUserApi(body);
          setAccountCreated(true);
          setFormData({
            email: "",
            first_Name: "",
            last_Name: "",
            companions: "",
            company_Size: "",
            company_Name: "",
            your_Role: "",
            monthly_Stocks: "",
            annual_Stocks: " ",
            country: "",
            state: "",
            amountPaid: "",
            currency: "",

          });
          setPhoneNumber("");
        } catch (error) {
          setError(3);
          setErrorMessage(error?.response?.data?.error);
          console.log(error);
        }
      } else {
        setError(2);
      }
    }
  };

  const handleCountrySelect = (e) => {
    setFormData({
      ...formData,
      country: e.target.value,
      countryCode: countryData.filter((elem) => elem.name === e.target.value)[0]
        .isoCode,
    });
  };

  const handleStateSelect = (e) => {
    setFormData({
      ...formData,
      state: e.target.value,
      stateCode: State.getStatesOfCountry(formData.countryCode).filter(
        (elem) => elem.name === e.target.value
      )[0].isoCode,
    });
  };

  useEffect(() => {
    setCountryData(Country.getAllCountries());
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="title">Email</Label>
                      <AvField
                        onChange={handleOnChange}
                        name="email"
                        value={email}
                        placeholder="Email"
                        type="text"
                        className="form-control"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">First Name</Label>
                      <AvField
                        onChange={handleOnChange}
                        name="first_Name"
                        value={first_Name}
                        placeholder="First Name"
                        type="text"
                        className="form-control"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">Last Name</Label>
                      <AvField
                        onChange={handleOnChange}
                        name="last_Name"
                        value={last_Name}
                        placeholder="Last Name"
                        type="text"
                        className="form-control"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">Company Name</Label>
                      <AvField
                        name="company_Name"
                        placeholder="Company Name"
                        type="text"
                        value={company_Name}
                        onChange={handleOnChange}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">Phone Number</Label>
                      <PhoneInput
                        enableSearch={true}
                        country={"in"}
                        value={phoneNumber}
                        specialLabel={""}
                        onChange={handleMobile}
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Select Country</Label>
                      <Input
                        name="country"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={formData.country}
                        onChange={handleCountrySelect}
                      >
                        <option value="">Select you country</option>
                        {countryData.map((elem) => (
                          <option value={elem.name}>{elem.name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">State</Label>
                      <Input
                        name="state"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={formData.state}
                        onChange={handleStateSelect}
                        id="languageSelect"
                      >
                        <option value="">Select your state</option>
                        {State.getStatesOfCountry(formData.countryCode).map(
                          (elem) => (
                            <option value={elem.name}>{elem.name}</option>
                          )
                        )}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">City</Label>
                      <Input
                        name="city"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={formData.city}
                        onChange={(e) =>
                          setFormData({ ...formData, city: e.target.value })
                        }
                        id="languageSelect"
                      >
                        <option value="">Select your city</option>
                        {City.getCitiesOfState(
                          formData.countryCode,
                          formData.stateCode
                        ).map((elem) => (
                          <option value={elem.name}>{elem.name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Your Role</Label>
                      <Input
                        name="your_Role"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={formData.your_Role}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            your_Role: e.target.value,
                          })
                        }
                        id="languageSelect"
                      >
                        <option value="">Select your designation</option>
                        <option value="Human Resources">Human Resources</option>
                        <option value="Employee Health & Wellness">
                          Employee Health & Wellness
                        </option>
                        <option value="Employee Benefits">
                          Employee Benefits
                        </option>
                        <option value="Company Executive / Senior Leader">
                          Company Executive / Senior Leader
                        </option>
                        <option value="Broker / Consultant">
                          Broker / Consultant
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">
                        Annual initial stocks
                      </Label>
                      <AvField
                        name="annual_Stocks"
                        placeholder="Annual stocks"
                        type="number"
                        value={formData.annual_Stocks}
                        onChange={handleOnChange}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">
                        Monthly initial stocks
                      </Label>
                      <AvField
                        name="monthly_Stocks"
                        placeholder="Monthly stocks"
                        type="number"
                        value={formData.monthly_Stocks}
                        onChange={handleOnChange}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">
                        Select Company Size
                      </Label>
                      <Input
                        name="company_Size"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={formData.company_Size}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            company_Size: e.target.value,
                          })
                        }
                        id="languageSelect"
                      >
                        <option value="">
                          Select the number of working staff
                        </option>
                        <option value="0-25">0-25</option>
                        <option value="26-300">26-300</option>
                        <option value="301-5000">301-5000</option>
                        <option value="5001-50000">5001-50000</option>
                        <option value="50000+">50000+</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">
                        Number of companions
                      </Label>
                      <AvField
                        name="companions"
                        placeholder="Number of companions"
                        type="number"
                        value={companions}
                        onChange={handleOnChange}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Select Currency</Label>
                      <Input
                        name="currency"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={formData.currency}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            currency: e.target.value,
                          })
                        }
                        id="languageSelect"
                      >
                        <option value="">Select the currency</option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Amount Paid</Label>
                      <AvField
                        name="amountPaid"
                        placeholder="Amount paid by user"
                        type="number"
                        value={amountPaid}
                        onChange={handleOnChange}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
              {error === 1 ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Please fill all the fields
                </p>
              ) : error === 2 ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Please enter correct mail
                </p>
              ) : error === 3 ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                <></>
              )}
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={handleCreateUser}
                >
                  {createBtnText}
                </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {accountCreated === true ? (
        <>
          <SweetAlert
            success
            title="Account Created"
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setAccountCreated(false);
            }}
          >
            Account Created

          </SweetAlert>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BusinessForm;
