import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getByCategoryAPI } from '../../Api'
import './style.scss'
const ViewSeries = () => {
  const [series, setSeries] = useState({})
  const { refId } = useParams()
  const getSeriesData = async() => {
    try {
      const { data } = await getByCategoryAPI(refId)
      setSeries(data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getSeriesData()
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {Object.keys(series).length === 0 ? (
        <div className="global-loader">Loading...</div>
      ) : (
        <div className="series-container" id="series-container">
          <h2 dangerouslySetInnerHTML={{ __html: `${series?.title}` }}></h2>
          <br />
          {series?.imgAsset && (
            <div className="series">
              <img src={`${series?.imgAsset}`} alt={`${series?.imgAsset}`} />
            </div>
          )}
          <div
            className="series-grid"
            dangerouslySetInnerHTML={{ __html: `${series?.description}` }}
          ></div>
        </div>
      )}
    </>
  )
}
export default ViewSeries