import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import AppointmentRoutes from "./Constant/routes";

const Appointments = () => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {AppointmentRoutes.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/user-appointment" />
      </Switch>
    </>
  );
};

export default Appointments;