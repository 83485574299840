import AllNutriDiet from "../Views/AllNutriDiet";
import CreateNewNutriDiet from "../Views/CreateNewNutriDiet";
import EditNutriDiet from "../Views/EditNutriDiet";

export const NutriDietsSubRoute = [
  {
    Component: AllNutriDiet,
    path: "/all",
    exact: true,
    id: "dashboardAllNutriDiet",
    routePath: "/dashboard/nutri-diets/all",
    name: "All Nutri Diet",
  },
  {
    Component: CreateNewNutriDiet,
    path: "/create",
    exact: true,
    id: "dashboardNutriDietCreate",
    routePath: "/dashboard/nutri-diets/create",
    name: "Create Nutri Diet",
  },
  {
    Component: EditNutriDiet,
    path: "/edit/:nutridietId",
    exact: true,
    id: "dashboardEditNutriDiet",
  },
];
