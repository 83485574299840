import React, { useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { createPlanReq } from "../../Views/Dashboard/Components/Plan/Plans.Apis";
import Select from "react-select";
import { useHistory } from "react-router-dom";

const PlansForm = ({
  pageHeading = "Create Plans",
  createBtnText = "Create Plan",
  formData,
  setFormData,
}) => {
  const history = useHistory();

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });

  const handelInput = (e) => {
    let val = e.target.name;
    if (val === "IOS" || val === "Android" || val === "WEB") {
      return setFormData({
        ...formData,
        [val]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [val]: e.target.value,
      });
    }
  };

  const countryCodeData = [
      { key: "INTERNATIONAL", label: "INTERNATIONAL", value: "INTERNATIONAL" },
    { key: "NATIONAL", label: "NATIONAL", value: "NATIONAL" },
  ];


  const handleCountryCode = (select) => {
    setFormData({...formData,country_code:select})
  };


  const handleSubmit = async () => {
    if (
      formData.plan_identifier === "" ||
      formData.plan_description === "" ||
      formData.plan_discount_price === "" ||
      formData.plan_display_price === "" ||
      formData.plan_duration_indays === "" ||
      formData.freeDays === "" ||
      formData.country_code.value === ""
    ) {
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Required field empty",
      });
    } else if (
      formData.IOS === false &&
      formData.Android === false &&
      formData.WEB === false
    ) {
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Please select atleast one device platform",
      });
    } else {
      try {
        setIsDisabledBtn(true);
        await createPlanReq({...formData,country_code:formData.country_code.value});
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Created Sucessfully",
        });
        setTimeout(() => {
          history.push("/dashboard/plan/all");
          setIsDisabledBtn(false);
        }, 1000);
      } catch (error) {
        setAlert({
          isAlert: true,
          alertType: "danger",
          alertText: "Unable to Create",
        });
        console.error(error.response.data, error);
      }
    }
  };

  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  // const isError = false;

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor="title">Plan Identifier</Label>
                    <AvField
                      name="plan_identifier"
                      placeholder="Enter Plan Identifier"
                      value={formData.plan_identifier}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="plan_identifier"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Plan Description</Label>
                <AvField
                  name="plan_description"
                  placeholder="Enter Plan description"
                  value={formData.plan_description}
                  onChange={handelInput}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="plan_description"
                />
              </FormGroup>

              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Display Price</Label>
                    <AvField
                      name="plan_display_price"
                      placeholder="Enter Display Price"
                      value={formData.plan_display_price}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Display Price is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="plan_display_price"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Discount Price</Label>
                    <AvField
                      name="plan_discount_price"
                      placeholder="Enter Discount Price"
                      value={formData.plan_discount_price}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Discount Price is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="plan_discount_price"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Duration</Label>
                    <AvField
                      name="plan_duration_indays"
                      placeholder="Enter Duration in Days"
                      value={formData.plan_duration_indays}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Display Price is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="plan_duration_indays"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col ls={6}>
                <FormGroup className="select2-container">
                    <Label className="control-label">Free Days</Label>
                    <AvField
                      name="freeDays"
                      placeholder="Enter Free Days"
                      value={formData.freeDays}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Free Day is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="freeDays"
                    />
                  </FormGroup>
                </Col>
                <Col ls={6}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Select Country Code</Label>
                    <Select
                      value={formData.country_code}
                      isMulti={false}
                      onChange={handleCountryCode}
                      options={countryCodeData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        countryCodeData?.length === 0
                          ? "Loading..."
                          : "Select Country Code"
                      }
                    />
                  </FormGroup>
                </Col> 
               
              </Row>
              <Row>
                <Col xs={4}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="IOS"
                      checked={formData.IOS}
                      onClick={handelInput}
                      id="IOS"
                    ></Input>
                    <Label className="custom-control-label" htmlFor="IOS">
                      iOS
                    </Label>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="Android"
                      checked={formData.Android}
                      onClick={handelInput}
                      id="Android"
                    ></Input>
                    <Label className="custom-control-label" htmlFor="Android">
                      Android
                    </Label>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="WEB"
                      checked={formData.WEB}
                      onClick={handelInput}
                      id="WEB"
                    ></Input>
                    <Label className="custom-control-label" htmlFor="WEB">
                      WEB
                    </Label>
                  </div>
                </Col>
              </Row>

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
            <br />
            <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role="alert">
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PlansForm;
