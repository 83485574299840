import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";

const MusicCard = ({
  tableData = [],
  tableHeading = [],
  handleViewMusic,
  handleUnpublishMusic,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleAlternateMusic,
}) => {
  // const history = useHistory();
  const [rowData, setRowData] = useState([]);

  const viewMusicFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <i
          className='ri-play-list-fill text-info h4 cursor-pointer mr-2'
          onClick={() => handleViewMusic(row._id)}
        />
      </div>
    );
  };

  const alternateMusicFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <i
          className='ri-file-edit-fill text-info h4 cursor-pointer mr-2'
          onClick={() => handleAlternateMusic(row._id)}
        />
      </div>
    );
  };

  const unpublishFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color='danger'
          className='waves-effect waves-light'
          style={{ width: '100%' }}
          onClick={() => handleUnpublishMusic(row._id)}
        >
          Unpublish
        </Button>
      </div>
    );
  };

  useEffect(() => {
    // tableHeading.push({
    //   text: 'Create Alternate',
    //   dataField: 'create',
    //   isDummyField: true,
    //   headerStyle: { textAlign: 'center', width: '10%' },
    //   formatter: alternateMusicFormatter,
    // });
    tableHeading.push({
      text: 'View Media',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewMusicFormatter,
    });
    tableHeading.push({
      text: 'Unpublish Media',
      dataField: 'unpublish',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: unpublishFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>Published Media</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                    {/* <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/media/bin")}
                    >
                      Music Bin
                    </Button>
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/media/create")}
                    >
                      Add Music
                    </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MusicCard;
