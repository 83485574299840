import React, { useState, useEffect } from "react";
import { Alert, FormGroup, Label } from "reactstrap";

import { getUser, searchUser, GetAllUsers, getAllUsersDataByDateReq, GetAllUsersByDate } from "./Users.Apis";

import UsersCard from "../../../../Components/UsersCard";
import { userTableHeading } from "./Users.Constants";
import UserInfo from "../../../../Modal/UserInfo";

import { downloadXl } from "./Users.Utils";
import DateRangePicker from "rsuite/DateRangePicker";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  });
  const { currentPage, totalSize } = paginationValue;
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState(0);
  const [endRangeDate, setEndRangeDate] = useState(0);
  const [filterData, setFilterDate] = useState({
    countrySearch: "",
    enabledQuery: "ALL",
    subscribedQuery: "ALL",
  });

  const { countrySearch, enabledQuery, subscribedQuery } = filterData;

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isDownloadingCSVByDate, setIsDownloadingCSVByDate] = useState(false);

  const toggleUserInfo = () => setIsUserInfoOpen(!isUserInfoOpen);

  const getData = async (queryParams) => {
    try {
      setIsLoading(true);
      if (isFilterApplied) {
        if (enabledQuery !== "ALL")
          queryParams.isDisabled = enabledQuery === "true" ? false : true;
        if (subscribedQuery === "FREETRAIL") {
          queryParams.isSubscribe = subscribedQuery;
        }
        if (subscribedQuery !== "ALL" && subscribedQuery !== "FREETRAIL")
          queryParams.isSubscribe = subscribedQuery === "true" ? true : false;
        if (countrySearch) queryParams.country = countrySearch;
      }
      if ((startRangeDate && endRangeDate) > 0) {
        queryParams.startDate = startRangeDate
        queryParams.endDate = endRangeDate
      }
      const { data } = await getUser(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setUsers(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFilterApplied) getData({ page: currentPage, limit: 10 });
    // eslint-disable-next-line
  }, [countrySearch, enabledQuery, subscribedQuery, isFilterApplied]);

  useEffect(() => {
    if (!isFilterApplied) getData({ page: currentPage, limit: 10 });
    // eslint-disable-next-line
  }, [isFilterApplied]);

  useEffect(() => {
    getData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const getSearchData = async (text) => {
    try {
      const { data } = await searchUser(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) { }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleUserInfo = (item) => {
    setSelectedUserId(item._id);
    toggleUserInfo();
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const select_user = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  async function handleDownloadCSV() {
    try {
      setIsDownloadingCSV(true);
      const { data } = await GetAllUsers();
      if (data && Array.isArray(data.data)) {
        const csvData = data.data.map((item) => {
          const itm = {
            ...item,
            ...(item.subscriptionsId && { ...item.subscriptionsId }),
          };
          delete itm._id;
          delete itm.firebaseToken;
          delete itm.userId;
          delete itm.planId;
          delete itm.orderId;
          delete itm.paymentId;
          delete itm.profileUrl;
          if (itm.subscriptionsId) delete itm.subscriptionsId;
          return itm;
        });
        downloadXl(csvData);
      }
    } catch (error) {
      setIsError("Error while downloading the file, please try again later!");
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    } finally {
      setIsDownloadingCSV(false);
    }
  }

  async function handleDownloadCSVByDate() {
    try {
      setIsDownloadingCSVByDate(true);
      const { data } = await GetAllUsersByDate(startRangeDate, endRangeDate);
      if (data && Array.isArray(data.data)) {
        const csvData = data.data.map((item) => {
          const itm = {
            ...item,
            ...(item.subscriptionsId && { ...item.subscriptionsId }),
          };
          delete itm._id;
          delete itm.firebaseToken;
          delete itm.userId;
          delete itm.planId;
          delete itm.orderId;
          delete itm.paymentId;
          delete itm.profileUrl;
          if (itm.subscriptionsId) delete itm.subscriptionsId;
          return itm;
        });
        downloadXl(csvData);
      }
    } catch (error) {
      setIsError("Error while downloading the file, please try again later!");
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    } finally {
      setIsDownloadingCSVByDate(false);
    }
  }

  const handleChangeRange = (data) => {
    if (data && !data[0]) {
      if (startRangeDate != 0 && endRangeDate == 0) {
        endRangeDate == 0 && data && setEndRangeDate(data)
      } else if (startRangeDate == 0 && endRangeDate == 0) {
        startRangeDate == 0 && data && setStartRangeDate(data)
      }
    } else {
      if (data && data[0] && data[1]) {
        data && data[0] && setStartRangeDate(data[0])
        data && data[1] && setEndRangeDate(data[1])
        getDataByDate(data, data[0], data[1]);
      }
    }
  }


  const getDataByDate = async (data, startDate, endDate, queryParams) => {
    if (data) {
      try {
        setIsLoading(true);
        if (isFilterApplied) {
          if (enabledQuery !== "ALL")
            queryParams.isDisabled = enabledQuery === "true" ? false : true;
          if (subscribedQuery !== "ALL")
            queryParams.isSubscribe = subscribedQuery === "true" ? true : false;
          if (countrySearch) queryParams.country = countrySearch;
        }
        const { data } = await getAllUsersDataByDateReq(
          startDate = startDate || startRangeDate,
          endDate = endDate || endRangeDate,
        );
        setUsers(data.data);
        setIsLoading(false);
        setPaginationValue({
          currentPage: queryParams.page,
          totalSize: data.count,
        });

      } catch (error) { }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        {isError && <Alert color="danger">{isError}</Alert>}
        <div className="page-title-box d-flex align-items-between justify-content-between w-100 p-0">
          <h2 style={{ margin: "0px" }}>All Users</h2>
          <div className="d-flex mr-5">
            <FormGroup>
              <Label>Start / End Date</Label>
              <DateRangePicker
                onOk={() => getDataByDate(false)}
                onSelect={(data) => handleChangeRange(data)}
                onChange={(data) => handleChangeRange(data)}
              />
            </FormGroup>
          </div>
          <div style={{ position: "relative" }}>
            <>
              <input
                name="search"
                value={search}
                placeholder="Search User"
                style={{
                  width: "300px",
                  backgroundColor: "transparent",
                  outline: "none",
                  border: "none",
                  borderBottom: "1px solid black",
                  padding: "5px",
                }}
                onChange={handleChange}
                onBlur={hideComponent}
                autoComplete="off"
              />
              {show === true && (
                <>
                  <div style={select_user}>
                    {currentSearch && currentSearch.length === 0 ? (
                      <div>Not found</div>
                    ) : (
                      currentSearch &&
                      currentSearch.map((item, i) => {
                        return (
                          <div
                            style={select_search_item}
                            key={i}
                            onClick={() => handleUserInfo(item)}
                          >
                            <div>{item ? item.firstName : "-"}</div>
                            <div>{item ? item.lastName : "-"}</div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </>
              )}
            </>
            <button
              className="btn btn-outline-primary ml-5"
              onClick={handleDownloadCSV}
              type="button"
              disabled={isDownloadingCSV}
            >
              {isDownloadingCSV ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp;
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                "Download All"
              )}
            </button>

            <button
              className={(startRangeDate && endRangeDate) > 0 ? "btn btn-outline-primary ml-5" : "btn btn-outline-primary ml-5 d-none"}
              onClick={handleDownloadCSVByDate}
              type="button"
              disabled={isDownloadingCSVByDate}
            >
              {isDownloadingCSVByDate ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp;
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                "Download By Dates"
              )}
            </button>
          </div>
        </div>
      </div>
      <UsersCard
        tableData={users}
        tableHeading={userTableHeading}
        setUsers={setUsers}
        handleTablePageChange={handleTablePageChange}
        handleView={handleUserInfo}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        filterData={filterData}
        setFilterDate={setFilterDate}
        isFilterApplied={isFilterApplied}
        setIsFilterApplied={setIsFilterApplied}
      />


      {selectedUserId && isUserInfoOpen && (
        <UserInfo
          userId={selectedUserId}
          isUserInfoOpen={isUserInfoOpen}
          toggleUserInfoModal={toggleUserInfo}
        />
      )}
    </>
  );
};

export default Users;
