import React from "react";
import { useState, useEffect } from "react";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import Loader from "../../../../../../Components/Loader";
import AdvisorForm from '../../../../../../Components/AdvisorForm'

import { creatingStates } from "../../../Blogs/Constants";
import { createAdvisorApi } from "../../advisors.Apis";

const CreateAdvisor = () => {
  const history = useHistory();
  const [advisorDetails, setAdvisorDetails] = useState({
    name: "",
    designation: "",
    advisorType: "",
    speciality: "",
    experience: "",
    rating: "",
    noOfPatients: "",
    imgAsset: ""
  });
  const [showPostAdvisorAlert, setShowPostAdvisorAlert] = useState(false);
  const [showPostAdvisorConfirm, setShowPostAdvisorConfirm] = useState(false);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);

  const [coverImage, setCoverImage] = useState("")

  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;
  const [specialityList, setSpecialityList] = useState([]);


  const { name, designation, advisorType, speciality, experience, rating, noOfPatients, imgAsset } = advisorDetails;

  const [advisorTypeData, setAdvisorTypeData] = useState("")
  const advisoryTypeOpt = [
    {
      name: 'COUNSELLOR',
    },
    {
      name: 'MENTOR',
    },
  ]
  const advisoryTypeOptions = advisoryTypeOpt.map((item) => ({
    value: item.name,
    label: item.name
  }))
  const handleSelectedAdvisoryType = (selectedOption) => {
    setAdvisorTypeData(selectedOption);
    console.log(selectedOption);
  };

  const handelInput = (e) => {
    // debugger;
    setAdvisorDetails({
      ...advisorDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddSpeciality = () => {
    const { speciality } = advisorDetails;
    if (speciality.trim() !== "") {
      setSpecialityList([...specialityList, speciality]);
      setAdvisorDetails({
        ...advisorDetails,
        speciality: "",
      });
    }
  };

  const handleRemoveSpeciality = (indexToRemove) => {
    const updatedSpecialityList = [...specialityList];
    updatedSpecialityList.splice(indexToRemove, 1);
    setSpecialityList(updatedSpecialityList);
  };

  const handleUpdate = async (e) => {
    e.preventDefault()

    try {
      if (!coverImage) {
        setError({
          isError: false,
          errorMessage: "",
          errorType: "warning",
        })
      }

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      const coverUrl = await uploadFile(
        coverImage,
        "IMAGE",
        setContentUploadPercentage,
        50
      );
      try {
        await createAdvisorApi({
          name: name,
          designation: designation,
          advisorType: advisorTypeData.label,
          speciality: specialityList,
          experience: experience,
          rating: rating,
          noOfPatients: noOfPatients,
          imgAsset: coverUrl,

        });
        setShowPostAdvisorConfirm(true);
        setError(0);
        setAdvisorDetails({
          ...advisorDetails,
          name: "",
          designation: "",
          advisorType: "",
          speciality: "",
          experience: "",
          rating: "",
          noOfPatients: ""
        });
      } catch (error) {
        console.log(error.response.data, error);
      }


    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <AdvisorForm
        heading="Create Advisor"
        advisoryTypeOptions={advisoryTypeOptions}
        advisorTypeData={advisorTypeData}
        handleSelectedAdvisoryType={handleSelectedAdvisoryType}
        image={coverImage}
        setImage={setCoverImage}
        handleInput={handelInput}
        createHandle={handleUpdate}
        setShowPostAdvisorAlert={setShowPostAdvisorAlert}
        handleAddSpeciality={handleAddSpeciality}
        specialityList={specialityList}
        handleRemoveSpeciality={handleRemoveSpeciality}
      />
      {showPostAdvisorConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/advisor/all");
          }}
        ></SweetAlert>
      ) : null}

    </>
  );
};

export default CreateAdvisor;
