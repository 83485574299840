import React, { useState, useEffect } from "react";
import CreateDiscoverNispandComp from "../../../../../../Components/CreateDiscoverNispandComp";
import {
  getAllSeriesPlaylistApi,
  createDiscoverApi,
  getAllMediaApi,
} from "../../Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { getMediaApi } from "../../../Tag/Tag.Apis";

const CreateDiscoverNispand = () => {

  const history = useHistory()
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [errMsg, setErrMsg] = useState()
  const [allSeries, setAllSeries] = useState();
  const [seriesData, setSeriesData] = useState();
  const [allMedia, setAllMedia] = useState();
  const [mediaData, setMediaData] = useState();
  const [allPlaylist, setAllPlaylist] = useState();
  const [playlistData, setPlaylistData] = useState();
  const [objType, setObjType] = useState();
  const [category, setCategory] = useState();
  const [categoryType, setCategoryType] = useState();
  const [seeAllType, setSeeAllType] = useState({ "key": "1", "label": "DISCOVER", "value": "1" });
  const [order, setOrder] = useState();
  const [mediaCount, setMediaCount] = useState();
  const [seriesCount, setSeriesCount] = useState();
  const [playlistCount, setPlaylistCount] = useState();
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const { title, description } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getAllMediaData = async (params) => {
    const { data } = await getAllMediaApi(params);
    setMediaData(data?.data);
    setMediaCount(data?.count);
    console.log(data?.count, "getAllMediaData count");
  };

  const getAllSeriesData = async (params) => {
    const { data } = await getAllSeriesPlaylistApi(params);
    setSeriesData(data?.data);
    setSeriesCount(data?.count);
    console.log(data?.data, "getAllMediaData");
  };
  const getAllPlaylistData = async (params) => {
    const { data } = await getAllSeriesPlaylistApi(params);
    setPlaylistData(data?.data);
    setPlaylistCount(data?.count);
    console.log(data?.data, "getAllMediaData");
  };

  useEffect(() => {
    getAllMediaData({ type: "MEDIA", page: 1, limit: mediaCount });
    getAllSeriesData({ type: "SERIES", page: 1, limit: seriesCount });
    getAllPlaylistData({ type: "PLAYLIST", page: 1, limit: playlistCount });
  }, [mediaCount, seriesCount, playlistCount]);
  const [getCategory, setGetCategory] = useState('')
  const [rawCatgeory, setRawCatgeory] = useState('')

  useEffect(async () => {
    const { data } = await getMediaApi({ objectType: 'CATEGORY', page: 1, limit: 100 })
    setRawCatgeory(data.data)
    setGetCategory(data.data.map(item => {
      return {
        key: item._id,
        label: item.name,
        value: item._id,
      }
    }))

    setCategory({
      key: data.data[0]._id,
      label: data.data[0].name,
      value: data.data[0]._id,
    })

    updateCategoryTypes({
      key: data.data[0]._id,
      label: data.data[0].name,
      value: data.data[0]._id,
    }, data.data)

  }, []);
  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };
  const [categoryValues, setCategoryValues] = useState()
  const updateCategoryTypes = (value, rawCatgeory) => {
    const matchedCategory = rawCatgeory.find(obj => obj._id === value.key);
    if (matchedCategory?.catType) {
      setCategoryValues([{
        key: matchedCategory.catType,
        label: matchedCategory.catType,
        value: matchedCategory.catType,
      }])
      setCategoryType({
        key: matchedCategory.catType,
        label: matchedCategory.catType,
        value: matchedCategory.catType,
      })
    }
    else {
      setCategoryValues([{
        key: "MIND",
        label: "MIND",
        value: "MIND",
      },
      {
        key: "BODY",
        label: "BODY",
        value: "BODY",
      }])
      setCategoryType({
        key: "MIND",
        label: "MIND",
        value: "MIND",
      })
    }

  }

  const handleSubmit = async () => {
    try {
      await createDiscoverApi({
        title: formData?.title,
        description: formData?.description,
        objectType: objType?.value,
        order: order?.value,
        seeAllType: seeAllType?.value,
        category: (seeAllType.value == '2' && (objType?.value === "MEDIA" || objType?.value === "SERIES")) ? category.value : null,
        catType: (seeAllType.value == '2' && (objType?.value === "MEDIA" || objType?.value === "SERIES")) ? categoryType.value : null,
        metaIds:
          objType?.value === "MEDIA"
            ? formatMultiSelectData(allMedia)
            : objType?.value === "SERIES"
              ? formatMultiSelectData(allSeries)
              : formatMultiSelectData(allPlaylist),
      })
      // setShowUnPublishAlert(true)
      // setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);

    } catch (error) {
      console.log(error.response.data.error);
      setErrMsg(error.response.data.error)
      if (error.response.data.success === false) {
        setModal(true)
      } else (setModal(false))
    }

  };

  return (
    <>
      <CreateDiscoverNispandComp
        order={order}
        setOrder={setOrder}
        allMedia={allMedia}
        setAllMedia={setAllMedia}
        mediaData={mediaData}
        setMediaData={setMediaData}
        allSeries={allSeries}
        setAllSeries={setAllSeries}
        seriesData={seriesData}
        setSeriesData={setSeriesData}
        allPlaylist={allPlaylist}
        setAllPlaylist={setAllPlaylist}
        playlistData={playlistData}
        setPlaylistData={setPlaylistData}
        objType={objType}
        categoryValues={categoryValues}
        setObjType={setObjType}
        seeAllType={seeAllType}
        setSeeAllType={setSeeAllType}
        category={category}
        setCategory={setCategory}
        rawCatgeory={rawCatgeory}
        updateCategoryTypes={updateCategoryTypes}
        categoryType={categoryType}
        setCategoryType={setCategoryType}
        formData={formData}
        setFormData={setFormData}
        title={title}
        description={description}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        setShowUnPublishAlert={setShowUnPublishAlert}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        errMsg={errMsg}
        setErrMsg={setErrMsg}
        getCategory={getCategory}
      />
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmit();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            // setShowUnPublishAlert();
            // setShowUnPublishConfirm();
            history.push("/dashboard/discover-nispand/all");
          }}
        >
          Discover Nispand has been created
        </SweetAlert>
      ) : null}
    </>
  );
};

export default CreateDiscoverNispand;
