export const notificationHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Title',
    dataField: 'title',
  },
  {
    text: 'Description',
    dataField: 'description',
  },
  {
    text: 'Type',
    dataField: 'objectType',
  },
  {
    text: 'Audience Type',
    dataField: 'audienceType',
  },
];