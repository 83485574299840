
import React, { useEffect, useState } from "react";
import AllMasterClassComp from '../../../../../../Components/AllMasterComp'
import { PubMasterClassTableHeading,UnPubMasterClassTableHeading } from "../../Constants/constant";
import { getAllEnableMasterClass, getAllDisableMasterClass, enableMasterClass, disableMasterClass } from "../../Constants/api";

import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import AllUnpublishMasterClass from "../../../../../../Components/AllUnpubMasterClass";

const AllMasterClass = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [publishedCourse, setPublishedCourse] = useState("");
  const [unpublishedCourse, setUnpublishedCourse] = useState("");
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;
  const [unpublishPage, setUnpublishPage] = useState({
    unpublishPageCurrentPage: 1,
    unpublishPageTotalSize: 10,
  });

  const { unpublishPageCurrentPage, unpublishPageTotalSize } = unpublishPage;

  const [publishID, setPublishID] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);

  const getAllPublishedCourseData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllEnableMasterClass(params);
      setPublishedCourse(data?.data);
      console.log(data.data,'setPublishedCourse');
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllUnPublishedCourseData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllDisableMasterClass(params);
      setUnpublishedCourse(data?.data);
      setUnpublishPage({
        unpublishPageCurrentPage: params?.page,
        unpublishPageTotalSize: data?.count,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllPublishedCourseData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  const handleTablePageChangeUnpublish = (type, { page, sizePerPage }) => {
    getAllUnPublishedCourseData({ page, limit: sizePerPage });
    setUnpublishPage({
      ...unpublishPage,
      unpublishPageCurrentPage: page,
    });
  };

  const handlePublishCourseClick = async (id) => {
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishCourseClick = async (id) => {
    setPublishID(id);
    setShowPublishAlert(true);
  };

  const publishCourseAction = async () => {
    await disableMasterClass(publishID);
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
    const filteredSeries = publishedCourse.filter(
      ({ _id }) => _id !== publishID
    );
    setPublishedCourse(filteredSeries);
    setPublishID("");
  };

  const UnPublishCourseAction = async () => {
    await enableMasterClass(publishID);
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
    const filteredSeries = unpublishedCourse.filter(
      ({ _id }) => _id !== publishID
    );
    setUnpublishedCourse(filteredSeries);
    setPublishID("");
  };

  const handleEditSeries = (id) => {
    history.push("/dashboard/master-class/edit/" + id);
  };

  useEffect(() => {
    getAllPublishedCourseData({ page: 1, limit: 10 });
    getAllUnPublishedCourseData({ page: 1, limit: 10 });
  }, []);

  return (
    <>
      <AllMasterClassComp
        tableData={publishedCourse}
        tableHeading={PubMasterClassTableHeading}
        handleTablePageChange={handleTablePageChange}
        handlePublishCourseClick={handlePublishCourseClick}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        handleEditSeries={handleEditSeries}
      />

      <AllUnpublishMasterClass
       tableData={unpublishedCourse}
       tableHeading={UnPubMasterClassTableHeading}
       handleTablePageChange={handleTablePageChangeUnpublish}
       handlePublishCourseClick={handleUnPublishCourseClick}
       isLoading={isLoading}
       page={unpublishPageCurrentPage}
       sizePerPage={10}
       totalSize={unpublishPageTotalSize}
       handleEditSeries={handleEditSeries}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishCourseAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Courses has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,publish it!"
          reverseButtons={true}
          onConfirm={UnPublishCourseAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Courses has been published
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllMasterClass;
