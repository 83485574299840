import React from 'react'
import { useState,useEffect } from 'react'
import PublishedPlaylist from '../../../../../../Components/PublishedPlaylist'
import UnpublishedPlaylist from '../../../../../../Components/UnpublishedPlaylist'
import { unpublishPlaylistTableHeading,publishPlaylistTableHeading } from '../../Playlist.constants'
import { getAllUnpublishedPlaylist,getAllPublishedPlaylist,publishPlaylistActions,unpublishPlaylistActions } from '../../Playlist.Apis'
import SweetAlert from "react-bootstrap-sweetalert";

const AllPlaylists = () => {
  const [ unpublishPlaylist,setUnpublishPlaylist ] = useState([]);
  const [ publishPlaylist,setPublishPlaylist ] = useState([]);
  const [publishId, setPublishId] = useState("");
  const [unpublishId, setUnpublishId] = useState("");
  const [showPublishedAlert, setShowPublishedAlert] = useState(false);
  const [showPublishedConfirm, setShowPublishedConfirm] = useState(false);
  const [showUnpublishAlert, setShowUnpublishAlert] = useState(false);
  const [showUnpublishConfirm, setShowUnpublishConfirm] = useState(false);
  
  
  
  
  
  const getUnpublishedPlaylist = async() => {
    try {
     const{data} = await getAllUnpublishedPlaylist();
    setUnpublishPlaylist(data.data);
    } catch (error) {
      console.log('error')
    }
  }
  const handleUnpublishAction = async () => {
    try {
      await unpublishPlaylistActions(unpublishId);
      getUnpublishedPlaylist(),
      getPublishedPlaylist()
    } catch (error) {
      console.log(error);
    }
  };
  const UnpublishPlaylistAction = async (id) => {
    setUnpublishId(id);
    setShowUnpublishAlert(true);
  };
  const getPublishedPlaylist = async() => {
    try {
      const{data} = await getAllPublishedPlaylist();
    setPublishPlaylist(data.data);
    } catch (error) {
      console.log('error')
    }
  }
  const handlePublishAction = async () => {
    try {
      await publishPlaylistActions(publishId);
      getUnpublishedPlaylist(),
      getPublishedPlaylist()
    } catch (error) {
      console.log(error);
    }
  };
  const publishPlaylistAction = (id) => {
    setPublishId(id);
    setShowPublishedAlert(true);
  };
  useEffect(()=>{
    getUnpublishedPlaylist(),
    getPublishedPlaylist()
  },[])
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>All Playlists</h2>
    </div>
    <PublishedPlaylist
        heading="Published playlist"
        tData={publishPlaylist}
        tableHeading={publishPlaylistTableHeading}
        // sizePerPage={10}
        unpublishPlaylist={UnpublishPlaylistAction}
        buttonName="Unpublish"
        // handleTablePageChange={handlePublishedTablePageChange}
        // totalSize={totalSize}
        // page={createdCurrentPage}
    />
     {showUnpublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={() => {
            handleUnpublishAction();
            setShowUnpublishConfirm(true);
          }}
          onCancel={() => setShowUnpublishAlert(false)}
        ></SweetAlert>
      ) : null}
      {showUnpublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished"
          onConfirm={() => {
            setShowUnpublishAlert(false);
            setShowUnpublishConfirm(false);
          }}
        ></SweetAlert>
      ) : null}
    <UnpublishedPlaylist
      heading="Unpublished playlist"
        tableData={unpublishPlaylist}
        tableHeading={unpublishPlaylistTableHeading}
        // sizePerPage={10}
        publishPlaylist={publishPlaylistAction}
        buttonName="Publish"
        // handleTablePageChange={handlePublishedTablePageChange}
        // totalSize={totalSize}
        // page={createdCurrentPage}
    />
    {showPublishedAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={() => {
            handlePublishAction();
            setShowPublishedConfirm(true);
          }}
          onCancel={() => setShowPublishedAlert(false)}
        ></SweetAlert>
      ) : null}
      {showPublishedConfirm ? (
        <SweetAlert
          success
          title="published"
          onConfirm={() => {
            setShowPublishedAlert(false);
            setShowPublishedConfirm(false);
          }}
        ></SweetAlert>
      ) : null}
    </>
  )
}

export default AllPlaylists
