
import AllDiscoverNispand from '../Components/AllDiscoverNispand'
import CreateDiscoverNispand from '../Components/CreateDiscoverNispand'
import EditDiscoverNispand from '../Components/EditDiscoverNispand'

const DiscoverNispandRoutes = [
  {
    Component: AllDiscoverNispand,
    path: '/all',
    exact: true,
    id: 'dashboardDiscoverNispandAll',
    routePath: '/dashboard/discover-nispand/all',
    name: 'All Discover Nispand',
  },
  {
    Component: CreateDiscoverNispand,
    path: '/create',
    exact: true,
    id: 'dashboardDiscoverNispandCreate',
    routePath: '/dashboard/discover-nispand/create',
    name: 'Create Discover Nispand',
  },
  {
    Component: EditDiscoverNispand,
    path: '/edit/:editID',
    exact: true,
    id: 'dashboardDiscover NispandEdit',
  },
  
]

export default DiscoverNispandRoutes