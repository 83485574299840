import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import FaqCard from "../../../../../../Components/FaqCard";
import CreateTagModal from "../../../../../../Modal/CreateTag";
import EditTagModal from "../../../../../../Modal/EditTag";

import {
  tagTypes,
  tagsTableHeading,
  categorieTableHeading,
} from "../../Faq.constants";

import {
  deleteTag,
  getTags,
  getCategory,
  searchTag,
  searchFaqTagApi,
} from "../../Faq.Apis";
import TagInfo from "../../../../../../Modal/TagInfo";
import { searchFaqAPI } from "../../../../../../Constants/apiRoutes";
// import CreateFaqTag from "../../../../../../Modal/CreateFaqTag";
import CreateFaqTag from "../../../../../../Modal/CreateFaqTag";
import { CreateFaqTagData } from "../../../../../../Modal/CreateFaqTag/CreateFaqTag.Constants";
import FaqTagInfo from "../../../../../../Modal/FaqTagInfo";
import EditFagTag from "../../../../../../Modal/EditFaqTag";
// import { filterTags } from "./Tag.Helpers";

const FaqAllTag = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTagInfoOpen, setIsTagInfoOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [currentTag, setCurrentTag] = useState(tagTypes.TAG);
  const [editSettings, setEditSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });
  const [deleteSettings, setDeleteSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });

  const [dataTags, setDataTags] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);

  const [pageData, setPageData] = useState({
    tagsData: dataTags,
    categoryData: dataCategory,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  });
  const { currentPageTags, totalSizeTags } = paginationValueTags;

  const [paginationValueCat, setPaginationValueCat] = useState({
    currentPageCat: 1,
    totalSizeCat: 20,
  });
  const { currentPageCat, totalSizeCat } = paginationValueCat;

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentSearch, setCurrentSearch] = useState([]);
  const [tagInfo, setTagInfo] = useState({
    name: "",
    description: "",
    objectType: "",
  });

  useEffect(() => {
    setPageData({
      ...pageData,
      tagsData: dataTags,
    });
    // eslint-disable-next-line
  }, [dataTags]);

  useEffect(() => {
    setPageData({
      ...pageData,
      categoryData: dataCategory,
    });
    // eslint-disable-next-line
  }, [dataCategory]);

  const { tagsData, categoryData } = pageData;

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);
  const toggleEditModel = () => setIsEditModalOpen(!isEditModalOpen);
  const toggleTagInfo = () => setIsTagInfoOpen(!isTagInfoOpen);

  // Edit Tags
  const handleEditTagClick = (id) => {
    setCurrentTag(tagTypes.TAG);
    setEditSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setIsEditModalOpen(true);
  };
  const handleEditCategoryClick = (id) => {
    setCurrentTag(tagTypes.CATEGORY);
    setEditSettings({
      tagID: id,
      tagArray: "categoryData",
    });

    setIsEditModalOpen(true);
  };

  // Delete Tags
  const handleDeleteTagClick = (id) => {
    setDeleteSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setShowDeleteAlert(true);
  };
  const handleDeleteCategoryClick = (id) => {
    setDeleteSettings({
      tagID: id,
      tagArray: "categoryData",
    });
    setShowDeleteAlert(true);
  };

  const deleteTagAction = async () => {
    await deleteTag(deleteSettings.tagID);
    const filteredTags = pageData[deleteSettings.tagArray].filter(
      ({ _id }) => _id !== deleteSettings.tagID
    );
    setPageData({
      ...pageData,
      [deleteSettings.tagArray]: filteredTags,
    });
    deleteSettings.tagArray === "tagsData" && setDataTags(filteredTags);
    deleteSettings.tagArray === "categoryData" && setDataCategory(filteredTags);
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  };

  // Get initial  data
  const getTagsData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getTags(queryParams);
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      });
      setDataTags(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTagsData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    getTagsData({ page, limit: sizePerPage });
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageTags: page,
    });
  };

  const getCategoryData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getCategory(queryParams);
      setPaginationValueCat({
        currentPageCat: queryParams.page,
        totalSizeCat: data.count,
      });
      setDataCategory(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCategoryData({ page: 1, limit: 10 });
  }, []);

  const handleTablePageChangeCat = (type, { page, sizePerPage }) => {
    getCategoryData({ page, limit: sizePerPage });
    setPaginationValueCat({
      ...paginationValueCat,
      currentPageCat: page,
    });
  };

  const getSearchData = async (text) => {
    try {
      const { data } = await searchFaqTagApi(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) {
      setCurrentSearch([]);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleTagInfo = (item) => {
    setTagInfo({
      name: item.name,
      description: item.description,
      objectType: item.objectType,
    });
    toggleTagInfo();
  };

  // Populate created tag
  const populateCreatedTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.TAG:
        setPageData({
          ...pageData,
          tagsData: [tag, ...tagsData],
        });
        setDataTags([tag, ...tagsData]);
        break;
      case tagTypes.CATEGORY:
        setPageData({
          ...pageData,
          categoryData: [tag, ...categoryData],
        });
        setDataCategory([tag, ...categoryData]);
        break;

      default:
        break;
    }
  };

  // Populate created tag
  const populateUpdatededTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.TAG:
        setPageData({
          ...pageData,
          tagsData: [tag, ...tagsData.filter(({ _id }) => _id !== tag._id)],
        });
        setDataTags([tag, ...tagsData.filter(({ _id }) => _id !== tag._id)]);
        break;
      case tagTypes.CATEGORY:
        setPageData({
          ...pageData,
          categoryData: [
            tag,
            ...categoryData.filter(({ _id }) => _id !== tag._id),
          ],
        });
        setDataCategory([
          tag,
          ...categoryData.filter(({ _id }) => _id !== tag._id),
        ]);
        break;

      default:
        break;
    }
  };

  // Handle Models
  const openCreateTagModel = () => {
    setCurrentTag(tagTypes.TAG);
    toggleCreateModel();
  };
  const openCreateCategorieMogel = () => {
    setCurrentTag(tagTypes.CATEGORY);
    toggleCreateModel();
  };

  const select_tag = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Tags &amp; Categories </h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Tags and Categories"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_tag}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleTagInfo(item)}
                      >
                        <div>{item ? item.name : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <FaqCard
        heading="Tags"
        openModel={openCreateTagModel}
        modelBtnText="Add Tag"
        tableData={dataTags}
        tableHeading={tagsTableHeading}
        handleEditTag={handleEditTagClick}
        handleDeleteTag={handleDeleteTagClick}
        handleTablePageChange={handleTablePageChangeTags}
        page={currentPageTags}
        sizePerPage={10}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        allTags={true}
      />

      <FaqCard
        heading="Categories"
        openModel={openCreateCategorieMogel}
        modelBtnText="Add Category"
        tableData={dataCategory}
        tableHeading={categorieTableHeading}
        handleEditTag={handleEditCategoryClick}
        handleDeleteTag={handleDeleteCategoryClick}
        handleTablePageChange={handleTablePageChangeCat}
        page={currentPageCat}
        sizePerPage={10}
        totalSize={totalSizeCat}
        isLoading={isLoading}
        allTags={true}
      />

      <FaqTagInfo
        name={tagInfo.name}
        description={tagInfo.description}
        objectType={tagInfo.objectType}
        isTagInfoOpen={isTagInfoOpen}
        toggleTagInfoModal={toggleTagInfo}
      />

 
       
      <CreateFaqTag
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        currentTag={currentTag}
        populateTag={populateCreatedTag}
      />

      <EditFagTag
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModel}
        currentTag={currentTag}
        currentTagID={editSettings.tagID}
        currentArray={pageData[editSettings.tagArray]}
        populateTag={populateUpdatededTag}
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteTagAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
    </>
  );
};

export default FaqAllTag;
