import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUploader from "../FileUploader";
import { useState } from "react";
import Select  from "react-select";

const EditPlaylistComp = ({
  heading,
  title,
  description,
  setPlaylistData,
  playlistData,
  mediaType,
  getData,
  handleUpdate,
  playlistDescription,
  coverImage,
  setCoverImage,
  handleAdd,
  handleRemove,
  handleInput,
  selectedMedia,
  setSelectedMedia,
  showAddButton,
  setShowAddButton,
  showRemoveButton,
  setShowRemoveButton,
  selectedTags,
  setSelectedTags,
  tagsData
}) => {
  const MediaType = [
    { label: "AUDIO", value: "AUDIO" },
    { label: "VIDEO", value: "VIDEO" },
  ];

  
  let tagsObj =
  tagsData &&
  tagsData?.map((i) => {
    return {
      key: i._id,
      value: i._id,
      label: i.name,
    };
  });

  const handleDropdownChange = (event, index) => {
    console.log(event,'ttttttttttttttttttt')
    console.log(playlistData,playlistDescription,'playlistData')

    const updatedMediaData = [...playlistDescription.mediaData];
    setSelectedMedia(updatedMediaData);
    updatedMediaData[index] = event;
    setPlaylistData({
      ...playlistData,
      mediaData: updatedMediaData
    });
  };
  console.log(playlistDescription,'ashish')
  const addDropdown = () => {
    handleInput({
      target: {
        name: "mediaData",
        value: [...playlistDescription.mediaData, ""],
      },
    });
    
  };
  const handleAddMedia = (preId, name) => {
    handleAdd(preId, name);
  };
  const handleRemoveMedia = (preId, name) => {
    handleRemove(preId, name);
  };
  console.log(getData,'abcd')
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm className="form-horizontal">
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter title"
                        value={title}
                        onChange={(e) =>
                          setPlaylistData({
                            ...playlistData,
                            title: e.target.value,
                          })
                        }
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="description">Description</Label>
                      <AvField
                        name="description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) =>
                          setPlaylistData({
                            ...playlistData,
                            description: e.target.value,
                          })
                        }
                        type="text"
                        errorMessage="Description is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="description"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="lessons">Tags</Label>
                      <Select
                        value={selectedTags}
                        isMulti={true}
                        onChange={(selected) => setSelectedTags(selected)}
                        options={tagsObj}
                        classNamePrefix="select2-selection"
                        placeholder="Select Tags"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor="notificationSelect">MediaType</Label>
                        <Input
                          name="mediaType"
                          placeholder="Select Type"
                          type="select"
                          value={mediaType}
                          onChange={(e) =>
                            setPlaylistData({
                              ...playlistData,
                              mediaType: e.target.value,
                            })
                          }
                          className="form-control"
                          id="languageSelect"
                        >
                          <option value="">Select Media Type</option>
                          {MediaType?.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                  
                  {playlistDescription.mediaData.map((media, index) => {
                    const item = getData.find((item) => item._id === media);
                    const mediaId = item ? item._id : "";
                    const mediaTitle = item ? item.title : "";
                    
                    return (
                      <Col xs={2} key={index}>
                        <FormGroup>
                          <div dir="ltr">
                            <Label htmlFor={`notificationSelect${index}`}>
                              MediaData
                            </Label>
                            <Select
                              name="mediaData"
                              placeholder="Select Type"
                              value={{ value: mediaId, label: mediaTitle }}
                              onChange={(event) =>
                                handleDropdownChange(
                                  event.value,
                                  index
                                )
                              }
                              options={[
                                { value: "", label: "Select MediaData" },
                                ...(getData || []).map((item) => ({
                                  value: item._id,
                                  label: item.title,
                                  selected: media === item._id,
                                })).filter(ele=> !playlistData.mediaData.includes(ele.value)),
                              ]}
                            />
                            <Button
                              color="primary"
                              className="w-8 mt-1 mr-2"
                              type="button"
                              onClick={() => handleAddMedia(mediaId, title)}
                            >
                              Add
                            </Button>
                            <Button
                              color="primary"
                              className="w-8 mt-1"
                              type="button"
                              onClick={() => handleRemoveMedia(mediaId, title)}
                            >
                              Remove
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>
                <Button
                  color="primary"
                  className="w-20 mt-5"
                  type="button"
                  onClick={addDropdown}
                >
                  Add MediaData
                </Button>
                <Row>
                  <Col>
                    <FileUploader
                      file={coverImage}
                      setFile={setCoverImage}
                      dragText="Drop Image here"
                      heading="Select Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                    />
                  </Col>
                </Row>

                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={handleUpdate}
                >
                  Update Playlist
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditPlaylistComp;
