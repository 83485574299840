import axios from "axios";
// const baseDomain = 'https://back-end-dev.nispand.com/v2'
// const baseDomain = 'https://5a57-180-151-73-106.ngrok-free.app/v2'
import { baseDomain, stage } from "../../../../../Constants/apiRoutes";

export const createMasterClassApi = (payload) => {
  return axios.post(`${baseDomain}${stage}/masterclass`, payload);
};

export const getAllSupportAdvisorApi = () => {
  return axios.get(`${baseDomain}${stage}/supportAdvisor`);
};

export const getAllEnableMasterClass = ({ page, limit }) => {
  return axios.get(
    `${baseDomain}${stage}/masterclass/getallenablemasterclass?page=${page}&limit=${limit}`
  );
};
export const getAllMasterClass = (payload) => {
  return axios.post(
    `${baseDomain}${stage}/masterclass/list`, payload
  );
};

export const getAllDisableMasterClass = ({ page, limit }) => {
  return axios.get(
    `${baseDomain}${stage}/masterclass/getalldisablemasterclass?page=${page}&limit=${limit}`
  );
};

export const enableMasterClass = (id) => {
  return axios.put(`${baseDomain}${stage}/masterclass/enablemasterclass/${id}`);
};

export const disableMasterClass = (id) => {
  return axios.put(
    `${baseDomain}${stage}/masterclass/disablemasterclass/${id}`
  );
};

export const getMasterClassByIDApi = (id) => {
  return axios.get(
    `${baseDomain}${stage}/masterclass/getmasterclassById/${id}`
  );
};

export const updateMasterClassByIDApi = (id, payload) => {
  return axios.put(
    `${baseDomain}${stage}/masterclass/updatemasterclass/${id}`,
    payload
  );
};

export const getAllMasterClassUsersById = ({ id }) => {
  return axios.get(
    `${baseDomain}${stage}/masterclass/allMasterAppointment/${id}`
  );
};

export const csvDataMasterClass = (params) => {
  return axios.get(
    `${baseDomain}${stage}/masterclassRegister/allregisteredmasterclass?${params}`
  );
}
export const allCsvDataMasterClass = (payload) => {
  return axios.post(
    `${baseDomain}${stage}/masterclassRegister/allregisteredmasterclass/list`, payload
  );
}