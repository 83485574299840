export const PubMasterClassTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Class',
    dataField: 'masterclassId.title',
  },
  {
    text: 'User Name',
    dataField: 'userId.firstName',
  },
  {
    text: 'Gender',
    dataField: 'userId.gender',
  },

  {
    text: 'Email Id',
    dataField: 'userId.email',
  },
  {
    text: 'Phone Number',
    dataField: 'userId.phoneNumber',
  },
  {
    text: 'Booked Date',
    dataField: 'masterclassId.targetDate',
  },
  {
    text: 'Master Class Date',
    dataField: 'createdAt',
  },

]

export const UnPubMasterClassTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Title',
    dataField: 'title',
  },


]

export const UserMasterClassTableHeading = [
  {
    text: 'FirstName',
    dataField: 'name',
  },
  {
    text: 'LastName',
    dataField: 'lastName',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Contact',
    dataField: 'contact',
  },
  {
    text: 'StartDate',
    dataField: 'targetDate',
  },
  // {
  //   text: 'Time',
  //   dataField: 'targetDate', 
  // },
  // {
  //   text:'StartDate',
  //   data:'date',
  // },  
  {
    text: 'Time',
    dataField: 'time',
  },

  // {
  //   text:'Tagline',
  //   dataField:'tagline'

  // },
  // {
  //   text: 'Plan',
  //   dataField: 'plan', 
  // },
  {
    text: 'Points',
    dataField: 'points'

  }
]