import React, { useState, useEffect } from 'react'
import SeriesCard from '../../../../../../Components/SeriesCard'
import { getPublishedSeriesAPI, getUnPublishedSeriesAPI, UpdateUnPublishSeriesAPI, UpdatePublishSeriesAPI } from '../../Api'
import { PublishedSeriesTableHeading, UnPublishedSeriesTableHeading } from '../../constant'
import UnPublishedSeries from '../../../../../../Components/UnPublishedSeries'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'

const AllSeries = () => {

  const [PublishedSeries, setPublishedSeries] = useState([])
  const [UnpublishedSeries, setUnPublishedSeries] = useState([])
  const [publishID, setPublishID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  const [showPublishAlert, setShowPublishAlert] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = paginationValue;

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 10,
  });
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue;

  const history = useHistory();

  const getPublishedSeriesData = async (queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getPublishedSeriesAPI(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      console.log(data.data)
      setPublishedSeries(data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const getUnPublishedSeriesData = async (queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUnPublishedSeriesAPI(queryParams)
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setUnPublishedSeries(data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  console.log(unpublishedPaginationValue,'unpublishedPaginationValue');

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getPublishedSeriesData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getUnPublishedSeriesData({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };


  const handleEditSeries = (id) => {
    history.push("/dashboard/series/edit/" + id);
  };

  const handlePublishSeriesClick = async (id) => {
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishSeriesClick = async (id) => {
    setPublishID(id);
    setShowPublishAlert(true);
  };

  const handleViewSeries = (refId) => {
    history.push("/dashboard/Series/view/" + refId);
  };


  const publishSeriesAction = async () => {
    await UpdateUnPublishSeriesAPI(publishID);
    setShowUnPublishAlert(false)
    setShowUnPublishConfirm(true)
    const filteredSeries = PublishedSeries.filter(({ _id }) => _id !== publishID);
    setPublishedSeries(filteredSeries);
    setPublishID("");
  };

  const UnPublishSeriesAction = async () => {
    await UpdatePublishSeriesAPI(publishID);
    setShowPublishAlert(false)
    setShowPublishConfirm(true)
    const filteredSeries = UnpublishedSeries.filter(({ _id }) => _id !== publishID);
    setUnPublishedSeries(filteredSeries);
    setPublishID("");
  };

  useEffect(() => {
    getPublishedSeriesData({ page: 1, limit: 10 })
    getUnPublishedSeriesData({ page: 1, limit: 10 });
  }, [])

  return (
    <div>
      <SeriesCard
        tableData={PublishedSeries}
        handleEditSeries={handleEditSeries}
        tableHeading={PublishedSeriesTableHeading}
        setPublishedSeries={setPublishedSeries}
        handlePublishSeries={handlePublishSeriesClick}
        handleViewSeries={handleViewSeries}
        isLoading={isLoading}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />

      <UnPublishedSeries
        tableData={UnpublishedSeries}
        tableHeading={UnPublishedSeriesTableHeading}
        setUnPublishedSeries={setUnPublishedSeries}
        handleEditSeries={handleEditSeries}
        handleViewSeries={handleViewSeries}
        handleUnPublishSeies={handleUnPublishSeriesClick}
        handleTablePageChange={handleUnpublishedTablePageChange}
        isLoading={isLoading}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        unpublishedTotalSize={unpublishedTotalSize}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishSeriesAction}
          onCancel={() => setShowUnPublishAlert(false)
          }
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            window.location.reload();
          }}
        >
          Series has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,publish it!"
          reverseButtons={true}
          onConfirm={UnPublishSeriesAction}
          onCancel={() => setShowPublishAlert(false)
          }
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            window.location.reload();
          }}
        >
          Series has been published
        </SweetAlert>
      ) : null}
    </div>
  )
}

export default AllSeries
