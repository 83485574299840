import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import {
  USER_LOGGEDIN,
  SET_LOADING,
  END_LOADING,
  LOGOUT,
  LOGIN_ERROR,
} from "../types";

import baseDomain, { loginAPI, getMe } from "../../Constants/apiRoutes";

import setDefaultToken from "../../utils/setDefaultToken";
import { setLocal, getLocal, clearLocal } from "../../utils/localStorage";

const AuthState = ({ children }) => {
  const initialState = {
    user: {},
    errorMessage: "",
    isLoggedIn: false,
    loading: true,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const loginUser = async (loginCredentials, history) => {
    try {
      const {
        data: { token },
      } = await axios.post(`${baseDomain}${loginAPI}`, loginCredentials);

      setLocal("tkn", token);

      history.push("/dashboard");
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  const retainLogin = async () => {
    try {
      const token = getLocal("tkn");
      setDefaultToken("authorization", `Bearer ${token}`);
      const { data } = await axios.get(`${baseDomain}${getMe}`);
      dispatch({
        type: USER_LOGGEDIN,
        payload: data.data,
      });
    } catch (error) {
      setDefaultToken("authorization");
      clearLocal();
      dispatch({
        type: END_LOADING,
      });
    }
  };

  const logout = async () => {
    setDefaultToken("authorization");
    clearLocal();
    dispatch({
      type: LOGOUT,
    });
  };

  //   // Clear Users
  //   const clearUsers = () => dispatch({ type: CLEAR_USERS });

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  // AuthContext.Provider props will be avalable to entire app
  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        errorMessage: state.errorMessage,
        isLoading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginUser,
        retainLogin,
        logout,
        setLoading,
      }}
    >
      {/* props.children because we will rap entire application in this provider */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
