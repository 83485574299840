import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../Table";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";

const AllUserByHomeSessionsComp = ({
  tableData = [],
  pageHeading = "All Home Sessions Class Users",
  tableHeading = [],
  handleTablePageChange,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  sessionsId,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick, handleAllExportToCSV
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  const handleUserEditAppointment = (userId) => {
    history.push(
      "/dashboard/n-live/booking-sessions/edit/" +
      userId +
      "?sessionId=" +
      sessionsId
    );
  };
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row.userId}>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleUserEditAppointment(row.userId, row.sessionsId)}
        />
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Action",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewContentFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      var result = tableData?.map(function (el, i) {
        var o = {
          ...el,
          name: el?.userName,
          email: el?.userEmail,
          contact: el?.userContact,
          date: el?.appDate?.slice(0, 10),
          endDate: el?.endDate?.slice(0, 10),
          time: el?.appTime?.slice(11, 16),
          plan: el?.planDesc
        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);
  console.log(tableData, 'tabledata')
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div style={{ display: 'flex', gap: '14px' }}>
                    <DatePicker
                      type="number"
                      className="date_picker"
                      placeholderText="Select Start Date"
                      dateFormat="dd/MM/yyyy"
                      onChange={handleStartDateChange}
                      selected={startDate}
                      clearIcon={null}
                    />

                    <DatePicker
                      type="number"
                      className="date_picker"
                      placeholderText="Select End Date"
                      dateFormat="dd/MM/yyyy"
                      onChange={handleEndDateChange}
                      selected={endDate}
                      clearIcon={null}
                    />
                  </div>
                </Col>
                <Col xs="auto">
                  <Button
                    color="primary"
                    className="waves-effect waves-light"
                    style={{ width: "100%", cursor: 'pointer' }}
                    disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}
                    onClick={() => handleConfirmClick()}
                  >
                    Apply Filters
                  </Button>
                </Col>
                {rowData?.length > 0 &&
                  <Col xs="auto">
                    <CSVLink
                      data={handleAllExportToCSV()}
                      filename={'all_home_session_users.csv'}
                      className="export-csv-btn" >
                      <Button
                        color="success"
                        className="waves-effect waves-light"
                        style={{ width: "100%", cursor: 'pointer' }}
                      >
                        Export to Csv
                      </Button>
                    </CSVLink>
                  </Col>}
              </Row>
              <br />
              {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red', position: 'relative', top: '-10px' }}>*Select a valid date range</span>}
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllUserByHomeSessionsComp;
