import axios from "axios";
import { baseDomain, stage } from "../../../../../../Constants/apiRoutes";

export const getAllUserByNurtiIDApi = ({ id, page, limit }) => {
  return axios.get(`${baseDomain}${stage}/nutriClinic/allNutriAppointment/${id}?page=${page}&limit=${limit}`)
}

export const addMeetinLinkNutriClassApi = (id, payload) => {
  return axios.put(`${baseDomain}${stage}/nutriClinic/addLink/${id}`, payload)
}

export const getAllNutriClassApi = ({ page, limit }) => {
  return axios.get(`${baseDomain}${stage}/nutriClinic?isPublished=true&page=${page}&limit=${limit}`)
}

export const getAllHomeSessionsApi = ({ page, limit, startDate, endDate }) => {
  return axios.get(`${baseDomain}${stage}/yogaClassSession/getAllYogaClass?page=${page}&limit=${limit}`)//&startDate=${startDate}&endDate=${endDate}
}

export const getAllUserByHomeSessionsIdApi = ({ id, page, limit, startDate, endDate }) => {
  return axios.get(`${baseDomain}${stage}/yogaClassForm/allById/${id}?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`)
}
export const getAllUserByHomeSessionsIdApiCSV = (payload) => {
  return axios.post(`${baseDomain}${stage}/yogaClassForm/list`, payload)
}
export const addLinkHomeSessionsApi = (id, payload) => {
  return axios.put(`${baseDomain}${stage}/yogaClassForm/addLink/${id}`, payload)
}

export const getCSVData = () => {
  return axios.get(`${baseDomain}${stage}/nutriClinic/getAll/appointments`)
}

export const getCSVDataHomeSessions = () => {
  return axios.get(`${baseDomain}${stage}/yogaClassForm/getAllYogaSessionUser`)
}

export const getAllHomeSessionsApiCSV = ({ startDate, endDate }) => {
  return axios.get(`${baseDomain}${stage}/yogaClassSession/getAllYogaClass?startDate=${startDate}&endDate=${endDate}`)
}
