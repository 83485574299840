import React from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
// import { BlogRoutes } from "./Constants/routes";
import BlogRoutes from '../Blogs/Constants/routes'

const Blogs = () => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {BlogRoutes.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/business" />
      </Switch>
    </>
  );
};

export default Blogs;
