import axios from "axios";
const domain = "https://back-end-preprod.nispand.com/v2";

// export const allTimeSlotsByAdvisorIdApi = (id) =>{
//   return axios.get(`${domain}/timeSlots/${id}`)
// }

export const createTimeSlotsApi = (payload) => {
  return axios.post(`${domain}/timeSlots`, payload);
};

export const getAllTimeSlotsByAdvsiorId = (id) => {
  return axios.get(`${domain}/timeSlots/${id}`);
};

export const getAllTimeSlotsByDateNIdApi = (payload) => {
  return axios.post(`${domain}/timeSlots/user`, payload);
};

export const updateTimeSlotsApi = (id, payload) => {
  return axios.put(`${domain}/timeSlots/add/${id}`, payload);
};

export const removeTimeSlotByIdApi = (id, payload) => {
  console.log(id, payload);
  return axios.delete(`${domain}/timeSlots/delete/${id}`, {
    data: payload,
  });
};
