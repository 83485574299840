import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Table from '../Table';
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { CSVLink } from 'react-csv';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { csvDataMasterClass, getAllEnableMasterClass, getAllMasterClass } from '../../Views/Dashboard/Components/MasterClass/Constants/api';

const AllUserByMasterId = ({
  tableData = [],
  pageHeading = "All Master Class Users",
  tableHeading = [],
  handleTablePageChange,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  // masterId,
  handleAllExportToCSV,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick,
  selectedClass,
  handleClassChange

}) => {

  const [rowData, setRowData] = useState([]);
  const [classes, setClasses] = useState([]);
  const history = useHistory()

  const handleUserEditAppointment = (_id) => {
    history.push("/dashboard/master-class/master-user/" + _id);
  };

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>

        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleUserEditAppointment(row._id)}
        />
      </div>
    );
  };
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const getAllPublishedCourseData = async (params) => {
    try {
      const { data } = await getAllMasterClass({ startDate: startDate, endDate: endDate })//getAllEnableMasterClass(params);
      let classList = data.data;
      setClasses(classList.map(({ _id, title }) => ({ value: _id, label: title })))

      // if (data?.allRegisteredMasterClass?.length > 0) {
      //   setMasterId(data.allRegisteredMasterClass[0]._id);
      // }

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPublishedCourseData({ page: 1, limit: 10 });
    // tableHeading.push({
    //   text: "Action",
    //   dataField: "view",
    //   isDummyField: true,
    //   headerStyle: { textAlign: "center", width: "10%" },
    //   formatter: viewContentFormatter,
    // });
    return () => {
      // tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      var result = tableData?.map(function (el, i) {
        var o = {
          ...el,
          // name: el?.userName,
          'createdAt': el?.createdAt?.slice(0, 10),
          'masterclassId.targetDate': el?.masterclassId?.targetDate?.slice(0, 10),
          // time: el?.appTime?.slice(11, 16),
        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    else {
      setRowData([])
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>

                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div style={{ display: 'flex', gap: '14px' }}>
                    <DatePicker
                      // type="number"
                      className="date_picker"
                      placeholderText="Select Start Date"
                      dateFormat="dd/MM/yyyy"
                      onChange={handleStartDateChange}
                      selected={startDate}
                      clearIcon={null}
                    />

                    <DatePicker
                      type="number"
                      className="date_picker"
                      placeholderText="Select End Date"
                      dateFormat="dd/MM/yyyy"
                      onChange={handleEndDateChange}
                      selected={endDate}
                      clearIcon={null}
                    />
                  </div>
                </Col>
                <Col xs={2}>
                  <Select
                    isMulti={false}
                    // value={selectedClass}
                    options={classes}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Class"
                    onChange={handleClassChange}
                  />
                </Col>
                <Col xs="auto">

                  <Button
                    color="primary"
                    className="waves-effect waves-light"
                    style={{ width: "100%", cursor: 'pointer' }}
                    disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}
                    onClick={() => handleConfirmClick()}
                  >
                    Apply Filters
                  </Button>
                </Col>
                {rowData?.length > 0 &&
                  <Col xs="auto">
                    <CSVLink
                      data={handleAllExportToCSV()}
                      filename={'all_master_class_users.csv'}
                      className="export-csv-btn" >
                      <Button
                        color="success"
                        className="waves-effect waves-light"
                        style={{ width: "100%", cursor: 'pointer' }}

                      // onClick={() => handleUnPublishSleepSessionClick(row._id)}
                      >
                        Export to Csv
                      </Button>
                    </CSVLink>
                  </Col>}
              </Row>
              <br />
              {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red', position: 'relative', top: '-10px' }}>*Select a valid date range</span>}
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AllUserByMasterId