import React, { Fragment } from "react";

// import {
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   InputGroup,
//   InputGroupAddon,
//   Input,
//   Button,
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
// } from "reactstrap";

import { Link } from "react-router-dom";

import ProfileMenu from "../../../../Components/ProfileMenu";

const Header = () => {
  return (
    <Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  Nispand
                </span>
                <span className="logo-lg">
                Nispand
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">
                Nispand
                </span>
                <span className="logo-lg">
                Nispand
                </span>
              </Link>
            </div>

            {/* <Button size="sm" color="none" type="button" onClick={this.toggleMenu} className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                    <i className="ri-menu-2-line align-middle"></i>
                </Button>

               
                <Form className="app-search d-none d-lg-block">
                    <div className="position-relative">
                        <Input type="text" className="form-control" placeholder={this.props.t('Search')}/>
                        <span className="ri-search-line"></span>
                    </div>
                </Form>

                <MegaMenu/> */}
          </div>

          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ml-2">
                    <button type="button" onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }}  className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                        <i className="ri-search-line"></i>
                    </button>
                    <div className={ this.state.isSearch === true ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                        aria-labelledby="page-header-search-dropdown">
            
                        <Form className="p-3">
                            <FormGroup className="m-0">
                                <InputGroup>
                                    <Input type="text" className="form-control" placeholder={this.props.t('Search')}/>
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary" type="submit"><i className="ri-search-line"></i></Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </div>
                </div>

                <LanguageDropdown/> */}

            {/* <Dropdown isOpen={this.state.isSocialPf} toggle={() => this.setState({isSocialPf : !this.state.isSocialPf})} className="d-none d-lg-inline-block ml-1">
                    <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect">
                        <i className="ri-apps-2-line"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg" right>
                        <div className="px-lg-2">
                            <Row className="no-gutters">
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={github} alt="Github"/>
                                        <span>{this.props.t('GitHub')}</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={bitbucket} alt="bitbucket"/>
                                        <span>{this.props.t('Bitbucket')}</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={dribbble} alt="dribbble"/>
                                        <span>{this.props.t('Dribbble')}</span>
                                    </Link>
                                </Col>
                            </Row>

                            <Row className="no-gutters">
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={dropbox} alt="dropbox"/>
                                        <span>{this.props.t('Dropbox')}</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={mail_chimp} alt="mail_chimp"/>
                                        <span>{this.props.t('Mail Chimp')}</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={slack} alt="slack"/>
                                        <span>{this.props.t('Slack')}</span>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </DropdownMenu>
                </Dropdown> */}

            {/* <div className="dropdown d-none d-lg-inline-block ml-1">
                    <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.toggleFullscreen}>
                        <i className="ri-fullscreen-line"></i>
                    </Button>
                </div> */}

            {/* <NotificationDropdown/> */}

            <ProfileMenu />

            {/* <div className="dropdown d-inline-block">
                    <Button color="none" onClick={this.toggleRightbar} type="button" className="header-item noti-icon right-bar-toggle waves-effect">
                        <i className="ri-settings-2-line"></i>
                    </Button>
                </div> */}
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
