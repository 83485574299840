import React, { useEffect } from "react";
import CoursesComp from "../../../../../../Components/CoursesComp";
import { useState } from "react";
import { EditorState } from "draft-js";
import { postCousreApi, getAllLessonApi } from "../../Constants/api";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import { getAllSupportAdvisorApi, getAllFaq,getAllTags } from "../../Constants/api";
import UploadBlogsStatus from "../../../../../../Components/UploadBlogsStatus";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateCourses = () => {
  const [errorRes, setErrorRes] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [certi, setCerti] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const [adName, setAdName] = useState([]);
  const [content, setContent] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [learnTitle, setLearnTitle] = useState("");
  const [learnImg, setLearnImg] = useState("");
  const [learnTitleTwo, setLearnTitleTwo] = useState("");
  const [learnImgTwo, setLearnImgTwo] = useState("");
  const [learnTitleThree, setLearnTitleThree] = useState("");
  const [learnImgThree, setLearnImgThree] = useState("");
  const [outComeGet, setOutComeGet] = useState("");
  const [certImg, setCertImg] = useState("");
  const [outCertImg, setOutCertImg] = useState("");
  const [certTitle, setCertTitle] = useState("");
  const [certDesc, setCertDesc] = useState("");
  const [gettingTitle, setGettingTitle] = useState("");
  const [gettingIcon, setGettingIcon] = useState("");
  const [gettingTitleTwo, setGettingTitleTwo] = useState("");
  const [gettingIconTwo, setGettingIconTwo] = useState("");
  const [gettingTitleThree, setGettingTitleThree] = useState("");
  const [gettingIconThree, setGettingIconThree] = useState("");
  const [headerImg, setHeaderImg] = useState("");
  const [lessonsData, setLessonsData] = useState([]);
  const [lessonName, setLessonName] = useState([]);
  const [intensityName, setIntensityName] = useState([]);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [faq, setFaq] = useState("");
  const [faqData, setFaqData] = useState("");
  const [tab, setTab] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [outComeCompletion, setOutComeCompletion] = useState(
    EditorState.createEmpty()
  );
  const [selectedTags,setSelectedTags] = useState('')
  const [tagsData,setTagsData] = useState([])
  const [formData, setFormData] = useState({
    title: "",
    imgAsset: "",
    supportAdvisor: "",
    learnings: [],
    calories: "",
    intensity: "",
    lessons: [],
    Faqs: [],
    outCome: [],
    gettings: [],
  });

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });

  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { title, imgAsset, supportAdvisor, calories, intensity } = formData;

  const handleChangeCourse = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getAllLessonData = async () => {
    const { data } = await getAllLessonApi();
    console.log(data?.data);
    setLessonsData(data?.data);
  };

  const getAllTagsData = async () => {
    const {data} = await getAllTags();
    console.log(data?.data,'hurraaahhhh');
    setTagsData(data?.data)
  }

  const getAllAdvisorData = async () => {
    const { data } = await getAllSupportAdvisorApi();
    console.log(data.data);
    setAdvisor(data?.data);
  };

  const getAllFaqData = async () => {
    const { data } = await getAllFaq();
    setFaqData(data?.faqs);
    console.log(data?.faqs, "asdfghjkl");
  };

  useEffect(() => {
    getAllAdvisorData();
    getAllLessonData();
    getAllFaqData();
    getAllTagsData();
  }, []);

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const intensityData = [
    {
      key: "LOW",
      label: "LOW",
      value: "LOW",
    },
    {
      key: "MEDIUM",
      label: "MEDIUM",
      value: "MEDIUM",
    },
    {
      key: "HIGH",
      label: "HIGH",
      value: "HIGH",
    },
  ];

  const handleCreateCourse = async () => {
    console.log("enter");
    try {
      setShowUnPublishAlert(true);
      if (!headerImg)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const headerImgUrl = await uploadFile(
        headerImg,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        20
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!learnImg)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const learnImgUrl = await uploadFile(
        learnImg,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        30
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!learnImgTwo)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const learnImgTwoUrl = await uploadFile(
        learnImgTwo,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        40
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!learnImgThree)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const learnImgThreeUrl = await uploadFile(
        learnImgThree,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        90
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!gettingIcon)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const gettingIconUrl = await uploadFile(
        gettingIcon,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        60
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!gettingIconTwo)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const gettingIconTwoUrl = await uploadFile(
        gettingIconTwo,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        70
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!gettingIconThree)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const gettingIconThreeUrl = await uploadFile(
        gettingIconThree,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        80
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const outCertImgUrl = await uploadFile(
        outCertImg,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        80
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      var certificateData = await Promise.all(
        certi.map(async (item) => {
          const certImgUrl = await uploadFile(
            item.imgAsset,
            "COURSES-IMAGE",
            setThumbnailUploadPercentage,
            90
          );
          setCreating({
            isCreating: true,
            creatingStage: creatingStates.UPLOADING_MUSIC,
          });

          setCreating({
            isCreating: true,
            creatingStage: creatingStates.UPLOADING_IMAGE,
          });

          return {
            title: item.title,
            imgAsset: certImgUrl,
            description: item.description,
          };
        })
      );

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      await postCousreApi({
        title: formData.title,
        description: content,
        imgAsset: headerImgUrl,
        supportAdvisor: formatMultiSelectData(adName),
        pageFilter: formatMultiSelectData(tab),
        learnings: [
          {
            img: learnImgUrl,
            title: learnTitle,
          },
          {
            img: learnImgTwoUrl,
            title: learnTitleTwo,
          },
          {
            img: learnImgThreeUrl,
            title: learnTitleThree,
          },
        ],
        calories: calories,
        intensity: formatMultiSelectData(intensityName),
        lessons: formatMultiSelectData(lessonName),
        Faqs: formatMultiSelectData(faq),
        outCome: {
          outComeGet: outComeGet,
          outComeCompletion: certificate,
          outComeCertificate: certificateData,
          courseCertificateAssetUrl: outCertImgUrl,
        },
        tags: formatMultiSelectData(selectedTags),
        gettings: [
          {
            icon: gettingIconUrl,
            title: gettingTitle,
          },
          {
            icon: gettingIconTwoUrl,
            title: gettingTitleTwo,
          },
          {
            icon: gettingIconThreeUrl,
            title: gettingTitleThree,
          },
        ],
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error, error);
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setContentUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Image please try again later",
        errorType: "danger",
      });
      setErrorRes(error.response.data.error);
      setShowErr(true);
    }
  };

  const reset = () => {
    setLearnImg(null);
    setLearnImgTwo(null);
    setLearnImgThree(null);
    setGettingIcon(null);
    setGettingIconTwo(null);
    setGettingIconThree(null);
    setHeaderImg(null);

    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      imgAsset: "",
      supportAdvisor: "",
      learnings: [],
      calories: "",
      intensity: "",
      lessons: [],
      Faqs: [],
      outCome: [],
      gettings: [],
    });
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  return (
    <>
      {isCreating ? (
        <UploadBlogsStatus
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={reset}
          fileName="Courses"
        />
      ) : (
        <CoursesComp
          setTab={setTab}
          tab={tab}
          setFaqData={setFaqData}
          faqData={faqData}
          faq={faq}
          setFaq={setFaq}
          setIntensityName={setIntensityName}
          intensityName={intensityName}
          intensityData={intensityData}
          setLessonName={setLessonName}
          lessonName={lessonName}
          setLessonsData={setLessonsData}
          lessonsData={lessonsData}
          setCerti={setCerti}
          certi={certi}
          setAdName={setAdName}
          adName={adName}
          setAdvisor={setAdvisor}
          advisor={advisor}
          setCertificate={setCertificate}
          outComeCompletion={outComeCompletion}
          setOutComeCompletion={setOutComeCompletion}
          setHeaderImg={setHeaderImg}
          headerImg={headerImg}
          setGettingIconThree={setGettingIconThree}
          setGettingTitleThree={setGettingTitleThree}
          setLearnImgThree={setLearnImgThree}
          setLearnTitleThree={setLearnTitleThree}
          setLearnImgTwo={setLearnImgTwo}
          setGettingIconTwo={setGettingIconTwo}
          setGettingTitleTwo={setGettingTitleTwo}
          setLearnTitleTwo={setLearnTitleTwo}
          gettingIconThree={gettingIconThree}
          gettingTitleThree={gettingTitleThree}
          gettingIconTwo={gettingIconTwo}
          gettingTitleTwo={gettingTitleTwo}
          learnImgThree={learnImgThree}
          learnTitleThree={learnTitleThree}
          learnImgTwo={learnImgTwo}
          learnTitleTwo={learnTitleTwo}
          setGettingIcon={setGettingIcon}
          gettingIcon={gettingIcon}
          setGettingTitle={setGettingTitle}
          gettingTitle={gettingTitle}
          setCertDesc={setCertDesc}
          certDesc={certDesc}
          certImg={certImg}
          setCertImg={setCertImg}
          certTitle={certTitle}
          setCertTitle={setCertTitle}
          setOutComeGet={setOutComeGet}
          outComeGet={outComeGet}
          setLearnImg={setLearnImg}
          learnImg={learnImg}
          setContent={setContent}
          formData={formData}
          setFormData={setFormData}
          title={title}
          description={description}
          setDescription={setDescription}
          imgAsset={imgAsset}
          supportAdvisor={supportAdvisor}
          calories={calories}
          intensity={intensity}
          learnTitle={learnTitle}
          setLearnTitle={setLearnTitle}
          handleChangeCourse={handleChangeCourse}
          handleCreateCourse={handleCreateCourse}
          outCertImg={outCertImg}
          setOutCertImg={setOutCertImg}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          tagsData={tagsData}
        />
      )}
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={() => {
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
          onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            window.location.reload();
          }}
        >
          Courses has been created
        </SweetAlert>
      ) : null}
    </>
  );
};

export default CreateCourses;
