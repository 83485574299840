import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditUserMasterComp from "../../../../../../../Components/EditUserMasterComp";
import { getAllMasterClassUsersById } from "../../../Constants/api";
// import { MasterUserTableHeading } from "../../../Constants/table";
import { UserMasterClassTableHeading } from "../../../Constants/constant";
// import { useParams } from "react-router-dom";


const EditUserMaster = () => {
  const { sessionsId } = useParams();
  const [masterUserData, setMasterUserData] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;

  const fetchMasterUserData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllMasterClassUsersById(params);
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });

      const tempArr = data?.data?.map((item) => {
        return {
          FirstName: item?.userId?.firstName,
          LastName: item?.userId?.lastName,
          userEmail: item?.userId?.email,
          userContact: item?.userId?.phoneNumber,
          userId: item?._id,
          startDate:item?.masterclassId?.targetDate,
          time:item?.masterclassId?.targetDate,
          // tagline:item?.masterclassId?.tagline,
          points:item?.masterclassId?.points,
          userRole: item?.userRole,
        };
      });
      setIsLoading(false);
      setMasterUserData(tempArr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    fetchMasterUserData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };
  console.log(sessionsId,'ye bhi id ')
  useEffect(() => {
    
    fetchMasterUserData({id: sessionsId });
  }, []);

  return (
    <div>
      <EditUserMasterComp 
        tableData={masterUserData}
        tableHeading={UserMasterClassTableHeading}
        handleTablePageChange={handleTablePageChange}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        sessionsId={sessionsId}
      />
    </div>
  );
};

export default EditUserMaster;
