export const sleepSessionTab = [
  {
    text: "Order.No.",
    dataField: "orderNo",
  },

  {
    text: "Questions",
    dataField: "question",
  },
];
export const sleepSessionTabUnpublish = [
  {
    text: "Order.No",
    dataField: "orderNo"
  },

  {
    text: "Questions",
    dataField: "question"
  }
]
export const SleepSurveyListHead = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'userId.firstName',
  },
  {
    text: 'Email',
    dataField: 'userId.email',
  },
  {
    text: 'Phone No.',
    dataField: 'userId.phoneNumber',
  },
  {
    text: 'Goal',
    dataField: 'goal',
  },
  {
    text: 'CreatedAt',
    dataField: 'createdAt',
  },


]