import AllAdvisors from "../Components/AllAdvisors";
import EditAllAdvisors from "../Components/EditAdvisors";
import CreateAdvisor from '../Components/CreateAdvisor'

export const AdvisorsRoutes = [
  
  {
    Component: AllAdvisors,
    path: "/all",
    exact: true,
    id: "dashboardAdvisorAll",
    routePath: "/dashboard/advisor/all",
    name:"All Advisors"
  },
  {
    Component: EditAllAdvisors,
    path: '/edit/:advisorId',
    exact: true,
    id: 'dashboardAdvisorEdit',
  },
  {
    Component: CreateAdvisor,
    path: "/create",
    exact: true,
    id: "dashboardAdvisorCreate",
    routePath: "/dashboard/advisor/create",
    name: "Create Advisor"
  }

]


