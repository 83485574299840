import React from "react";
import { useState, useEffect } from "react";
import {
  getAllBookingSessionById,
  updateBookingSessions,
} from "../../Constants/api";
import { useHistory, useParams } from "react-router-dom";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import EditSessionsComp from "../../../../../../Components/EditSessionComp";
import { EditorState } from "draft-js";
import { getAllSupportAdvisorApi } from "../../../Courses/Constants/api";
import { getAllFaq } from "../../../Courses/Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";

const EditSessions = () => {
  const { sessionsId } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    supportAdvisor: "",
    Faqs: [],
  });
  const [advisor, setAdvisor] = useState([]);
  const [sessionData, getSessionData] = useState([]);
  const [sessionsById, getSessionsById] = useState("");
  const [adName, setAdName] = useState([]);
  const [learnTitle, setLearnTitle] = useState("");
  const [learnImg, setLearnImg] = useState("");
  const [learnTitleTwo, setLearnTitleTwo] = useState("");
  const [learnImgTwo, setLearnImgTwo] = useState("");
  const [learnTitleThree, setLearnTitleThree] = useState("");
  const [learnImgThree, setLearnImgThree] = useState("");
  const [faq, setFaq] = useState([]);
  const [faqData, setFaqData] = useState("");
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [content, setContent] = useState(null);
  const [headerImg, setHeaderImg] = useState("");
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });

  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { title, image, supportAdvisor } = formData;

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const getAllFaqData = async () => {
    const { data } = await getAllFaq();
    setFaqData(data?.faqs);
  };
  useEffect(() => {
    getAllFaqData();
  }, []);
  function htmlDecode(input) {
    console.log(input, "ceck");
    var e = document.createElement("div");
    e.innerHTML = input;
    console.log(e.innerHTML, "check");
    return e.firstChild.nodeValue;
  }

  // const getSessionById = async () => {
  //   try {
  //     const { data } = await getAllBookingSessionById(sessionsId);
  //     getSessionData(data?.data);
  //     setFormData({
  //       title: data?.data?.title,
  //     });
  //     setHeaderImg(data?.data?.image);
  //     setLearnImg(data?.data?.learning[0]?.image);
  //     setLearnTitle(data?.data?.learning[0]?.title);
  //     setLearnTitleTwo(data?.data?.learning[1]?.title);
  //     setLearnImgTwo(data?.data?.learning[1]?.image);
  //     setLearnTitleThree(data?.data?.learning[2]?.title);
  //     setLearnImgThree(data?.data?.learning[2]?.image);
  //     if (data?.data?.description) {
  //       setContent(data?.data?.description);
  //     }

  //     const res = await getAllSupportAdvisorApi();
  //     const res2 = await getAllFaq();
  //     setAdvisor(res?.data?.data);
  //     const advisorByID = res?.data?.data;

  //     const advisorName = data?.data?.supportAdvisorId._id;
  //     const filAdvsiorId = advisorByID?.filter((i) => i._id === advisorName);
  //     console.log(filAdvsiorId,'hevevsv')
  //     const advName = filAdvsiorId[0]?.name;
  //     const faqName = data.data.faqId;
  //     const faqID = res2.data.faqs;
  //       const filFaq = faqID?.filter((ele) => ele?._id === faqName);
  //       getSessionsById(data?.data);

  //       setAdName({
  //         key: data?.data?.supportAdvisorId,
  //         label: advName,
  //         value: data?.data?.supportAdvisorId,
  //       });

  //       setFaq([
  //         ...faq,
  //         {
  //           key: faqName?._id,
  //           label: faqName?.title,
  //           value: faqName?.title,
  //         },
  //       ]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getSessionById = async () => {
    try {
      const { data } = await getAllBookingSessionById(sessionsId);
      getSessionData(data?.data);
      setFormData({
        title: data?.data?.title,
      });
      setHeaderImg(data?.data?.image);
      setLearnImg(data?.data?.learning[0]?.image);
      setLearnTitle(data?.data?.learning[0]?.title);
      setLearnTitleTwo(data?.data?.learning[1]?.title);
      setLearnImgTwo(data?.data?.learning[1]?.image);
      setLearnTitleThree(data?.data?.learning[2]?.title);
      setLearnImgThree(data?.data?.learning[2]?.image);
      if (data?.data?.description) {
        setContent(data?.data?.description);
      }

      const res = await getAllSupportAdvisorApi();
      const res2 = await getAllFaq();
      setAdvisor(res?.data?.data);

      const advisorByID = res?.data?.data;
      const advisorName = data?.data?.supportAdvisorId._id;
      const filAdvsiorId = advisorByID?.filter((i) => i._id === advisorName);
      const advName = filAdvsiorId[0]?.name;
      const faqIdArray = data.data.faqId;
      const faqData = res2.data.faqs;

      // Map the faqIdArray to extract the required data
      const faqList = faqIdArray.map((faqId) => {
        const faqItem = faqData.find((ele) => ele._id === faqId._id);
        console.log(faqItem, "habrbabr");
        return {
          key: faqItem?._id,
          label: faqItem?.title,
          value: faqItem?.title,
        };
      });

      getSessionsById(data?.data);

      setAdName({
        key: data?.data?.supportAdvisorId,
        label: advName,
        value: data?.data?.supportAdvisorId,
      });

      setFaq(faqList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSessionById();
  }, [sessionsId]);

  const handleSubmit = async () => {
    try {
      let headerImgUrl = headerImg;
      let learnImgUrl = learnImg;
      let learnImgTwoUrl = learnImgTwo;
      let learnImgThreeUrl = learnImgThree;

      // setShowUnPublishAlert(true);
      if (headerImg && typeof headerImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        headerImgUrl = await uploadFile(
          headerImg,
          "COURSES-IMAGE-HEADER",
          setThumbnailUploadPercentage,
          20
        );
      } else setContentUploadPercentage(20);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImg && typeof learnImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgUrl = await uploadFile(
          learnImg,
          "COURSES-IMAGE-LEARN1",
          setThumbnailUploadPercentage,
          30
        );
      } else setContentUploadPercentage(30);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImgTwo && typeof learnImgTwo !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgTwoUrl = await uploadFile(
          learnImgTwo,
          "COURSES-IMAGE-LEARN2",
          setThumbnailUploadPercentage,
          40
        );
      } else setContentUploadPercentage(40);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImgThree && typeof learnImgThree !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgThreeUrl = await uploadFile(
          learnImgThree,
          "COURSES-IMAGE-LEARN3",
          setThumbnailUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      await updateBookingSessions(sessionsId, {
        title: formData.title,
        description: content,
        image: headerImgUrl,
        supportAdvisorId: formatMultiSelectData(adName),
        learning: [
          {
            image: learnImgUrl,
            title: learnTitle,
          },
          {
            image: learnImgTwoUrl ? learnImgTwoUrl : "",
            title: learnTitleTwo,
          },
          {
            image: learnImgThreeUrl,
            title: learnTitleThree,
          },
        ],
        faqId: faq?.map((i) => i.key),
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSession = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {content && (
        <EditSessionsComp
          title={title}
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          adName={adName}
          setAdName={setAdName}
          learnImg={learnImg}
          setLearnImg={setLearnImg}
          learnTitle={learnTitle}
          setLearnTitle={setLearnTitle}
          learnImgTwo={learnImgTwo}
          setLearnImgTwo={setLearnImgTwo}
          learnTitleTwo={learnTitleTwo}
          setLearnTitleTwo={setLearnTitleTwo}
          learnImgThree={learnImgThree}
          setLearnImgThree={setLearnImgThree}
          learnTitleThree={learnTitleThree}
          setLearnTitleThree={setLearnTitleThree}
          content={content}
          setContent={setContent}
          advisor={advisor}
          setAdvisor={setAdvisor}
          setFaqData={setFaqData}
          faqData={faqData}
          faq={faq}
          setFaq={setFaq}
          handleSubmit={handleSubmit}
          handleChangeSession={handleChangeSession}
          showUnPublishAlert={showUnPublishAlert}
          setShowUnPublishAlert={setShowUnPublishAlert}
        />
      )}
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Update it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmit();
            setShowUnPublishAlert();
            setShowUnPublishConfirm(true);
          }}
          onCancel={() => setShowUnPublishAlert()}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            // setShowUnPublishAlert();
            // setShowUnPublishConfirm();
            history.push("/dashboard/booking-session/all");
          }}
        >
          Sessions has been edited
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditSessions;
