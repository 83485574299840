import React from 'react'
import AllCourses from '../Components/AllCourses'
import CreateCourses from '../Components/CreateCourses'
import EditCourses from '../Components/EditCourses'

const CoursesRoutes = [
  {
    Component: AllCourses,
    path: '/all',
    exact: true,
    id: 'dashboardCoursesAll',
    routePath: '/dashboard/courses/all',
    name: 'All Courses',
  },
  {
    Component: CreateCourses,
    path: '/create',
    exact: true,
    id: 'dashboardCoursesCreate',
    routePath: '/dashboard/courses/create',
    name: 'Create Courses',
  },
  {
    Component: EditCourses,
    path: '/edit/:editID',
    exact: true,
    id: 'dashboardCoursesEdit',
  },
  
]

export default CoursesRoutes