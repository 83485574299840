
export const tabsTableHeading =[
  {
    text: "S.no",
    dataField: "sNo"

  },
  {
    text: "Tabs",
    dataField: "filter"
  
  },
  {
    text: "Type",
    dataField: "page"
  
  },
]