import {
  getAllBusinessReq,
  getActiveBusniessReq,
  searchBusinessUsersApi,
  userSubscription,
} from "../../Business.Api";
import React, { useState, useEffect } from "react";
import { allUsersHeading } from "../../Business.Constants";
import { useHistory } from "react-router-dom";
import UserCard from "../../../../../../Components/BusinessUsersCard";
import UserInfoModal from "../../../../../../Components/BusinessUserInfoModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

const AllAccounts = () => {
  const [userData, setUserData] = useState([]);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showUserStatus, setShowUserStatus] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    country: "",
  });
  const [isActiveBtn, setIsActiveBtn] = useState({
    isActiveId: "",
    isActiveType: "",
  });
  const { isActiveId, isActiveType } = isActiveBtn;
  const [rowId,setRowId]=useState()
  const history = useHistory();
  const toggleUserInfo = () => setIsUserInfoOpen(!isUserInfoOpen);

  const [paginationValue, setPaginationValue] = useState({
    page: 1,
  });
  const { page, totalSize } = paginationValue;

  const handleUserInfo = (item) => {
    setUserInfo({
      firstName: item.firstName,
      lastName: item.lastName,
      companyName: item.companyName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      country: item.country,
      id: item["_id"],
    });
    toggleUserInfo();
  };

  const handleEditUserClick = (userId) => {
    history.push(`/dashboard/business/edit/` + userId);
  };

  const handleAllUsers = async (queryParams) => {
    try {
      const { data } = await getAllBusinessReq(queryParams);
      setUserData(data.data);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleActiveUsers = async (queryParams) => {
    try {
      const { data } = await getActiveBusniessReq(queryParams);
      setUserData(data.data);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInActiveUsers = async (queryParams) => {
    try {
      const { data } = await getActiveBusniessReq(queryParams);
      setUserData(data.data);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const searchUserData = async (text) => {
    try {
      const { data } = await searchBusinessUsersApi(text);
      setCurrentSearch(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        {row?.isDisabled ? null : (
          <div style={{ textAlign: "center" }} key={row._id}>
            <i
              className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
              onClick={() => handleEditUserClick(row._id)}
            />
          </div>
        )}
        
      </>
    );
  };

  const UserSubscriptionFormatter = (cell, row) => {
    let isType = row?.isDisabled ? "enable" : "disable";
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color={row?.isDisabled ? "success" : "danger"}
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => {
            setIsActiveBtn({
              ...isActiveBtn,
              isActiveType: isType,
              isActiveId: row._id,
            });
            setShowAlert(true);
          }}
        >
          {row?.isDisabled ? "Activate" : "Deactivate"}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    allUsersHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    allUsersHeading.push({
      text: "Subscriptions",
      dataField: "Subscriptions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: UserSubscriptionFormatter,
    });
    return () => {
      allUsersHeading.pop();
      allUsersHeading.pop();
    };
  }, []);

  const handleUserSubscription = async (type, userId) => {
    const { data } = await userSubscription(type, userId);

    if (data.sucess) handleAllUsers({ page: 1, limit: 10 });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    searchUserData(searchBar);
  };
  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  useEffect(() => {
    handleAllUsers({ page: 1, limit: 10 });
  }, []);

  const handlePageChange = (type, { page, sizePerPage }) => {
    handleAllUsers({ page: page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const select_user = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  const selector1 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#1cbb8c",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  const selector2 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#fcb92c",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  const selector3 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "red",
    borderRadius: "50%",
    marginRight: "1rem",
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Users</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Bussiness Form"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_user}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => {handleUserInfo(item); setRowId(item.isDisabled)}}
                      >
                        {item && item.isDisabled === false && (
                          <div style={selector1}></div>
                        )}
                        {item && item.isDisabled === true && (
                          <div style={selector3}></div>
                        )}
                        <div>{item ? item.firstName : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
        {showAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes"
            reverseButtons={true}
            onConfirm={() => {
              handleUserSubscription(isActiveType, isActiveId);
              setShowUserStatus(true);
            }}
            onCancel={() => setShowAlert(false)}
          ></SweetAlert>
        ) : null}

        {showUserStatus ? (
          <SweetAlert
            success
            title="Status Changed"
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setShowAlert(false);
              setShowUserStatus(false);
            }}
          >
            User Status Changed
          </SweetAlert>
        ) : null}
      </div>
      <UserCard
        pageHeading="All Users"
        tableData={userData}
        tableHeading={allUsersHeading}
        handleEditUserClick={handleEditUserClick}
        handleUserSubscription={handleUserSubscription}
        handleAllUsers={handleAllUsers}
        handleActiveUsers={handleActiveUsers}
        handleInActiveUsers={handleInActiveUsers}
        handlePageChange={handlePageChange}
        page={page}
        sizePerPage={10}
        totalSize={totalSize}
      />

      <UserInfoModal
        firstName={userInfo.firstName}
        lastName={userInfo.lastName}
        companyName={userInfo.companyName}
        email={userInfo.email}
        contact={userInfo.phoneNumber}
        country={userInfo.country}
        isUserInfoOpen={isUserInfoOpen}
        toggleUserInfoModal={toggleUserInfo}
        handleEditUserClick={() => handleEditUserClick(userInfo.id)}
        rowId={rowId}
      />
    </>
  );
};

export default AllAccounts;
