import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";
import { sendNotificationReq } from "./NotificationsCard.Api";

const NotificationsCard = ({
  openModel,
  tableData = [],
  tableHeading = [],
  setNotifications,
  showSendConfirm,
  setShowSendConfirm,
  handleDeleteNoti,
  handleRestoreNoti,
  allNoti,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
}) => {
  const history = useHistory();
  const [rowData, setRowData] = useState([]);

  const actionsFormatter = (cell, row) => {
    const handleSendNotification = async (notificationid) => {
      await sendNotificationReq(notificationid);
      setShowSendConfirm(true);

      setNotifications((noti) => {
        const temp = noti.map((notification) => {
          if (notification._id === notificationid) {
            return {
              ...notification,
              isSent: true,
            };
          }
          return notification;
        });
        return temp;
      });
    };

    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        {row?.isSent ? (
          <i
            className="ri-chat-follow-up-line text-info h4 cursor-pointer mr-2"
            onClick={() => handleSendNotification(row._id)}
          />
        ) : (
          <i
            className="ri-chat-forward-fill text-info h4 cursor-pointer mr-2"
            onClick={() => handleSendNotification(row._id)}
          />
        )}
      </div>
    );
  };

  const sentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "right" }} key={row._id}>
        {row.isSent === true && (
          <i className="ri-check-double-fill text-info h4 cursor-pointer mr-2" />
        )}
        {row.isSent === false && (
          <i className="ri-close-fill text-danger h4 cursor-pointer mr-2" />
        )}
      </div>
    );
  };

  const deleteFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
          onClick={() => handleDeleteNoti(row._id)}
        />
      </div>
    );
  };

  const restoreFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleRestoreNoti(row._id)}
        >
          Restore
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (allNoti === true) {
      tableHeading.push({
        text: "Send Notification",
        dataField: "actions",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "15%" },
        formatter: actionsFormatter,
      });
      tableHeading.push({
        text: "Sent",
        dataField: "isSent",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "5%" },
        formatter: sentFormatter,
      });
      tableHeading.push({
        text: "Delete Notification",
        dataField: "deleted",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "15%" },
        formatter: deleteFormatter,
      });
    } else {
      tableHeading.push({
        text: "Restore Notifications",
        dataField: "restore",
        isDummyField: true,
        headerStyle: { textAlign: "center", width: "15%" },
        formatter: restoreFormatter,
      });
    }
    return () => {
      if (allNoti === true) {
        tableHeading.pop();
        tableHeading.pop();
        tableHeading.pop();
      } else {
        tableHeading.pop();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {allNoti === true && <h3 className="m-0">Notifications</h3>}
                  {allNoti === false && (
                    <h3 className="m-0">Notifications Bin</h3>
                  )}
                  {allNoti === true && (
                    <div
                      className="page-title-right"
                      style={{ display: "flex", width: "25%", gap: "10px" }}
                    >
                      <Button
                        color="danger"
                        className="waves-effect waves-light"
                        style={{ width: "100%" }}
                        onClick={() =>
                          history.push("/dashboard/notifications/bin")
                        }
                      >
                        Notifications Bin
                      </Button>
                      <Button
                        color="primary"
                        className="waves-effect waves-light"
                        style={{ width: "100%" }}
                        onClick={openModel}
                      >
                        Add Notifications
                      </Button>
                    </div>
                  )}
                  {allNoti === false && (
                    <div className="page-title-right">
                      <Button
                        color="primary"
                        className="waves-effect waves-light"
                        style={{ width: "100%" }}
                        onClick={() =>
                          history.push("/dashboard/notifications/all")
                        }
                      >
                        All Notifications
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default NotificationsCard;
