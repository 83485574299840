export const editTagData = {
  TAG: {
    heading: "Edit Tag",
    labelName: "Tag Name",
    nameError: "Enter Tag Name",
    labelDescription: "Tag Description",
    descriptionError: "Enter Tag Description",
    createBtn: "Edit Tag",
  },
  CATEGORY: {
    heading: "Edit Category",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Category Description",
    descriptionError: "Enter Category Description",
    createBtn: "Edit Category",
  },
  SUBCATEGORY: {
    heading: "Edit Sub-Category",
    labelName: "Sub-Category Name",
    nameError: "Enter Sub-Category Name",
    labelDescription: "Sub-Category Description",
    descriptionError: "Enter Sub-Category Description",
    createBtn: "Edit Sub-Category",
  },
};
