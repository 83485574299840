import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUploader from "../FileUploader";
import Select from "react-select";

const PlaylistForm = ({
  heading,
  getData,
  error,
  coverImage,
  setCoverImage,
  createHandle,
  playlistDescription,
  handleInput,
  tagsData,
  selectedTags,
  setSelectedTags
}) => {
  const MediaType = [
    { label: "AUDIO", value: "AUDIO" },
    { label: "VIDEO", value: "VIDEO" },
  ];
  
  let tagsObj =
  tagsData &&
  tagsData?.map((i) => {
    return {
      key: i._id,
      value: i._id,
      label: i.name,
    };
  });

  const handleDropdownChange = (event, index) => {
    const { name, value } = event.target;
    const updatedMediaData = [...playlistDescription.mediaData];
    updatedMediaData[index] = value;
    handleInput({ target: { name: "mediaData", value: updatedMediaData } });
  };

  const addDropdown = () => {
    handleInput({
      target: {
        name: "mediaData",
        value: [...playlistDescription.mediaData, ""],
      },
    });
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{heading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={playlistDescription?.title}
                      onChange={handleInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <AvField
                      name="description"
                      placeholder="Enter description"
                      value={playlistDescription?.description}
                      onChange={handleInput}
                      type="text"
                      errorMessage="Description is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="description"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="lessons">Tags</Label>
                      <Select
                        value={selectedTags}
                        isMulti={true}
                        onChange={(selected) => setSelectedTags(selected)}
                        options={tagsObj}
                        classNamePrefix="select2-selection"
                        placeholder="Select Tags"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">MediaType</Label>
                      <Input
                        name="mediaType"
                        placeholder="Select Type"
                        type="select"
                        value={playlistDescription?.mediaType}
                        onChange={handleInput}
                        className="form-control"
                        id="languageSelect"
                      >
                        <option value="">Select MediaType</option>
                        {MediaType?.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
                {playlistDescription.mediaData.map((media, index) => (
                  <Col xs={3} key={index}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor={`notificationSelect${index}`}>
                          MediaData
                        </Label>
                        <Input
                          name="mediaData"
                          placeholder="Select Type"
                          type="select"
                          value={media}
                          onChange={(event) =>
                            handleDropdownChange(event, index)
                          }
                          className="form-control"
                          id={`notificationSelect${index}`}
                        >
                          <option value="">Select MediaData</option>
                          {getData?.map((item) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {item.title}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
              <Button
                color="primary"
                className="w-20 mt-5"
                type="button"
                onClick={addDropdown}
              >
                Add MediaData
              </Button>
              <Row>
                <Col>
                  <FileUploader
                    file={coverImage}
                    setFile={setCoverImage}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                </Col>
              </Row>

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                // disabled={isDisabledBtn}
                onClick={createHandle}
              >
                CreatePlaylist
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PlaylistForm;
