import React, { useState } from "react";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { createQuote } from "./CreateQuotes.Api";

const CreateQuote = ({ isOpen, toggleModal, populateTag }) => {
  const [quoteForm, setQuoteForm] = useState({
    text: '',
    isPriority: false,
    language: 'en',
    specialDate: '',
    objectType: ''
  });
  const objectTypeOpt = [
    {
      key: "QUOTES",
      label: "QUOTES",
      value: 'QUOTES',
    },
    {
      key: "FACTS",
      label: "FACTS",
      value: 'FACTS',
    },


  ];

  const { text, isPriority, specialDate, language, objectType } = quoteForm;

  const [isCreating, setIsCreating] = useState(false);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: 'success',
    alertText: 'Created Sucessfully',
  });
  const { isAlert, alertType, alertText } = alert;

  const handelInput = (e) => {
    let { type, value, checked, name } = e.target;
    if (type === 'checkbox')
      return setQuoteForm({ ...quoteForm, isPriority: checked });
    else setQuoteForm({ ...quoteForm, [name]: value });
  };

  const handleObjectTypeChange = (selectedOption) => {
    setQuoteForm({ ...quoteForm, objectType: selectedOption.value });
  };

  const handleClose = () => {
    setQuoteForm({
      text: '',
      isPriority: false,
      objectType: ''
    });
    setAlert({
      isAlert: false,
      alertType: 'success',
      alertText: 'Created Sucessfully',
    });
    toggleModal();
  };

  const handelCreateQuote = async () => {
    try {
      setIsCreating(true);
      if (isPriority && !moment(specialDate).isValid())
        return setAlert({
          isAlert: true,
          alertType: 'warning',
          alertText: 'Please select date',
        });

      const { data } = await createQuote({
        text,
        isPriority,
        language,
        specialDate,
        objectType
      });
      setAlert({
        isAlert: true,
        alertType: 'success',
        alertText: 'Created Sucessfully',
      });
      populateTag(data.data);
      setTimeout(() => {
        setAlert({ isAlert: false });
        setQuoteForm({
          text: '',
          isPriority: false,
          language: 'en',
          specialDate: '',
        });
        toggleModal();
        setIsCreating(false);
      }, 1000);
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: 'danger',
        alertText: 'Unable to Create',
      });
      console.error(error.response.data, error);
    }
  };

  return (
    <Modal size='lg' isOpen={isOpen} backdrop='static'>
      <AvForm className='needs-validation' onValidSubmit={handelCreateQuote}>
        <ModalHeader>Create Quote</ModalHeader>
        <ModalBody>
          <Row>
            <Col md='9'>
              <FormGroup>
                <Label htmlFor='quote'>Enter a Quote</Label>
                <AvField
                  name='text'
                  placeholder='Enter a Quote'
                  type='text'
                  errorMessage='Please Enter a Quote'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  value={text}
                  onChange={handelInput}
                  id='text'
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md='5'>
              <FormGroup>
                <div>
                  <Label htmlFor="supportAdvisor">Quotes/Facts</Label>
                  <Select
                    value={objectTypeOpt.find(option => option.value === quoteForm.objectType)}
                    isMulti={false}
                    onChange={handleObjectTypeChange}
                    options={objectTypeOpt}
                    classNamePrefix="select2-selection"
                    placeholder="Select Quotes/Facts"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md='3'>
              
              <FormGroup>
                <div className='custom-control custom-switch' dir='ltr'>
                  <Label htmlFor='notificationSelect'>Language</Label>
                  <Input
                    name='language'
                    placeholder='Select Type'
                    type='select'
                    className='form-control'
                    onChange={handelInput}
                    validate={{ required: { value: true } }}
                    id='languageSelect'
                    disabled={isCreating}
                  >
                    <option value='en'>ENGLISH</option>
                    <option value='hi'>HINDI</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <div
                  className='custom-control custom-switch'
                  style={{ textAlign: 'end' }}
                  dir='ltr'
                >
                  <Input
                    type='checkbox'
                    className='custom-control-input'
                    value={isPriority}
                    name='isPriority'
                    id='customSwitchDate'
                    onClick={handelInput}
                  ></Input>
                  <Label
                    className='custom-control-label'
                    htmlFor='customSwitchDate'
                  >
                    Special Quote
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          {isPriority && (
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label htmlFor='specialDate'>Date</Label>
                  <Input
                    className='form-control'
                    name='specialDate'
                    type='date'
                    value={specialDate}
                    id='specialDate'
                    onChange={handelInput}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          {isAlert && (
            <Alert color={alertType} role='alert'>
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type='button'
            onClick={handleClose}
            color='light'
            className='waves-effect'
          >
            Close
          </Button>
          <Button color='primary' type='submit' disabled={isCreating}>
            Add Quote
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default CreateQuote;
