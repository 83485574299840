import React, { Fragment, useEffect, useContext } from "react";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Sidebar from "./Components/Sidebar";

import AuthContext from "../../context/auth/authContext";

const DashboardLayout = ({ children }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      {authContext.isLoading && (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      )}

      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          {children}
          <Footer />
        </div>
      </div>
      {/* <Rightbar /> */}
    </Fragment>
  );
};

export default DashboardLayout;
