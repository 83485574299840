import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Alert,
  ModalFooter,
} from "reactstrap";
import DiscoverForm from "../../Components/DiscoverForm";

const ViewDiscoverModal = ({
  isOpen,
  toggleModal,
  isEditable = false,
  populatedata,
  isLoading = false
}) => {
  const handleClose = () => {
    toggleModal();
    setAlert({ isAlert: false });
  };

  const [formData, setFormData] = useState({
    name: "",
    image: "",
    card_desription: "",
    series_desription: "",
    singles_desription: "",
    backgroundImage: "",
    seriesBackgroundImage: ""
  });

  useEffect(() => {
    setFormData({
      name: populatedata?.name,
      image: populatedata?.image,
      card_desription: populatedata?.card_desription,
      series_desription: populatedata?.series_desription,
      singles_desription: populatedata?.singles_desription,
      backgroundImage: populatedata?.backgroundImage,
      seriesBackgroundImage: populatedata?.seriesBackgroundImage,
      modules: populatedata?.modules
    })
  }, [populatedata])

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <DiscoverForm pageHeading={isEditable ? "Edit Discover" : "View Discover"} isEditable={false} setFormData={setFormData} formData={formData} />
      <Row>
        <Col>
          {alert.isAlert && (
            <Alert color={alert.alertType} role="alert">
              {alert.alertText}
            </Alert>
          )}
        </Col>
      </Row>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewDiscoverModal;
