
import Mind from "../Components/EditMind";
import MindTab from "../Components/MindTab";


export const MindRoutes = [
  {
    Component:MindTab,
    path: "/mindtab",
    exact: true,
    id: "dashboardMindTab",
    routePath: "/dashboard/Mind/mindtab",
    name: "Tab",
  },
  
  {
    Component: Mind,
    path: "/all/:tabId",
    exact: true,
    id: "dashboardMindAll",
   
  }

 
]