import axios from 'axios'

import { baseDomain } from '../../../../Constants/apiRoutes';

export const SeriesCreateAPI = (payload) => {
  return axios.post(`${baseDomain}/v2/modules`, payload)
}

export const getCategoryAPI = () => {
  return axios.get(`${baseDomain}/v2/mediatags/get`, {
    params: {
      objectType: 'CATEGORY',
      page: 1,
      limit: 70,
    }
  });
};

export const getMediaAPI = (categoryId) => {
  return axios.get(`${baseDomain}/v2/media/getMediaByCategory/${categoryId}`);
};

export const getPublishedSeriesAPI = ({ page, limit }) => {
  return axios.get(`${baseDomain}/v2/modules/get?page=${page}&limit=${limit}`);
};

export const getUnPublishedSeriesAPI = ({page,limit}) => {
  return axios.get(`${baseDomain}/v2/modules/unpublished?type=SERIES&page=${page}&limit=${limit}`);
};

export const getByCategoryAPI = (refId) => {
  return axios.get(`${baseDomain}/v2/modules/catMod/${refId}`)

}

export const editSeriesAPI = (id, payload) => {
  return axios.put(`${baseDomain}/v2/modules/update/${id}`, payload)
}

export const UpdateUnPublishSeriesAPI = (id) => {
  return axios.put(`${baseDomain}/v2/modules/unpublish/${id}`)
}

export const UpdatePublishSeriesAPI = (id) => {
  return axios.put(`${baseDomain}/v2/modules/publish/${id}`)
}

export const getAllTags = () => {
  return axios.get(`${baseDomain}/v2/mediatags/get?objectType=TAG&page=1&limit=4000`)
}
