import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, Button, Label, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addMeetinLinkNutriClassApi } from "../../Constants/api";
import { useHistory, useParams } from "react-router-dom";

const EditNutriLive = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [meetingLink, setMeetingLink] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const nutriID = queryParameters.get("nutriId");

  const addMeetinLinkNutriClassData = async () => {
    try {
      setShowUnPublishAlert(true);
      await addMeetinLinkNutriClassApi(userId, {
        meetingLink: meetingLink,
      });

      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Add Meeting Link </h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
                onValidSubmit={() => setShowUnPublishAlert(true)}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="meetingLink">Meeting Link</Label>
                      <AvField
                        name="meetingLink"
                        placeholder="Enter meeting link"
                        value={meetingLink}
                        onChange={(e) => setMeetingLink(e.target.value)}
                        type="text"
                        errorMessage="Meeting Link is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  disabled={false}
                >
                  Add Meeting Link
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Add Meeting Link"
          reverseButtons={true}
          onConfirm={() => {
            addMeetinLinkNutriClassData();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Added!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push(
              `${"/dashboard/n-live/nutri-clinic/all-nutri-user/" + nutriID}`
            );
          }}
        >
          Meeting Link has been added for Nutri Diet Class
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditNutriLive;
