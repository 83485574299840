import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import Loader from "../../../../../../Components/Loader";
import { getAllAdvisorsById, updateAdvisors } from "../../advisors.Apis";
import EditAdvisorForm from "../../../../../../Components/EditAdvisorForm";
// import Loader from "../../../../../../Components/Loader";
import { useHistory } from "react-router-dom"

const EditAllAdvisors = () => {
  const { advisorId } = useParams();
  const history = useHistory()
  // console.log(advisorId)

  const [advisorData, setAdvisorData] = useState({
    name: "",
    designation: "",
    experience: "",
    rating: parseInt(),
    imgAsset: "",
    speciality: [],
    advisorType: "",
    noOfPatients: "",

  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [advisorTypeData, setAdvisorTypeData] = useState("")
  const [specialityInput, setSpecialityInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [coverImage, setCoverImage] = useState("")
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;
  const getAdvisorsById = async () => {
    try {
      const { data } = await getAllAdvisorsById(advisorId)
      setAdvisorData({
        name: data?.data?.name,
        designation: data?.data?.designation,
        experience: data?.data?.experience,
        rating: data?.data?.rating,
        speciality: data?.data?.speciality,
        noOfPatients: data?.data?.noOfPatients,

      })
      setAdvisorTypeData({
        label: data?.data?.advisorType,
        value: data?.data?.advisorType
      })
      setCoverImage(data.data.imgAsset)
    }
    catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  console.log(advisorData.speciality)
  useEffect(() => {
    getAdvisorsById()
  }, [])

  const { speciality } = advisorData;

  const handleChange = (e) => {
    if (e.target.name === "speciality") {
      setSpecialityInput(e.target.value)
    } else {
      setAdvisorData({
        ...advisorData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const advisoryTypeOpt = [
    {
      name: 'COUNSELLOR',
    },
    {
      name: 'MENTOR',
    },
  ]
  const advisoryTypeOptions = advisoryTypeOpt.map((item) => ({
    value: item.name,
    label: item.name
  }))

  const handleSelectedAdvisoryType = (selectedOption) => {
    setAdvisorTypeData(selectedOption);
    console.log(selectedOption);
  };

  const handleUpdate = async (e) => {
    e.preventDefault()

    try {
      let coverUrl = coverImage;


      if (coverImage && typeof coverImage !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        coverUrl = await uploadFile(
          coverImage,
          "IMAGE",
          setContentUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      await updateAdvisors(advisorId, {
        name: advisorData.name,
        designation: advisorData.designation,
        imgAsset: coverUrl,
        rating: advisorData.rating,
        experience: advisorData.experience,
        speciality: advisorData.speciality,
        noOfPatients: advisorData.noOfPatients,
        advisorType: advisorTypeData.label,
      })


    } catch (error) {
      console.log(error);
    }
    // window.location.reload()
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  }

  console.log(advisorData.speciality)

  const handleAddSpeciality = () => {

    if (specialityInput.trim() !== "") {
      setAdvisorData({
        ...advisorData,
        speciality: [...(advisorData.speciality), specialityInput],
      });
    }
    setSpecialityInput("")
  };

  const handleRemoveSpeciality = (indexToRemove) => {
    const updatedSpeciality = speciality.filter((_, index) => index !== indexToRemove);
    setAdvisorData({
      ...advisorData,
      speciality: updatedSpeciality,
    });
  };


  return (
    <>
      {isLoading ? (<Loader />) : <EditAdvisorForm
        name={advisorData.name}
        designation={advisorData.designation}
        experience={advisorData.experience}
        rating={advisorData.rating}
        image={coverImage}
        noOfPatients={advisorData.noOfPatients}
        setImage={setCoverImage}
        handleChange={handleChange}
        handleUpdateAdvisor={handleUpdate}
        speciality={advisorData.speciality}
        advisoryTypeOptions={advisoryTypeOptions}
        advisorTypeData={advisorTypeData}
        handleSelectedAdvisoryType={handleSelectedAdvisoryType}
        handleAddSpeciality={handleAddSpeciality}
        specialityInput={specialityInput}
        handleRemoveSpeciality={handleRemoveSpeciality}
      />}

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={() => {
            handleUpdate()

          }}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}
      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Changes Saved"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
            history.push("/dashboard/advisor/all")
          }}
        ></SweetAlert>
      ) : null}

    </>
  )
};

export default EditAllAdvisors;