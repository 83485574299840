export const PublishedMusicTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Media Name",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
  // {
  //   text: "Transcode",
  //   dataField: "isTranscoding",
  // },
];

export const CreatedMusicTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Media Name",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
  {
    text: "Video Ready to Publish",
    dataField: "isTranscoding",
  },
];

export const fileTypes = {
  TRACK_IMAGE: "TRACK_IMAGE",
  IMG_115X150: "IMG_115X150",
  IMG_145X345: "IMG_145X345",
  IMG_180X160: "IMG_180X160",
  IMG_155X135: "IMG_155X135",
  TRACK_VIDEO: "TRACK_VIDEO",
};

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
};