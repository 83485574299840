import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import UserAnalyticsGraphCard from "./Components/UserAnalyticsGraphCard";

const UsersAnalyticsCard = ({
  activeUsers,
  totalUsers,
  subscribedUsers,
  graphData,
  handleUpdateData,
}) => {
  return (
    <React.Fragment>
      <Card>
        <UserAnalyticsGraphCard
          mainHeading="Users Analytics"
          graphData={graphData}
          handleUpdateData={handleUpdateData}
        />

        <CardBody className="border-top text-center">
          <Row>
            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-success font-size-10 mr-1"></i>{" "}
                  Active Users :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0">{activeUsers}</h5>
                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-danger font-size-10 mr-1"></i>{" "}
                  Total Users :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0">{totalUsers}</h5>
                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-primary font-size-10 mr-1"></i>{" "}
                  Total Subscribed Users :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0 mr-2">{subscribedUsers}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UsersAnalyticsCard;
