import AllNotifications from "../Components/AllNotifications";
import NotificationsBin from "../Components/NotificationsBin";

export const NotificationsRoutes = [
  {
    Component: AllNotifications,
    path: "/all",
    exact: true,
    id: "dashboardNotificationsAll",
    routePath: "/dashboard/notifications/all",
    name: "All Notifications",
  },
  {
    Component: NotificationsBin,
    path: "/bin",
    exact: true,
    id: "dashboardNotificationsBin",
    routePath: "/dashboard/notifications/bin",
    name: "Notifications Bin",
  },
];