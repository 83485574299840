import React, { useEffect, useState } from "react";
import { LiveSessionTableHeading, UnPubLiveSessionTableHeading } from "../../Constants/constant";
import {
  getAllLiveSessions,
  getAllLiveSessionsUnpublished,
  goLiveSession,
  endLiveSession,
} from "../../Constants/api";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import AllLiveSessionsComp from "../../../../../../Components/AllLiveSessionsComp";
import AllLiveSessionsCompUnpublished from "../../../../../../Components/AllLiveSessionsUnplishedComp";

const AllLiveSessions = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [publishedLiveSession, setPublishedLiveSession] = useState("");
  const [unpublishedLiveSession, setUnpublishedLiveSession] = useState("");
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;
  const [unpublishPage, setUnpublishPage] = useState({
    unpublishPageCurrentPage: 1,
    unpublishPageTotalSize: 10,
  });

  const { unpublishPageCurrentPage, unpublishPageTotalSize } = unpublishPage;

  const [liveSessionID, setLiveSessionID] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);

  const getAllPublishedCourseData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllLiveSessions(params);
      setPublishedLiveSession(data?.data);
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    console.log(publishedLiveSession)
  };

  const getAllUnPublishedCourseData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllLiveSessionsUnpublished(params);
      setUnpublishedLiveSession(data?.data);
      setUnpublishPage({
        unpublishPageCurrentPage: params?.page,
        unpublishPageTotalSize: data?.count,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  console.log(unpublishedLiveSession)

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllPublishedCourseData({ pubType:true, page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  const handleTablePageChangeUnpublish = (type, { page, sizePerPage }) => {
    console.log(page, sizePerPage,'fjhfjhfhfjh');
    getAllUnPublishedCourseData({ pubType:false,page, limit: sizePerPage });
    setUnpublishPage({
      ...unpublishPage,
      unpublishPageCurrentPage: page,
    });
  };

  const handlePublishSessionClick = async (id) => {
    setLiveSessionID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishSessionClick = async (id) => {
    setLiveSessionID(id);
    setShowPublishAlert(true);
  };

  const publishCourseAction = async () => {
    await endLiveSession(liveSessionID);
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
    const filteredSeries = publishedLiveSession.filter(
      ({ _id }) => _id !== liveSessionID
    );
    setPublishedLiveSession(filteredSeries);
    setLiveSessionID("");
  };

  const UnPublishCourseAction = async () => {
    await goLiveSession(liveSessionID);
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
    const filteredSeries = unpublishedLiveSession.filter(
      ({ _id }) => _id !== liveSessionID
    );
    setUnpublishedLiveSession(filteredSeries);
    setLiveSessionID("");
  };

  const handleEditLiveSession = (liveSessionID) => {
    console.log('click edit');
    history.push("/dashboard/live-sessions/edit/" + liveSessionID);
  };

  useEffect(() => {
    getAllPublishedCourseData({pubType:true ,page: 1, limit: 10 });
    getAllUnPublishedCourseData({pubType:false, page: 1, limit: 10 });
  }, []);

  return (
    <>
      <AllLiveSessionsComp
        tableData={publishedLiveSession}
        tableHeading={LiveSessionTableHeading}
        handleTablePageChange={handleTablePageChange}
        handlePublishSessionClick={handlePublishSessionClick}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        handleEditLiveSession={handleEditLiveSession}
      />

      <AllLiveSessionsCompUnpublished
       tableData={unpublishedLiveSession}
       tableHeading={UnPubLiveSessionTableHeading}
       handleTablePageChange={handleTablePageChangeUnpublish}
       handleUnPublishSessionClick={handleUnPublishSessionClick}
       isLoading={isLoading}
       page={unpublishPageCurrentPage}
       sizePerPage={10}
       totalSize={unpublishPageTotalSize}
       handleEditLiveSession={handleEditLiveSession}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes!"
          reverseButtons={true}
          onConfirm={publishCourseAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes!"
          reverseButtons={true}
          onConfirm={UnPublishCourseAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="LIVE!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllLiveSessions;
