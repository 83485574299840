import { Country } from "country-state-city";
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BusinessForm from "../../../../../../Components/BusinessForm";

const CreateUser = () => {
  const [formData, setFormData] = useState({
    email: "",
    first_Name: "",
    last_Name: "",
    company_Name: "",
    company_Size: "",
    your_Role: "",
    monthly_Stocks: "",
    annual_Stocks: "",
    companions: "",
    country: "",
    state: "",
    countrycode: "",
    city: "",
    stateCode: "",
    amountPaid:"",
  });
  return (
    <>
      <BusinessForm setFormData={setFormData} formData={formData} />
    </>
  );
};

export default CreateUser;
