import React, { useState } from "react";
import { useEffect } from "react";
import AllAppointmentComp from "../../../../../../Components/AllAppointmnetComp";
import { getAllAppointmentApi } from "../../Constant/api";
import { AppointmentHeading } from "../../Constant/table";

const AllAppointment = () => {
  const [appointmentData, setAppointmentData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;
  const getAllAppointmentData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllAppointmentApi(params);
      console.log(data.data, "getAllAppointmentApi");
      
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });

      let tempArr = data?.data?.map((item) => {
        return {
          userName:item?.userId?.firstName ,
          appDate: item?.timeSlot?.date,
          appTime: item?.timeSlot?.time,
          userId: item?._id,
          nutriId: item?.nutriId !== '' ? 'Nutri Diet' : 'Home Sessions'
        };
      });
     
      setAppointmentData(tempArr);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllAppointmentData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  useEffect(() => {
    getAllAppointmentData({ page: 1, limit: 10 });
  }, []);
  return (
    <>
      <AllAppointmentComp
        tableData={appointmentData}
        tableHeading={AppointmentHeading}
        handleTablePageChange={handleTablePageChange}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />
    </>
  );
};

export default AllAppointment;
