import React from "react";
import FaqForm from "../../../../../../Components/FaqForm";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState, useEffect } from "react";
import { faqFormAPi } from "../../Faq.Apis";
import { createFaq } from "../../Faq.Apis";
import { useHistory } from "react-router-dom";
import { EditorState } from "draft-js";

const CreateFaq = () => {
  const [getData, setGetData] = useState([]);
  const [error, setError] = useState(0);
  const [faqType, SetFaqType] = useState("");
  const [content, setContent] = useState(null)
  const [postDetails, setPostDetails] = useState({
    title: "",
    tag: "",
    category: "",
    description: "",
  });
  const [showPostFaqAlert, setShowPostFaqAlert] = useState(false);
  const [showPostFaqConfirm, setShowPostFaqConfirm] = useState(false);
  const [descrip, setDescrip] = useState(EditorState.createEmpty());
  const { title, tag, category, description } = postDetails;

  const getAllFaqInfo = async () => {
    const { data } = await faqFormAPi();
    setGetData(data.data);
    console.log(getData);
  };
  useEffect(() => {
    getAllFaqInfo();
  }, []);
  const handelInput = (e) => {
    // debugger;
    setPostDetails({
      ...postDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleFaqtype = (event) => {
    SetFaqType(event.target.value);
  };
  const onChange = (evt) => {
    console.log(evt, "evtr");
    var newContent = evt.editor.getData();
    setPostDetails((prev) => {
      return { ...prev, description: newContent };
    });
  };

  const faqPublishHandle = async () => {
    if (postDetails.title === "") {
      setError(1);
    } else if (postDetails.tag === "") {
      setError(2);
    } else if (postDetails.category === "") {
      setError(3);
    } else if (descrip === "") {
      setError(4);
    } else if (faqType === ""){
      setError(5);
    }
     else {
      try {
        await createFaq({
          title: title,
          tag: tag,
          category: category,
          description: content,
          faqType:faqType
        });
        setShowPostFaqConfirm(true);
        setError(0);
        setPostDetails({
          ...postDetails,
          title: "",
          tag: "",
          category: "",
          description: "",
        });
        SetFaqType("")
      } catch (error) {
        console.log(error.response.data, error);
      }
    }
  };
  return (
    <div>
      <FaqForm
        heading="Create FAQ's"
        getData={getData}
        setGetData={setGetData}
        setShowPostFaqAlert={setShowPostFaqAlert}
        publishHandle={faqPublishHandle}
        planDescription={postDetails}
        handleInput={handelInput}
        onChange={onChange}
        setShowPostFaqConfirm={setShowPostFaqConfirm}
        error={error}
        handleFaqtype={handleFaqtype}
        faqType={faqType}
        setContent={setContent}
        descrip={descrip}
        setDescrip={setDescrip}
      />

      {showPostFaqAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Upload"
          reverseButtons={true}
          onConfirm={setShowPostFaqConfirm(true)}
          onCancel={() => setShowPostFaqAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPostFaqConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            window.location.reload();
          }}
        ></SweetAlert>
      ) : null}
    </div>
  );
};

export default CreateFaq;
