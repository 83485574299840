import React, { useEffect } from "react";
import { useState } from "react";
import VideoCard from "../../../../../../Components/VideoCard";
import { videoTableHeading } from "../../YtVideos.Constants";
import { getAllYoutubeVideos, editYoutubeVideos, updateYoutubeVideos } from "../../YtVideos.Api";
import VideoModal from "../../../../../../Modal/YoutubeVideo";
import SweetAlert from "react-bootstrap-sweetalert";
const AllVideos = () => {
  
  const [getData, setGetData] = useState([]);
  const[userId,setUserId]=useState()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [updateConfirm,setUpdateConfirm] = useState(false)
  const [videoData, setVideoData] = useState({
    title: "",
    thumbnailPic: "",
    url: "",
  });
  const { title, thumbnailPic, url } = videoData;
  const toggleEditModel = () => setIsEditModalOpen(!isEditModalOpen);

  const getAllVideosData = async () => {
    try {
      const { data } = await getAllYoutubeVideos();
      setGetData(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const editVideoData = async (userId) => {
    try {
      const { data } = await editYoutubeVideos(userId);
      setVideoData(
        {
          title: data.data.title,
          thumbnailPic: data.data.thumbnailPic,
          url: data.data.url,
        },
        userId
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleViewVideoClick = (userId) => {
    setUserId(userId)

    setIsEditModalOpen(true) ;

    editVideoData(userId);
    
  };
  const handleUpdateDetails = async() =>{
    try {
      await updateYoutubeVideos(videoData,userId)
      setUpdateConfirm(true)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllVideosData();
  }, []);
  return (
    <>
      <VideoCard
        heading="All Videos"
        tableHeading={videoTableHeading}
        tableData={getData}
        handleViewVideoClick={handleViewVideoClick}
      />
      <VideoModal
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModel}
        title={title}
        thumbnailPic={thumbnailPic}
        url={url}
        videoData={videoData}
        setVideoData={setVideoData}
        handleUpdate={handleUpdateDetails}
      />
      {updateConfirm?(
      <SweetAlert
      success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setUpdateConfirm(false);
            window.location.reload()
            }}
      
      />):null}
    </>
  );
};

export default AllVideos;
