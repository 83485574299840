export const tagTypes = {
  TAG: "FAQTAG",
  CATEGORY: "FAQCATEGORY",
};

export const tagsTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Tag Name",
    dataField: "name",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const categorieTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Categories Name",
    dataField: "name",
  },
  {
    text: "Description",
    dataField: "description",
  },
];
export const allFaqTableHeading = [
  {
    text: "S.No",
    dataField: "sNo"
  },
  {
    text : "Topic",
    dataField: "tag.name"
  },
  {
    text: "Category",
    dataField:"category.name"
  },
  {
    text: "Title",
    dataField: "title",
  },
];

export const publishedTableHeading = [
  {
  text: "S.No",
  dataField: "sNo"
  },
  {
  text : "Topic",
  dataField: "tag.name"
  },
  {
  text: "Category",
  dataField:"category.name"
  },
  {
  text: "Title",
  dataField: "title",
  }
];
