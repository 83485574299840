import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";

const CreatedMusic = ({
  tableData = [],
  tableHeading = [],
  handleEditMusic,
  handleViewMusic,
  handleDeleteMusic,
  handlePublishMusic,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  mediaName
}) => {
  const history = useHistory();
  const [rowData, setRowData] = useState([]);

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewMusic(row._id)}
        />
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditMusic(row._id)}
        />
        <i
          className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
          onClick={() => handleDeleteMusic(row._id)}
        />
      </div>
    );
  };

  const publishFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handlePublishMusic(row._id)}
          disabled={
            mediaName === "video" ? (row.isTranscoding ? false : true) : ""
          }
        >
          Publish
        </Button>
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    tableHeading.push({
      text: "Publish Media",
      dataField: "publish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: publishFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Unpublised Media</h3>
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  >
                    {mediaName === "video" ? (
                      <>
                        {" "}
                        <Button
                          color="danger"
                          className="waves-effect waves-light"
                          style={{ width: "100%" }}
                          onClick={() => history.push("/dashboard/video/bin")}
                        >
                          Media Bin
                        </Button>
                        <Button
                          color="primary"
                          className="waves-effect waves-light"
                          style={{ width: "100%" }}
                          onClick={() =>
                            history.push("/dashboard/video/create")
                          }
                        >
                          Add Media
                        </Button>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Button
                          color="danger"
                          className="waves-effect waves-light"
                          style={{ width: "100%" }}
                          onClick={() => history.push("/dashboard/media/bin")}
                        >
                          Media Bin
                        </Button>
                        <Button
                          color="primary"
                          className="waves-effect waves-light"
                          style={{ width: "100%" }}
                          onClick={() =>
                            history.push("/dashboard/media/create")
                          }
                        >
                          Add Media
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CreatedMusic;
