import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditFaqComp from "../../../../../../Components/EditFaqInfo";
import { updateFaqInfo, editFaq, faqFormAPi } from "../../Faq.Apis";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

const EditFaq = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [getData, setGetData] = useState([]);
  const [showPostFaqConfirm, setShowPostFaqConfirm] = useState(false);
  const [faqType, SetFaqType] = useState("");
  const [postDetails, setPostDetails] = useState({
    title: "",
    tag: "",
    category: "",
    description: "",
  });
  const [content, setContent] = useState(null);
  const [ckDesc, setCkDesc] = useState("");
  const { title, tag, category, description } = postDetails;
  const handelInput = (e) => {
    setPostDetails({
      ...postDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleFaqtype = (event) => {
    SetFaqType(event.target.value);
  };

  const getAllFaqInfo = async () => {
    try {
      const { data } = await faqFormAPi();
      setGetData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    console.log("first");
  };

  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setPostDetails((prev) => {
      return { ...prev, description: newContent };
    });
  };

  const getEditData = async () => {
    try {
      const { data } = await editFaq(userId);
      setPostDetails({
        title: data.data.title,
        tag: data.data.tag,
        category: data.data.category,
      });
      SetFaqType(data.data.faqType);
      setContent(data.data.description);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEditData();
    getAllFaqInfo();
  }, []);

  const updateFaqData = async () => {
    try {
      await updateFaqInfo(userId, {
        ...postDetails,
        faqType: faqType,
        description: content,
      });
      setShowPostFaqConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {content && (
        <EditFaqComp
          handelInput={handelInput}
          heading="Edit faq"
          handleSubmit={handleSubmit}
          postDetails={postDetails}
          title={title}
          tag={tag}
          category={category}
          description={description}
          onChange={onChange}
          handelUpdate={updateFaqData}
          setPostDetails={setPostDetails}
          ckDesc={ckDesc}
          getData={getData}
          faqType={faqType}
          handleFaqtype={handleFaqtype}
          content={content}
          setContent={setContent}
        />
      )}

      {showPostFaqConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/faq/allFaq");
          }}
        ></SweetAlert>
      ) : null}
    </>
  );
};

export default EditFaq;
