import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Button } from "reactstrap";
import FileUploader from "../FileUploader";
import { SketchPicker } from "react-color";
import { Input } from "reactstrap";

const CreateStoriesComp = ({
  createStoryHandle,
  storyImg,
  setStoryImg,
  handelInput,
  formData,
  setFormData,
  title,
  description,
  setThumbnailColor,
  thumbnailColor,
  textColour,
}) => {
  const [openColorBox, setopenColorBox] = useState(false);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Create Story</h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
                // onValidSubmit={() => createMasterClass()}
              >
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="title">Story Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter title"
                        value={title}
                        onChange={handelInput}
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3 ">
                    <FormGroup>
                      <Label htmlFor="Description">Description</Label>
                      <AvField
                        name="description"
                        placeholder="Enter description"
                        value={description}
                        onChange={handelInput}
                        type="textarea"
                        errorMessage="Description is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="description"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FileUploader
                      file={storyImg}
                      setFile={setStoryImg}
                      dragText="Drop media image here"
                      heading="Select Track Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                      setImageColor={setThumbnailColor}
                      imageColor={storyImg && thumbnailColor}
                      isImage
                      imgRatioRangeLow={1.55}
                      imgRatioRangeHigh={1.8}
                      errMsg="Please select the image in ratio of 9:16"
                    />
                  </Col>
                </Row>
                {thumbnailColor && (
                  <Row>
                    <Col md={3}>
                      <Input
                        type="text"
                        onClick={() => setopenColorBox(!openColorBox)}
                        value={thumbnailColor}
                        readOnly
                      />
                      {openColorBox && (
                        <SketchPicker
                          color="#fff"
                          value={thumbnailColor}
                          width="350px"
                          onChangeComplete={(e) => setThumbnailColor(e.hex)}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="textColour">Text Colour (Enter the code without # , eg- 1a2b3c)</Label>
                      <AvField
                        name="textColour"
                        placeholder="Enter textColour"
                        value={textColour}
                        onChange={handelInput}
                        type="text"
                        errorMessage="TextColour is required "
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="TextColour"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={() => createStoryHandle()}
                >
                  Create Story
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default CreateStoriesComp;
