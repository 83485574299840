import AllSeries from "../Views/AllSeries";
import CreateSeries from "../Views/CreateSeries";
import EditSeries from "../Views/EditSeries";
import ViewSeries from "../Views/ViewSeries";

const SeriesRoutes = [
  {
    Component: AllSeries,
    path: '/all',
    exact: true,
    id: 'dashboardSeriesAll',
    routePath: '/dashboard/Series/all',
    name: 'All Series',
  },
  {
    Component: CreateSeries,
    path: '/create',
    exact: true,
    id: 'dashboardSeriesCreate',
    routePath: '/dashboard/Series/create',
    name: 'Create Series',
  },
  {
    Component: EditSeries,
    path:'/edit/:refId',
    exact:true,
    id: 'dashboardSeriesEdit',
  },
  {
    Component: ViewSeries,
    path:'/view/:refId',
    exact:true,
    id: 'dashboardSeriesView',
  },
]

export default SeriesRoutes