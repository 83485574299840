export const editFaqTagData = {
  FAQTAG: {
    heading: "Edit Tag",
    labelName: "Tag Name",
    nameError: "Enter Tag Name",
    labelDescription: "Tag Description",
    descriptionError: "Enter Tag Description",
    createBtn: "Edit Tag",
  },
  FAQCATEGORY: {
    heading: "Edit Category",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Category Description",
    descriptionError: "Enter Category Description",
    createBtn: "Edit Category",
  },

};
