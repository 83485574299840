import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { creatingStates } from "../../Views/Dashboard/Components/Series/constant";
import Select from "react-select";
import { createTagData } from "./CreateTag.Constants";
import {
  createMediaDropdownListApi,
  createTagCatSubCatApi,
} from "./CreateTag.Api";
import FileUploader from "../../Components/FileUploader";
import { uploadFile } from "../../Views/Dashboard/Components/Blogs/others/Uploads/upload.Helper";

const CreateMediaTag = ({
  isOpen,
  toggleModal,
  currentTag,
  populateTag,
  mediaDropdown,
  catImg,
  catMedia,
  tagCreate,
  handleCatTypeChange,
  handleFilterChange,
  catType,
  setCatType,
  filter,
  setFilter,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    mediaIds: [],
    selectedMediaOneValue: "",
    selectedMediaTwoValue: "",
  });
  const [dropDown, setDropDown] = useState([]);
  const [imgUpload, setImgUpload] = useState(null);

  const catTypeOptions = [
    { value: "MIND", label: "MIND" },
    { value: "BODY", label: "BODY" },
  ];

  const filterTypeOptions = [
    { value: "GOALS", label: "GOALS" },
    { value: "TECHNIQUES", label: "TECHNIQUES" },
  ];

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;
  const {
    name,
    description,
    selectedMediaOneValue,
    selectedMediaTwoValue,
    mediaIds,
  } = formData;
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);

  const getMediaDropdownDataCreate = async (params) => {
    console.log(params, "doob k maar ja");
    try {
      const { data } = await createMediaDropdownListApi(params);
      const res = await createMediaDropdownListApi({
        page: 1,
        limit: data.count,
      });
      console.log(res, "maar");
      setPagination({ page: 1, limit: data.count });
      setDropDown(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMediaDropdownDataCreate(pagination);
  }, []);

  const [isCreating, setIsCreating] = useState(false);
  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });
  const { isAlert, alertType, alertText } = alert;

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    if (catMedia === true || tagCreate === true) {
      setFormData({
        name: "",
        description: "",
        mediaIds: [],
      });
    } else {
      setFormData({
        name: "",
        description: "",
        mediaIds: [selectedMediaOneValue, selectedMediaTwoValue],
        // selectedMediaOneValue:"",
        // selectedMediaTwoValue:""
      });
    }
    setAlert({
      isAlert: false,
      alertType: "success",
      alertText: "Created Sucessfully",
    });
    toggleModal();
  };

  const handelCreateTag = async () => {
    setIsCreating(true);
    try {
      if (catImg === true) {
        if (!imgUpload) {
          return setError({
            isError: true,
            errorMessage: "Please select a category image",
            errorType: "warning",
          });
        }
        const thumbnailUrl = await uploadFile(
          imgUpload,
          "CATEGORY-COVER",
          setThumbnailUploadPercentage,
          30
        );
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_MUSIC,
        });
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        if (catMedia === true) {
          const { data } = await createTagCatSubCatApi({
            name,
            description,
            mediaIds: [],
            objectType: currentTag,
            imgAsset: thumbnailUrl,
            catType: catType.value,
            filter: filter.value,
          });
        } else if (tagCreate === true) {
          const { data } = await createTagCatSubCatApi({
            name,
            description,
            mediaIds: [],
            objectType: currentTag,
          });
        } else {
          const { data } = await createTagCatSubCatApi({
            name,
            description,
            mediaIds: [selectedMediaOneValue, selectedMediaTwoValue],
            objectType: currentTag,
            imgAsset: thumbnailUrl,
          });
        }

        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Created Sucessfully",
        });
        setTimeout(() => {
          setAlert({ isAlert: false });
          if (catMedia === true || tagCreate === true) {
            setFormData({
              name: "",
              description: "",
              mediaIds: [],
            });
          } else {
            setFormData({
              name: "",
              description: "",
              mediaIds: [selectedMediaOneValue, selectedMediaTwoValue],
            });
          }
          setIsCreating(false);
          toggleModal();
        }, 1000);
      } else {
        if (catMedia === true) {
          const { data } = await createTagCatSubCatApi({
            name,
            description,
            mediaIds: [],
            objectType: currentTag,
            imgAsset: thumbnailUrl,
          });
        } else if (tagCreate === true) {
          const { data } = await createTagCatSubCatApi({
            name,
            description,
            mediaIds: [],
            objectType: currentTag,
          });
        } else {
          const { data } = await createTagCatSubCatApi({
            name,
            description,
            mediaIds: [selectedMediaOneValue, selectedMediaTwoValue],
            objectType: currentTag,
            imgAsset: thumbnailUrl,
          });
        }
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Created Sucessfully",
        });

        setTimeout(() => {
          setAlert({ isAlert: false });
          setFormData({
            name: "",
            description: "",
            mediaIds: [selectedMediaOneValue, selectedMediaTwoValue],
          });
          setIsCreating(false);
          toggleModal();
        }, 1000);
      }
    } catch (error) {
      console.log(error, "imgerroro");
      setAlert({
        isAlert: true,
        alertType: "danger",
        alertText: "Please provide values for all the fields",
      });
      setIsCreating(false);
    }
  };

  const handleSelectMediaOneInput = (event) => {
    event.preventDefault();
    setFormData({ ...formData, selectedMediaOneValue: event.target.value });
  };
  const handleSelectMediaTwoInput = (event) => {
    event.preventDefault();
    setFormData({ ...formData, selectedMediaTwoValue: event.target.value });
    console.log(event.target.value);
  };

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <AvForm className="needs-validation" onValidSubmit={handelCreateTag}>
        <ModalHeader>{createTagData[currentTag].heading}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">
                  {createTagData[currentTag].labelName}
                </Label>
                <AvField
                  name="name"
                  placeholder={createTagData[currentTag].labelName}
                  type="text"
                  errorMessage={createTagData[currentTag].nameError}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={name}
                  onChange={handelInput}
                  id="name"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">
                  {createTagData[currentTag].labelDescription}
                </Label>
                <AvField
                  name="description"
                  placeholder={createTagData[currentTag].labelDescription}
                  type="text"
                  errorMessage={createTagData[currentTag].descriptionError}
                  className="form-control"
                  value={description}
                  onChange={handelInput}
                  validate={{ required: { value: true } }}
                  id="description"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            {/* {createTagData[currentTag].heading = 'Create Category' && (
              <>
              <Col ls="10">
              <FormGroup>
                <Label htmlFor="catType">CatType</Label>
                <Select
                  name="catType"
                  id="catType"
                  value={catType}
                  onChange={handleCatTypeChange}
                  options = {catTypeOptions}
                />
               
              </FormGroup>
            </Col>
            <Col ls="10">
              <FormGroup>
                <Label htmlFor="filter">Filter</Label>
                <Select
                  name="filter"
                  id="filter"
                  value={filter}
                  onChange={handleFilterChange}
                  options={filterTypeOptions}
                />
                  
              </FormGroup>
            </Col>

              </>
            )} */}

            {catImg === true && (
              <Col md="12">
                <FileUploader
                  file={imgUpload}
                  setFile={setImgUpload}
                  dragText="Drop Cover Image here"
                  heading="Select Cover Image"
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                />
              </Col>
            )}

            {mediaDropdown === true && (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mediaOne">
                      {createTagData[currentTag].mediaOne}
                    </Label>
                    <Input
                      name="tag"
                      placeholder="Select Type"
                      type="select"
                      value={selectedMediaOneValue}
                      onChange={(e) => handleSelectMediaOneInput(e)}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value={"Select Media"}>Select Media One</option>
                      {dropDown?.map((item) => {
                        return (
                          <option
                            disabled={
                              item._id === formData.selectedMediaTwoValue
                            }
                            key={item._id}
                            value={item._id}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mediaTwo">
                      {createTagData[currentTag].mediaTwo}
                    </Label>
                    <Input
                      name="tag"
                      placeholder="Select Type"
                      type="select"
                      value={selectedMediaTwoValue}
                      onChange={(e) => handleSelectMediaTwoInput(e)}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value={"Select Media"}>Select Media Two</option>
                      {dropDown?.map((item) => {
                        return (
                          <option
                            disabled={
                              item._id === formData.selectedMediaOneValue
                            }
                            key={item._id}
                            value={item._id}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          {isAlert && (
            <Alert color={alertType} role="alert">
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
          <Button color="primary" type="submit" disabled={isCreating}>
            {createTagData[currentTag].createBtn}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default CreateMediaTag;
