import React from "react";
import CreateStoriesComp from "../../../../../../Components/CreateStoriesComp";
import { useState } from "react";
// import { createStoryReq } from "";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { createStoryReq } from "../../Story.Apis";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const CreateStories = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    textColour:""
  });
  const [thumbnailColor, setThumbnailColor] = useState(null);
  const [storyImg, setStoryImg] = useState(null);
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const [createStoryConfirm, setCreateStoryConfirm] = useState(false);

  const { title, description,textColour } = formData;

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
 
  const createStoryHandle = async () => {
    try {
      if (!storyImg)
        return setError({
          isError: true,
          errorMessage: "Please select a story image",
          errorType: "warning",
        });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      const storyImgUrl = await uploadFile(
        storyImg,
        "STORY-IMAGE",
        setThumbnailUploadPercentage,
        20
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      await createStoryReq({
        title: title,
        description: description,
        objectType: "STORIES",
        assetURL: storyImgUrl,
        thumbnailColorPalette:thumbnailColor,
        textColour:'#'+ textColour
        
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      setCreateStoryConfirm(true);
    } catch (error) {
      console.log("error");
    }
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
    setThumbnailUploadPercentage(0);
    setContentUploadPercentage(0);
    setError({
      isError: true,
      errorMessage: "Unable to Create Image please try again later",
      errorType: "danger",
    });
  };
  return (
    <>
      <CreateStoriesComp
        createStoryHandle={createStoryHandle}
        storyImg={storyImg}
        setStoryImg={setStoryImg}
        handelInput={handelInput}
        formData={formData}
        setFormData={setFormData}
        title={title}
        description={description}
        thumbnailColor={thumbnailColor}
        setThumbnailColor={setThumbnailColor}
        textColour={textColour}
      />
      {createStoryConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            // history.push("/dashboard/stories/all");
            setCreateStoryConfirm(false);
            window.location.reload();
          }}
        >
          Story has been Created.
        </SweetAlert>
      ) : null}
    </>
  );
};
export default CreateStories;
