import React, { useState, useEffect } from 'react'
import EditSeriesForm from '../../../../../../Components/EditSeriesForm'
import { getByCategoryAPI, editSeriesAPI, getCategoryAPI, getMediaAPI,getAllTags } from '../../Api'
import { creatingStates } from '../../constant'
import { uploadFile } from '../../../Blogs/others/Uploads/upload.Helper'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useParams, useHistory } from 'react-router-dom'
import Loader from '../../../../../../Components/Loader'


const EditSeries = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    mediaType: '',
    mediaData: []
  })

  const { title, description, mediaType, mediaData } = formData;

  const [getAllMediaOptions, setGetAllMediaOptions] = useState([])
  const [getAllCategoryOptions, setGetAllCategoryOptions] = useState([])
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  // const [mediaArray, setMediaArray] = useState([...formData?.mediaData]);
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [seriesImage, setSeriesImage] = useState(null)
  const [previousData, setPreviousData] = useState({})
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [editedData, setEditedData] = useState({})
  const { refId } = useParams()
  const [categoryName, setCategoryName] = useState('')
  const [selectedTags,setSelectedTags] = useState('');
  const [tagsData,setTagsData] = useState([]);

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const history = useHistory()
  const [mediaDataFilled, setMediaDataFilled] = useState([])

  const isFieldsChanged = (curr) => {
    let prev = JSON.stringify(previousData)
    let current = JSON.stringify(curr)
    if (prev !== current) {
      setIsDataUpdated(true)
      return true
    } else if (previousData.imgAsset !== seriesImage) {
      setIsDataUpdated(true)
    } else if (editedData.deletedMedia.length > 0) {
      return true;
    } else {
      setIsDataUpdated(false)
      return false
    }
  }


  useEffect(() => {
    setIsDataUpdated(isFieldsChanged());
  }, [editedData]);

  
  const getAllTagsData = async () => {
    const {data} = await getAllTags();
    console.log(data?.data,'hurraaahhhh');
    setTagsData(data?.data)
  }


  const preFilledCat = async (queryParams) => {
    const { data } = await getByCategoryAPI(refId)
    const res = await getCategoryAPI(queryParams)

    const catId = data?.data?.categoryId
    const catName = res?.data.data
    const temp = catName?.find(ele => ele._id === catId)?.name
  }
  useEffect(() => {
    preFilledCat()
  }, [])

  let debounce
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value
    })
    clearTimeout(debounce)
    debounce = setTimeout(
      isFieldsChanged({
        ...formData,
        [e.target.name]: e.target.value
      }),
      500
    )
  }
  const [newMedia, setNewMedia] = useState('')

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };
  const getMediaData = async (categoryId) => {
    try {
      const { data } = await getMediaAPI(categoryId);
      setNewMedia(data.data)
      setGetAllMediaOptions(data?.data?.map((i) => {
        return {
          key: i._id,
          value: i._id,
          label: i.title
        }
      }))
      const res = await getByCategoryAPI(refId)
      // const temp = data?.data?.filter((item) => {
      //   return res.data.data.mediaData.includes(item._id)}).map(ele => { return { label: ele?.title, value: ele?._id } })
      const temp = res.data?.data?.mediaData.map((ele,idx)=>{ return { label: ele?.title, value: ele?._id } })
      console.log(temp, 'sgsg')
      setMediaDataFilled(temp)
    } catch (error) {
      console.error(error);
    }
  }

  const MediaType = [
    { label: "AUDIO", value: "AUDIO" },
    { label: "VIDEO", value: "VIDEO" }
  ]

  const getCategoryData = async (queryParams) => {
    try {
      const { data } = await getCategoryAPI(queryParams);
      const category = data.data;
      setGetAllCategoryOptions(category);
    } catch (error) {
      console.log(error);
    }
  };
  const getSeriesByCategory = async () => {
    try {
      const { data } = await getByCategoryAPI(refId)
      console.log(data.data, 'editmedia')
      setFormData({
        ...formData,
        title: data?.data?.title,
        description: data?.data?.description,
        category: data?.data?.categoryId,
        mediaType: data?.data?.mediaType,
        mediaData: data?.data?.mediaData
      })
      setSeriesImage(data?.data?.imgAsset)
      setSelectedTags(
        data?.data?.tags?.map((ele) => {
          return {
            key: ele?._id,
            label: ele?.name,
            value: data?.data?.tags,
          };
        })
      )
      setPreviousData({
        ...data?.data,
      })
    } catch (error) {
      console.log(error)
    }
  }
  console.log(previousData, 'editMedia');

  const handleSubmit = () => {
    setShowUnPublishAlert(true)
  }

  useEffect(() => {
    getCategoryData();
    if (formData.category) {
      getMediaData(formData.category);
    }
  }, [formData.category]);


  useEffect(() => {
    getSeriesByCategory()
    getAllTagsData();
  }, [])

  const handleMediaOnChange = (e) => {
    setMediaDataFilled(e)
    const arr = e?.map(i => i?.value)
    setFormData({
      ...formData,
      mediaData: arr
    })
  }

  const getEditSeries = async () => {
    try {
      let SeriesURL = null;
      if (seriesImage && typeof seriesImage !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        SeriesURL = await uploadFile(
          seriesImage,
          seriesImage?.type,
          setThumbnailUploadPercentage,
          30
        )
      }
      else setThumbnailUploadPercentage(30);
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      })
      console.log(formData.mediaData, 'shrey');
      await editSeriesAPI(
        refId,
        {
          title: formData.title,
          description: formData.description,
          mediaData: formData.mediaData,
          mediaType: formData.mediaType,
          categoryId: formData.category,
          imgAsset: SeriesURL,
        }
      )
      console.log(mediaData, 'zxcvbnmz 2')
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });

    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setContentUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Image please try again later",
        errorType: "danger",
      });
    }
  }
  return (
    <div>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <EditSeriesForm
            title={title}
            description={description}
            formData={formData}
            setFormData={setFormData}
            getAllMediaOptions={getAllMediaOptions}
            isDisabled={isDisabled}
            getAllCategoryOptions={getAllCategoryOptions}
            seriesImage={seriesImage}
            setSeriesImage={setSeriesImage}
            MediaType={MediaType}
            mediaType={mediaType}
            mediaData={mediaData}
            setEditedData={setEditedData}
            handleChange={handleChange}
            isDataUpdated={isDataUpdated}
            handleSubmit={handleSubmit}
            categoryName={categoryName}
            mediaDataFilled={mediaDataFilled}
            handleMediaOnChange={handleMediaOnChange}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            tagsData={tagsData}
          />

          {showUnPublishAlert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes,Edit it!"
              reverseButtons={true}
              onConfirm={() => {
                getEditSeries()
                setShowUnPublishAlert();
              }}
              onCancel={() => setShowUnPublishAlert()}
            ></SweetAlert>
          ) : null}

          {showUnPublishConfirm ? (
            <SweetAlert
              success
              title="Edited!"
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                setShowUnPublishAlert()
                setShowUnPublishConfirm()
                history.push('/dashboard/Series/all')
              }}
            >
              Series has been edited
            </SweetAlert>
          ) : null}
        </>
      )}
    </div>
  )
}

export default EditSeries
