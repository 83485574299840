import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUploader from "../../Components/FileUploader";
import { editApi } from "../EditMedia/EditTag.Api";
import { uploadFile } from "../../Views/Dashboard/Components/Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../Views/Dashboard/Components/Series/constant";
import Select from "react-select";

const MediaInfo = ({
  isTagInfoOpen,
  toggleTagInfoModal,
  name,
  description,
  objectType,
  setTagInfo,
  tagInfo,
  mapping,
  imgAsset,
  editid,
  setImgAsset,
  catType,
  setCatType,
  filter,
  setFilter,
  handleCatTypeChange,
  handleFilterChange,
}) => {
  const handleClose = () => {
    toggleTagInfoModal();
  };

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });

  const catTypeOptions = [
    { value: "MIND", label: "MIND" },
    { value: "BODY", label: "BODY" },
  ];

  const filterTypeOptions = [
    { value: "GOALS", label: "GOALS" },
    { value: "TECHNIQUES", label: "TECHNIQUES" },
  ];

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Edited Sucessfully",
  });
  const { isAlert, alertType, alertText } = alert;

  const handelInput = (e) => {
    setTagInfo({
      ...tagInfo,
      [e.target.name]: e.target.value,
    });
  };

  const editCatApi = async () => {
    try {
      let imgAssetURL = null;
      if (imgAsset && typeof imgAsset !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        imgAssetURL = await uploadFile(
          imgAsset,
          "CATEGORY-IMAGE",
          setThumbnailUploadPercentage,
          30
        );
      } else setThumbnailUploadPercentage(30);
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
      await editApi(editid, {
        ...tagInfo,
        objectType: "CATEGORY",
        mediaIds: [],
        imgAsset: imgAssetURL,
        catType: catType.value,
        filter: filter.value,
      });

      setAlert({
        isAlert: true,
        alertType: "success",
        alertText: "Edited Sucessfully",
      });

      setTimeout(() => {
        setAlert({ isAlert: false });
        toggleTagInfoModal();
      }, 1000);
    } catch (error) {
      console.log(error);
      setAlert({
        isAlert: true,
        alertType: "danger",
        alertText: "Unable to Edit",
      });
    }
  };

  return (
    <Modal size="lg" isOpen={isTagInfoOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Tags and Categories Information</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <AvField
                  name="name"
                  type="text"
                  className="form-control"
                  value={name}
                  id="name"
                  onChange={handelInput}
                />
              </FormGroup>
            </Col>
            {mapping === true && (
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="subCategory">Sub Category</Label>
                  <AvField
                    name="subCategory"
                    type="text"
                    className="form-control"
                    value={description}
                    id="subCategory"
                  />
                </FormGroup>
              </Col>
            )}
            {mapping === false && (
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="description">Description</Label>
                  <AvField
                    name="description"
                    type="text"
                    className="form-control"
                    value={description}
                    id="description"
                    onChange={handelInput}
                  />
                </FormGroup>
              </Col>
            )}

            {mapping === false && (
              <Col md="12">
                <FileUploader
                  file={imgAsset}
                  setFile={setImgAsset}
                  dragText="Drop Cover Image here"
                  heading="Select Cover Image"
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                />
              </Col>
            )}

            {mapping === false && (
              <Col>
                <Col ls="10">
                  <FormGroup>
                    <Label htmlFor="catType">CatType</Label>
                    <Select
                      name="catType"
                      id="catType"
                      value={catType}
                      onChange={handleCatTypeChange}
                      options={catTypeOptions}
                    />
                  </FormGroup>
                </Col>
                <Col ls="10">
                  <FormGroup>
                    <Label htmlFor="filter">Filter</Label>
                    <Select
                      name="filter"
                      id="filter"
                      value={filter}
                      onChange={handleFilterChange}
                      options={filterTypeOptions}
                    />
                  </FormGroup>
                </Col>
              </Col>
            )}

            <Col md="12">
              <FormGroup>
                <Label htmlFor="objectType">Type</Label>
                <AvField
                  name="objectType"
                  type="text"
                  className="form-control"
                  value={objectType}
                  id="objectType"
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={editCatApi}
            color="light"
            className="waves-effect"
          >
            Edit
          </Button>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default MediaInfo;
