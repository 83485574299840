import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AllTimeByIdComp from "../../../../../../Components/AllTimeById";
import { getAllSupportAdvisorApi } from "../../../MasterClass/Constants/api";
import { getAllTimeSlotsByAdvsiorId } from "../../Constants/api";
import { AllAdvisorTimeTableHeadingTime } from "../../Constants/constant";

const AdvisorTime = () => {
  const { editTimeID } = useParams();
  const [advisorDataTime, setAdvisorDataTime] = useState([]);
  const [advisorData, setAdvisorData] = useState();
  const [advisorName, setAdvisorName] = useState()

  const getAllAdvisorDataTime = async () => {
    try {
      const { data } = await getAllTimeSlotsByAdvsiorId(editTimeID);
      setAdvisorDataTime(data?.timeSlots);
      console.log(data?.timeSlots, 'timeSlots')
      const res = await getAllSupportAdvisorApi();
      setAdvisorData(res?.data?.data);
      let adId = (data?.timeSlots?.map(i => i?.supportAdvisorId)[0])
      let adName = res?.data?.data
      let adIdName = data?.timeSlots?.length > 0
        ? res?.data?.data?.find((ele) => ele._id === data.timeSlots[0].supportAdvisorId)?.name
        : null;
      adIdName = adIdName || res?.data?.data?.find((ele) => ele._id === editTimeID)?.name || "";
      setAdvisorName(adIdName);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
      getAllAdvisorDataTime();

  }, [editTimeID]);
  return (
    <>
      <AllTimeByIdComp
        tableHeading={AllAdvisorTimeTableHeadingTime}
        tableData={advisorDataTime}
        page={1}
        sizePerPage={advisorDataTime.length}
        totalSize={advisorDataTime.length}
        advisorName={advisorName}
      />
    </>
  );
};

export default AdvisorTime;
