import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import OfferForm from "../../../../../../Components/OffersForm";

const CreateOffer = () => {
  const [plans, setPlans] = useState([]);
  const location = useLocation();
  const [data,setData] = useState({
    heading:"Create Offer",
    offerData:null,
    buttonText:"Create Offer",
    update:false,
  });

  useEffect(()=>{
    if(location.state){
      const d= location.state
      setData({
        ...data,
        heading:d.heading,
        offerData:d.offerData,
        buttonText:d.buttonText,
        update:d.update
      })
    }
  },[location.state])
    
  const {heading,offerData,buttonText,update} = data ;

  return (
    <OfferForm
      plansList={plans}
      pageHeading={heading}
      offerData={offerData}
      buttonText={buttonText}
      update={update}
    />
  );
};

export default CreateOffer;
