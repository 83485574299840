import React, { useState, useEffect } from "react";
import DiscoverCard from "../../../../../Components/DiscoverCard";
import ViewDiscoverModal from "../../../../../Modal/ViewDiscover";

import { getAllDiscoverReq, getOneDiscoverReq } from "../Discover.Apis";

const AllDiscovers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allDiscovers, setAllDiscovers] = useState([]);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [populatedata, setPopulateData] = useState({});

  const getAllDiscovers = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllDiscoverReq();
      setAllDiscovers(data.result);
      setIsLoading(false);
    } catch (error) {
      console.error("\x1b[34m%s\x1b[0m", "AllDiscovers.js error", error);
    }
  };



  useEffect(() => {
    if (allDiscovers.length !== 0) {
      let array = [];
      allDiscovers.forEach((item, i) => {
        array.push({ sNo: i + 1, ...item._source.data, id: item._id })
      });
      setFormattedTableData(array)
    }
  }, [allDiscovers]);


  const toggleModal = async (id) => {
    // console.log(id);
    setIsOpen(!isOpen);
    if (id) {
      const { data } = await getOneDiscoverReq(id);
      const abc = data.result;
      setPopulateData(abc);
    }
  };

  useEffect(() => {
    getAllDiscovers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        {isOpen && (
          <ViewDiscoverModal isOpen={isOpen} toggleModal={toggleModal} isEditable={isEditable} populatedata={populatedata} />
        )}
        <h2 style={{ margin: "0px" }}>All Discovers</h2>
      </div>
      <DiscoverCard
        tableData={formattedTableData}
        tableHeading={"All Discovers"}
        toggleModal={toggleModal}
        isLoading={isLoading}
      />
    </>
  );
};

export default AllDiscovers;
