import { useState, useEffect } from "react";
import EditDiscoverNispandComp from "../../../../../../Components/EditDiscoverNispandComp";
import { useLocation, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  getAllMediaApi,
  getAllSeriesPlaylistApi,
  getDiscoverNispandByIdApi,
  updateDiscoverNispandApi,
} from "../../Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMediaApi } from "../../../Tag/Tag.Apis";

const EditDiscoverNispand = () => {
  const params = useParams();
  const location = useLocation();
  const idval = params;
  const { editID } = useParams()
  const userRefPath = location.state.userRefPath;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [errMsg, setErrMsg] = useState()
  const [seriesData, setSeriesData] = useState();
  const [mediaData, setMediaData] = useState();
  const [playlistData, setPlaylistData] = useState();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [objType, setObjType] = useState();
  const [currOrder, setCurrOrder] = useState()
  const [order, setOrder] = useState();
  const [mediaCount, setMediaCount] = useState();
  const [seriesCount, setSeriesCount] = useState();
  const [playlistCount, setPlaylistCount] = useState();
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [seeAllType, setSeeAllType] = useState({ "key": "1", "label": "DISCOVER", "value": "1" });
  const [category, setCategory] = useState();
  const [categoryType, setCategoryType] = useState();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [rawCatgeory, setRawCatgeory] = useState('')

  const { title, description } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getAllMediaData = async (params) => {
    const { data } = await getAllMediaApi(params);
    setMediaData(data?.data);
    setMediaCount(data?.count);
  };

  const getAllSeriesData = async (params) => {
    const { data } = await getAllSeriesPlaylistApi(params);
    setSeriesData(data?.data);
    setSeriesCount(data?.count);
  };
  const getAllPlaylistData = async (params) => {
    const { data } = await getAllSeriesPlaylistApi(params);
    setPlaylistData(data?.data);
    setPlaylistCount(data?.count);
  };
  const [getCategory, setGetCategory] = useState()

  const getCatList = async (category) => {
    const { data } = await getMediaApi({ objectType: 'CATEGORY', page: 1, limit: 100 })
    setRawCatgeory(data.data)

    setGetCategory(data?.data?.map(item => {
      return {
        key: item._id,
        label: item.name,
        value: item._id,
      }
    }))
    let selectedCategory = data?.data?.find(obj => obj._id === category);
    if (selectedCategory) {
      setCategory({
        key: selectedCategory?._id,
        label: selectedCategory?.name,
        value: selectedCategory?._id,
      })
      updateCategoryTypes({
        key: selectedCategory._id,
        label: selectedCategory.name,
        value: selectedCategory._id,
      }, data.data, false)
    }

  }

  useEffect(() => {
    const getByIdFunc = async () => {
      try {
        const { data } = await getDiscoverNispandByIdApi({
          id: idval.editID,
          userRefPath,
        });

        if (data && data.data) {
          setFormData({
            title: data.data.title,
            description: data.data.description,
          });
          setObjType({
            value: data.data.objectType,
            label: data.data.objectType,
            key: data.data.objectType,
          });
          setCategoryType({
            key: data.data.catType,
            label: data.data.catType,
            value: data.data.catType
          })
          getCatList(data.data.category)
          if (data.data.objectType === "MEDIA") {
            setSelectedMedia(
              data.data.metaIds.map((i) => ({
                value: i._id,
                label: i.title,
                key: i._id,
              }))
            );
          } else if (data.data.objectType === "PLAYLIST") {
            setSelectedPlaylist(
              data.data.metaIds.map((i) => ({
                value: i._id,
                label: i.title,
                key: i._id,
              }))
            );
          } else if (data.data.objectType === "SERIES") {
            setSelectedSeries(
              data.data.metaIds.map((i) => ({
                value: i._id,
                label: i.title,
                key: i._id,
              }))
            );
          }
          setOrder({
            value: data.data.order,
            label: data.data.order,
            key: data.data.order,
          });
          setCurrOrder({
            value: data.data.order,
            label: data.data.order,
            key: data.data.order,
          });
        }
        if (data.data?.seeAllType == '2') {
          setSeeAllType({ "key": "2", "label": "ALL", "value": "2" })
        }
        else {
          setSeeAllType({ "key": "1", "label": "DISCOVER", "value": "1" })
        }
      } catch (error) {
        console.log("Error fetching by ID:", error);
      }
    };

    getByIdFunc();
  }, [idval]);



  useEffect(() => {
    getAllMediaData({ page: 1, limit: mediaCount });
    getAllSeriesData({ type: "SERIES", page: 1, limit: seriesCount });
    getAllPlaylistData({ type: "PLAYLIST", page: 1, limit: playlistCount });
  }, [mediaCount, seriesCount, playlistCount]);

  const formatMultiSelectData = (data) => {
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };
  const [categoryValues, setCategoryValues] = useState()
  const updateCategoryTypes = (value, rawCatgeory, toggle) => {
    const matchedCategory = rawCatgeory.find(obj => obj._id === value.key);
    if (matchedCategory?.catType) {
      setCategoryValues([{
        key: matchedCategory.catType,
        label: matchedCategory.catType,
        value: matchedCategory.catType,
      }])
      if (toggle) {
        setCategoryType({
          key: matchedCategory.catType,
          label: matchedCategory.catType,
          value: matchedCategory.catType,
        })
      }
    }
    else {
      setCategoryValues([{
        key: "MIND",
        label: "MIND",
        value: "MIND",
      },
      {
        key: "BODY",
        label: "BODY",
        value: "BODY",
      }])
      if (toggle) {
        setCategoryType({
          key: "MIND",
          label: "MIND",
          value: "MIND",
        })
      }
    }

  }

  const handleSubmit = async () => {
    try {
      setShowUnPublishAlert(false);
      if (currOrder?.value === order?.value) {
        await updateDiscoverNispandApi(editID, {
          title: formData?.title,
          description: formData?.description,
          objectType: objType?.value,
          seeAllType: seeAllType?.value,
          category: (seeAllType.value == '2' && (objType?.value === "MEDIA" || objType?.value === "SERIES")) ? category.value : null,
          catType: (seeAllType.value == '2' && (objType?.value === "MEDIA" || objType?.value === "SERIES")) ? categoryType.value : null,

          // order: order?.value,
          metaIds:
            objType?.value === "MEDIA"
              ? formatMultiSelectData(selectedMedia)
              : objType?.value === "SERIES"
                ? formatMultiSelectData(selectedSeries)
                : formatMultiSelectData(selectedPlaylist),
        });
      } else {
        await updateDiscoverNispandApi(editID, {
          title: formData?.title,
          description: formData?.description,
          objectType: objType?.value,
          order: order?.value,
          seeAllType: seeAllType?.value,
          category: (seeAllType.value == '2' && (objType?.value === "MEDIA" || objType?.value === "SERIES")) ? category.value : null,
          catType: (seeAllType.value == '2' && (objType?.value === "MEDIA" || objType?.value === "SERIES")) ? categoryType.value : null,

          metaIds:
            objType?.value === "MEDIA"
              ? formatMultiSelectData(selectedMedia)
              : objType?.value === "SERIES"
                ? formatMultiSelectData(selectedSeries)
                : formatMultiSelectData(selectedPlaylist),
        });
      }

      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      setErrMsg(error.response.data.error)
      if (error.response.data.success === false) {
        setModal(true)
      } else (setModal(false))
    }
  };
  console.log(currOrder, 'error.response.data.error');
  return (
    <>
      <EditDiscoverNispandComp
        order={order}
        setOrder={setOrder}
        mediaData={mediaData}
        setMediaData={setMediaData}
        seriesData={seriesData}
        playlistData={playlistData}
        objType={objType}
        setSelectedMedia={setSelectedMedia}
        setSelectedPlaylist={setSelectedPlaylist}
        selectedPlaylist={selectedPlaylist}
        setSelectedSeries={setSelectedSeries}
        selectedSeries={selectedSeries}
        selectedMedia={selectedMedia}
        setObjType={setObjType}
        title={title}
        description={description}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        setShowUnPublishAlert={setShowUnPublishAlert}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        errMsg={errMsg}
        setErrMsg={setErrMsg}
        setSeeAllType={setSeeAllType}
        seeAllType={seeAllType}
        getCategory={getCategory}
        category={category}
        setCategory={setCategory}
        categoryType={categoryType}
        setCategoryType={setCategoryType}
        rawCatgeory={rawCatgeory}
        updateCategoryTypes={updateCategoryTypes}
        categoryValues={categoryValues}

      />
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmit();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            window.location.reload()
          }}
        >
          Discover Nispand has been Updated
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditDiscoverNispand;
