import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import NutriLiveRoutes from "./Constants/routes";

const NutriLiveClass = () => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {NutriLiveRoutes.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/n-live/nutri-clinic/all" />
      </Switch>
    </>
  );
};

export default NutriLiveClass;