import React from 'react'
import YtVideoForm from '../../../../../../Components/YtVideoForm'
import { createYoutubeVideos } from '../../YtVideos.Api'
import { useState } from 'react'
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from 'react-router-dom';


const YoutubeVideos = () => {
  const [formData , setFormData] = useState({
    title:"",
    thumbnailPic:"",
    url:""
  })
  const history = useHistory()  
  const[createAlert,setCreateAlert] = useState(false)
  const[createAlertConfirm,setCreateAlertConfirm] = useState(false)
  const createVideoHandle = async () => {
    try {
     await createYoutubeVideos(formData)
     setCreateAlertConfirm(true)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
    <YtVideoForm 
    createVideoHandle={createVideoHandle}
    formData={formData}
    setFormData={setFormData}
    setCreateAlert={setCreateAlert}
    />

    {createAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Create it!"
          reverseButtons={true}
          onConfirm={() => createVideoHandle()}
          onCancel={() => setCreateAlert(false)}
        ></SweetAlert>
      ) : null}

      {createAlertConfirm?  (
      <SweetAlert
      success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setCreateAlert(false);
            setCreateAlertConfirm(false);
            history.push('/dashboard/ytVideos/all')
          }}
      />):null}
    </>
  )
}

export default YoutubeVideos
