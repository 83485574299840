import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import Table from '../Table';
import { CSVLink } from "react-csv";

const AllNutriLiveComp = ({
  tableData = [],
  pageHeading = "All Nutri Class",
  tableHeading = [],
  handleTablePageChange,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  handleAllExportToCSV
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory()
  const handleUserEditAppointment = (_id) => {
    console.log(_id, 'click edit');
    history.push("/dashboard/n-live/nutri-clinic/all-nutri-user/" + _id);
  };
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>

        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleUserEditAppointment(row._id)}
        />
      </div>
    );
  };

  useEffect(() => {

    tableHeading.push({
      text: "Action",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewContentFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData) {
      var result = tableData && tableData.length > 0 && tableData?.map(function (el, i) {
        var o = {
          ...el,
          name: el.userName,
          date: el.appDate?.slice(0, 10),
          time: el.appTime?.slice(11, 16),

        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                    <CSVLink
                      data={handleAllExportToCSV()}
                      filename={'selected_users.csv'}
                      className="export-csv-btn" >
                      <Button
                        color="success"
                        className="waves-effect waves-light"
                        style={{ width: "100%", cursor: 'pointer' }}

                      // onClick={() => handleUnPublishSleepSessionClick(row._id)}
                      >
                        Export to Csv
                      </Button>
                    </CSVLink>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AllNutriLiveComp