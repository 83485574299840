import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { savePDF } from "@progress/kendo-react-pdf";

import AnalysisCard from "../../../../Components/AnalysisCard";
import PlansAnalysisCard from "../../../../Components/PlansAnalysisCard";
import RevenueAnalyticsCard from "../../../../Components/RevenueAnalyticsCard";
import UsersAnalyticsCard from "../../../../Components/UsersAnalyticsCard";
import DashobardMiniWidgets from "../../../../Components/DashboardMiniWidgets";
import Loader from "../../../../Components/Loader";
import {
  getDashboardDataReq,
  getUserAnalyticsDataReq,
  getRevenueAnalyticsDataReq,
  getDashboardDataByDateReq,
} from "./Dashboard.Apis";
import { getPercentage } from "./Dashboard.helpers";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";

const DashboardStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userGraphData, setUserGraphData] = useState([]);
  const [revenueAnalyticsGraph, setRevenueAnalyticsGraph] = useState([]);
  const [startDate, setStartDate] = useState([""]);
  const [endDate, setEndDate] = useState([""]);
  const [startRangeDate, setStartRangeDate] = useState(0);
  const [endRangeDate, setEndRangeDate] = useState(0);
  const [dashboardData, setDashboardDate] = useState({
    totalUserCount: 0,
    activeUser: 0,
    totalSubscribedUserCount: 0,
    activeSubscriberUserCount: 0,
    inActiveSubscriberUserCount: 0,
    renewedSubscribedUserCount: 0,
    paidSubscriber: 0,
    freeSubscriber: 0,
    totalMusicCount: 0,
    publishMusicCount: 0,
    createdMusicCount: 0,
    deletedMusicCount: 0,
    totalPlans: 0,
    activePlans: 0,
    deletedPlans: 0,
    earnedYesterday: 0,
    earnedLastMonth: 0,
    earnedLastYear: 0,
    plandataWithSoldCount: [],
    totalUserCreatedCountTimeFrame: 0,
    totalSubscribtionExpiringCountTimeFrame: 0,
    totalSubscribtionCreatedCountTimeFrame: 0,
  });
  const {
    totalUserCount,
    activeUser,
    totalSubscribedUserCount,
    activeSubscriberUserCount,
    inActiveSubscriberUserCount,
    renewedSubscribedUserCount,
    paidSubscriber,
    freeSubscriber,
    totalMusicCount,
    publishMusicCount,
    createdMusicCount,
    deletedMusicCount,
    totalPlans,
    activePlans,
    deletedPlans,
    earnedYesterday,
    earnedLastMonth,
    earnedLastYear,
    plandataWithSoldCount,
    totalUserCreatedCountTimeFrame,
    totalSubscribtionExpiringCountTimeFrame,
    totalSubscribtionCreatedCountTimeFrame,
  } = dashboardData;
  const history = useHistory();
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const bodyRef = useRef();

  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getDashboardDataReq();
      setDashboardDate(data.data);
      setUserGraphData(data.data.userGraphData);
      setRevenueAnalyticsGraph(data.data.revenueAnalyticsGraph);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleUserAnalyticsData = async ({ startDate, endDate }) => {
    try {
      const { data } = await getUserAnalyticsDataReq({
        startDate,
        endDate: new Date(endDate.setDate(endDate.getDate() + 1)),
      });
      setUserGraphData(data.data);
    } catch (error) { }
  };

  const handleRevenueAnalyticsData = async ({ startDate, endDate }) => {
    try {
      const { data } = await getRevenueAnalyticsDataReq({
        startDate,
        endDate: new Date(endDate.setDate(endDate.getDate() + 1)),
      });
      setRevenueAnalyticsGraph(data.data);
    } catch (error) { }
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line
  }, []);

  const createPdf = (html) => {
    setIsCreatingPdf(true);
    savePDF(
      html,
      {
        paperSize: "A2",
        landscape: true,
        fileName: "dashboard.pdf",
        margin: 3,
      },
      () => {
        setIsCreatingPdf(false);
      }
    );
  };

  const exportToPdf = () => {
    createPdf(bodyRef.current);
  };

  const handleClavertapRedirection = () => {
    window.open("https://eu1.dashboard.clevertap.com/");
  };

  const handleChange = (data) => {

    if (data && !data[0]) {
      if (startRangeDate != 0 && endRangeDate == 0) {
        endRangeDate == 0 && data && setEndRangeDate(data)
      } else if (startRangeDate == 0 && endRangeDate == 0) {
        startRangeDate == 0 && data && setStartRangeDate(data)
      }
    } else {
      if (data && data[0] && data[1]) {
        data && data[0] && setStartRangeDate(data[0])
        data && data[1] && setEndRangeDate(data[1])
        getDataByDate(data, data[0], data[1]);
      }
    }
  };

  const getDataByDate = async (data, startDate, endDate) => {
    if (data) {
      try {
        const { data } = await getDashboardDataByDateReq(
          startDate = startDate || startRangeDate,
          endDate = endDate || endRangeDate,
        );
        setUserGraphData(data.data.userGraphData);
        setDashboardDate(data.data);
        // setRevenueAnalyticsGraph(data.result.revenueAnalyticsGraph);  // revenue data not required as per abhishek
      } catch (error) { }
    }
  };


  return (
    <div>
      <Row>
        <Col xs={6}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h3 className="mb-0">Dashboard</h3>
          </div>
        </Col>
        <Col xs={6}>
          <div className="page-title-box d-flex align-items-center justify-content-end">
            <div className="d-flex mr-5">
              <FormGroup>
                <Label>Start / End Date</Label>
                <DateRangePicker
                  onOk={() => getDataByDate(false)}
                  onSelect={(data) => handleChange(data)}
                  onChange={(data) => handleChange(data)}
                />
              </FormGroup>
            </div>

            {!isLoading && (
              <Button onClick={exportToPdf} disabled={isCreatingPdf}>
                {isCreatingPdf ? "Creating Pdf..." : "Export Data"}
              </Button>
            )}
            <Button
              onClick={handleClavertapRedirection}
              color="primary"
              className="waves-effect waves-light ml-3"
            >
              Clevertap
            </Button>
          </div>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="pdf-body" ref={bodyRef}>
            <Row>
              <Col cl={8}>
                <Row>
                  <Col sm="4">
                    <DashobardMiniWidgets
                      title="New Users"
                      value={totalUserCreatedCountTimeFrame}
                      icon="ri-user-3-fill"
                    // iconColorClass="text-primary"
                    />
                  </Col>
                  <Col sm="4">
                    <DashobardMiniWidgets
                      title="New Subscriptions"
                      value={totalSubscribtionCreatedCountTimeFrame}
                      icon="fas fa-hourglass-start"
                    // iconColorClass="text-success"
                    />
                  </Col>
                  <Col sm="4">
                    <DashobardMiniWidgets
                      title="Expired Subscriptions"
                      value={totalSubscribtionExpiringCountTimeFrame}
                      icon="fas fa-hourglass-end"
                    // iconColorClass="text-danger"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <UsersAnalyticsCard
                      activeUsers={activeUser}
                      totalUsers={totalUserCount}
                      subscribedUsers={totalSubscribedUserCount}
                      graphData={userGraphData}
                      handleUpdateData={handleUserAnalyticsData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <AnalysisCard
                      heading="Total Subscribtions: "
                      amount={totalSubscribedUserCount}
                      btnText="View More"
                      btnHandleClick={() => history.push("/dashboard/users")}
                      cardColNum={2}
                      customStyle={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      charts={[
                        {
                          subHeading: "Active Subscribers",
                          subAmount: activeSubscriberUserCount,
                          color: "PRIMARY",
                          percent: getPercentage({
                            total: totalSubscribedUserCount,
                            amount: activeSubscriberUserCount,
                          }),
                        },
                        {
                          subHeading: "InActive Subscribers",
                          subAmount: inActiveSubscriberUserCount,
                          color: "DANGER",
                          percent: getPercentage({
                            total: totalSubscribedUserCount,
                            amount: inActiveSubscriberUserCount,
                          }),
                        },
                        {
                          subHeading: "Renewed Subscribers",
                          subAmount: renewedSubscribedUserCount,
                          color: "SUCCESS",
                          percent: getPercentage({
                            total: totalSubscribedUserCount,
                            amount: renewedSubscribedUserCount,
                          }),
                        },
                        {
                          subHeading: "Paid Subscribers",
                          subAmount: paidSubscriber,
                          color: "YELLO",
                          percent: getPercentage({
                            total: totalSubscribedUserCount,
                            amount: paidSubscriber,
                          }),
                        },
                        {
                          subHeading: "Free Subscribers",
                          subAmount: freeSubscriber,
                          color: "CRIMSON_RED",
                          percent: getPercentage({
                            total: totalSubscribedUserCount,
                            amount: freeSubscriber,
                          }),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={4}>
                <AnalysisCard
                  heading="Total Music: "
                  amount={totalMusicCount}
                  btnText="Add Media"
                  btnHandleClick={() => history.push("/dashboard/media/create")}
                  charts={[
                    {
                      subHeading: "Published Media",
                      subAmount: publishMusicCount,
                      color: "SUCCESS",
                      percent: getPercentage({
                        total: totalMusicCount,
                        amount: publishMusicCount,
                      }),
                      clickHandler: () => history.push("/dashboard/media/all"),
                    },
                    {
                      subHeading: "Created Media",
                      subAmount: createdMusicCount,
                      color: "PRIMARY",
                      percent: getPercentage({
                        total: totalMusicCount,
                        amount: createdMusicCount,
                      }),
                      clickHandler: () => history.push("/dashboard/media/all"),
                    },
                    {
                      subHeading: "Deleted Music",
                      subAmount: deletedMusicCount,
                      color: "DANGER",
                      percent: getPercentage({
                        total: totalMusicCount,
                        amount: deletedMusicCount,
                      }),
                      clickHandler: () => history.push("/dashboard/media/bin"),
                    },
                  ]}
                />

                <PlansAnalysisCard
                  heading="Plans States"
                  data={plandataWithSoldCount}
                />

                <AnalysisCard
                  heading="Total Plans: "
                  amount={totalPlans}
                  btnText="Add Plan"
                  btnHandleClick={() => history.push("/dashboard/plan/create")}
                  charts={[
                    {
                      subHeading: "Active Plans",
                      subAmount: activePlans,
                      color: "SUCCESS",
                      percent: getPercentage({
                        total: totalPlans,
                        amount: activePlans,
                      }),
                      clickHandler: () => history.push("/dashboard/plan/all"),
                    },
                    {
                      subHeading: "Deleted Categories",
                      subAmount: deletedPlans,
                      color: "DANGER",
                      percent: getPercentage({
                        total: totalPlans,
                        amount: deletedPlans,
                      }),
                      clickHandler: () => history.push("/dashboard/plan/bin"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardStats;
