export const PendingBusinessHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Name",
    dataField: "firstName",
  },
  {
    text: "Email",
    dataField: "email",
  },
  {
    text:"Phone Number",
    dataField:"phoneNumber"
  },
  {
    text: "Status",
    dataField: "approveStatus",
  },
  // {
  //   text: "Result",
  //   dataField: "result",
  // },
];
export const ApprovedBusinessHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Name",
    dataField: "firstName",
  },
  {
    text: "Email",
    dataField: "email",
  },
  {
    text:"Phone Number",
    dataField:"phoneNumber"
  },
  {
    text: "Status",
    dataField: "approveStatus",
  },
  // {
  //   text: "Result",
  //   dataField: "result",
  // },
];
export const RejectedBusinessHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Name",
    dataField: "firstName",
  },
  {
    text: "Email",
    dataField: "email",
  },
  {
    text:"Phone Number",
    dataField:"phoneNumber"
  },
  {
    text: "Status",
    dataField: "approveStatus",
  },
  // {
  //   text: "Result",
  //   dataField: "result",
  // },
];
export const allUsersHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "First name",
    dataField: "firstName"
  },
  {
    text: "Company name",
    dataField: 'companyName'
  },
  {
    text: 'Email',
    dataField: "email"
  },
  {
    text: 'ContactNo',
    dataField: 'phoneNumber'
  },
  
]

