import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";
import { useHistory } from "react-router-dom";

const SleepSessionCard = ({
  heading = "Published questions",
  tableData = [],
  tableHeading = [],
  handlePublishSleepSessionClick
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();
  
  const handleEditSessionClick = (questionId) => {
    history.push("/dashboard/sleep-sessions/editquestions/" + questionId);
  };
  const viewSeriesFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handlePublishSleepSessionClick(row._id)}
        >
          UnPublish
        </Button>
      </div>
    );
  };

  useEffect(() => {

    tableHeading.push({
      text: "Un-Publish Sleep Sessions",
      dataField: "unpublish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewSeriesFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      const sortedData = [...tableData];
      sortedData.sort((a, b) => a.orderNo - b.orderNo);
      setRowData(sortedData);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SleepSessionCard;
