import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Input,
  ListGroup,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { editTagData } from "./EditTag.Constants";
import { editApi, editMappingApi } from "./EditTag.Api";
import { getMediaTags } from "../../Views/Dashboard/Components/MediaProperties/Tag.Apis";
import { createMediaDropdownListApi } from "./EditTag.Api";
import Select from "react-select";
import FileUploader from "../../Components/FileUploader";
import { uploadFile } from "../../Views/Dashboard/Components/Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../Views/Dashboard/Components/Series/constant";

const EditMedia = ({
  isOpen,
  toggleModal,
  currentTag,
  currentTagID,
  currentArray,
  populateTag,
  setIsEditModalOpen,
  isEditModalOpen,
  mediaDropdown,
  mappingDropDown,
  catImg,
  catType,
  setCatType,
  filter,
  setFilter,
  handleCatTypeChange,
  handleFilterChange,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    mediaIds: [],
    refIds: [],
    subData: [],
  });
  const [dropDown, setDropDown] = useState([]);
  const [subDropDown, setSubDropDown] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [count, setCount] = useState("");
  const [subCount, setSubCount] = useState("");
  const [categ, setCateg] = useState("");

  const getMediaDropdownData = async (params) => {
    try {
      const { data } = await createMediaDropdownListApi(params);
      setSubCount(data.count);

      setDropDown(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const catTypeOptions = [
    { value: "MIND", label: "MIND" },
    { value: "BODY", label: "BODY" },
  ];

  const filterTypeOptions = [
    { value: "GOALS", label: "GOALS" },
    { value: "TECHNIQUES", label: "TECHNIQUES" },
  ];

  useEffect(() => {
    getMediaDropdownData({ page: 1, limit: subCount });
  }, [subCount]);

  const getSubCategoryDropdown = async (queryParams) => {
    try {
      const { data } = await getMediaTags(queryParams);
      setSubDropDown(data.data);
      setCount(data.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubCategoryDropdown({
      objectType: "SUBCATEGORY",
      page: 1,
      limit: count,
    });
  }, [count]);

  const subCategoriesData = subDropDown.map((item) => {
    return {
      key: item._id,
      label: item.name,
      value: item.name,
    };
  });

  const { name, description, mediaIds, refIds } = formData;
  const [isEditing, setIsEditing] = useState(false);
  const [isNotEdited, setIsNotEdited] = useState(true);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Edited Sucessfully",
  });
  const { isAlert, alertType, alertText } = alert;

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [imgAsset, setImgAsset] = useState(null);

  useEffect(() => {
    if (currentArray) {
      const tagData = currentArray?.filter(
        ({ _id }) => currentTagID === _id
      )[0];
      console.log(tagData, "hdhdhhdhdh");
      setImgAsset(tagData?.imgAsset);
      setSubCat(
        tagData?.subCategories?.map((item) => {
          return {
            key: item?._id,
            value: item?.name,
            label: item?.name,
          };
        })
      );
      console.log(
        currentArray?.filter(({ _id }) => currentTagID === _id),
        "filter"
      );
      if (tagData) {
        setFormData({
          name: tagData.name,
          description: tagData.description,
          mediaIds: [tagData?.mediaIds?.[0], tagData?.mediaIds?.[1]],
          refIds: [],
          subData: [subCat],
        }),
          setImgAsset(tagData?.imgAsset);
      }
    }
  }, [currentArray, currentTagID]);

  const handelInput = (e) => {
    setIsNotEdited(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    toggleModal();
  };
  const handleSubCat = (e) => {
    setIsNotEdited(false);
    setSubCat(e);
  };

  const handelEditTag = async () => {
    setIsEditing(true);
    try {
      if (catImg === true) {
        let imgAssetURL = null;
        if (imgAsset && typeof imgAsset !== "string") {
          setCreating({
            isCreating: true,
            creatingStage: creatingStates.UPLOADING_IMAGE,
          });
          imgAssetURL = await uploadFile(
            imgAsset,
            "CATEGORY-IMAGE",
            setThumbnailUploadPercentage,
            30
          );
        } else setThumbnailUploadPercentage(30);
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.CREATING_MUSIC,
        });

        if (mappingDropDown === true) {
          const { data } = await editMappingApi(currentTagID, {
            refIds: subCat ? subCat.map((item) => item.key) : [],
          });
        } else {
          const { data } = await editApi(currentTagID, {
            ...formData,
            objectType: currentTag,
            mediaIds: formData.mediaIds,
            refIds: [],
            imgAsset: imgAssetURL,
            // catType: catType.value,
            // filter: filter.value,
          });
        }
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Edited Sucessfully",
        });
        if (
          mappingDropDown &&
          mappingDropDown.length > 0 &&
          mappingDropDown !== true
        ) {
          populateTag({
            tag: data?.data,
            tagType: currentTag,
          });
        }
        setIsNotEdited(true);
        setTimeout(() => {
          setAlert({ isAlert: false });
          window.location.reload();
          setIsEditModalOpen(!isEditModalOpen);
        }, 1000);
      } else {
        if (mappingDropDown === true) {
          const { data } = await editMappingApi(currentTagID, {
            refIds: subCat ? subCat.map((item) => item.key) : [],
          });
        } else {
          const { data } = await editApi(currentTagID, {
            ...formData,
            objectType: currentTag,
            mediaIds: formData.mediaIds,
            refIds: [],
            // catType: catType.value,
            // filter: filter.value,
          });
        }
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Edited Sucessfully",
        });
        if (
          mappingDropDown &&
          mappingDropDown.length > 0 &&
          mappingDropDown !== true
        ) {
          populateTag({
            tag: data?.data,
            tagType: currentTag,
          });
        }
        setIsNotEdited(true);
        setTimeout(() => {
          setAlert({ isAlert: false });
          setIsEditModalOpen(!isEditModalOpen);
        }, 1000);
      }
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: "danger",
        alertText: "Unable to Edit",
      });
      console.error(error);
    }
    setIsEditing(false);
  };

  const handleSelectMediaOneInput = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      mediaIds: [event.target.value, formData.mediaIds[1]],
    });
  };
  const handleSelectMediaTwoInput = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      mediaIds: [formData.mediaIds[0], event.target.value],
    });
    console.log(event.target.value, "event.target.value");
  };
  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <AvForm className="needs-validation" onValidSubmit={handelEditTag}>
        <ModalHeader>{editTagData[currentTag].heading}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">
                  {editTagData[currentTag].labelName}
                </Label>
                <AvField
                  name="name"
                  placeholder={editTagData[currentTag].labelName}
                  type="text"
                  errorMessage={editTagData[currentTag].nameError}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={name}
                  onChange={handelInput}
                  id="name"
                  disabled={isEditing || mappingDropDown === true}
                />
              </FormGroup>
            </Col>
            {mappingDropDown === false && (
              <>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="description">
                      {editTagData[currentTag].labelDescription}
                    </Label>
                    <AvField
                      name="description"
                      placeholder={editTagData[currentTag].labelDescription}
                      type="text"
                      errorMessage={editTagData[currentTag].descriptionError}
                      className="form-control"
                      value={description}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="description"
                      disabled={isEditing}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            {catImg === true && (
              <Col md="12">
                <FileUploader
                  file={imgAsset}
                  setFile={setImgAsset}
                  dragText="Drop Cover Image here"
                  heading="Select Cover Image"
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                />
              </Col>
            )}
            {editTagData[currentTag].heading === "Edit Category" && (
              <>
                <Col ls="10">
                  <FormGroup>
                    <Label htmlFor="catType">CatType</Label>
                    <Select
                      name="catType"
                      id="catType"
                      value={catType}
                      onChange={handleCatTypeChange}
                      options={catTypeOptions}
                    />
                  </FormGroup>
                </Col>
                <Col ls="10">
                  <FormGroup>
                    <Label htmlFor="filter">Filter</Label>
                    <Select
                      name="filter"
                      id="filter"
                      value={filter}
                      onChange={handleFilterChange}
                      options={filterTypeOptions}
                    />
                  </FormGroup>
                </Col>
              </>
            )}

            {mappingDropDown === true && (
              <>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="mediaOne">Select SubCategory</Label>
                    <Select
                      value={subCat}
                      isMulti={true}
                      onChange={(e) => handleSubCat(e)}
                      options={subCategoriesData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        subCategoriesData && subCategoriesData.length === 0
                          ? "Loading..."
                          : "Select SubCategory"
                      }
                    />
                  </FormGroup>
                </Col>
              </>
            )}

            {mediaDropdown === true && (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mediaOne">
                      {editTagData[currentTag].mediaOne}
                    </Label>
                    <Input
                      name="tag"
                      placeholder="Select Type"
                      type="select"
                      value={formData.mediaIds[0]}
                      onChange={(e) => handleSelectMediaOneInput(e)}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value={"Select Media"}>Select Media One</option>
                      {dropDown?.map((item) => {
                        return (
                          <option
                            disabled={item._id === formData.mediaIds[1]}
                            selected={item._id === formData.mediaIds[0]}
                            key={item._id}
                            value={item._id}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mediaTwo">
                      {editTagData[currentTag].mediaTwo}
                    </Label>
                    <Input
                      name="tag"
                      placeholder="Select Type"
                      type="select"
                      value={formData.mediaIds[1]}
                      onChange={(e) => handleSelectMediaTwoInput(e)}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value={"Select Media"}>Select Media Two</option>
                      {dropDown?.map((item) => {
                        return (
                          <option
                            disabled={item._id === formData.mediaIds[0]}
                            selected={item._id === formData.mediaIds[1]}
                            key={item._id}
                            value={item._id}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          {isAlert && (
            <Alert color={alertType} role="alert">
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
          <Button
            color="primary"
            type="submit"
            // disabled={isEditing || isNotEdited}
            disabled={isEditing}
          >
            {editTagData[currentTag].createBtn}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default EditMedia;
