import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { ProgramRoutes } from './Constants/routes';

const ProgramsAll = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
      {ProgramRoutes.map(({ path, Component, exact, id }) => (
           <Route
             path={`${match.url}${path}`}
             exact={exact}
             component={Component}
             key={id}
           />
         ))}
         <Redirect to="/dashboard/programs" />
      </Switch>
    </>
  )
}

export default ProgramsAll


// const Series = () => {
//   const match = useRouteMatch();
//   return (
//     <>
//       <Switch>
//         <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
//         {SeriesRoutes.map(({ path, Component, exact, id }) => (
//           <Route
//             path={`${match.url}${path}`}
//             exact={exact}
//             component={Component}
//             key={id}
//           />
//         ))}
//         <Redirect to="/dashboard/business" />
//       </Switch>
//     </>
//   );
// };

// export default Series;

