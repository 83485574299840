export const AllAdvisorTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'name',
  },
  {
    text: 'Designation',
    dataField: 'designation',
  }
]