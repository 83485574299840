import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";

const SeriesCard = ({
  tableData = [],
  tableHeading = [],
  handleViewSeries,
  handleEditSeries,
  handlePublishSeries,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
}) => {
  const [rowData, setRowData] = useState([]);
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
          <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewSeries(row._id)}
        />
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditSeries(row._id)}
        />
      </div>
    );
  };

  const viewSeriesFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handlePublishSeries(row._id)}
        >
          UnPublish
        </Button>
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewContentFormatter,
    });
    tableHeading.push({
      text: "Un-Publish Series",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewSeriesFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = {
          ...el,
          name: el.title,
          description: el.excerpt?.replace(/<\/?p>/g, ""),
        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Published Series</h3>
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SeriesCard;

