import axios from "axios";

import baseDomain, { mediaTagCatAndSubCatAPI } from "../../Constants/apiRoutes";

export const createTag = (data) => {
  return axios.post(`${baseDomain}${tagAPI}`, data);
};

export const createTagCatSubCatApi = (data) => {
  return axios.post(`${baseDomain}${mediaTagCatAndSubCatAPI}`, data);
};

export const createMediaDropdownListApi =({page, limit})=>{
  return axios.get(`${baseDomain}/media/getAllAudioVideo?page=${page}&limit=${limit}`); 
}