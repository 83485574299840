import React from 'react'
import { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
const FaqInfo = ({topic,category,title,isFaqInfoOpen,toggleFaqInfoModal}) => {
  
  const handleClose = () => {
    toggleFaqInfoModal();
  };

  return (
    <>
    <Modal size="lg" isOpen={isFaqInfoOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Faq Information</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">Topic</Label>
                <AvField
                  name="name"
                  type="text"
                  className="form-control"
                  value={topic}
                  id="name"
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">
                  Category
                </Label>
                <AvField
                  name="description"
                  type="text"
                  className="form-control"
                  value={category}
                  id="description"
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="objectType">
                  Title
                </Label>
                <AvField
                  name="objectType"
                  type="text"
                  className="form-control"
                  value={title}
                  id="objectType"
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
    
    </>
  )
}

export default FaqInfo
