export const headingSettings = {
  // "/auth/register": {
  //   heading: "Register account",
  //   subHead: "Get your free NextgenIot Solutions account now.",
  //   redirectLink: "/auth/login",
  //   redirectText: "Login",
  //   bottomMessage: "Already have an account ?",
  // },
  "/auth/login": {
    heading: "Welcome Back!",
    subHead: "Sign in to continue to NexGen IoT Solutions.",
    // redirectLink: "/auth/register",
    // redirectText: "Register",
    // bottomMessage: "Don't have an account ?",
  },
  // "/auth/forgot-password": {
  //   heading: "Reset Password",
  //   subHead: "Reset your password.",
  //   redirectLink: "/auth/login",
  //   redirectText: "Login",
  //   bottomMessage: "Remember password ?",
  // },
};
