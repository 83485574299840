import React, { useState, useEffect } from "react";
import MindForm from "../../../../../../Components/MindForm";
import { useParams, useHistory } from "react-router-dom";
import {
  allCategories,
  getAllMedia,
  editMindData,
  mediaitems,
  updateMind,
  getAllDataByCategoriesAndSubcategories,
  getAllCategoryImages,
  getAllTags,
  getAllTagsData,
  getAllDataTags,
} from "../../Mind.Api";
// import { uploadFile } from "../../../Blogs/others/Uploads/Helper";
import SweetAlert from "react-bootstrap-sweetalert";
const Mind = () => {
  const { tabId } = useParams();
  const history = useHistory();
  const [categoryValue, setCategoryValue] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [mediaData3, setMediaData3] = useState([]);
  const [mediaData4, setMediaData4] = useState([]);
  const [categoryData2, setCategoryData2] = useState([]);
  const [mediaData2, setMediaData2] = useState([]);
  const [otherCategory, setOtherCategory] = useState([]);
  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const [allSubCategoriesData, setAllSubCategoriesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [otherCat1, setOtherCat1] = useState(null);
  const [otherCat2, setOtherCat2] = useState(null);
  const [otherCat3, setOtherCat3] = useState(null);
  const [otherCat4, setOtherCat4] = useState(null);
  const [otherCat5, setOtherCat5] = useState(null);
  const [otherCat6, setOtherCat6] = useState(null);
  const [otherCat7, setOtherCat7] = useState(null);
  const [otherCat8, setOtherCat8] = useState(null);
  const [otherCat9, setOtherCat9] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [img4, setImg4] = useState(null);
  const [img5, setImg5] = useState(null);
  const [img6, setImg6] = useState(null);
  const [img7, setImg7] = useState(null);
  const [img8, setImg8] = useState(null);
  const [img9, setImg9] = useState(null);
  const [allMediaOptions, setAllMediaOptions] = useState([]);
  const [catImages, setCatImages] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [otCat, setOtCat] = useState([]);
  const [otImages, setOtImages] = useState([]);

  const getDataByCategories = async () => {
    try {
      const { data } = await getAllDataByCategoriesAndSubcategories();
      setAllCategoriesData(data.data);
    } catch (error) {
      console.log("error");
    }
  };
  const getDataTags = async () => {
    try {
      const { data } = await getAllDataTags();
      setTags(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const selectedTagIds = selectedTags?.map((tag) => tag.value);
  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const handleOtChange = (selectedOptions) => {
    setOtCat(selectedOptions);
    console.log(otCat, "hurrahhhhhhhhhh");
  };

  const getMediaUnderCategory = async (catId) => {
    try {
      const { data } = await mediaitems(catId);
      return data.data;
    } catch (error) {
      console.log("error");
      return [];
    }
  };
  const filterOptions = (selectedOptions) => {
    const selectedCategoryIds = selectedOptions.map((option) => option.value);
    return allCategoriesData.filter(
      (option) => !selectedCategoryIds.includes(option.value)
    );
  };
  console.log();

  useEffect(() => {
    getDataByCategories();
    getDataTags();
  }, []);

  useEffect(() => {
    if (tags?.length) editMindTabs();
  }, [tags]);

  const editMindTabs = async () => {
    try {
      const { data } = await editMindData(tabId);
      setFilterName(data.data.filter);
      const categories = data.data.category;
      const otherCategories = data.data.otherCategories;
      setOtherCategory(data.data.category);
      let tagsData = data.data.tags;
      const otherCatOptions = otherCategories.map((otherCat) => ({
        value: otherCat.catId._id,
        label: otherCat.catId.name,
        assetImage: otherCat.assetImg,
      }));
      // console.log(otherCategories,'assseeeetttttttImage')
      const categoryDataOptions = categories.map((category) => ({
        value: category.catId._id,
        label: category.catId.name,
      }));
      const otherCatImages = otherCategories.map(
        (otherCat) => otherCat.assetImg
      );
      const matchedOptions = tagsData.map((tagId) => {
        return { value: tagId._id, label: tagId.name };
      });

      setSelectedTags(matchedOptions);
      setOtCat(otherCatOptions);
      setOtImages(otherCatImages);

      // setOtherCat1([otherCatOptions[0]]);
      // setImg1(otherCatImages[0]);

      // setOtherCat2([otherCatOptions[1]]);
      // setImg2(otherCatImages[1]);

      // setOtherCat3([otherCatOptions[2]]);
      // setImg3(otherCatImages[2]);

      // setOtherCat4([otherCatOptions[3]]);
      // setImg4(otherCatImages[3]);

      // setOtherCat5([otherCatOptions[4]]);
      // setImg5(otherCatImages[4]);

      // setOtherCat6([otherCatOptions[5]]);
      // setImg6(otherCatImages[5]);

      // setOtherCat7([otherCatOptions[6]]);
      // setImg7(otherCatImages[6]);

      // setOtherCat8([otherCatOptions[7]]);
      // setImg8(otherCatImages[7]);

      // setOtherCat9([otherCatOptions[8]]);
      // setImg9(otherCatImages[8]);

      setCategoryData([categoryDataOptions[0]]);
      setMediaData([
        {
          value: categories[0].mediaIds[0]._id,
          label: categories[0].mediaIds[0].title,
        },
      ]);
      setMediaData3([
        {
          value: categories[0].mediaIds[1]._id,
          label: categories[0].mediaIds[1].title,
        },
      ]);
      setCategoryData2([categoryDataOptions[1]]);
      setMediaData2([
        {
          value: categories[1].mediaIds[0]._id,
          label: categories[1].mediaIds[0].title,
        },
      ]);
      setMediaData4([
        {
          value: categories[1].mediaIds[1]._id,
          label: categories[1].mediaIds[1].title,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = async (selectedOption) => {
    setCategoryData([selectedOption]);

    const catId = selectedOption.value;

    try {
      const options = await getMediaUnderCategory(catId);
      setAllMediaOptions(options);
      setMediaData([]);
      setMediaData3([]);
    } catch (error) {
      console.log("error");
    }
  };

  const handleSelectChange2 = async (selectedOption2) => {
    setCategoryData2([selectedOption2]);
    const catId = selectedOption2.value;

    try {
      const options = await getMediaUnderCategory(catId);
      setAllMediaOptions(options);
      setMediaData2([]);
      setMediaData4([]);
    } catch (error) {
      console.log("error");
    }
  };

  const formatMultiSelectData = (data) => {
    if (!data) return; // Return an empty array if data is falsy

    // Check if data is an array
    if (Array.isArray(data)) {
      // If data is an array, map over it to extract the ids
      return data.map(({ value }) => value);
    } else {
      // If data is a single value, extract the id directly
      return data.value;
    }
  };
  console.log(categoryData, "harmeeetttttt");

  const handleUpdate = async () => {
    try {
      const { data } = await updateMind(tabId, {
        filter: filterName,
        category: [
          {
            catId: formatMultiSelectData(categoryData),
            mediaIds: [
              ...formatMultiSelectData(mediaData),
              ...formatMultiSelectData(mediaData3),
            ],
          },
          {
            catId: formatMultiSelectData(categoryData2),
            mediaIds: [
              formatMultiSelectData(mediaData2),
              formatMultiSelectData(mediaData4),
            ],
          },
        ],
        otherCategories: otCat.map((cat) => ({
          catId: cat.value,
          assetImg: cat.assetImage,
        })),

        tags: selectedTagIds,
      });
      setIsUpdated(true);
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div>
      <MindForm
        pageHeading={filterName == "GOALS" ? "GOALS" : "HEALTHCARE"}
        handleSubmit={""}
        handleUpdate={handleUpdate}
        createBtnText="Update"
        tags={tags}
        setTags={setTags}
        selectedTags={selectedTags}
        handleTagChange={handleTagChange}
        otCat={otCat}
        handleOtChange={handleOtChange}
        categoryData={categoryData}
        categoriesData={categoryData}
        mediaData={mediaData}
        setMediaData={setMediaData}
        setCategoryData={setCategoryData}
        handleSelectChange={handleSelectChange}
        handleSelectChange2={handleSelectChange2}
        itemData={itemData}
        setMediaData3={setMediaData3}
        categoryData2={categoryData2}
        mediaData2={mediaData2}
        setMediaData2={setMediaData2}
        setCategoryData2={setCategoryData2}
        selectedOption2={selectedOption2}
        categoryValue={categoryValue}
        mediaData3={mediaData3}
        mediaData4={mediaData4}
        setMediaData4={setMediaData4}
        otherCategory={otherCategory}
        allCategoriesData={allCategoriesData}
        selectedOption={selectedOption}
        // otherCat1={otherCat1}
        // otherCat2={otherCat2}
        // otherCat3={otherCat3}
        // otherCat4={otherCat4}
        // otherCat5={otherCat5}
        // otherCat6={otherCat6}
        // otherCat7={otherCat7}
        // otherCat8={otherCat8}
        // otherCat9={otherCat9}
        // handleSelectOtherCat1={handleSelectOtherCat1}
        // handleSelectOtherCat2={handleSelectOtherCat2}
        // handleSelectOtherCat3={handleSelectOtherCat3}
        // handleSelectOtherCat4={handleSelectOtherCat4}
        // handleSelectOtherCat5={handleSelectOtherCat5}
        // handleSelectOtherCat6={handleSelectOtherCat6}
        // handleSelectOtherCat7={handleSelectOtherCat7}
        // handleSelectOtherCat8={handleSelectOtherCat8}
        // handleSelectOtherCat9={handleSelectOtherCat9}
        filterOptions={filterOptions}
        img1={img1}
        setImg1={setImg1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
        allMediaOptions={allMediaOptions}
        handleCatData={getDataByCategories}
      />

      {isUpdated ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/Mind/mindtab");
          }}
        ></SweetAlert>
      ) : null}
    </div>
  );
};

export default Mind;
