import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { createTagData } from "./CreateTag.Constants";
import { createTag } from "./CreateTag.Api";

const CreateTag = ({ isOpen, toggleModal, currentTag, populateTag }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const { name, description } = formData;

  const [isCreating, setIsCreating] = useState(false);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });
  const { isAlert, alertType, alertText } = alert;

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setFormData({
      name: "",
      description: "",
    });
    setAlert({
      isAlert: false,
      alertType: "success",
      alertText: "Created Sucessfully",
    });
    toggleModal();
  };

  const handelCreateTag = async () => {
    setIsCreating(true);
    try {
      const { data } = await createTag({
        ...formData,
        objectType: currentTag,
      });
      setAlert({
        isAlert: true,
        alertType: "success",
        alertText: "Created Sucessfully",
      });

      populateTag({
        tag: data.data,
        tagType: currentTag,
      });
      setTimeout(() => {
        setAlert({ isAlert: false });
        setFormData({
          name: "",
          description: "",
        });
        setIsCreating(false);
        toggleModal();
      }, 1000);
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: "danger",
        alertText:
          (error && error.response.data && error.response.data.error) ||
          "Unable to Create",
      });
      setIsCreating(false);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <AvForm className="needs-validation" onValidSubmit={handelCreateTag}>
        <ModalHeader>{createTagData[currentTag].heading}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">
                  {createTagData[currentTag].labelName}
                </Label>
                <AvField
                  name="name"
                  placeholder={createTagData[currentTag].labelName}
                  type="text"
                  errorMessage={createTagData[currentTag].nameError}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={name}
                  onChange={handelInput}
                  id="name"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">
                  {createTagData[currentTag].labelDescription}
                </Label>
                <AvField
                  name="description"
                  placeholder={createTagData[currentTag].labelDescription}
                  type="text"
                  errorMessage={createTagData[currentTag].descriptionError}
                  className="form-control"
                  value={description}
                  onChange={handelInput}
                  validate={{ required: { value: true } }}
                  id="description"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
          </Row>
          {isAlert && (
            <Alert color={alertType} role="alert">
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
          <Button color="primary" type="submit" disabled={isCreating}>
            {createTagData[currentTag].createBtn}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default CreateTag;
