import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import QuotesCard from "../../../../../../Components/QuotesCard";

import {
  // getQuote,
  getQuoteBin,
  restoreQuote,
} from "../../Quotes.Apis";
import { quoteHeading } from "../../Quotes.Constants";

const QuotesBin = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreID, setRestoreID] = useState("");
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  });
  const { currentPage, totalSize } = paginationValue;

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);

  // Restore Tags
  const handleRestoreClick = (id) => {
    setRestoreID(id);
    setShowRestoreAlert(true);
  };

  const restoreTagAction = async () => {
    await restoreQuote(restoreID);
    const filteredQuotes = quotes.filter(({ _id }) => _id !== restoreID);
    setQuotes(filteredQuotes);
    setRestoreID("");
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  // Get initial  data
  const getData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getQuoteBin(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setQuotes(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  return (
    <>
      <QuotesCard
        openModel={toggleCreateModel}
        tableData={quotes}
        tableHeading={quoteHeading}
        handleRestoreTag={handleRestoreClick}
        setQuotes={setQuotes}
        allQuotes={false}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore it!"
          reverseButtons={true}
          onConfirm={restoreTagAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
          }}
        >
          Quote has been Restored
        </SweetAlert>
      ) : null}
    </>
  );
};

export default QuotesBin;
