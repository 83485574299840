import AllTags from "../Components/AllTags";
import TagsBin from "../Components/TagsBin";

export const TagsRoutes = [
  {
    Component: AllTags,
    path: "/all",
    exact: true,
    id: "dashboardTagsAll",
    routePath: "/dashboard/tag/all",
    name: "All Tags",
  },
  {
    Component: TagsBin,
    path: "/bin",
    exact: true,
    id: "dashboardTagsBin",
    routePath: "/dashboard/tag/bin",
    name: "Tags Bin",
  },
];