import React from "react";
import ProgramsCard from "../../../../../../Components/AllProgramsCard";
import { AllProgramsTableHeading } from "../../allPrograms.constants";
import { useState, useEffect } from "react";
import {
  getAllPrograms,
  getAllProgramsById,
  updatePrograms,
} from "../../programs.Apis";

const AllPrograms = () => {
  const [progData, setProgData] = useState([]);

  const getAllProgramsData = async () => {
    try {
      const { data } = await getAllPrograms();
      setProgData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProgramsData();
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <h2 style={{ margin: "0px" }}>All Programs</h2>
      </div>

      {<ProgramsCard 
        tableData={progData}
        tableHeading={AllProgramsTableHeading}
      />}
    </>
  );
};

export default AllPrograms;
