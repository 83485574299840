import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import DeletedMusic from "../../../../../../Components/DeletedMusic";
import { CreatedMusicTableHeading } from "../../constant";
import { getMusicBin , restoreMusic} from "../../api";
import { useHistory } from "react-router-dom";

const VideoBin = () => {
  const [musics, setMusics] = useState([]);
  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreID, setRestoreID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  });
  const { currentPage, totalSize } = paginationValue;

  const history = useHistory();

  // Get all music data
  const getMusicData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getMusicBin(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setMusics(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMusicData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const handleViewMusicClick = (musicID) => {
    history.push("/dashboard/media/view/" + musicID);
  };

  const handleRestoreMusicClick = (musicID) => {
    setRestoreID(musicID);
    setShowRestoreAlert(true);
  };

  // const handleTablePageChange = (type, { page, sizePerPage }) =>{
  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getMusicData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const restoreMusicAction = async () => {
    await restoreMusic(restoreID);
    const filteredMusics = musics.filter(({ _id }) => _id !== restoreID);
    setMusics(filteredMusics);
    setRestoreID("");
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  return (
    <>
      <DeletedMusic
        tableData={musics}
        tableHeading={CreatedMusicTableHeading}
        handleViewMusic={handleViewMusicClick}
        handleRestoreMusic={handleRestoreMusicClick}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore it!"
          reverseButtons={true}
          onConfirm={restoreMusicAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
          }}
        >
          File has been Restored.
        </SweetAlert>
      ) : null}
    </>
  );
};

export default VideoBin;
