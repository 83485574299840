import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import { getFormattedData, colorsArray } from "./PlanAnalysysCard.helpers";

import PlanAnalysisCardGraph from "./Components/PlanAnalysisCardGraph";

const SalesAnalytics = ({ heading, data }) => {
  const [graphData, setGraphData] = useState({
    series: [],
    labels: [],
    planCount: [],
  });

  const { series, labels, planCount } = graphData;

  useEffect(() => {
    if (data.length !== 0) {
      const formattedData = getFormattedData(data);
      setGraphData(formattedData);
    }
  }, [data]);

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">{heading}</h4>
        {data && data.length !== 0 ? (
          <>
            <PlanAnalysisCardGraph
              data={data}
              series={series}
              labels={labels}
            />

            <Row>
              {labels.map((name, i) => (
                <Col xs={4}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate" style={{whiteSpace: 'unset'}}>
                      <i
                        className="mdi mdi-circle font-size-10 mr-1"
                        style={{ color: colorsArray[i] }}
                      ></i>
                      {name}
                    </p>
                    <h5>{planCount[i]}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <h4>No Data Found</h4>
        )}
      </CardBody>
    </Card>
  );
};

export default SalesAnalytics;
